import React from 'react';
import { IconProps } from './IconInterface';

const DoctorIcon = ({ width = 24, height = 24, color = '#000' }: IconProps) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_2204_56998)">
				<path
					d="M11 12C12.2124 12 13.3752 11.5259 14.2325 10.682C15.0898 9.83807 15.5714 8.69347 15.5714 7.5C15.5714 6.30653 15.0898 5.16193 14.2325 4.31802C13.3752 3.47411 12.2124 3 11 3C9.78758 3 8.62482 3.47411 7.76751 4.31802C6.9102 5.16193 6.42857 6.30653 6.42857 7.5C6.42857 8.69347 6.9102 9.83807 7.76751 10.682C8.62482 11.5259 9.78758 12 11 12ZM7.57143 13.9406C4.92857 14.7035 3 17.1082 3 19.9559C3 20.5324 3.475 21 4.06071 21H17.9393C18.525 21 19 20.5324 19 19.9559C19 17.1082 17.0714 14.7035 14.4286 13.9406V15.7266C15.4143 15.9762 16.1429 16.8586 16.1429 17.9062V19.3125C16.1429 19.6219 15.8857 19.875 15.5714 19.875H15C14.6857 19.875 14.4286 19.6219 14.4286 19.3125C14.4286 19.0031 14.6857 18.75 15 18.75V17.9062C15 17.284 14.4893 16.7812 13.8571 16.7812C13.225 16.7812 12.7143 17.284 12.7143 17.9062V18.75C13.0286 18.75 13.2857 19.0031 13.2857 19.3125C13.2857 19.6219 13.0286 19.875 12.7143 19.875H12.1429C11.8286 19.875 11.5714 19.6219 11.5714 19.3125V17.9062C11.5714 16.8586 12.3 15.9762 13.2857 15.7266V13.7191C13.0714 13.698 12.8536 13.6875 12.6321 13.6875H9.36786C9.14643 13.6875 8.92857 13.698 8.71429 13.7191V16.0184C9.53929 16.2609 10.1429 17.0133 10.1429 17.9062C10.1429 18.9926 9.24643 19.875 8.14286 19.875C7.03929 19.875 6.14286 18.9926 6.14286 17.9062C6.14286 17.0133 6.74643 16.2609 7.57143 16.0184V13.9406ZM8.14286 18.75C8.37019 18.75 8.5882 18.6611 8.74895 18.5029C8.90969 18.3446 9 18.13 9 17.9062C9 17.6825 8.90969 17.4679 8.74895 17.3096C8.5882 17.1514 8.37019 17.0625 8.14286 17.0625C7.91553 17.0625 7.69751 17.1514 7.53677 17.3096C7.37602 17.4679 7.28571 17.6825 7.28571 17.9062C7.28571 18.13 7.37602 18.3446 7.53677 18.5029C7.69751 18.6611 7.91553 18.75 8.14286 18.75Z"
					fill={color}
				/>
			</g>
			<path
				d="M18.25 1C18.4489 1 18.6397 1.07902 18.7803 1.21967C18.921 1.36032 19 1.55109 19 1.75V2.5H19.75C19.9489 2.5 20.1397 2.57902 20.2803 2.71967C20.421 2.86032 20.5 3.05109 20.5 3.25C20.5 3.44891 20.421 3.63968 20.2803 3.78033C20.1397 3.92098 19.9489 4 19.75 4H19V4.75C19 4.94891 18.921 5.13968 18.7803 5.28033C18.6397 5.42098 18.4489 5.5 18.25 5.5C18.0511 5.5 17.8603 5.42098 17.7197 5.28033C17.579 5.13968 17.5 4.94891 17.5 4.75V4H16.75C16.5511 4 16.3603 3.92098 16.2197 3.78033C16.079 3.63968 16 3.44891 16 3.25C16 3.05109 16.079 2.86032 16.2197 2.71967C16.3603 2.57902 16.5511 2.5 16.75 2.5H17.5V1.75C17.5 1.55109 17.579 1.36032 17.7197 1.21967C17.8603 1.07902 18.0511 1 18.25 1Z"
				fill={color}
			/>
			<defs>
				<clipPath id="clip0_2204_56998">
					<rect
						width="16"
						height="18"
						fill="white"
						transform="translate(3 3)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default DoctorIcon;
