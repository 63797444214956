import React from 'react';
import { IconProps } from './IconInterface';

const HidrationIcon = ({
	color = '#000000',
	width = 24,
	height = 24,
}: IconProps) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M8.8 22H15.2C15.6943 22.0003 16.1711 21.8175 16.5386 21.487C16.906 21.1564 17.1381 20.7015 17.19 20.21L18.0009 12.5C17.0006 13.3333 14.5 15 12 12.5C9.96397 10.464 6.6 11.395 6 13L6.8 20.21C6.85207 20.7032 7.08556 21.1595 7.45514 21.4903C7.82472 21.8211 8.30402 22.0027 8.8 22Z"
				fill={color}
			/>
			<path
				d="M18.0009 12.5L19 3H5L6 13M18.0009 12.5L17.19 20.21C17.1381 20.7015 16.906 21.1564 16.5386 21.487C16.1711 21.8175 15.6943 22.0003 15.2 22H8.8C8.30402 22.0027 7.82472 21.8211 7.45514 21.4903C7.08556 21.1595 6.85207 20.7032 6.8 20.21L6 13M18.0009 12.5C17.0006 13.3333 14.5 15 12 12.5C9.96397 10.464 6.6 11.395 6 13"
				stroke={color}
				stroke-width="2"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
};

export default HidrationIcon;
