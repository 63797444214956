import React, { ReactNode, useMemo } from 'react';
import { StyledRow } from '../../../../../../common/styledCommonComponents';
import StyledText from '../../../../../../common/components/Text';
import { useTheme } from 'styled-components';
import PersonIcon from '../../../../../../assets/PersonIcon';
import BloodPressureIcon from '../../../../../../assets/BloodPressureIcon';
import BeatsPerMinuteIcon from '../../../../../../assets/BeatsPerMinuteIcon';

export type BiometricDataType = 'height' | 'weight' | 'SYS' | 'DIA' | 'cardiac';

interface BiometricDataProps {
	type: BiometricDataType;
	data: {
		id: string;
		status: string;
		startDate: string;
		endDate: string;
		questionAnswers: {
			id: string;
			answer: string;
			question: string;
			questionId: string;
		}[];
	};
}

export const BiometricDataField = ({ type, data }: BiometricDataProps) => {
	const theme = useTheme();

	const categories: Record<
		BiometricDataType,
		{ questionId: string; unit: string; icon: ReactNode; title: string }
	> = useMemo(() => {
		return {
			height: {
				unit: ' cm',
				questionId: 'b9b9de06-5ef4-11ee-8c99-0242ac120002',
				icon: (
					<PersonIcon width={16} height={16} color={theme.primary500} />
				),
				title: 'Altura',
			},
			weight: {
				unit: ' kg',
				questionId: 'b9b9e4be-5ef4-11ee-8c99-0242ac120002',
				icon: (
					<PersonIcon width={16} height={16} color={theme.primary500} />
				),
				title: 'Peso',
			},
			SYS: {
				unit: ' mmHg',
				questionId: 'b9b9e644-5ef4-11ee-8c99-0242ac120002',
				icon: (
					<BloodPressureIcon
						width={16}
						height={16}
						color={theme.primary500}
					/>
				),
				title: 'Presión arterial SYS',
			},
			DIA: {
				unit: ' mmHg',
				questionId: 'b9b9e770-5ef4-11ee-8c99-0242ac120002',
				icon: (
					<BloodPressureIcon
						width={16}
						height={16}
						color={theme.primary500}
					/>
				),
				title: 'Presión arterial DIA',
			},
			cardiac: {
				unit: ' latidos por minuto',
				questionId: 'b9b9e888-5ef4-11ee-8c99-0242ac120002',
				icon: (
					<BeatsPerMinuteIcon
						width={16}
						height={16}
						color={theme.primary500}
					/>
				),
				title: 'Frecuencia cardíaca',
			},
		};
	}, []);

	return (
		<StyledRow
			css={{
				padding: '8px',
				justifyContent: 'space-between',
				alignItems: 'center',
				alignSelf: 'stretch',
				borderBottom:
					type === 'cardiac' ? 'none' : `1px solid ${theme.gray100}`,
			}}
		>
			<StyledRow
				css={{
					alignItems: 'center',
					gap: '8px',
				}}
			>
				<StyledRow
					css={{
						height: '30px',
						width: '30px',
						alignItems: 'center',
						justifyContent: 'center',
						background: theme.primary200,
						borderRadius: '50%',
					}}
				>
					{categories[type].icon}
				</StyledRow>
				<StyledText variant="body1Bold">
					{categories[type].title}
				</StyledText>
			</StyledRow>
			{data.questionAnswers.length > 0 &&
			data?.questionAnswers?.find(
				(answer: any) =>
					answer?.questionId === categories[type]?.questionId,
			)
				? data?.questionAnswers?.find(
						(answer: any) =>
							answer?.questionId === categories[type]?.questionId,
				  )?.answer + categories[type]?.unit
				: 'No se registró datos'}
		</StyledRow>
	);
};
