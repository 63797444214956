import React from 'react';

const IconStomach = (props) => {
	return (
		<svg
			width="30"
			height="30"
			viewBox="0 0 30 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle cx="15" cy="15" r="15" fill="#E2EAFF" />
			<path
				d="M10.3079 22.4141H7.82516L7.27344 19.9313V17.1727L8.65275 15.2417H10.5838L13.0665 15.7934L14.17 15.2417L15.2734 14.4141L15.8252 13.5865V12.7589L14.9976 11.6555L14.4459 10.552L14.17 8.89685L14.4459 6.96582H17.2045L16.9286 8.89685L17.2045 10.552L17.4803 10.8279L18.0321 11.3796H18.8596L19.6872 10.8279L20.7907 11.1038L21.8941 11.6555L22.7217 12.4831L22.9976 13.8624L22.7217 15.2417L21.6183 18.2762L20.239 19.6555L18.3079 21.0348L15.5493 21.5865L13.3424 21.0348L10.8596 18.0003L10.0321 18.2762C9.36999 18.4969 9.7562 21.3106 10.3079 22.4141Z"
				fill="#5784F7"
			/>
			<path
				d="M14.5881 6.96582C14.1299 8.77896 14.1299 10.1258 14.5881 11.006C15.2754 12.326 16.8672 13.0557 14.8176 14.8164C12.768 16.5775 10.6901 15.0997 9.40702 15.0997C8.12434 15.0997 7 16.5685 7 18.7553C7 20.2131 7.26389 21.4327 7.79088 22.4141"
				stroke="#E2EAFF"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M17.3756 6.96582C16.7989 9.13224 16.9302 10.5063 17.7698 11.0881C19.0295 11.9601 19.126 10.1384 21.4402 11.2222C23.7544 12.306 23.1529 16.0848 21.8215 18.03C20.4906 19.9749 17.6489 21.9405 14.9841 21.601C12.3194 21.2616 11.3285 17.9504 10.9856 17.9504C10.6426 17.9504 9.91791 17.9605 9.77625 19.3314C9.6818 20.2452 9.95032 21.273 10.5806 22.4141"
				stroke="#E2EAFF"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M19.4434 16.418C19.2362 17.0317 18.8859 17.5106 18.393 17.8555C17.9001 18.2004 17.2745 18.4033 16.5156 18.4649"
				stroke="#E2EAFF"
				strokeWidth="2"
				strokeLinecap="round"
			/>
		</svg>
	);
};

export default IconStomach;
