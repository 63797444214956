import React from 'react';
import { IconProps } from './IconInterface';

const ChevronIcon = ({
	color = '#000000',
	width = 24,
	height = 24,
}: IconProps) => {
	return (
		<svg
			height={height}
			width={width}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 24 24"
		>
			<g
				xmlns="http://www.w3.org/2000/svg"
				transform="matrix(-1 0 0 -1 24 24)"
			>
				<g id="Variants=chevron-down">
					<path
						id="Vector"
						d="M17.5481 8.39977L11.9928 13.7027L6.43758 8.39977C5.87919 7.86674 4.97718 7.86674 4.41879 8.39977C3.8604 8.9328 3.8604 9.79385 4.41879 10.3269L10.9906 16.6002C11.549 17.1333 12.451 17.1333 13.0094 16.6002L19.5812 10.3269C20.1396 9.79385 20.1396 8.9328 19.5812 8.39977C19.0228 7.88041 18.1065 7.86674 17.5481 8.39977Z"
						fill={color}
					/>
				</g>
			</g>
		</svg>
	);
};
export default ChevronIcon;
