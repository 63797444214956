import React from 'react';
import { IconProps } from './IconInterface';

const BiomarkerIcon = ({
	width = 24,
	height = 24,
	color = '#171717',
}: IconProps) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g id="Variants=biomark">
				<path
					id="heart.text.square.fill"
					d="M6.81648 20H17.1835C19.0412 20 20 19.0412 20 17.2092V6.7908C20 4.9588 19.0412 4 17.1835 4H6.81648C4.96736 4 4 4.95024 4 6.7908V17.2092C4 19.0412 4.96736 20 6.81648 20ZM8.49438 7.10754C8.96522 7.10754 9.31621 7.38149 9.51311 7.7496C9.71001 7.38149 10.0696 7.10754 10.5147 7.10754C11.2167 7.10754 11.7389 7.65543 11.7389 8.40877C11.7389 9.57303 10.4805 10.5918 9.74425 11.0369C9.6672 11.0797 9.59016 11.1311 9.52167 11.1311C9.46174 11.1311 9.36758 11.0883 9.29053 11.0369C8.57143 10.5404 7.28732 9.57303 7.28732 8.40877C7.28732 7.65543 7.80952 7.10754 8.49438 7.10754ZM7.85233 13.6394C7.52702 13.6394 7.28732 13.3911 7.28732 13.0744C7.28732 12.7662 7.52702 12.535 7.85233 12.535H16.1562C16.473 12.535 16.7127 12.7662 16.7127 13.0744C16.7127 13.3911 16.473 13.6394 16.1562 13.6394H7.85233ZM7.85233 16.4901C7.52702 16.4901 7.28732 16.2504 7.28732 15.9422C7.28732 15.634 7.52702 15.3858 7.85233 15.3858H13.9989C14.3157 15.3858 14.5554 15.634 14.5554 15.9422C14.5554 16.2504 14.3157 16.4901 13.9989 16.4901H7.85233Z"
					fill={color}
				/>
			</g>
		</svg>
	);
};

export default BiomarkerIcon;
