import React, { useMemo } from 'react';
import {
	StyledColumn,
	StyledRow,
} from '../../../../common/styledCommonComponents';
import Dropdown from '../../../../common/components/Dropdown';
import RemoveButton from '../RemoveButton';
import { useOncoDispatch, useOncoSelector } from '../../../../hooks/redux-hook';
import {
	BiomarkerInterface,
	removeAdditionalField,
	removeBiomarker,
	updateBiomarker,
	valuesBiomarker,
} from '../../../../redux/slices/validationSlice';
import {
	useGetBiomarkersQuery,
	useGetEvaluationsQuery,
	useGetSamplesQuery,
} from '../../../../redux/api/validateFormApi';
import TextInputField from '../../../../common/components/TextInputField';

interface BiomarkerContainerProps {
	id: string;
}

const BiomarkerContainer = ({ id }: BiomarkerContainerProps) => {
	const dispatch = useOncoDispatch();
	const errors = useOncoSelector((state) => state.validationSlice.errors)?.[
		id
	];

	const errorBiomarker: any = useMemo(() => {
		if (!errors) return undefined;
		let errorToReturn = {};
		errors?.properties?.forEach((proper) => {
			errorToReturn = {
				...errorToReturn,
				[proper.key]: proper,
			};
		});
		return errorToReturn;
	}, [errors, id]);

	const biomarker: BiomarkerInterface = useOncoSelector((state) =>
		valuesBiomarker(state, { id }),
	);
	const primaryTumor = useOncoSelector(
		(state) => state.validationSlice.values.primaryTumor,
	);
	const { values, auxiliarData } = useOncoSelector(
		(state) => state.validationSlice,
	);

	const primaryTumorId = auxiliarData
		? Object.entries(auxiliarData.primaryTumor).find(([id, tumor]) => {
				return tumor === primaryTumor || id === primaryTumor;
		  })?.[0]
		: null;

	const { data: sampleOptions, isLoading: loadingSamples } =
		useGetSamplesQuery('', {
			selectFromResult: ({ data, error, isLoading }) => {
				if (error || !data || data.length === 0) {
					return { data, error, isLoading };
				}
				let aux: any = {};
				data.forEach(({ id, value }: any) => (aux[id] = value));
				return { data: aux, error, isLoading };
			},
		});

	const { data: biomarkerOptions, isLoading: loadingBiomarkers } =
		useGetBiomarkersQuery(primaryTumorId, {
			skip: !primaryTumorId || primaryTumorId === 'no_value',
			refetchOnMountOrArgChange: true,
			selectFromResult: ({ data, error, isLoading }) => {
				if (error || !data || data.length === 0) {
					return { data, error, isLoading };
				}
				let aux: any = {};
				data.forEach(({ id, value }: any) => (aux[id] = value));
				return { data: aux, error, isLoading };
			},
		});

	const { data: evaluationsOptions, isLoading: loadingEvaluations } =
		useGetEvaluationsQuery(biomarker.biomarkerId, {
			skip: !biomarker.biomarkerId || biomarker.biomarkerId === 'no_value',
			refetchOnMountOrArgChange: true,
			selectFromResult: ({ data, error, isLoading }) => {
				if (error || !data || data.length === 0) {
					return { data, error, isLoading };
				}
				let aux: any = {};
				data.forEach(({ id, value }: any) => (aux[id] = value));
				return { data: aux, error, isLoading };
			},
		});

	const handleRemoveBiomarker = () => {
		dispatch(removeBiomarker({ id }));
		dispatch(removeAdditionalField({ id }));
	};

	const handleOnChange = (name: string, value: string) => {
		dispatch(
			updateBiomarker({
				id,
				key: name as keyof typeof biomarker,
				value,
			}),
		);
	};

	let isTextfield = false;

	if (biomarkerOptions) {
		if (biomarkerOptions[biomarker.biomarkerId]) {
			isTextfield =
				biomarkerOptions[biomarker.biomarkerId].includes('%') ||
				biomarkerOptions[biomarker.biomarkerId].includes('PDL1');
		}
	}

	const isEditable =
		primaryTumorId &&
		primaryTumorId !== 'no_value' &&
		(auxiliarData?.additionalFields.some(
			(field: { id: string }) => field.id === id,
		) ||
			values.status === 'WAITING');

	return (
		<StyledRow css={{ width: '100%', alignItems: 'flex-end', gap: 16 }}>
			<StyledColumn css={{ width: '100%', gap: 16 }}>
				<StyledRow css={{ gap: 32, alignItems: 'flex-end' }}>
					<Dropdown
						value={biomarker.sampleId}
						label={'Muestra'}
						onChange={handleOnChange}
						name={'sampleId'}
						options={sampleOptions}
						error={errorBiomarker?.sampleId?.error?.[0] ?? false}
						disabled={!isEditable}
						required
					/>
					<Dropdown
						value={biomarker.biomarkerId}
						label={'Biomarcador'}
						onChange={handleOnChange}
						name={'biomarkerId'}
						options={biomarkerOptions}
						error={errorBiomarker?.biomarkerId?.error?.[0] ?? false}
						required
						disabled={!isEditable || !biomarkerOptions}
						css={{
							minWidth: 300,
						}}
					/>
					{!isTextfield ? (
						<Dropdown
							value={biomarker.evaluationId}
							label={'Evaluacion'}
							onChange={handleOnChange}
							name={'evaluationId'}
							error={errorBiomarker?.evaluationId?.error?.[0] ?? false}
							disabled={
								!isEditable ||
								!biomarker.biomarkerId ||
								biomarker.biomarkerId === 'no_value'
							}
							options={evaluationsOptions}
							required
						/>
					) : (
						<TextInputField
							type={'number'}
							value={
								biomarker.evaluationId === 'no_value'
									? ''
									: biomarker.evaluationId
							}
							label={'Porcentaje del PDL'}
							onChange={handleOnChange}
							name={'evaluationId'}
							error={errorBiomarker?.value?.error?.[0] ?? false}
							placeholder={'%'}
							disabled={
								!isEditable ||
								!biomarker.biomarkerId ||
								biomarker.biomarkerId === 'no_value'
							}
							required
						/>
					)}
				</StyledRow>
			</StyledColumn>
			{isEditable && (
				<RemoveButton
					onClick={handleRemoveBiomarker}
					css={{ marginBottom: 23 }}
				/>
			)}
		</StyledRow>
	);
};

export default BiomarkerContainer;
