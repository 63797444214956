import React from 'react';
import Navbar from './index';
import { StyledBox, StyledScreen } from '../../common/styledCommonComponents';
import { Outlet } from 'react-router-dom';
import { withModal } from '../HOC/withModal';
import { Feedback } from '../Feedback';

const Wrapper = () => {
	return (
		<StyledBox
			css={{
				flexDirection: 'row',
				display: 'flex',
				width: '100vw',
				maxHeight: '100vh',
			}}
		>
			<Navbar />
			<StyledScreen>
				<Outlet />
				<Feedback />
			</StyledScreen>
		</StyledBox>
	);
};

export default withModal(Wrapper);
