import React from 'react';
import HeaderValidationForm from '../components/HeaderValidationForm';
import { StyledColumn } from '../../../common/styledCommonComponents';
import { useOncoDispatch, useOncoSelector } from '../../../hooks/redux-hook';
import BiomarkerContainer from '../components/BiomarkerContainer';
import Button from '../../../common/components/Button';
import PlusCircleIcon from '../../../assets/PlusCircleIcon';
import { addBiomarker } from '../../../redux/slices/validationSlice';
import BiomarkerIcon from '../../../assets/BiomarkerIcon';

const BiomarkerForm = () => {
	const biomarkers = useOncoSelector(
		(state) => state.validationSlice.values.biomarkers,
	);
	const isEditable = useOncoSelector(
		(state) => state.validationSlice.isEditable,
	);
	const dispatch = useOncoDispatch();
	const primaryTumor = useOncoSelector(
		(state) => state.validationSlice.values.primaryTumor,
	);

	const handleAddNewBiomarker = () => {
		dispatch(addBiomarker());
	};
	return (
		<StyledColumn>
			<HeaderValidationForm title={'Biomarcadores'} icon={BiomarkerIcon} />
			<StyledColumn css={{ padding: 32, gap: 16 }}>
				{biomarkers.map((biomarker) => (
					<BiomarkerContainer id={biomarker.id} />
				))}
				{
					<Button
						css={{ width: '100%' }}
						onClick={handleAddNewBiomarker}
						variant={'outlined'}
						size={'medium'}
						icon={PlusCircleIcon}
						disabled={!primaryTumor || primaryTumor === 'no_value'}
					>
						Agregar biomarcador
					</Button>
				}
			</StyledColumn>
		</StyledColumn>
	);
};

export default BiomarkerForm;
