import styled, {
	css as styledComponentCss,
	DefaultTheme,
} from 'styled-components';
import { CustomCssProps } from '../../styledCommonComponents';

export const InputVariantStyle = (theme: DefaultTheme) => ({
	default: {
		backgroundColor: theme.white,
		border: `1px solid ${theme.gray200}`,
	},
	focus: {
		backgroundColor: theme.white,
		border: `1px solid ${theme.gray200}`,
		outline: `1px solid ${theme.primary200}`,
	},
	disabled: {
		backgroundColor: theme.gray50,
		border: `1px solid ${theme.gray200}`,
		color: theme.gray400,
	},
	error: {
		backgroundColor: theme.white,
		outline: `1px solid ${theme.oncoRed}`,
	},
});

export interface StyledInputProps extends CustomCssProps {
	disabled?: boolean;
	error?: boolean;
}

export const StyledInput = styled.input<StyledInputProps>`
	width: 100%;
	display: flex;
	padding: 12px 16px;
	margin: 0;
	border-style: none;
	font-size: 16px;
	box-sizing: border-box;
	border-radius: 8px;
	font-family: Inter, sans-serif;
	color: ${(props) => props.theme.black};
	${(props) => InputVariantStyle(props.theme).default};

	${(props) => props.disabled && InputVariantStyle(props.theme).disabled};
	${(props) => props.error && InputVariantStyle(props.theme).error};

	&:focus {
		${(props) => InputVariantStyle(props.theme).focus};
	}
	${(props) => styledComponentCss`${props.css}`};

	&::placeholder {
		color: ${(props) => props.theme.oncoGrey300};
	}
`;
