import React from 'react';

const DoubleChevronRightIcon = ({
	color,
	width,
	height,
}: {
	color: string;
	width: number;
	height: number;
}) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M12.3998 18.8599C11.8667 18.3269 11.8667 17.4658 12.3998 16.9328L17.7027 11.6298L12.3998 6.32689C11.8667 5.79386 11.8667 4.93281 12.3998 4.39978C12.9328 3.86675 13.7938 3.86675 14.3269 4.39978L20.6002 10.6731C21.1333 11.2062 21.1333 12.0672 20.6002 12.6002L14.3269 18.8736C13.8075 19.3929 12.9328 19.3929 12.3998 18.8599Z"
				fill={color}
			/>
			<path
				d="M4.39977 18.8599C3.86674 18.3269 3.86674 17.4658 4.39977 16.9328L9.70273 11.6298L4.39977 6.32689C3.86674 5.79386 3.86674 4.93281 4.39977 4.39978C4.9328 3.86675 5.79385 3.86675 6.32688 4.39978L12.6002 10.6731C13.1333 11.2062 13.1333 12.0672 12.6002 12.6002L6.32688 18.8736C5.80752 19.3929 4.9328 19.3929 4.39977 18.8599Z"
				fill={color}
			/>
		</svg>
	);
};
export default DoubleChevronRightIcon;
