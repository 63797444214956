import React, { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import {
	CapitalizeText,
	getProfileImageFromName,
	ProfileConfigButton,
} from '../../utils/utils';
import { StyledBox } from '../../common/styledCommonComponents';
import ProfileButton from '../../pages/PatientProfile/components/ProfileButtons';
import { useParams } from 'react-router-dom';
import { useLazyGetPatientDataQuery } from '../../redux/api/patientApi';
import { StyledCircularProgress } from '../CustomCircularProgress/styles';
import { StyledH5 } from '../../common/components/Text/styles';
import { PatientProfileDetails } from './components/PatientProfileDetails';
import { PatientStatus, statusMap } from './components/PatientStatus';
import { ButtonVariant } from '../../common/components/Button';
import { useOncoDispatch, useOncoSelector } from '../../hooks/redux-hook';
import { setMedicalStatus } from '../../redux/slices/utilsSlice';

const PatientProfileCard = () => {
	const theme = useTheme();
	const [age, setAge] = useState(new Date());
	const [birthday, setBirthday] = useState<number>(0);
	const { patientId } = useParams();
	const [refetchPatientData, { data: dataPatient, isSuccess, isLoading }] =
		useLazyGetPatientDataQuery();
	const { medicalStatus } = useOncoSelector((state: any) => state.utilsSlice);
	const dispatch = useOncoDispatch();

	const genderValue = (sex: 'MALE' | 'FEMALE') => {
		switch (sex) {
			case 'MALE':
				return 'Masculino';
			case 'FEMALE':
				return 'Femenino';
		}
	};

	useEffect(() => {
		refetchPatientData(patientId).then((res: any) => {
			dispatch(setMedicalStatus(res.data.medicalStatus));
		});
	}, [patientId]);

	useEffect(() => {
		// @ts-ignore
		setBirthday(new Date(dataPatient?.birthday).getTime());
	}, [dataPatient]);

	useEffect(() => {
		let aux = Date.now() - birthday;

		// @ts-ignore
		setAge(new Date(aux).getFullYear() - 1970);
	}, [birthday]);

	return (
		<StyledBox
			css={{
				maxWidth: '878px',
				minHeight: '160px',
				height: '160px',
				display: 'flex',
				width: '820px',
				padding: '24px',
				justifyContent: 'space-between',
				alignItems: 'center',
				borderRadius: '20px',
				background: '#FFF',
				boxShadow: '0px 1px 25px 0px rgba(175, 126, 255, 0.10)',
			}}
		>
			{isLoading ? (
				<StyledBox
					css={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						height: '100%',
						width: '100%',
					}}
				>
					<StyledCircularProgress />
				</StyledBox>
			) : (
				<>
					<StyledBox
						css={{
							display: 'flex',
							alignItems: 'flex-start',
							gap: '24px',
						}}
					>
						{dataPatient && (
							<>
								<StyledBox>
									{getProfileImageFromName({
										name:
											dataPatient.name + ' ' + dataPatient.surname,
										size: {
											width: 80,
											height: 80,
											fontSize: '38px',
										},
									})}
								</StyledBox>

								<StyledBox
									css={{
										display: 'flex',
										flexDirection: 'column',
										alignItems: 'flex-start',
										gap: '4px',
									}}
								>
									<StyledBox
										css={{
											display: 'flex',
											alignItems: 'center',
											gap: '16px',
										}}
									>
										<StyledH5
											css={{
												color: 'black',
											}}
										>
											{CapitalizeText(dataPatient?.name) +
												' ' +
												CapitalizeText(dataPatient?.surname)}
										</StyledH5>
										{PatientStatus(dataPatient.medicalStatus)}
									</StyledBox>
									<PatientProfileDetails
										age={age}
										sex={genderValue(dataPatient?.sex)}
										dni={dataPatient?.dni}
										medicalHistoryNumber={
											dataPatient?.medicalHistoryNumber
										}
									/>
								</StyledBox>
							</>
						)}
					</StyledBox>
					<StyledBox
						css={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'flex-start',
							gap: '5px',
						}}
					>
						{ProfileConfigButton.map((item, index) => (
							<ProfileButton
								text={item.text}
								icon={item.icon}
								key={index}
								type={item.type}
								variant={item.variant as ButtonVariant}
							/>
						))}
					</StyledBox>
				</>
			)}
		</StyledBox>
	);
};

export default PatientProfileCard;
