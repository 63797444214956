import React, { useEffect, useRef, useState } from 'react';
import { useOncoDispatch } from '../../../../../../hooks/redux-hook';
import { setValue } from '../../../../../../redux/slices/formSlice';
import SubmenuField from '../SubmenuField';
import { MenuType } from '../../utils';

interface TNMFieldProps {
	value: any;
	label: string;
	name: string;
	onChange: (name: string, value: string) => void;
	error?: boolean | string;
	required?: boolean;
	disabled?: boolean;
	options?: MenuType[];
}

const TNMField = ({
	value,
	name,
	label,
	disabled,
	required,
	options,
	onChange,
	error,
}: TNMFieldProps) => {
	// const {data, isLoading} = useGetTNMQuery()
	const dispatch = useOncoDispatch();
	const ref = useRef(null);
	const [width, setWidth] = useState(250);

	const handleOnChange = (name: string, value: string) => {
		onChange(name, value);
		// dispatch(setValue({ name, value }));
	};

	useEffect(() => {
		// @ts-ignore
		if (ref.current.getBoundingClientRect().width < 250) {
			// @ts-ignore
			setWidth(ref.current.getBoundingClientRect().width);
		}
	}, [ref]);
	return (
		<SubmenuField
			ref={ref}
			menuList={options ?? []}
			name={name}
			label={label}
			onChange={handleOnChange}
			value={value}
			loading={false}
			disabled={disabled}
			required={required}
			width={width}
			error={error}
		/>
	);
};
export default TNMField;
