import React, { useEffect, useMemo } from 'react';
import {
	Background,
	LoaderContainer,
	RoundLoaderCircle,
} from './LoadingScreenStyles';
import { useNavigate } from 'react-router-dom';
import { useLazyRoleCheckQuery } from '../../redux/api/sessionApi';
import { useAuth0 } from '@auth0/auth0-react';

const LoadingScreen = () => {
	const navigate = useNavigate();

	const {
		isAuthenticated,
		getAccessTokenSilently,
		isLoading: AuthLoading,
	} = useAuth0();

	const [fetch, { isSuccess, data, isError, isLoading }] =
		useLazyRoleCheckQuery();

	const token = localStorage.getItem('token');

	const isVerified = useMemo(() => {
		if (isSuccess && !isError && !isLoading) {
			if (data.refresh === true) {
				getAccessTokenSilently({ cacheMode: 'off' }).then((res) => {
					localStorage.setItem('token', res);
					navigate('/loading');
				});
			}
			return true;
		}
	}, [isSuccess, isError, isLoading]);

	useEffect(() => {
		if (isAuthenticated) {
			getAccessTokenSilently().then((res) => {
				localStorage.setItem('token', res);
			});
		}
	}, [isAuthenticated]);

	useEffect(() => {
		if (token) {
			fetch();
		}
	}, [token]);

	useEffect(() => {
		if (isVerified && isAuthenticated) {
			navigate('/home');
		} else if (!isVerified && isAuthenticated && isError && !isLoading) {
			navigate('/registro');
		} else if (!isAuthenticated && !AuthLoading) {
			navigate('/login');
		}
	}, [isVerified, isAuthenticated, AuthLoading, isLoading, isError]);

	return (
		<Background>
			<LoaderContainer>
				<RoundLoaderCircle />
			</LoaderContainer>
		</Background>
	);
};

export default LoadingScreen;
