import React from 'react';
import { IconProps } from './IconInterface';
const CheckIcon = ({
	color = '#000000',
	width = 24,
	height = 24,
}: IconProps) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			viewBox="0 0 24 24"
			fill="none"
		>
			<path
				d="M9.00014 16.17L5.53014 12.7C5.14014 12.31 4.51014 12.31 4.12014 12.7C3.73014 13.09 3.73014 13.72 4.12014 14.11L8.30014 18.29C8.69014 18.68 9.32014 18.68 9.71014 18.29L20.2901 7.71001C20.6801 7.32001 20.6801 6.69001 20.2901 6.30001C19.9001 5.91001 19.2701 5.91001 18.8801 6.30001L9.00014 16.17Z"
				fill={color}
			/>
		</svg>
	);
};
export default CheckIcon;
