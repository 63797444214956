import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
	StyledRow,
	StyledBox,
	StyledColumn,
} from '../../../common/styledCommonComponents';
import { ModalTypeEnum, getProfileImageFromName } from '../../../utils/utils';
import { useTheme } from 'styled-components';
import StyledText from '../../../common/components/Text';
import CallIconOutline from '../../../assets/CallIconOutline';
import { useOncoDispatch } from '../../../hooks/redux-hook';
import { setModalOpen } from '../../../redux/slices/utilsSlice';
import { StyledPatientContainer } from './styles';

interface PatientContainerProps {
	name: string;
	surname: string;
	primaryTumor: string;
	css?: any;
	patientId: string;
}

const PatientContainer = ({
	name,
	surname,
	primaryTumor,
	css,
	patientId,
}: PatientContainerProps) => {
	const navigate = useNavigate();
	const theme = useTheme();
	const dispatch = useOncoDispatch();

	const handleContact = (event: any) => {
		event.stopPropagation();
		dispatch(
			setModalOpen({
				open: true,
				type: ModalTypeEnum.CONTACT_MODAL,
				patientId: patientId,
			}),
		);
	};

	return (
		<StyledPatientContainer onClick={() => navigate(`/profile/${patientId}`)}>
			<StyledRow
				css={{
					alignItems: 'center',
					gap: '8px',
				}}
			>
				{getProfileImageFromName({
					name: name + ' ' + surname,
					size: { width: 28, height: 28 },
				})}
				<StyledColumn>
					<StyledText variant="body3Bold" css={{ color: theme.black }}>
						{' '}
						{name + ' ' + surname}{' '}
					</StyledText>
					<StyledText variant="body3" css={{ color: theme.gray600 }}>
						{' '}
						{primaryTumor}{' '}
					</StyledText>
				</StyledColumn>
			</StyledRow>
			<StyledBox
				onClick={(event) => handleContact(event)}
				css={{
					width: '28px',
					height: '28px',
					padding: '4px',
					alignItems: 'flex-start',
					gap: '10px',
					borderRadius: '50%',
					background: theme.gray50,
				}}
			>
				<CallIconOutline width={20} height={20} color={theme.gray600} />
			</StyledBox>
		</StyledPatientContainer>
	);
};

export default PatientContainer;
