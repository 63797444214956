import React from 'react';
import Modal from '../Modal';
import StyledText from '../../../../../common/components/Text';
import { useTheme } from 'styled-components';
import { StyledColumn } from '../../../../../common/styledCommonComponents';

interface AuxiliarRegisterProps {
	handleOnClose: () => void;
}

const AuxiliarRegister = ({ handleOnClose }: AuxiliarRegisterProps) => {
	const theme = useTheme();
	return (
		<Modal
			onClose={handleOnClose}
			width={'568px'}
			header={
				<StyledColumn css={{ gap: 8, color: theme.black }}>
					<StyledText variant={'h5'}>Solicitar acceso</StyledText>
					<StyledText variant={'body2'}>
						Para solicitar acceso, debe pedirle al médico correspondiente
						que lo agregue como usuario auxiliar al sistema.
					</StyledText>
				</StyledColumn>
			}
			body={undefined}
		/>
	);
};

export default AuxiliarRegister;
