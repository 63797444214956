import React, { useCallback } from 'react';
import {
	StyledColumn,
	StyledRow,
} from '../../../../common/styledCommonComponents';
import {
	BubbleChart,
	BubbleLabel,
	BubbleSeries,
	ChartTooltip,
	DiscreteLegend,
	DiscreteLegendEntry,
	DiscreteLegendSymbol,
} from 'reaviz';
import Card from '../../../../components/Card';
import StyledText from '../../../../common/components/Text';
import { StyledTumorProportionChart } from './styles';
import { rgba } from 'polished';
import { CustomBubble } from './components/CustomBubble';
import { useOncoDispatch } from '../../../../hooks/redux-hook';
import { useCancerOrganProportionQuery } from '../../../../redux/api/homeApi';
import { setModalOpen } from '../../../../redux/slices/utilsSlice';
import { ModalTypeEnum } from '../../../../utils/utils';
import { StyledCircularProgress } from '../../../../components/CustomCircularProgress/styles';
import {
	StyledH6,
	TextVariants,
} from '../../../../common/components/Text/styles';
import { useTheme } from 'styled-components';
import ErrorComponent from '../../../../components/ErrorComponent';

const TumorProportionChart = () => {
	const theme = useTheme();
	const { data, isLoading, isError, refetch } = useCancerOrganProportionQuery(
		undefined,
		{
			selectFromResult: (result) => {
				const { data } = result;
				let newData: any = {
					data: [],
					total: data?.totalPatients,
				};
				data?.tumors.forEach((tumor: any) => {
					const porcentage = ((tumor.patients * 100) / data?.totalPatients)
						.toFixed(1)
						.replace(/\.0$/, '');
					newData = {
						...newData,
						data: [
							...newData.data,
							{
								key: tumor.tumor,
								data: porcentage,
								metadata: {
									name: `${porcentage}%`,
									males: tumor?.males ?? [],
									females: tumor?.females ?? [],
									malesQnt: tumor?.males?.length ?? 0,
									femalesQnt: tumor?.females?.length ?? 0,
								},
							},
						],
					};
				});
				newData.data = newData.data.sort(
					(a: any, b: any) => b.data - a.data,
				);
				return { ...result, data: newData };
			},
		},
	);

	const dispatch = useOncoDispatch();
	const color = [
		'#80CA8F',
		'#F7CCC8',
		'#6989F7',
		'#AD7EF9',
		'#D18EEB',
		'#BACBF9',
	];

	const handleOpenModal = useCallback(
		(tumor: any) => {
			const index = data.data?.findIndex(
				(t: any) => tumor.data.key === t.key,
			);
			dispatch(
				setModalOpen({
					type: ModalTypeEnum.TUMOR_PROPORTION_MODAL,
					payload: {
						...tumor,
						color: color[index % 8],
					},
				}),
			);
		},
		[data],
	);

	const getBubbleTextVariant = (bubbleSize: number): TextVariants => {
		if (bubbleSize < 10) return 'body5Bold';
		if (bubbleSize < 30) return 'body3Bold';
		return 'body1Bold';
	};

	return (
		<Card
			width={'440px'}
			height={'276px'}
			title={'Proporción de tumores primarios'}
		>
			{isLoading ? (
				<StyledRow
					css={{
						width: '100%',
						height: '100%',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<StyledCircularProgress size={50} />
				</StyledRow>
			) : data?.data?.length === 0 ? (
				<StyledRow
					css={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						width: '100%',
						height: '100%',
					}}
				>
					<StyledH6 css={{ color: theme.gray300 }}>Sin Datos</StyledH6>
				</StyledRow>
			) : isError ? (
				<ErrorComponent reload={refetch} />
			) : (
				<StyledTumorProportionChart>
					<BubbleChart
						data={data.data}
						height={186}
						width={240}
						margins={0}
						centerY
						series={
							<BubbleSeries
								colorScheme={color}
								label={
									<BubbleLabel
										fill={'red'}
										format={(data) => {
											return (
												<foreignObject
													height={data.r + 5}
													width={data.r + 5}
													x={-(data.r + 5) / 2}
													y={-(data.r + 5) / 2}
													style={{
														pointerEvents: 'none',
													}}
												>
													<StyledColumn
														css={{
															alignItems: 'center',
															justifyContent: 'center',
															height: '100%',
														}}
													>
														<StyledText
															variant={getBubbleTextVariant(
																data.value,
															)}
															css={{
																color: 'white',
															}}
														>
															{data?.data?.metadata?.name ?? ''}
														</StyledText>
													</StyledColumn>
												</foreignObject>
											);
										}}
									/>
								}
								bubble={
									<CustomBubble
										onClick={(e, data) => handleOpenModal(data)}
										tooltip={
											<ChartTooltip
												content={(data: any) => {
													return (
														<StyledColumn
															css={{
																backgroundColor: rgba(
																	'#232323',
																	0.85,
																),
																borderRadius: 4,
																padding: 8,
															}}
														>
															<StyledText
																variant={'body2'}
																css={{
																	color: 'white',
																	marginBottom: 4,
																}}
															>
																{data?.x}
																{` (${data.metadata.name})`}
															</StyledText>
															<StyledText
																variant={'body3'}
																css={{ color: 'white' }}
															>
																Hombres:{' '}
																{data?.metadata?.malesQnt}{' '}
																pacientes
															</StyledText>
															<StyledText
																variant={'body3'}
																css={{ color: 'white' }}
															>
																Mujeres:{' '}
																{data?.metadata?.femalesQnt}{' '}
																pacientes
															</StyledText>
														</StyledColumn>
													);
												}}
											/>
										}
									/>
								}
							/>
						}
					/>
					<DiscreteLegend
						style={{
							width: '120px',
						}}
						entries={data.data.map((value: any, index: number) => (
							<DiscreteLegendEntry
								label={value.key}
								color={color[index % 8]}
								symbol={
									<DiscreteLegendSymbol className={'legend-circle'} />
								}
							/>
						))}
					/>
				</StyledTumorProportionChart>
			)}
		</Card>
	);
};

export default TumorProportionChart;
