import React from 'react';
import { useTheme } from 'styled-components';
import { StyledRow } from '../../../../common/styledCommonComponents';
import { useDispatch } from 'react-redux';
import { removeMedication } from '../../../../redux/slices/homeSlice';
import {
	StyledAnimatedIcon,
	StyledAnimatedRow,
	StyledAnimatedText,
} from './styles';

export const FilterTag = ({
	text,
	removable,
}: {
	text: string;
	removable: boolean;
}) => {
	const theme = useTheme();
	const dispatch = useDispatch();

	const handleRemove = () => {
		dispatch(removeMedication(text));
	};

	return (
		<StyledAnimatedRow
			css={{
				padding: '4px',
				justifyContent: 'center',
				alignItems: 'center',
				gap: '2px',
				height: '22px',
				borderRadius: '8px',
				background: theme.primary400,
			}}
		>
			<StyledAnimatedText variant="filterTag" css={{ color: '#FFF' }}>
				{text}
			</StyledAnimatedText>
			{removable && (
				<StyledRow css={{ cursor: 'pointer' }} onClick={handleRemove}>
					<StyledAnimatedIcon color={'#FFF'} width={14} height={14} />
				</StyledRow>
			)}
		</StyledAnimatedRow>
	);
};
