import styled from 'styled-components';
import { StyledBox } from '../../common/styledCommonComponents';

export const StyledWaitingListContainer = styled(StyledBox)`
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	flex: 1;
	height: 100%;

	&::-webkit-scrollbar {
		width: 2px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: lightgray;
	}
`;
