import React from 'react';
import { IconProps } from './IconInterface';

const PersonIcon = ({
	color = '#000000',
	width = 24,
	height = 24,
}: IconProps) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M7.88562 4.11395C8.70615 4.11395 9.37133 3.49994 9.37133 2.74252C9.37133 1.9851 8.70615 1.37109 7.88562 1.37109C7.06508 1.37109 6.3999 1.9851 6.3999 2.74252C6.3999 3.49994 7.06508 4.11395 7.88562 4.11395Z"
				fill={color}
			/>
			<path
				d="M12.5598 4.57207H3.21141C3.02649 4.57207 2.84914 4.64651 2.71838 4.77902C2.58762 4.91152 2.51416 5.09124 2.51416 5.27862C2.51416 5.46601 2.58762 5.64573 2.71838 5.77823C2.84914 5.91074 3.02649 5.98518 3.21141 5.98518H5.84238C6.02082 5.98518 6.22974 6.06604 6.34802 6.3777C6.48618 6.73961 6.41852 7.43858 6.3333 7.98L6.22174 8.61982C6.22194 8.62149 6.22177 8.62318 6.22124 8.62477C6.2207 8.62636 6.21981 8.6278 6.21864 8.62898L5.32512 13.7792C5.29272 13.9662 5.33471 14.1585 5.44191 14.3142C5.5491 14.4698 5.71278 14.5761 5.89713 14.6098C5.98827 14.6261 6.0817 14.624 6.172 14.6034C6.26231 14.5829 6.34768 14.5443 6.42317 14.4901C6.49866 14.4358 6.56275 14.3669 6.61174 14.2873C6.66072 14.2077 6.69362 14.1191 6.70852 14.0265L7.32598 10.4467C7.32598 10.4467 7.52405 9.59643 7.88559 9.59643C8.25307 9.59643 8.44701 10.4467 8.44701 10.4467L9.06446 14.0296C9.0794 14.123 9.11253 14.2125 9.16192 14.2927C9.21131 14.373 9.27597 14.4425 9.35214 14.4972C9.42831 14.5519 9.51446 14.5907 9.60557 14.6113C9.69668 14.6319 9.79093 14.6339 9.88282 14.6173C9.97472 14.6006 10.0624 14.5656 10.1408 14.5142C10.2192 14.4628 10.2867 14.3962 10.3394 14.3181C10.3921 14.24 10.429 14.1521 10.4478 14.0594C10.4667 13.9668 10.4671 13.8713 10.4492 13.7784L9.55538 8.6282C9.55557 8.62655 9.55541 8.62489 9.55492 8.62331C9.55443 8.62173 9.55362 8.62028 9.55254 8.61904L9.44072 7.97922C9.3555 7.43779 9.28784 6.73883 9.426 6.37692C9.54376 6.06604 9.75939 5.98439 9.92389 5.98439H12.5598C12.7447 5.98439 12.922 5.90995 13.0528 5.77745C13.1836 5.64494 13.257 5.46523 13.257 5.27784C13.257 5.09045 13.1836 4.91074 13.0528 4.77823C12.922 4.64573 12.7447 4.57129 12.5598 4.57129V4.57207Z"
				fill={color}
			/>
		</svg>
	);
};
export default PersonIcon;
