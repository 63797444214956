import { StyledRow } from '../../../common/styledCommonComponents';
import styled from 'styled-components';

export const StyledPatientContainer = styled(StyledRow)`
	max-height: 46px;
	width: 100%;
	cursor: pointer;
	padding: 8px;
	justify-content: space-between;
	align-items: center;
	align-self: stretch;
	border-radius: 8px;
	background-color: ${({ theme }) => theme.white};
	border: 1px solid ${({ theme }) => theme.gray50};
	&:hover {
		background-color: ${({ theme }) => theme.gray50};
	}
`;
