import React from 'react';
import StyledText from '../../../../common/components/Text/styles';
import { StyledBox } from '../../../../common/styledCommonComponents';

type ProfileDetailsProps = {
	age: Date | string;
	sex: 'Masculino' | 'Femenino' | 'Cargando...';
	dni: string;
	medicalHistoryNumber: string;
};

export const PatientProfileDetails = ({
	age = 'Cargando...',
	sex = 'Cargando...',
	dni = 'Cargando...',
	medicalHistoryNumber = 'Cargando...',
}: ProfileDetailsProps) => {
	return (
		<StyledBox
			css={{
				display: 'flex',
				width: '256px',
				flexDirection: 'column',
				alignItems: 'flex-start',
				gap: '4px',
			}}
		>
			<StyledText variant="body3">Edad: {age}</StyledText>
			<StyledText variant="body3">Sexo: {sex}</StyledText>
			<StyledText variant="body3">DNI: {dni}</StyledText>
			<StyledText variant="body3">
				Historial Médico: {medicalHistoryNumber}
			</StyledText>
		</StyledBox>
	);
};
