import React from 'react';
import {
	StyledBox,
	StyledRow,
} from '../../../../../../common/styledCommonComponents';
import { StyledCircularProgress } from '../../../../../CustomCircularProgress/styles';
import Button from '../../../../../../common/components/Button';
import {
	useOncoDispatch,
	useOncoSelector,
} from '../../../../../../hooks/redux-hook';
import { useTheme } from 'styled-components';
import TextInputField from '../../../../../../common/components/TextInputField';
import Dropdown from '../../../../../../common/components/Dropdown';
import {
	addAdditionalField,
	addProgression,
	addSetback,
	setTreatmentValue,
	setValidationErrors,
	TreatmentInterface,
	valuesLastTreatment,
} from '../../../../../../redux/slices/validationSlice';
import { treatment } from '../../../../../../redux/api/validateFormApi';

interface TreatmentBodyProps {
	onClose: () => void;
}

const TreatmentBody = ({ onClose }: TreatmentBodyProps) => {
	const theme = useTheme();

	const stage = useOncoSelector((state) => state.validationSlice.values.stage);

	const {
		treatment: values,
		prefix,
		id,
	} = useOncoSelector((state) => valuesLastTreatment(state));

	const dispatch = useOncoDispatch();

	// const treatment = useOncoSelector((state) =>
	// 	checkTreatmentEndingDate(state),
	// );

	const handleFinishTreatment = () => {
		dispatch(
			addAdditionalField({
				id,
				prefix: 'treatment',
				finishDate: values.finishDate,
				endingMotive: values.endingMotive,
				otherMotive: values.otherMotive || '',
			}),
		);
		if (prefix === 'treatment' && stage !== 'IV') {
			dispatch(addSetback());
		} else {
			dispatch(addProgression());
		}
		dispatch(
			setTreatmentValue({
				id,
				prefix: prefix as 'treatment' | 'setbacks' | 'progressions',
				key: 'isFinished',
				value: true,
			}),
		);
		dispatch(setValidationErrors({ validation: [] }));
		onClose();
	};

	const handleClose = () => {
		dispatch(
			setTreatmentValue({
				id,
				prefix: prefix as 'treatment' | 'setbacks' | 'progressions',
				key: 'finishDate',
				value: null,
			}),
		);
		dispatch(
			setTreatmentValue({
				id,
				prefix: prefix as 'treatment' | 'setbacks' | 'progressions',
				key: 'endingMotive',
				value: null,
			}),
		);
		dispatch(
			setTreatmentValue({
				id,
				prefix: prefix as 'treatment' | 'setbacks' | 'progressions',
				key: 'otherMotive',
				value: null,
			}),
		);

		onClose();
	};

	const handleOnChange = (name: string, value: string) => {
		dispatch(
			setTreatmentValue({
				id,
				prefix: prefix as 'treatment' | 'setbacks' | 'progressions',
				key: name as keyof TreatmentInterface,
				value,
			}),
		);
	};

	const loading = false;

	return (
		<StyledBox css={{ boxSizing: 'border-box', width: '100%' }}>
			{loading ? (
				<StyledBox
					css={{
						height: '141px',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<StyledCircularProgress />
				</StyledBox>
			) : (
				<>
					<StyledRow css={{ gap: 24 }}>
						<TextInputField
							value={values.finishDate}
							label={'Fecha de finalización'}
							onChange={handleOnChange}
							name={'finishDate'}
							required
							type={'date'}
							css={{
								maxWidth: 180,
							}}
							max="today"
						/>
						<Dropdown
							value={values.endingMotive}
							label={'Motivo'}
							onChange={handleOnChange}
							name={'endingMotive'}
							options={{
								Toxicity: 'Toxicidad',
								DiseaseProgression: 'Progresión de enfermedad',
								DoctorOrPatientDecision:
									'Decisión del médico o paciente',
								CompletedTreatment: 'Completó tratamiento',
								Other: 'Otros',
							}}
							required
						/>
					</StyledRow>
					{values?.endingMotive === 'Other' && (
						<StyledRow css={{ width: '100%' }}>
							<TextInputField
								value={values.otherMotive}
								label={'Otras razones'}
								onChange={handleOnChange}
								name={'otherMotive'}
								required
								type={'text'}
							/>
						</StyledRow>
					)}
					<StyledRow
						css={{
							gap: '16px',
							marginTop: '24px',
							justifyContent: 'flex-end',
							alignItems: 'flex-end',
							alignSelf: 'stretch',
						}}
					>
						<Button
							text={'Cancelar'}
							disabled={false}
							size={'medium'}
							onClick={handleClose}
							css={{
								width: 130,
								backgroundColor: theme.error400,
								'&:hover': {
									backgroundColor: theme.error400,
								},
							}}
						/>
						<Button
							text={'Finalizar'}
							disabled={
								!(
									values?.finishDate &&
									values?.endingMotive !== 'no_value'
								)
							}
							size={'medium'}
							onClick={() => handleFinishTreatment()} // check if inputs are valid
							css={{
								width: 130,
							}}
						/>
					</StyledRow>
				</>
			)}
		</StyledBox>
	);
};

export default TreatmentBody;
