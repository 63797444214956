import { api } from './api';

export const feedbackApi = api.injectEndpoints({
	endpoints: (builder) => ({
		createFeedback: builder.mutation({
			query: (body) => ({
				url: '/feedback',
				method: 'POST',
				body,
			}),
		}),
	}),
});

export const { useCreateFeedbackMutation } = feedbackApi;
