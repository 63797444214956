import React from 'react';
import { StyledBox } from '../../../../../../common/styledCommonComponents';
import { useTheme } from 'styled-components';
import StyledText from '../../../../../../common/components/Text';
import { useNavigate } from 'react-router-dom';

export const ReportComponentHeader = ({ patientId }: { patientId: string }) => {
	const theme = useTheme();
	const navigate = useNavigate();

	return (
		<StyledBox
			css={{
				display: 'flex',
				padding: '4px 4px 8px 4px',
				justifyContent: 'space-between',
				alignItems: 'flex-en',
				alignSelf: 'stretch',
				borderBottom: `1px solid ${theme.gray50}`,
			}}
		>
			<StyledText
				variant="body2Bold"
				css={{
					color: theme.gray700,
				}}
			>
				Encuestas realizadas
			</StyledText>
			<StyledText
				variant="body3"
				css={{
					color: theme.primary500,
					textAlign: 'center',
					cursor: 'pointer',
				}}
				onClick={() => navigate(`/reportes/${patientId}`)}
			>
				Ver más
			</StyledText>
		</StyledBox>
	);
};
