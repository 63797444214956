import React, { useEffect, useRef, useState } from 'react';
import styled, { keyframes, useTheme } from 'styled-components';
import { useParams } from 'react-router-dom';
import {
	StyledBox,
	StyledColumn,
	StyledRow,
} from '../../common/styledCommonComponents';
import StyledText from '../../common/components/Text';
import Card from '../Card';
import { StyledCircularProgress } from '../CustomCircularProgress/styles';
import { StyledH6 } from '../../common/components/Text/styles';
import { FilterTag } from './components/FilterTag';
import { FrequencyComponent } from './components/FrequencyComponent';
import { FilterCard } from '../FilterCard';
import { useOncoSelector } from '../../hooks/redux-hook';
import useOutsideAlerter from '../../hooks/useOutsiderAlerter';
import { useGetPROCTCAESummaryQuery } from '../../redux/api/patientApi';
import ErrorComponent from '../ErrorComponent';

const stretch = keyframes`
  from {
    height: 0;
  }
  to {
    height: 100%;
  }
`;

const AnimatedRow = styled(StyledRow)`
	animation: ${stretch} 1s linear backwards;
`;

export const FrequencySymptomsChart = () => {
	const theme = useTheme();
	const { patientId } = useParams();
	const [open, setOpen] = useState<boolean>(false);
	const ref = useRef(null);
	const { medicationsFilter } = useOncoSelector((state) => state.homeSlice);

	const {
		data,
		isLoading: loadingQuery,
		refetch,
		isError,
	} = useGetPROCTCAESummaryQuery(medicationsFilter || []);

	useEffect(() => {
		if (ref.current) {
			// @ts-ignore
		}
	}, [ref.current]);

	useOutsideAlerter(ref, () => setOpen(false));

	useEffect(() => {
		refetch();
	}, [medicationsFilter]);

	const reportListHeader = () => (
		<StyledBox
			css={{
				display: 'flex',
				padding: '4px 4px 8px 4px',
				justifyContent: 'space-between',
				alignItems: 'flex-en',
				alignSelf: 'stretch',
				borderBottom: `1px solid ${theme.gray50}`,
			}}
		>
			<StyledText
				variant="body2Bold"
				css={{
					color: theme.gray800,
				}}
			>
				Síntomas frecuentes Calidad de Vida
			</StyledText>
			{/* {!loadingQuery && (
				<StyledText
					variant="body3Bold"
					onClick={() => setOpen(!open)}
					css={{
						color: theme.primary500,
						textAlign: 'center',
						cursor: 'pointer',
					}}
				>
					Filtrar
				</StyledText>
			)} */}
		</StyledBox>
	);

	function maxPatients() {
		let patients: number[] = [];
		data.forEach((info: { patients: { id: string; name: string }[] }) => {
			patients.push(info.patients.length);
		});
		return Math.max(...patients);
	}

	return (
		<>
			<Card
				headerComponent={reportListHeader()}
				width={'810px'}
				height={medicationsFilter.length > 0 ? '270px' : '260px'}
				css={{ maxHeight: '' }}
			>
				<StyledBox
					css={{
						width: '100%',
						height: '100%',
						overflowY: 'auto',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					{loadingQuery ? (
						<StyledBox
							css={{
								height: '100%',
								width: '100%',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<StyledCircularProgress />
						</StyledBox>
					) : isError ? (
						<ErrorComponent reload={() => refetch()} />
					) : (
						<StyledColumn
							css={{
								alignItems: 'flex-start',
								gap: '8px',
								width: '100%',
								alignSelf: 'stretch',
								height: '100%',
							}}
						>
							{medicationsFilter.length > 0 && (
								<AnimatedRow
									css={{
										alignItems: 'flex-start',
										gap: '4px',
										height: '22px',
									}}
								>
									{medicationsFilter.map((name: string) => (
										<FilterTag removable={true} text={name} />
									))}
								</AnimatedRow>
							)}

							<StyledColumn
								css={{
									alignItems: 'flex-start',
									gap: '8px',
									alignSelf: 'stretch',
								}}
							>
								{data.length <= 0 ? (
									<StyledBox
										css={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											height: '164px',
											width: '100%',
										}}
									>
										<StyledH6 css={{ color: theme.gray300 }}>
											Sin Datos
										</StyledH6>
									</StyledBox>
								) : (
									data?.map((data: any) => (
										<FrequencyComponent
											type={data.categoryName}
											symptom={data.subcategoryName}
											maxPatients={maxPatients()}
											patients={data.patients}
										/>
									))
								)}
							</StyledColumn>
						</StyledColumn>
					)}
				</StyledBox>
			</Card>
			{open && <FilterCard ref={ref} />}
		</>
	);
};
