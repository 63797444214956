import React from 'react';
import styled, { keyframes, useTheme } from 'styled-components';

// Define the keyframe animation for the loader spin
const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

// Create a styled div for the loader
const LoaderContainer = styled.div<{ color: string }>`
	border: 4px solid transparent;
	border-top: 4px solid ${(props) => props.color};
	border-radius: 50%;
	width: 40px;
	height: 40px;
	animation: ${spin} 2s linear infinite;
	background: transparent;
	background-clip: content-box;
	box-shadow: 0 0 0 1px transparent;
`;

// Create the CircularLoader component
const CircularLoader = () => {
	const theme = useTheme();
	return <LoaderContainer color={theme.gray100} />;
};

export default CircularLoader;
