import React from 'react';
import HeaderValidationForm from '../components/HeaderValidationForm';
import BiomarkerIcon from '../../../assets/BiomarkerIcon';
import { StyledColumn } from '../../../common/styledCommonComponents';
import { useOncoDispatch, useOncoSelector } from '../../../hooks/redux-hook';
import SetbackContainer from '../components/SetbackContainer';
import {
	SetbackInterface,
	setValidationErrors,
	valuesLastTreatment,
	addSetback,
} from '../../../redux/slices/validationSlice';
import Button from '../../../common/components/Button';
import PlusCircleIcon from '../../../assets/PlusCircleIcon';
import { setModalOpen } from '../../../redux/slices/utilsSlice';
import { ModalTypeEnum } from '../../../utils/utils';
import { validateForm } from '../utils';

const SetbackForm = () => {
	const setbacks = useOncoSelector(
		(state) => state.validationSlice.values.setbacks,
	);
	const medication = useOncoSelector(
		(state) => state.validationSlice.values.medication,
	);
	const isEditable = useOncoSelector(
		(state) => state.validationSlice.isEditable,
	);
	const stage = useOncoSelector((state) => state.validationSlice.values.stage);
	const dispatch = useOncoDispatch();
	const {
		treatment: values,
		prefix,
		id,
	} = useOncoSelector((state) => valuesLastTreatment(state));
	const validationsRules = useOncoSelector(
		(state) => state.validationSlice.validations,
	);
	const handleAddSetback = () => {
		let valuesToTest = {};
		const { isFinished, endingMotive, ...treatmentValues } = values;
		if (prefix === 'treatment') {
			valuesToTest = {
				treatment: treatmentValues,
				medication,
			};
		} else {
			valuesToTest = {
				[prefix]: treatmentValues,
				medication,
			};
		}
		const validation = validateForm(valuesToTest, validationsRules);
		if (
			validation.find(
				(item: any) =>
					(item?.error?.length ?? 0) ||
					item?.properties?.find((prop: any) => prop?.error?.length ?? 0),
			)
		) {
			dispatch(setValidationErrors({ validation }));
		} else if (isFinished || values.finishDate || values.endingMotive) {
			dispatch(addSetback());
		} else {
			dispatch(
				setModalOpen({
					type: ModalTypeEnum.TREATMENT_MODAL,
					prefix: 'setbacks',
				}),
			);
		}
	};

	const disableAddSetback = !(prefix === 'treatment' && stage !== 'IV');

	return (
		<StyledColumn>
			<HeaderValidationForm title={'Recaidas'} icon={BiomarkerIcon} />
			<StyledColumn css={{ padding: 32, gap: 16 }}>
				{setbacks?.map((setback: SetbackInterface) => (
					<SetbackContainer
						id={setback.id}
						setback={setback}
						key={setback.id}
					/>
				))}
				{setbacks.length === 0 && (
					<Button
						css={{ width: '100%' }}
						onClick={handleAddSetback}
						variant={'outlined'}
						size={'medium'}
						icon={PlusCircleIcon}
						disabled={disableAddSetback}
					>
						Agregar recaida
					</Button>
				)}
			</StyledColumn>
		</StyledColumn>
	);
};

export default SetbackForm;
