import React, { ReactNode, useMemo } from 'react';
import {
	StyledBox,
	StyledRow,
} from '../../../../../../common/styledCommonComponents';
import MoodIcon from '../../../../../../assets/MoodIcon';
import PainIcon from '../../../../../../assets/PainIcon';
import ApetiteIcon from '../../../../../../assets/ApetiteIcon';
import HidrationIcon from '../../../../../../assets/HidrationIcon';
import SocialActivityIcon from '../../../../../../assets/SocialActivityIcon';
import _ from 'lodash';
import { useTheme } from 'styled-components';
import StyledText from '../../../../../../common/components/Text';
import PhysicalActivityIcon from '../../../../../../assets/PhysicalActivityIcon';

const DailyBody = ({ data }: { data: any }) => {
	const theme = useTheme();

	type DailyType =
		| 'mood'
		| 'pain'
		| 'hunger'
		| 'hydration'
		| 'physical'
		| 'social';
	const categories = [
		'mood',
		'pain',
		'hunger',
		'hydration',
		'physical',
		'social',
	];

	const categoriesRecord: Record<
		DailyType,
		{ title: string; questionId: string; icon: ReactNode }
	> = useMemo(() => {
		return {
			mood: {
				title: 'Ánimo',
				questionId: '73d3c051-d3b8-47f5-a9ff-50216b8d4d4a',
				icon: <MoodIcon color="#AF7EFF" width={16} height={16} />,
			},
			pain: {
				title: 'Dolor',
				questionId: '02649688-f280-494d-8045-78b993dbee24',
				icon: <PainIcon color="#AF7EFF" width={16} height={16} />,
			},
			hunger: {
				title: 'Apetito',
				questionId: '27316d1c-9e5e-4d02-bbf1-e994af587286',
				icon: <ApetiteIcon color="#AF7EFF" width={16} height={16} />,
			},
			hydration: {
				title: 'Hidratación',
				questionId: '72fbeec3-3226-4195-99b3-672bf12cb7f2',
				icon: <HidrationIcon color="#AF7EFF" width={16} height={16} />,
			},
			physical: {
				title: 'Actividad Física',
				questionId: '6900b6a0-a54f-4f8e-a4ae-9c43728264d2',
				icon: (
					<PhysicalActivityIcon color="#AF7EFF" width={16} height={16} />
				),
			},
			social: {
				title: 'Actividad Social',
				questionId: '712ce9db-e52a-4606-8717-9f7deb3d696a',
				icon: <SocialActivityIcon color="#AF7EFF" width={16} height={16} />,
			},
		};
	}, []);

	const TransformAnswers = (questionId: string, value: string) => {
		if (!value) return value ?? 'No se registró respuesta';
		switch (questionId) {
			case '73d3c051-d3b8-47f5-a9ff-50216b8d4d4a':
			case '02649688-f280-494d-8045-78b993dbee24':
				return `${value} de 10`;
			case '27316d1c-9e5e-4d02-bbf1-e994af587286':
			case '72fbeec3-3226-4195-99b3-672bf12cb7f2':
			case '6900b6a0-a54f-4f8e-a4ae-9c43728264d2':
			case '712ce9db-e52a-4606-8717-9f7deb3d696a':
				return value ?? 'No se registró respuesta';
			default:
				return 'No se registró respuesta';
		}
	};

	const DisplayAnswers = (id: string) => {
		if (data && data.questionAnswers) {
			const result = data.questionAnswers.find(
				(item: any) => item.questionId === id,
			)?.answer;
			return TransformAnswers(id, result);
		}
	};

	return (
		<StyledBox css={{ width: '100%' }}>
			{categories?.map((type, index) => (
				<StyledRow
					css={{
						display: 'flex',
						padding: '8px',
						justifyContent: 'space-between',
						alignItems: 'center',
						alignSelf: 'stretch',
						borderBottom:
							type === 'social' ? 'none' : `1px solid ${theme.gray100}`,
					}}
				>
					<StyledBox
						css={{
							display: 'flex',
							alignItems: 'center',
							gap: 8,
							marginTop: 5,
						}}
					>
						<StyledBox
							css={{
								width: '30px',
								height: '30px',
								borderRadius: '50%',
								backgroundColor: '#F0E8FF',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							{categoriesRecord[type as DailyType].icon}
						</StyledBox>
						<StyledText
							variant="body1Bold"
							css={{ color: `${theme.black}` }}
						>
							{' '}
							{categoriesRecord[type as DailyType].title}{' '}
						</StyledText>
					</StyledBox>
					<StyledBox>
						<StyledText variant="body1">
							{data.questionAnswers.length > 0
								? DisplayAnswers(
										categoriesRecord[type as DailyType].questionId,
								  )
								: 'No se registró respuesta'}
						</StyledText>
					</StyledBox>
				</StyledRow>
			))}
		</StyledBox>
	);
};

export default DailyBody;
