import React from 'react';
import { useTheme } from 'styled-components';
import OralIcon from '../assets/OralIcon';
import SexualIcon from '../assets/SexualIcon';
import RespiratoryIcon from '../assets/RespiratoryIcon';
import DigestiveIcon from '../assets/DigestiveIcon';
import ColorIcon from '../assets/ColorIcon';
import PainIcon from '../assets/PainIcon';
import MoodIcon from '../assets/MoodIcon';
import VisualIcon from '../assets/VisualIcon';
import CircularOralIcon from '../assets/circularIcon/CircularOralIcon';
import CircularSexualIcon from '../assets/circularIcon/CircularSexualIcon';
import CircularCardiacIcon from '../assets/circularIcon/CircularCardiacIcon';
import CircularDigestiveIcon from '../assets/circularIcon/CircularDigestiveIcon';
import CircularGenitalIcon from '../assets/circularIcon/CircularGenitalIcon';
import CircularMiscellaneousIcon from '../assets/circularIcon/CircularMiscellaneousIcon';
import CircularMoodIcon from '../assets/circularIcon/CircularMoodIcon';
import CircularPainIcon from '../assets/circularIcon/CircularPainIcon';
import CircularRespiratoryIcon from '../assets/circularIcon/CircularRespiratoryIcon';
import CircularSkinIcon from '../assets/circularIcon/CircularSkinIcon';
import CircularVisualIcon from '../assets/circularIcon/CircularVisualIcon';
import MemoryIcon from '../assets/MemoryIcon';
import IconBrain from '../assets/IconBrain';
import IconSleep from '../assets/IconSleep';
import GenitalIcon from '../assets/GenitalIcon';
import SkinIcon from '../assets/SkinIcon';
import MiscelaneousIcon from '../assets/MiscelaneousIcon';
import CardiacIcon from '../assets/CardiacIcon';

interface BiometricStyle {
	name: string;
	color: string;
	icon: any;
	circularIcon: any;
	gradient: string;
}

export const categoryTranslate: { [key: string]: string } = {
	RESPIRATORY: 'Respiratorio',
	ORAL: 'Oral',
	// NEUROLOGICAL: 'Neurologico',
	// SLEEP: 'Sueño',
	SEXUAL: 'Sexual',
	DIGESTIVE: 'Gastrointestinal',
	CARDIAC: 'Cardiaco',
	SKIN: 'Cutaneo',
	MOOD: 'Ánimo',
	VISUAL: 'Visual',
	// MEMORY: 'Memoria',
	PAIN: 'Dolor',
	GENITAL: 'Genital',
	MISCELLANEOUS: 'Miscelaneo',
};

export const categoryBiometric = (
	type: string,
	width?: number,
	height?: number,
	color?: string,
): BiometricStyle => {
	const theme = useTheme();
	switch (type) {
		case 'ORAL':
		case 'Oral':
			return {
				name: 'Oral',
				color: theme.blue500,
				icon: (
					<OralIcon
						width={width}
						height={height}
						color={color ?? theme.blue500}
					/>
				),
				circularIcon: <CircularOralIcon width={width} height={height} />,
				gradient:
					'linear-gradient(270deg, rgb(106 137 248) 0%, rgb(106 137 248 / 50%) 100%)',
			};
		case 'SEXUAL':
		case 'Sexual':
			return {
				name: 'Sexual',
				color: theme.primary500,
				icon: (
					<SexualIcon
						width={width}
						height={height}
						color={color ?? theme.primary500}
					/>
				),
				circularIcon: <CircularSexualIcon width={width} height={height} />,
				gradient:
					'linear-gradient(270deg, rgb(147, 87, 247) 0%, rgba(173, 126, 249, 0.49) 100%)',
			};
		case 'RESPIRATORY':
		case 'Respiratorio':
			return {
				name: 'Respiratorio',
				color: theme.green500,
				icon: (
					<RespiratoryIcon
						width={width}
						height={height}
						color={color ?? theme.green500}
					/>
				),
				circularIcon: (
					<CircularRespiratoryIcon width={width} height={height} />
				),
				gradient:
					'linear-gradient(270deg, rgb(82 191 126) 0%, rgb(82 191 126 / 50%) 100%)',
			};
		case 'PAIN':
		case 'Dolor':
			return {
				name: 'Dolor',
				color: theme.pink500,
				icon: (
					<PainIcon
						width={width}
						height={height}
						color={color ?? theme.pink500}
					/>
				),
				circularIcon: <CircularPainIcon width={width} height={height} />,
				gradient:
					'linear-gradient(270deg, #D18EEB 0%, rgba(209, 142, 235, 0.50) 100%)',
			};
		case 'MOOD':
		case 'Ánimo':
			return {
				name: 'Animo',
				color: theme.orange500,
				icon: (
					<MoodIcon
						width={width}
						height={height}
						color={color ?? theme.orange500}
					/>
				),
				circularIcon: <CircularMoodIcon width={width} height={height} />,
				gradient:
					'linear-gradient(270deg, #ECA485 0%, rgba(236, 164, 133, 0.50) 100%)',
			};
		case 'DIGESTIVE':
		case 'Gastrointestinal':
			return {
				name: 'Gastrointestinal',
				color: theme.blue500,
				icon: (
					<DigestiveIcon
						width={width}
						height={height}
						color={color ?? theme.blue500}
					/>
				),
				circularIcon: (
					<CircularDigestiveIcon width={width} height={height} />
				),
				gradient:
					'linear-gradient(270deg, rgb(106 137 248) 0%, rgb(106 137 248 / 50%) 100%)', // ask for this category gradient
			};
		case 'VISUAL':
		case 'Visual':
			return {
				name: 'Visual',
				color: theme.green500,
				icon: (
					<VisualIcon
						width={width}
						height={height}
						color={color ?? theme.green500}
					/>
				),
				circularIcon: <CircularVisualIcon width={width} height={height} />,
				gradient:
					'linear-gradient(270deg, rgb(82 191 126) 0%, rgb(82 191 126 / 50%) 100%)', // ask for this category gradient
			};
		case 'MEMORY':
		case 'Memoria':
			return {
				name: 'Memoria',
				color: theme.blue500,
				icon: (
					<MemoryIcon
						width={width}
						height={height}
						color={color ?? theme.blue500}
					/>
				),
				circularIcon: (
					<CircularVisualIcon // CircularMemoryIcon
						width={width}
						height={height}
					/>
				),
				gradient:
					'linear-gradient(270deg, rgb(106 137 248) 0%, rgb(106 137 248 / 50%) 100%)', // ask for this category gradient
			};
		case 'GENITAL':
		case 'Genital':
			return {
				name: 'Genital',
				color: theme.pink500,
				icon: (
					<GenitalIcon
						width={width}
						height={height}
						color={color ?? theme.pink500}
					/>
				),
				circularIcon: <CircularGenitalIcon width={width} height={height} />,
				gradient:
					'linear-gradient(270deg, #ECA485 0%, rgba(236, 164, 133, 0.50) 100%)', // ask for this category gradient
			};
		case 'SKIN':
		case 'Cutaneo':
			return {
				name: 'Cutaneo',
				color: theme.primary500,
				icon: (
					<SkinIcon
						width={width}
						height={height}
						color={color ?? theme.primary500}
					/>
				),
				circularIcon: <CircularSkinIcon width={width} height={height} />,
				gradient:
					'linear-gradient(270deg, rgb(147, 87, 247) 0%, rgba(173, 126, 249, 0.49) 100%)', // ask for this category gradient
			};

		case 'MISCELLANEOUS':
		case 'Miscelaneo':
			return {
				name: 'Miscelaneo',
				color: theme.primary500,
				icon: (
					<MiscelaneousIcon
						width={width}
						height={height}
						color={color ?? theme.primary500}
					/>
				),
				circularIcon: (
					<CircularMiscellaneousIcon width={width} height={height} />
				),
				gradient:
					'linear-gradient(270deg, rgb(147, 87, 247) 0%, rgba(173, 126, 249, 0.49) 100%)', // ask for this category gradient
			};
		case 'CARDIAC':
		case 'Cardiaco':
			return {
				name: 'Cardiaco',
				color: theme.green500,
				icon: (
					<CardiacIcon
						width={width}
						height={height}
						color={color ?? theme.green500}
					/>
				),
				circularIcon: <CircularCardiacIcon width={width} height={height} />,
				gradient:
					'linear-gradient(270deg, rgb(82 191 126) 0%, rgb(82 191 126 / 50%) 100%)', // ask for this category gradient
			};
		case 'Neurologico':
			return {
				name: 'Neurológico',
				color: theme.orange500,
				icon: (
					<IconBrain
						width={width}
						height={height}
						color={color ?? theme.orange500}
					/>
				),
				circularIcon: <CircularCardiacIcon width={width} height={height} />,
				gradient:
					'linear-gradient(270deg, #ECA485 0%, rgba(236, 164, 133, 0.50) 100%)', // ask for this category gradient
			};
		case 'Sueño':
			return {
				name: 'Sueño',
				color: theme.pink500,
				icon: (
					<IconSleep
						width={width}
						height={height}
						color={color ?? theme.pink500}
					/>
				),
				circularIcon: <CircularCardiacIcon width={width} height={height} />,
				gradient:
					'linear-gradient(270deg, #ECA485 0%, rgba(236, 164, 133, 0.50) 100%)', // ask for this category gradient
			};
		default:
			return {
				name: 'Biometric',
				color: '',
				icon: <ColorIcon width={width} height={height} color={color} />,
				circularIcon: (
					<ColorIcon width={width} height={height} color={color} />
				),
				gradient:
					'linear-gradient(270deg, #d6d8d6 0%, rgba(41, 41, 41, 0.5) 100%)',
			};
	}
};
