import React, { ChangeEventHandler, useEffect, useMemo } from 'react';
import {
	StyledBox,
	StyledInput,
	StyledRow,
} from '../../../common/styledCommonComponents';
import SearchIcon from '../../../assets/SearchIcon';
import { useTheme } from 'styled-components';
import { debounce } from 'lodash';

interface SearchProps {
	onSearch: (searchText: string) => void;
	placeholder: string;
}

const Search: React.FC<SearchProps> = ({ onSearch, placeholder }) => {
	const theme = useTheme();

	const debouncedSearch = useMemo(
		() =>
			debounce(async (word) => {
				onSearch(`${word.charAt(0).toUpperCase()}${word.slice(1)}`);
			}, 300),
		[onSearch],
	);

	useEffect(() => {
		return () => {
			debouncedSearch.cancel();
		};
	}, [debouncedSearch]);

	const handleInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
		const value = e.target.value;
		debouncedSearch(value);
	};
	return (
		<StyledBox
			style={{
				flexShrink: 0,
			}}
		>
			<StyledRow
				css={{
					width: '100%',
					minWidth: '360px',
					gap: '16px',
					alignItems: 'center',
					paddingBottom: 12,
					borderBottom: `1px solid ${theme.gray200}`,
				}}
			>
				<SearchIcon color={theme.gray800} />
				<StyledInput
					key="SearchInput"
					type="text"
					placeholder={placeholder}
					onChange={handleInputChange}
					css={{
						color: theme.black,
						fontSize: '14px',
						border: 'none',
						outline: 'none',
						width: '100%',
					}}
				/>
			</StyledRow>
		</StyledBox>
	);
};

export default Search;
