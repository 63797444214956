import { MenuType } from '../../pages/ValidationForm/components/TNMContainer/utils';
import { api } from './api';
import { HospitalResponse, SpecResponse } from './types';

type DiseaseResponseType = {
	id: string;
	value: string;
	description: string;
	diseaseSpecChoiceChildren?: {
		id: string;
		value: string;
		description: string;
	}[];
};

export const validateFormApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getCancer: builder.query<any, void>({
			query: () => ({
				url: 'cancer',
				method: 'GET',
			}),
		}),
		getMedications: builder.query<any, { medicationIntention: string }>({
			query: ({ medicationIntention }) => ({
				url: `/disease/medication/${medicationIntention}`,
			}),
		}),
		treatment: builder.query<any, void>({
			query: () => ({
				url: '/treatment/primaryTreatment',
			}),
		}),
		medicationIntention: builder.query<any, void>({
			query: () => ({
				url: '/treatment/medicationIntention',
			}),
		}),
		getCancerOrgan: builder.query<SpecResponse[], any>({
			query: () => ({
				url: '/disease/spec/cancer_organ',
				method: 'GET',
			}),
		}),
		getCancerType: builder.query<SpecResponse[], any>({
			query: (cancerId) => ({
				url: `/disease/spec/cancer_type/${cancerId}`,
				method: 'GET',
			}),
		}),
		getCancerSubType: builder.query<SpecResponse[], any>({
			query: (cancerTypeId: string) => ({
				url: `/disease/spec/cancer_subtype/${cancerTypeId}`,
				method: 'GET',
			}),
		}),
		getCancerStage: builder.query<any, any>({
			query: (cancerId) => ({
				url: `/disease/cancer/stage/${cancerId}`,
				method: 'GET',
			}),
		}),
		hospitals: builder.query<HospitalResponse[], any>({
			query: () => ({
				url: '/institution',
			}),
		}),
		getTumor: builder.query<MenuType[], any>({
			query: (cancerId) => ({
				url: `/disease/spec/tumor/${cancerId}`,
				method: 'GET',
			}),
			transformResponse: (response: DiseaseResponseType[]): MenuType[] => {
				return response.map((tumor: DiseaseResponseType) => {
					let finalResponse: MenuType = {
						label: tumor.value,
						value: tumor.id,
						description: tumor.description,
					};
					if (
						tumor?.diseaseSpecChoiceChildren &&
						tumor?.diseaseSpecChoiceChildren?.length > 0
					) {
						finalResponse = {
							...finalResponse,
							children: [
								{
									label: tumor.value,
									value: tumor.id,
									description: tumor.description,
								},
								...tumor.diseaseSpecChoiceChildren.map(
									(
										child: Omit<
											DiseaseResponseType,
											'diseaseSpecChoiceChildren'
										>,
									) => ({
										label: child.value,
										value: child.id,
										description: child.description,
									}),
								),
							],
						};
					}
					return finalResponse;
				});
			},
		}),
		getNodule: builder.query<MenuType[], any>({
			query: (cancerId) => ({
				url: `/disease/spec/nodule/${cancerId}`,
				method: 'GET',
			}),
			transformResponse: (response: DiseaseResponseType[]): MenuType[] => {
				return response.map((nodule: DiseaseResponseType) => {
					let finalResponse: MenuType = {
						label: nodule.value,
						value: nodule.id,
						description: nodule.description,
					};
					if (
						nodule?.diseaseSpecChoiceChildren &&
						nodule?.diseaseSpecChoiceChildren?.length > 0
					) {
						finalResponse = {
							...finalResponse,
							children: [
								{
									label: nodule.value,
									value: nodule.id,
									description: nodule.description,
								},
								...nodule.diseaseSpecChoiceChildren.map(
									(
										child: Omit<
											DiseaseResponseType,
											'diseaseSpecChoiceChildren'
										>,
									) => ({
										label: child.value,
										value: child.id,
										description: child.description,
									}),
								),
							],
						};
					}
					return finalResponse;
				});
			},
		}),
		getMetastasis: builder.query<MenuType[], any>({
			query: (cancerId) => ({
				url: `/disease/spec/metastasis/${cancerId}`,
				method: 'GET',
			}),
			transformResponse: (response: DiseaseResponseType[]): MenuType[] => {
				return response.map((metastasis: DiseaseResponseType) => {
					let finalResponse: MenuType = {
						label: metastasis.value,
						value: metastasis.id,
						description: metastasis.description,
					};
					if (
						metastasis?.diseaseSpecChoiceChildren &&
						metastasis?.diseaseSpecChoiceChildren?.length > 0
					) {
						finalResponse = {
							...finalResponse,
							children: [
								{
									label: metastasis.value,
									value: metastasis.id,
									description: metastasis.description,
								},
								...metastasis.diseaseSpecChoiceChildren.map(
									(
										child: Omit<
											DiseaseResponseType,
											'diseaseSpecChoiceChildren'
										>,
									) => ({
										label: child.value,
										value: child.id,
										description: child.description,
									}),
								),
							],
						};
					}
					return finalResponse;
				});
			},
		}),
		getCancerMedication: builder.query<any, void>({
			query: () => ({
				url: '/treatment/cancer-medication',
				method: 'GET',
			}),
		}),
		getBiomarkers: builder.query<any, any>({
			query: (cancerId) => ({
				url: `/disease/spec/biomarker/${cancerId}`,
				method: 'GET',
			}),
		}),
		getEvaluations: builder.query<any, any>({
			query: (biomarkerId) => ({
				url: `/disease/spec/evaluation/${biomarkerId}`,
				method: 'GET',
			}),
		}),
		getSamples: builder.query<any, any>({
			query: (cancerId) => ({
				url: `/disease/spec/sample`,
				method: 'GET',
			}),
		}),
		getTreatmentOptions: builder.query<any, any>({
			query: (spec) => ({
				url: `/treatment/spec/${spec}`,
				method: 'GET',
			}),
		}),
		denyPatientForm: builder.mutation<any, any>({
			query: (patientProfileId) => ({
				url: `/user/deny/${patientProfileId}`, // waiting for endpoint
				method: 'POST',
			}),
		}),
		setPatientForm: builder.mutation<any, any>({
			query: (medicalHistory) => ({
				url: '/medical-history',
				method: 'POST',
				body: medicalHistory,
			}),
		}),
		setSetbacks: builder.mutation<any, any>({
			query: (setBacks) => ({
				url: '/medical-history/setback',
				method: 'POST',
				body: setBacks,
			}),
		}),
		setBiomarker: builder.mutation<any, any>({
			query: (biomarker) => ({
				url: '/medical-history/biomarker',
				method: 'POST',
				body: biomarker,
			}),
		}),
		setTreatmentForm: builder.mutation({
			query: (treatment) => ({
				url: '/treatment',
				method: 'POST',
				body: treatment,
			}),
			invalidatesTags: ['TreatmentList'],
		}),
		createTreatmentForm: builder.mutation({
			query: ({ body }) => ({
				url: '/user/form/treatment',
				body,
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
			}),
		}),
		updateTreatmentForm: builder.mutation({
			query: (body) => ({
				url: '/user/form/treatment',
				body,
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json',
				},
			}),
		}),
		updateValidatePatient: builder.mutation({
			query: (status) => ({
				url: '/user/validate',
				method: 'POST',
				body: status,
			}),
			invalidatesTags: [
				'UserList',
				'CancerStatistics',
				'PatientsStatistics',
			],
		}),
		getDiseases: builder.query<any, void>({
			query: () => ({
				url: '/medic-data/other-diseases',
				method: 'GET',
			}),
		}),
		getOtherMedications: builder.query<any, void>({
			query: () => ({
				url: '/medic-data/other-medication',
				method: 'GET',
			}),
		}),
		postForm: builder.mutation({
			query: ({ specType, body }: { specType: string; body: any }) => ({
				url: `/user/form/${specType}`,
				method: 'POST',
				body: body,
			}),
		}),
		removePatient: builder.mutation({
			query: (userId: string) => ({
				url: `/user/deny/patient/${userId}`,
				method: 'POST',
			}),
		}),
	}),
});

export const {
	//
	useGetDiseasesQuery,
	useGetOtherMedicationsQuery,

	//
	useRemovePatientMutation,

	useHospitalsQuery,
	useGetMedicationsQuery,
	//
	useLazyGetCancerQuery,
	useGetCancerOrganQuery,
	useGetCancerSubTypeQuery,
	useGetCancerStageQuery,
	useLazyGetMedicationsQuery,
	useGetCancerQuery,
	useGetBiomarkersQuery,
	useLazyGetBiomarkersQuery,
	useGetSamplesQuery,
	useLazyGetSamplesQuery,
	useGetCancerTypeQuery,
	useLazyGetTumorQuery,
	useLazyGetNoduleQuery,
	useLazyGetMetastasisQuery,
	useGetTumorQuery,
	useGetNoduleQuery,
	useGetEvaluationsQuery,
	useGetMetastasisQuery,
	useLazyGetCancerStageQuery,
	useLazyGetCancerTypeQuery,
	useLazyGetCancerSubTypeQuery,
	useGetCancerMedicationQuery,
	useSetPatientFormMutation,
	useSetBiomarkerMutation,
	useSetSetbacksMutation,
	useSetTreatmentFormMutation,
	useCreateTreatmentFormMutation,
	useUpdateTreatmentFormMutation,
	useUpdateValidatePatientMutation,
	useTreatmentQuery,
	useMedicationIntentionQuery,
	useDenyPatientFormMutation,
	usePostFormMutation,
	useGetTreatmentOptionsQuery,
} = validateFormApi;

export const {
	endpoints: {
		getMedications,
		getCancer,
		getBiomarkers,
		getSamples,
		getCancerOrgan,
		getCancerType,
		getCancerSubType,
		getCancerMedication,
		denyPatientForm,
		setPatientForm,
		getEvaluations,
		setBiomarker,
		setSetbacks,
		setTreatmentForm,
		updateValidatePatient,
		treatment,
		medicationIntention,
		getTreatmentOptions,
	},
} = validateFormApi;
