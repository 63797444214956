import React from 'react';
import { StyledBox, StyledP, StyledRow } from '../../styledCommonComponents';
import { StyledInput } from './styles';
import StyledText from '../Text/styles';
import { theme } from '../../theme';
import { CSSObject } from 'styled-components';
import { transform } from '../../../validations/transform';
import { ValidationType } from '../../../validations';

interface TextInputFieldProps {
	id?: string;
	required?: boolean;
	section?: string;
	height?: string;
	width?: string;
	placeholder?: string;
	value: string;
	label: string;
	onChange: any; // (...args: any) => void;
	name: string;
	type?: string;
	disabled?: boolean;
	max?: string | number;
	min?: string | number;
	error?: boolean | string;
	isTreatment?: boolean;
	hasError?: boolean; // This is to remove the margin bottom of the input
	css?: CSSObject;
}

const TextInputField = ({
	id,
	required,
	section,
	height,
	width = '100%',
	placeholder,
	value,
	label,
	onChange,
	name,
	type,
	disabled,
	max,
	min,
	error,
	isTreatment,
	css,
	hasError = true,
}: TextInputFieldProps) => {
	const handleOnChange = (newValue: string) =>
		onChange(name, newValue, id, section);
	const today = new Date().toISOString().split('T')[0];
	return (
		<StyledBox
			css={{
				width,
				height,
				display: 'flex',
				flexDirection: 'column',
				boxSizing: 'border-box',
				justifyContent: 'flex-end',
				pointerEvents: disabled
					? 'none'
					: // @ts-ignore
					  ('default' as React.CSSProperties['pointerEvents']),
				...css,
			}}
		>
			<StyledRow css={{ gap: '4px' }}>
				<StyledText
					variant="body2"
					css={{
						marginBottom: '8px',
						color: disabled ? theme.gray300 : 'black',
					}}
				>
					{label}
				</StyledText>
				{required && (
					<StyledText
						variant="body2"
						css={{ marginBottom: '8px', color: theme.error400 }}
					>
						*
					</StyledText>
				)}
			</StyledRow>
			<StyledInput
				disabled={disabled}
				max={max === 'today' ? today : max}
				min={min === 'today' ? today : min}
				type={type}
				placeholder={placeholder}
				value={value}
				onChange={(e) => handleOnChange(e.target.value)}
				error={!!error}
				css={{
					textTransform: type === 'date' ? 'uppercase' : 'none',
				}}
			/>
			{hasError && (
				<StyledText
					variant={'helperText'}
					css={{ color: theme.error400, height: 15, marginTop: 8 }}
				>
					{!!error && transform(error as ValidationType)}
				</StyledText>
			)}
		</StyledBox>
	);
};

export default TextInputField;
