import { StyledRow } from '../../../../common/styledCommonComponents';
import React from 'react';
import Search from '../../../../components/TableComponent/Search';
import Button from '../../../../common/components/Button';
import PlusIcon from '../../../../assets/PlusIcon';
import { useOncoDispatch } from '../../../../hooks/redux-hook';
import { setModalOpen } from '../../../../redux/slices/utilsSlice';
import { ModalTypeEnum } from '../../../../utils/utils';

interface HeaderProps {
	handleOpenModal: () => void;
}

const Header = ({ handleOpenModal }: HeaderProps) => {
	return (
		<StyledRow
			css={{
				width: '100%',
				justifyContent: 'space-between',
				alignItems: 'flex-end',
				minHeight: '82px',
				padding: '24px 24px 16px',
			}}
		>
			<Search onSearch={() => {}} placeholder={'Buscar...'} />

			<Button
				variant={'filled'}
				onClick={handleOpenModal}
				icon={PlusIcon}
				size={'medium'}
			>
				Agregar nuevo archivo
			</Button>
		</StyledRow>
	);
};
export default Header;
