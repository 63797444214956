import React from 'react';
import StyledText from '../../../common/components/Text';
import { StyledRow } from '../../../common/styledCommonComponents';
import { getProfileImageFromName } from '../../../utils/utils';

interface PatientDataHeaderProps {
	name: string;
	surname: string;
}
export const PatientDataHeader = ({
	name,
	surname,
}: PatientDataHeaderProps) => {
	return (
		<StyledRow css={{ gap: 16, alignItems: 'center' }}>
			{getProfileImageFromName({
				name: name ?? '',
				surname: surname ?? '',
				size: { width: 28, height: 28, fontSize: '12px' },
			})}
			<StyledText variant="body1" css={{ color: '#000' }}>
				{!name ? 'Cargando...' : `${name} ${surname}`}
			</StyledText>
		</StyledRow>
	);
};
