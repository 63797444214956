import styled, { keyframes } from 'styled-components';

export const LoaderContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const Background = styled.div`
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #fff;
`;

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const RoundLoaderCircle = styled.div`
	border: 8px solid #f3f3f3;
	border-top: 8px solid #9357f7;
	border-radius: 50%;
	width: 50px;
	height: 50px;
	animation: ${spin} 1s linear infinite;
`;
