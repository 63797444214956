import React from 'react';
import { IconProps } from './IconInterface';

const OralIcon = ({
	color = '#000000',
	width = 24,
	height = 24,
}: IconProps) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g id="Variants=oral">
				<path
					id="Union"
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M11.9882 9.79587L12 9.79582L12.0118 9.79587C12.0928 9.79637 12.2764 9.79752 12.3924 9.63642C12.6131 9.32988 13.0913 9.17047 13.0913 9.17047C13.0913 9.17047 14.4646 8.65549 17.1744 9.4157C18.7317 9.75012 21 10.8381 21 10.8381L20.7548 10.9852C20.7548 10.9852 18.2902 11.6351 16.6226 11.7822C14.8202 11.9412 13.1403 12.0152 12 12.0152C10.8597 12.0152 9.17984 11.9412 7.37738 11.7822C5.70981 11.6351 3.24523 10.9852 3.24523 10.9852L3 10.8381C3 10.8381 5.26832 9.75012 6.82561 9.4157C9.53542 8.65549 10.9087 9.17047 10.9087 9.17047C10.9087 9.17047 11.3869 9.32988 11.6076 9.63642C11.7236 9.79752 11.9072 9.79637 11.9882 9.79587ZM4.87602 12.1623C4.87602 12.1623 7.43259 14.3694 7.8188 14.6269C8.20501 14.8844 10.2343 15.7795 12 15.7795C13.7657 15.7795 15.795 14.8844 16.1812 14.6269C16.5674 14.3694 19.124 12.1623 19.124 12.1623C19.124 12.1623 17.6907 12.3946 16.782 12.5302C14.9352 12.8056 13.5215 12.784 12.5407 12.7689C12.3428 12.7659 12.1626 12.7631 12 12.7631C11.8374 12.7631 11.6572 12.7659 11.4593 12.7689C10.4785 12.784 9.06477 12.8056 7.21798 12.5302C6.30933 12.3946 4.87602 12.1623 4.87602 12.1623Z"
					fill={color}
				/>
			</g>
		</svg>
	);
};
export default OralIcon;
