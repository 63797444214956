import styled from 'styled-components';
import { Accordion } from '@mui/material';
// gap 24
export const StyledCustomAccordion = styled(Accordion)`
	&.MuiAccordion-root {
		box-shadow: none !important;
		margin: 0 !important;
	}

	&.MuiButtonBase-root {
		min-height: 54px !important;
		max-height: 54px !important;
		height: 54px !important;
	}

	&.MuiAccordionSummary-content {
		max-height: 54px;
		min-height: 54px !important;
		margin: 12px 0 !important;
	}

	&.Mui-expanded {
		margin: 0 !important;
	}

	&.MuiAccordionSummary-contentGutters {
		min-height: 54px !important;
		max-height: 54px !important;
	}
	&.MuiAccordionSummary-content.Mui-expanded {
		min-height: 54px !important;
		max-height: 54px !important;
		margin: 12px 0 !important;
	}
	&.MuiAccordionSummary-root {
		max-height: 54px !important;
	}
	.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
		min-height: 54px !important;
		max-height: 54px !important;
	}
`;
