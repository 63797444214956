import styled, { CSSObject, DefaultTheme } from 'styled-components';
import { Menu } from '@szhsin/react-menu';
import { StyledRow } from '../../../../../../common/styledCommonComponents';

export const StyledMenu = styled(Menu)<{ width: number }>`
	.szh-menu {
		padding: 8px;
		border-radius: 8px;
		margin-top: 4px !important;
		background-color: white;
		box-shadow: none !important;
		width: ${(props) => props.width}px;
		border: 1px solid ${({ theme }) => theme.gray100};
	}

	.szh-menu__item {
		padding: 16px;
		border-radius: 8px;
		&:hover {
			background-color: ${({ theme }) => theme.primary100};
		}
	}

	.custom-item,
	.szh-menu__item--submenu {
		padding: 16px;
		border-radius: 8px;
		background-color: white;

		&:hover {
			background-color: ${({ theme }) => theme.primary100};
		}

		&::after {
			display: none;
		}
	}

	.szh-menu__item--submenu {
		padding: 16px 0 16px 16px;
	}
`;

export type SubmenuVariant = 'default' | 'error' | 'disabled';

interface StyledSubmenuButtonProps {
	variant: SubmenuVariant;
}

const getVariantStyle = (
	theme: DefaultTheme,
): Record<SubmenuVariant, CSSObject> => {
	return {
		default: {
			backgroundColor: theme.white,
			border: `1px solid ${theme.gray200}`,
		},
		error: {
			border: `1px solid ${theme.error400}`,
		},
		disabled: {
			backgroundColor: theme.gray50,
			border: `1px solid ${theme.gray200}`,
		},
	};
};
export const StyledSubmenuButton = styled(StyledRow)<StyledSubmenuButtonProps>`
	padding: 12px 12px 12px 16px;
	border-radius: 8px;
	height: 43px;
	justify-content: space-between;
	${(props) => getVariantStyle(props.theme)[props.variant]}
`;
