import React, { useMemo, useState } from 'react';
import {
	StyledBox,
	StyledColumn,
	StyledRow,
	StyledScreen,
} from '../../common/styledCommonComponents';
import { TableContainer } from '@mui/material';
import TableComponent from '../../components/TableComponent';
import { formatDate, ReportTableHeader } from './utils';
import { useNavigate, useParams } from 'react-router-dom';
import { PatientDataHeader } from '../../components/TableComponent/PatientDataHeader';
import {
	useGetPatientDataQuery,
	useGetPatientReportsQuery,
} from '../../redux/api/patientApi';
import StyledText, { StyledH5 } from '../../common/components/Text/styles';
import { theme } from '../../common/theme';
import { CapitalizeText, ModalTypeEnum } from '../../utils/utils';
import { useOncoDispatch } from '../../hooks/redux-hook';
import { setModalOpen } from '../../redux/slices/utilsSlice';
import ReportItem from './components/ReportItem';
import ChevronLeftIcon from '../../assets/ChevronLeftIcon';
import { useTheme } from 'styled-components';

const PatientReportScreen = () => {
	const { patientId } = useParams();
	const [actualPage, setActualPage] = useState<number>(0);
	const navigate = useNavigate();
	const theme = useTheme();

	const dispatch = useOncoDispatch();

	const { data: patientData } = useGetPatientDataQuery(patientId);

	const { data: patientReports, isLoading: loadingReports } =
		useGetPatientReportsQuery({
			patientId: patientId || '',
			firstPage: actualPage,
			pageSize: 8,
		});
	const handlePageChange = (newPage: number) => {
		setActualPage(newPage - 1);
	};

	const onRowClick = (row: any) => {
		let reportType: string = row.icon;
		let reportId: string = row.id;

		switch (reportType) {
			case 'weekly':
				dispatch(
					setModalOpen({
						open: true,
						type: ModalTypeEnum.WEEKLY_MODAL,
						id: reportId,
					}),
				);
				break;
			case 'daily':
				dispatch(
					setModalOpen({
						open: true,
						type: ModalTypeEnum.DAILY_MODAL,
						id: reportId,
					}),
				);

				break;
			case 'biometric':
				dispatch(
					setModalOpen({
						open: true,
						type: ModalTypeEnum.SUMMARY_QUESTIONARY_MODAL,
						id: reportId,
					}),
				);
				break;
			default:
				break;
		}
	};
	const formName = (name: string) => {
		switch (name) {
			case 'weekly':
				return 'PRO-CTCAE';
			case 'daily':
				return 'Estado general del paciente';
			case 'biometric':
				return 'Datos biométricos';
			default:
				return 'Nombre de formulario no reconocido';
		}
	};

	const EmptyFormTable = () => (
		<StyledColumn
			css={{
				flex: 1,
				backgroundColor: 'white',
				justifyContent: 'center',
				alignItems: 'center',
				gap: '16px',
			}}
		>
			<StyledH5 css={{ color: theme.primary500 }}>
				No hay encuestas completadas
			</StyledH5>
			<StyledText variant="body1" css={{ color: theme.gray500 }}>
				¡Anima a {patientData.name} a completar su primera encuesta!
			</StyledText>
		</StyledColumn>
	);

	const formattedData = useMemo(() => {
		return patientReports?.patients.map((form: any) => ({
			id: form.id,
			formId: form.formId,
			name: formName(form.name),
			icon: form.name,
			startDate: form.startDate,
			finishDate: form.finishDate,
		}));
	}, [patientReports]);

	return (
		<>
			<StyledScreen
				css={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
				}}
			>
				<StyledColumn
					css={{
						margin: '20px',
						padding: '20px 40px',
						width: '100%',
						boxSizing: 'border-box',
						flex: 1,
						gap: 8,
					}}
				>
					<StyledRow
						css={{
							padding: 8,
							gap: 4,
							cursor: 'pointer',
						}}
						onClick={() => navigate('/profile/' + patientId)}
					>
						<ChevronLeftIcon
							width={16}
							height={16}
							color={theme.gray850}
						/>
						<StyledText
							variant={'body2'}
							css={{
								color: theme.gray850,
							}}
						>
							Volver al perfil
						</StyledText>
					</StyledRow>
					<StyledColumn
						as={TableContainer}
						css={{
							width: 'calc(100vw - 40px)',
							height: '100%',
							maxWidth: '1124px',
							maxHeight: '824px',
							flex: 1,
							justifyContent: 'space-between',
						}}
					>
						<TableComponent
							formsTable={true}
							headerComponent={
								<PatientDataHeader
									name={CapitalizeText(patientData?.name)}
									surname={CapitalizeText(patientData?.surname)}
								/>
							}
							emptyComponent={<EmptyFormTable />}
							patientName={patientData?.name}
							headerStructure={ReportTableHeader}
							dataList={formattedData}
							loading={loadingReports}
							actualPage={actualPage + 1}
							totalPages={
								patientReports?.maxPage ? patientReports.maxPage + 1 : 0
							}
							handlePageChange={handlePageChange}
							onRowClick={onRowClick}
							itemComponent={ReportItem}
							handleOptionsClick={[
								{ type: 'Ver reporte', handler: onRowClick },
							]}
						/>
					</StyledColumn>
				</StyledColumn>
			</StyledScreen>
		</>
	);
};
export default PatientReportScreen;
