import React, { forwardRef } from 'react';
import { useTheme } from 'styled-components';
import { MenuType } from '../../utils';
import {
	StyledRow,
	StyledColumn,
} from '../../../../../../common/styledCommonComponents';
import StyledText from '../../../../../../common/components/Text';
import ChevronRightIcon from '../../../../../../assets/ChevronRightIcon';
import { MenuItem, SubMenu } from '@szhsin/react-menu';

import { StyledMenu, StyledSubmenuButton } from './styles';
import ChevronDownIcon from '../../../../../../assets/ChevronDownIcon';
import { ValidationType } from '../../../../../../validations';
import { transform } from '../../../../../../validations/transform';
import '@szhsin/react-menu/dist/index.css';
import { CircularProgress } from '@mui/material';

const ItemComponent = ({
	label,
	description,
	hasArrow = false,
}: Omit<MenuType, 'children'> & {
	hasArrow?: boolean;
}) => {
	const theme = useTheme();
	return (
		<StyledRow
			css={{
				justifyContent: hasArrow ? 'space-between' : 'start',
				alignItems: 'center',
				width: '100%',
			}}
		>
			<StyledColumn css={{ gap: 4 }}>
				<StyledText variant={'body1Bold'}>{label}</StyledText>
				<StyledText variant={'body3'}>{description}</StyledText>
			</StyledColumn>
			{hasArrow && (
				<ChevronRightIcon
					color={theme.gray700}
					width={20}
					height={20}
					styles={{ minWidth: 20 }}
				/>
			)}
		</StyledRow>
	);
};

const MenuComponent = ({
	element,
	onChange,
}: {
	element: MenuType;
	onChange: (value: string) => void;
}) => {
	return (
		<>
			{element?.children ? (
				<SubMenu
					label={<ItemComponent {...element} hasArrow />}
					arrow={false}
				>
					{element?.children.map((menu) => (
						<MenuComponent element={menu} onChange={onChange} />
					))}
				</SubMenu>
			) : (
				<MenuItem onClick={() => onChange(element.label)}>
					<ItemComponent {...element} />
				</MenuItem>
			)}
		</>
	);
};

interface SubmenuFieldProps {
	menuList: MenuType[];
	name: string;
	label: string;
	onChange: (name: string, value: string) => void;
	value: string;
	loading: boolean;
	disabled?: boolean;
	required?: boolean;
	width?: number;
	error?: boolean | string;
}

const SubmenuField = forwardRef<HTMLDivElement, SubmenuFieldProps>(
	(
		{
			menuList,
			name,
			value,
			label,
			onChange,
			loading,
			disabled,
			required,
			width,
			error,
		},
		ref,
	) => {
		const theme = useTheme();
		return (
			<StyledColumn
				css={{
					width: '100%',
					pointerEvents: disabled ? 'none' : 'auto',
				}}
				ref={ref}
			>
				<StyledRow css={{ gap: 4 }}>
					<StyledText
						variant="body2"
						css={{
							marginBottom: '8px',
							color: disabled ? theme.gray300 : 'black',
						}}
					>
						{label}
					</StyledText>
					{required && (
						<StyledText
							variant="body2"
							css={{ marginBottom: '8px', color: theme.error400 }}
						>
							*
						</StyledText>
					)}
				</StyledRow>

				{loading ? (
					<StyledRow
						css={{
							padding: '12px 12px 12px 16px',
							border: `1px solid ${theme.gray200}`,
							backgroundColor: theme.gray50,
							borderRadius: 8,
							height: 43,
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<CircularProgress
							style={{ color: theme.gray300 }}
							size={18}
						/>
					</StyledRow>
				) : (
					<StyledColumn>
						<StyledMenu
							width={width ?? 250}
							menuStyle={{
								margin: '8px 0 0 0 !important',
								boxShadow: 'none',
							}}
							menuButton={
								<StyledSubmenuButton
									variant={
										disabled
											? 'disabled'
											: error
											? 'error'
											: 'default'
									}
								>
									<StyledText
										variant={'body1'}
										css={{
											color: disabled ? theme.gray400 : theme.black,
										}}
									>
										{!value || value === 'no_value'
											? `Seleccionar ${label}`
											: value}
									</StyledText>
									<ChevronDownIcon
										color={disabled ? theme.gray300 : theme.black}
										width={18}
										height={18}
									/>
								</StyledSubmenuButton>
							}
							onClick={(e) =>
								(disabled || loading) && e.preventDefault()
							}
						>
							{menuList.map((menu, index) => (
								<MenuComponent
									key={index + '-' + menu.label}
									element={menu}
									onChange={(value: string) => onChange(name, value)}
								/>
							))}
						</StyledMenu>
						<StyledText
							variant={'helperText'}
							css={{ color: theme.error400, height: 15, marginTop: 8 }}
						>
							{!!error && transform(error as ValidationType)}
						</StyledText>
					</StyledColumn>
				)}
			</StyledColumn>
		);
	},
);

export default SubmenuField;
