import { toast, ToastOptions } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { theme } from '../../common/theme';

const useToast = () => {
	const options: ToastOptions = {
		position: 'top-right',
		autoClose: 2500,
		hideProgressBar: true,
		closeOnClick: true,
		pauseOnHover: true,
		theme: 'colored',
		icon: false,
	};
	const toastSuccess = (component: any) => {
		toast.success(component, {
			...options,
			style: { background: `${theme.success500}` },
		});
	};
	const toastError = (component: any) => {
		toast.error(component, {
			...options,
			style: { background: `${theme.error400}` },
		});
	};
	const toastWarning = (component: any) => {
		toast.warning(component, {
			...options,
			style: { background: `${theme.warning400}` },
		});
	};
	const toastInfo = (component: any) => {
		toast.info(component, {
			...options,
			style: { background: `${theme.info400}` },
		});
	};
	return { toastSuccess, toastInfo, toastError, toastWarning };
};
export default useToast;
