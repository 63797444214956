import styled from 'styled-components';
import { StyledColumn } from '../../../../../common/styledCommonComponents';

export const StyledDragAndDrop = styled(StyledColumn)`
	border: 1px dashed ${({ theme }) => theme.primary500};
	border-radius: 20px;
	height: 150px;
	width: 100%;
	align-items: center;
	justify-content: center;
	gap: 16px;
`;
