import React, { SVGProps } from 'react';

const FeedbackIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={28}
		height={26}
		fill="none"
		{...props}
	>
		<path
			fill="#fff"
			d="M22.001 0a5.333 5.333 0 0 1 5.334 5.333V16A5.333 5.333 0 0 1 22 21.333h-6.298l-6.35 3.81a1.334 1.334 0 0 1-2.01-.991L7.335 24v-2.667H6a5.334 5.334 0 0 1-5.326-5.066L.668 16V5.333A5.333 5.333 0 0 1 6.001 0h16Zm-5.333 12h-8a1.334 1.334 0 0 0 0 2.667h8a1.333 1.333 0 0 0 0-2.667Zm2.667-5.333H8.668a1.333 1.333 0 1 0 0 2.666h10.667a1.333 1.333 0 1 0 0-2.666Z"
		/>
	</svg>
);

export default FeedbackIcon;
