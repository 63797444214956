import React from 'react';
import { IconProps } from './IconInterface';

const CircleInprogressIcon = ({
	color,
	width = 24,
	height = 24,
	...props
}: IconProps) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<rect x="2" y="2" width="20" height="20" rx="10" fill={color} />
			<path
				d="M13.1665 5.58334H10.8331C10.5123 5.58334 10.2498 5.84584 10.2498 6.16668C10.2498 6.48751 10.5123 6.75001 10.8331 6.75001H13.1665C13.4873 6.75001 13.7498 6.48751 13.7498 6.16668C13.7498 5.84584 13.4873 5.58334 13.1665 5.58334ZM11.9998 13.1667C12.3206 13.1667 12.5831 12.9042 12.5831 12.5833V10.25C12.5831 9.92918 12.3206 9.66668 11.9998 9.66668C11.679 9.66668 11.4165 9.92918 11.4165 10.25V12.5833C11.4165 12.9042 11.679 13.1667 11.9998 13.1667ZM16.1006 9.31084L16.5381 8.87334C16.7598 8.65168 16.7656 8.28418 16.5381 8.05668L16.5323 8.05084C16.3048 7.82334 15.9431 7.82918 15.7156 8.05084L15.2781 8.48834C14.374 7.76501 13.2365 7.33334 11.9998 7.33334C9.19981 7.33334 6.81981 9.64334 6.74981 12.4433C6.67398 15.4067 9.04815 17.8333 11.9998 17.8333C14.9048 17.8333 17.2498 15.4825 17.2498 12.5833C17.2498 11.3467 16.8181 10.2092 16.1006 9.31084ZM11.9998 16.6667C9.74231 16.6667 7.91648 14.8408 7.91648 12.5833C7.91648 10.3258 9.74231 8.50001 11.9998 8.50001C14.2573 8.50001 16.0831 10.3258 16.0831 12.5833C16.0831 14.8408 14.2573 16.6667 11.9998 16.6667Z"
				fill="white"
			/>
		</svg>
	);
};
export default CircleInprogressIcon;
