import React from 'react';
import {
	removeSetback,
	SetbackInterface,
	updateSetback,
	removeAdditionalField,
} from '../../../../redux/slices/validationSlice';
import {
	StyledBox,
	StyledColumn,
	StyledRow,
} from '../../../../common/styledCommonComponents';
import Dropdown from '../../../../common/components/Dropdown';
import TextInputField from '../../../../common/components/TextInputField';
import { useOncoDispatch, useOncoSelector } from '../../../../hooks/redux-hook';
import TreatmentContainer from '../TreatmentContainer';
import { useTheme } from 'styled-components';
import RemoveButton from '../RemoveButton';
import moment from 'moment';

interface SetbackContainerProps {
	id: string;
	setback: SetbackInterface;
}

const SetbackContainer = ({ id, setback }: SetbackContainerProps) => {
	const dispatch = useOncoDispatch();

	const auxiliarData = useOncoSelector(
		(state) => state.validationSlice.auxiliarData,
	);

	const theme = useTheme();
	const errors = useOncoSelector((state) => state.validationSlice.errors)[id];
	const handleOnChange = (name: string, value: string) => {
		dispatch(
			updateSetback({
				id,
				key: name as keyof typeof setback,
				value,
			}),
		);
	};

	const handleRemoveSetback = () => {
		dispatch(
			removeSetback({
				id,
			}),
		);
		dispatch(removeAdditionalField({ id }));
	};

	const isEditable =
		auxiliarData?.additionalFields.some(
			(field: { id: string }) => field.id === id,
		) ?? false;

	return (
		<StyledColumn
			css={{
				flex: 1,
				gap: 24,
				padding: 16,
				border: `1px solid ${theme.gray200}`,
				borderRadius: 12,
			}}
		>
			<StyledRow css={{ gap: 32, alignItems: 'flex-end' }}>
				<TextInputField
					value={moment(setback.date).format('YYYY-MM-DD')}
					label={'Fecha de recaida'}
					onChange={handleOnChange}
					name={'date'}
					disabled={!isEditable}
					error={
						errors?.properties?.find((item) => item.key === 'date')
							?.error[0] ?? false
					}
					type={'date'}
					required
					max="today"
				/>
				<Dropdown
					value={setback.localOrDistance}
					label={'Local o distancia'}
					onChange={handleOnChange}
					name={'localOrDistance'}
					options={{
						Local: 'Local',
						Distancia: 'Distancia',
						'Local y Distancia': 'Local y Distancia',
					}}
					error={
						errors?.properties?.find(
							(item) => item.key === 'localOrDistance',
						)?.error[0] ?? false
					}
					disabled={!isEditable}
					required
				/>
				{setback.localOrDistance !== 'Local' ? (
					<Dropdown
						value={setback.placeMetastasis ?? ''}
						label={'Sitio de metastasis'}
						onChange={handleOnChange}
						name={'placeMetastasis'}
						options={{
							Pulmón: 'Pulmón',
							Cerebro: 'Cerebro',
							Higado: 'Higado',
							Hueso: 'Hueso',
							'Glándula suprarrenal': 'Glándula suprarrenal',
							Ganglionar: 'Ganglionar',
							Pleura: 'Pleura',
							'Piel y partes blandas': 'Piel y partes blandas',
							Bazo: 'Bazo',
							Páncreas: 'Páncreas',
							Riñón: 'Riñón',
							Tiroides: 'Tiroides',
						}}
						disabled={!isEditable}
						error={
							errors?.properties?.find(
								(item) => item.key === 'placeMetastasis',
							)?.error[0] ?? false
						}
						required
					/>
				) : (
					<StyledBox
						css={{
							flex: 1,
						}}
					/>
				)}
				{!setback.treatment.isFinished && isEditable && (
					<RemoveButton
						onClick={handleRemoveSetback}
						css={{ marginBottom: 23 }}
					/>
				)}
			</StyledRow>
			<TreatmentContainer
				id={id}
				prefix={'setbacks'}
				isEditable={isEditable}
			/>
		</StyledColumn>
	);
};

export default SetbackContainer;
