import React from 'react';
import CustomAccordionWeekly from '../CustomAccordionWeekly';
import { StyledAccordionWeekly } from './styles';

const Body = ({ data }: any) => {
	function transformArrayToHTML(array: any[]) {
		let result = '<p>';
		array.forEach((item: any) => {
			if (item.style === 'NORMAL') {
				result += item.text;
			} else if (item.style === 'BOLD') {
				result += `<strong>${item.text}</strong>`;
			}
		});
		result += '</p>';
		return result;
	}

	function parseSymptoms(symptoms: any) {
		return symptoms.map((symptom: any) => {
			return {
				name: symptom.subcategory,
				questions: symptom.questions.map((question: any) => {
					return {
						date: question.date ? new Date(question.date) : undefined,
						type: question.category,
						value: question.answer,
						question: transformArrayToHTML(JSON.parse(question.question)),
						questionId: question.questionId,
					};
				}),
			};
		});
	}

	return (
		<StyledAccordionWeekly>
			{data &&
				data.categories.map((category: any) => (
					<CustomAccordionWeekly
						id={category.categoryId}
						category={category.category}
						symptoms={parseSymptoms(category.subcategories)}
						endDate={category.subcategories[0].questions[0].date}
					/>
				))}
		</StyledAccordionWeekly>
	);
};

export default Body;
