import React from 'react';
import Card from '../../../../components/Card';
import { ApexOptions } from 'apexcharts';
import { StyledSummaryChart } from './styles';
import { renderToString } from 'react-dom/server';
import { useGetAnswersDistributionQuery } from '../../../../redux/api/homeApi';
import { SummaryReportResponse } from '../../../../redux/api/types';
import { StyledCircularProgress } from '../../../../components/CustomCircularProgress/styles';
import { StyledRow } from '../../../../common/styledCommonComponents';
import { StyledH6 } from '../../../../common/components/Text/styles';
import { useTheme } from 'styled-components';
import { useOncoDispatch } from '../../../../hooks/redux-hook';
import { setModalOpen } from '../../../../redux/slices/utilsSlice';
import { ModalTypeEnum } from '../../../../utils/utils';
import { ErrorMessage } from '../../../PatientProfile/components/ErrorMessage';

const SummaryQuestionaries = () => {
	const theme = useTheme();
	const dispatch = useOncoDispatch();

	const {
		data: answers,
		isLoading: loadingAnswers,
		refetch: refetchAnswers,
		isError: errorAnswers,
	} = useGetAnswersDistributionQuery(7, {
		selectFromResult: (data: any) => {
			const answers: any[] = [];
			const formNames = ['daily', 'weekly', 'biometric'];

			data.data?.forEach((item: any) => {
				const utcDate = new Date(item.date);

				const utcOffset = utcDate.getTimezoneOffset();

				const gmtDate = new Date(utcDate.getTime() + utcOffset * 60 * 1000);

				const date = gmtDate;
				let forms: any = Array.isArray(item.forms) ? [...item.forms] : [];

				formNames.forEach((formName) => {
					if (!forms.some((form: any) => form.formName === formName)) {
						forms = [...forms, { formName, patients: [] }];
					}
				});

				answers.push({ date, forms });
			});
			return { ...data, data: answers.reverse() };
		},
	});

	const DayRecord: Record<number, string> = {
		0: 'Dom',
		1: 'Lun',
		2: 'Mar',
		3: 'Mie',
		4: 'Jue',
		5: 'Vie',
		6: 'Sab',
	};

	const dataTransformed = (reports: SummaryReportResponse) => {
		let patientsByDate: any[] = [];
		let dates: any[] = [];
		let series: { group: string; name: string; data: number[] }[] = [
			{
				group: 'Q1',
				name: 'Estado General',
				data: [],
			},
			{
				group: 'Q2',
				name: 'Calidad de vida',
				data: [],
			},
			{
				group: 'Q3',
				name: 'Biométricos',
				data: [],
			},
			{
				group: 'Q1',
				name: 'Q2',
				data: [],
			},
			{
				group: 'Q2',
				name: 'Q2 2',
				data: [],
			},
			{
				group: 'Q3',
				name: 'Q2 3',
				data: [],
			},
		];
		reports.forEach((report, indexDate) => {
			report.forms.forEach((form, index) => {
				let dataToPush =
					(form.patients.filter(
						(patient) => patient.formStatus === 'COMPLETE',
					).length *
						100) /
					form.patients.length;
				if (isNaN(dataToPush)) dataToPush = 0;
				series[index].data.push(dataToPush);
				series[index + 3].data.push(100 - dataToPush);
				patientsByDate[indexDate] = {
					...(patientsByDate[indexDate] ?? {}),
					[form.formName]: form.patients,
				};
			});
			patientsByDate[indexDate] = {
				...(patientsByDate[indexDate] ?? {}),
				date: report.date,
			};
			dates = [...dates, DayRecord[report.date.getDay()]];
		});
		return { series, dates, patientsByDate };
	};

	const useThisData = dataTransformed(answers);

	const handleOpenDetails = (dateIndex: number) => {
		dispatch(
			setModalOpen({
				open: true,
				type: ModalTypeEnum.SUMMARY_QUESTIONARY_MODAL,
				payload: {
					patients: useThisData?.patientsByDate[dateIndex],
					date: answers[dateIndex]?.date,
				},
			}),
		);
	};

	const state: { options: ApexOptions } = {
		options: {
			chart: {
				type: 'bar',
				stacked: true,
				height: 217,
				toolbar: {
					show: false,
				},
				events: {
					click(e: any, chart?: any, options?: any) {
						options.dataPointIndex >= 0 &&
							handleOpenDetails(options.dataPointIndex);
					},
				},
			},
			stroke: {
				width: 1,
				colors: ['#fff'],
			},
			dataLabels: {
				enabled: false,
				formatter: (val: any) => {
					return val / 1000 + 'K';
				},
			},
			plotOptions: {
				bar: {
					horizontal: false,
					borderRadius: 4,
					borderRadiusApplication: 'around',
					borderRadiusWhenStacked: 'all',
					hideZeroBarsWhenGrouped: true,
				},
			},
			xaxis: {
				categories: useThisData.dates,
				crosshairs: {
					show: false,
				},
				axisTicks: {
					show: false,
				},
				axisBorder: {
					show: false,
				},
			},
			fill: {
				opacity: 1,
			},
			colors: [
				'#D18EEB',
				'#6989F7',
				'#80CA8F',
				'#E0E1E3',
				'#E0E1E3',
				'#E0E1E3',
			],
			yaxis: {
				min: 0,
				max: 100,
				labels: {
					show: false,
					formatter: (val) => {
						return val / 1000 + 'K';
					},
				},
			},
			legend: {
				position: 'bottom',
				horizontalAlign: 'center',
				markers: {
					shape: 'circle',
				},
				onItemClick: {
					toggleDataSeries: false,
				},
				onItemHover: {
					highlightDataSeries: false,
				},
			},
			grid: {
				show: false,
			},
			tooltip: {
				enabled: true,
				marker: {
					show: false,
				},
				custom: function ({ series, seriesIndex, dataPointIndex, w }) {
					const patients: {
						id: string;
						patientProfileId: string;
						name: string;
						surname: string;
						formStatus: 'EMPTY' | 'COMPLETE' | 'INCOMPLETE';
					}[] = Object.values(useThisData.patientsByDate[dataPointIndex])[
						seriesIndex % 3
					] as {
						id: string;
						patientProfileId: string;
						name: string;
						surname: string;
						formStatus: 'EMPTY' | 'COMPLETE' | 'INCOMPLETE';
					}[];

					const completed =
						patients.filter(
							(patient) => patient.formStatus === 'COMPLETE',
						)?.length ?? 0;

					return renderToString(
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								padding: 8,
							}}
						>
							<p> {completed} pacientes completaron</p>
							<p>
								{' '}
								{patients.length - completed ?? 0} pacientes no
								completaron
							</p>
						</div>,
					);
				},
			},
			states: {
				active: {
					filter: {
						type: 'none',
						value: 0,
					},
				},
				hover: {
					filter: {
						type: 'none',
						value: 0.1,
					},
				},
			},
		},
	};

	return (
		<Card
			width={'445px'}
			height={'260px'}
			title={'Cantidad de respuestas por encuesta'}
			css={{ overflow: 'visible' }}
			childrenCss={{
				overflow: 'visible',
			}}
		>
			{loadingAnswers ? (
				<StyledRow
					css={{
						width: '100%',
						height: '100%',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<StyledCircularProgress size={50} />
				</StyledRow>
			) : errorAnswers ? (
				<ErrorMessage refetch={refetchAnswers} />
			) : !answers ||
			  answers.length === 0 ||
			  useThisData.series.length === 0 ? (
				<StyledRow
					css={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						width: '100%',
						height: '100%',
					}}
				>
					<StyledH6 css={{ color: theme.gray300 }}>Sin Datos</StyledH6>
				</StyledRow>
			) : (
				<StyledSummaryChart
					options={state.options}
					series={useThisData.series}
					type="bar"
					height={210}
				/>
			)}
		</Card>
	);
};

export default SummaryQuestionaries;
