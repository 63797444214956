import React from 'react';
import { IconProps } from './IconInterface';

const GenitalIcon = ({
	width = 24,
	height = 24,
	color = '#0E0E0E',
}: IconProps) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10.6754 3H12.6385V4.32587C13.263 4.28702 13.8682 4.32417 14.412 4.44148C12.1052 5.36927 11.0438 7.75359 10.87 8.14406L10.8593 8.16798C10.2956 8.08052 9.68491 8.0078 9.10703 8.01322C8.51152 8.06507 7.93373 8.15499 6.98217 8.70268C6.84799 8.79957 6.66709 8.95849 6.42126 9.20082C6.4034 9.18768 6.38426 9.17549 6.35923 9.15949C6.33921 9.1467 6.31531 9.13151 6.28527 9.11145C6.14938 9.02073 5.95096 8.89796 5.709 8.77535C5.21058 8.52264 4.59918 8.30715 4 8.30715V6.36219C5.03605 6.36219 5.96754 6.72161 6.60319 7.04388C6.8237 7.15566 7.01721 7.26739 7.17735 7.36669C7.31904 7.05767 7.50597 6.77026 7.72947 6.50527L6.65144 4.77667L8.37945 3.62997L9.37401 5.22479C9.78361 5.00895 10.2229 4.82984 10.6754 4.68908V3ZM15.9544 7.35851C15.239 7.69738 14.6197 8.25439 14.0614 9.32659C14.0614 9.32659 12.8228 8.74776 11.8179 8.44691C12.5891 6.84914 13.4866 5.95016 14.8101 5.32329C16.0903 4.71689 17.6176 4.56162 19.4319 4.62569L19.346 7.01203C17.672 6.9529 16.713 6.99919 15.9544 7.35851ZM7.37778 9.62955C3.81345 12.8782 8.90532 21 12.9789 21C17.0524 21 22.6616 12.8855 18.58 9.16546C18.4858 9.0796 18.4034 9.00237 18.3268 8.93066L18.3268 8.93061C17.9894 8.61452 17.7649 8.40424 17.1424 8.02601C15.8898 8.02601 15.135 9.30638 14.7913 10.0864C14.7738 10.126 14.7523 10.163 14.7274 10.1969L13.9352 12.4489L13.6497 13.4961C13.6195 13.6067 13.6274 13.7245 13.672 13.8298L14.0054 14.6173L15.732 14.4125L15.8452 15.4088L14.2213 15.6014L14.3269 17.182L13.3476 17.2503L13.2159 15.2785L12.7712 14.2281C12.6374 13.9121 12.6137 13.5587 12.7041 13.227L12.7126 13.1957L11.7144 13.657L11.0617 15.3429L10.149 14.974L10.6659 13.6388L9.09029 13.2594L9.31536 12.2832L11.2812 12.7564L13.0757 11.9271L13.6791 10.2117C12.1244 9.46531 8.93153 8.21337 7.37778 9.62955Z"
				fill={color}
			/>
		</svg>
	);
};

export default GenitalIcon;
