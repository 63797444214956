import React from 'react';
import { IconProps } from './IconInterface';

const SexualIcon = ({
	color = '#000000',
	width = 24,
	height = 24,
}: IconProps) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M6.07953 6.94585C6.42161 6.58883 6.82777 6.30561 7.27481 6.11238C7.72184 5.91916 8.20099 5.8197 8.68488 5.8197C9.16877 5.8197 9.64792 5.91916 10.095 6.11238C10.542 6.30561 10.9482 6.58883 11.2902 6.94585L12.0002 7.68645L12.7101 6.94585C13.4011 6.22503 14.3383 5.82008 15.3155 5.82008C16.2927 5.82008 17.2298 6.22503 17.9208 6.94585C18.6118 7.66667 19 8.64431 19 9.6637C19 10.6831 18.6118 11.6607 17.9208 12.3816L17.2109 13.1222L12.0002 18.5579L6.78947 13.1222L6.07953 12.3816C5.73729 12.0247 5.4658 11.601 5.28057 11.1347C5.09534 10.6683 5 10.1685 5 9.6637C5 9.15892 5.09534 8.65908 5.28057 8.19274C5.4658 7.7264 5.73729 7.3027 6.07953 6.94585Z"
				fill={color}
			/>
		</svg>
	);
};
export default SexualIcon;
