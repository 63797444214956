import { createSelector, createSlice, current } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import {
	actionTypeEnum,
	CapitalizeText,
	InputTypeEnum,
	ITreatment,
	IBiomarker,
	IProgression,
	ISetback,
} from '../../utils/utils';
import { patientApi } from '../api/patientApi';
import { validateFormApi } from '../api/validateFormApi';
import { tnmOptions } from '../../utils/tnm_options';
import { RootState } from '../store';
import React from 'react';
import { tumorMama } from '../../utils/mockedData';
import { MenuType } from '../../pages/ValidationForm/components/TNMContainer/utils';

export type FieldType = {
	id?: string;
	label?: string;
	placeholder?: string;
	type?: string;
	input_type: InputTypeEnum;
	name?: string;
	names?: string[];
	icon?: JSX.Element;
	disabled?: boolean;
	options?: any;
	handleClick?: (e?: any) => void | any;
	dependsOn?: {
		name: string;
		values: string[] | number[] | boolean;
	};
	errors?: { [key in string]: boolean };
	error?: boolean;
	validators?: {
		type: string;
		message: string;
	}[];
	css?: { [key in string]: string | number | boolean };
};

type initialStateFormType = {
	patients: {
		title: string;
		icon: string;
		fields: FieldType[][] | object[][];
	};
	hospital: {
		title: string;
		icon: string;
		fields: FieldType[][] | object[][];
	};
	biomarkers: {
		title: string;
		icon: string;
		fields: FieldType[][] | object[][];
		options: any;
		samples: any;
	};
	setbacks: {
		title: string;
		icon: string;
		fields: FieldType[][] | object[][];
	};
	progressions: {
		title: string;
		icon: string;
		fields: FieldType[][] | object[][];
	};
	edit: boolean;
	tnmOptions: MenuType[];
	values: {
		patientProfileId?: string;
		name?: string;
		surname?: string;
		sex?: string;
		email?: string;
		birthDate?: string;
		phoneNumber?: string;
		hospital?: string;
		medicHistoryNumber?: string;
		signUpDate?: string;
		smoker?: string[];
		riskFactors?: {
			riskFactorId: string;
			response: string;
		}[];
		otherMedication?: {
			medicationDataFieldId: string;
			response: string;
		}[];
		tumor: string;
		nodule: string;
		metastasis: string;
		cancerStage: string;
		cancerOrgan: string;
		cancerType: string;
		cancerSubtype: string;
		treatment: ITreatment;
		biomarkers: IBiomarker[];
		progressions: IProgression[];
		setbacks: ISetback[];
	};
};

const initialState: initialStateFormType = {
	edit: true,
	patients: {
		title: 'Datos del paciente',
		icon: 'patient',
		fields: [
			[
				{
					label: 'Nombre',
					placeholder: 'Introduzca nombre',
					type: 'text',
					input_type: InputTypeEnum.TEXTFIELD,
					name: 'name',
					disabled: true,
					error: false,
					validators: [
						{
							type: 'REQUIRED',
							message: 'El nombre es requerido',
						},
						{
							type: 'IS_STRING',
							message: 'El elemento debe ser un string',
						},
					],
				},
				{
					label: 'Apellido',
					placeholder: 'Introduzca apellido',
					type: 'text',
					input_type: InputTypeEnum.TEXTFIELD,
					name: 'surname',
					disabled: true,
					error: false,
					validators: [
						{
							type: 'REQUIRED',
							message: 'El apellido es requerido',
						},
						{
							type: 'IS_STRING',
							message: 'El elemento debe ser un string',
						},
					],
				},
			],
			[
				{
					label: 'Fecha de nacimiento',
					placeholder: 'XX/XX/XX',
					input_type: InputTypeEnum.DATEFIELD,
					name: 'birthDate',
					type: 'text',
					disabled: true,
					error: false,
					validators: [
						{
							type: 'REQUIRED',
							message: 'La fecha de nacimiento es requerida',
						},
						{
							type: 'IS_DATE',
							message: 'La fecha debe ser válida',
						},
					],
				},
				{
					label: 'Género',
					options: {
						MALE: 'Masculino',
						FEMALE: 'Femenino',
					},
					input_type: InputTypeEnum.SELECTOR,
					name: 'sex',
					disabled: true,
					error: false,
					validators: [
						{
							type: 'REQUIRED',
							message: 'El genero es requerido',
						},
						{
							type: 'IS_GENDER',
							message: 'Debe ser Masculino o Femenino',
						},
					],
				},
			],
			[
				{
					label: 'Correo Electrónico',
					placeholder: 'Introduzca email',
					type: 'email',
					input_type: InputTypeEnum.TEXTFIELD,
					name: 'email',
					disabled: true,
					error: false,
					validators: [
						{
							type: 'REQUIRED',
							message: 'El email es requerido',
						},
						{
							type: 'IS_EMAIL',
							message: 'El email debe ser válido',
						},
					],
				},
				{
					label: 'Número de Celular',
					placeholder: 'Introduzca número de celular',
					type: 'number',
					input_type: InputTypeEnum.TEXTFIELD,
					name: 'phoneNumber',
					disabled: true,
					error: false,
					validators: [
						{
							type: 'REQUIRED',
							message: 'El número de celular es requerido',
						},
					],
				},
			],
			[
				{
					label: 'Hospital',
					placeholder: 'Introduzca hospital',
					input_type: InputTypeEnum.SELECTOR,
					options: {
						no_value: 'Seleccionar Hospital',
					},
					name: 'institution',
					disabled: true,
					error: false,
					validators: [
						{
							type: 'REQUIRED',
							message: 'El número de celular es requerido',
						},
					],
				},
			],
			[
				{
					label: 'Número de Historial Médico',
					placeholder: 'Introduzca número de historial médico',
					type: 'text',
					input_type: InputTypeEnum.TEXTFIELD,
					name: 'medicHistoryNumber',
					disabled: true,
					error: false,
					validators: [
						{
							type: 'REQUIRED',
							message: 'El número de historial médico es requerido',
						},
						{
							type: 'IS_NUMBER',
							message: 'El elemento debe ser un número',
						},
					],
				},
				{
					label: 'Fecha de carga',
					placeholder: 'XX/XX/XX',
					input_type: InputTypeEnum.DATEFIELD,
					name: 'signUpDate',
					type: 'text',
					disabled: true,
					error: false,
					validators: [
						{
							type: 'REQUIRED',
							message: 'La fecha de carga es requerida',
						},
						{
							type: 'IS_DATE',
							message: 'La fecha debe ser válida',
						},
						{
							type: 'PAST_DATE',
							message: 'La fecha debe ser válida',
						},
					],
				},
			],
			[
				{
					label: 'Fumador',
					values: [],
					input_type: InputTypeEnum.SMOKER,
					name: 'smoker',
					disabled: true,
				},
			],
			[
				{
					label: 'Factores de riesgo',
					values: [],
					input_type: InputTypeEnum.LIST,
					name: 'riskFactors',
					disabled: true,
				},
				{
					label: 'Medicación habitual',
					values: [],
					input_type: InputTypeEnum.LIST,
					name: 'otherMedication',
					disabled: true,
				},
			],
		],
	},
	hospital: {
		title: 'Datos Hospitalario',
		icon: 'hospital',
		fields: [
			[
				{
					label: 'Fecha de diagnostico',
					placeholder: 'XX/XX/XX',
					input_type: InputTypeEnum.DATEFIELD,
					name: 'diagnosisDate',
					type: 'text',
					error: false,
					validators: [
						{
							type: 'REQUIRED',
							message: 'La fecha de diagnostico es requerida',
						},
						{
							type: 'IS_DATE',
							message: 'La fecha debe ser válida',
						},
					],
				},
				{
					label: 'Tumor primario',
					options: {
						no_value: 'Seleccionar Tumor primario',
					},
					input_type: InputTypeEnum.SELECTOR,
					name: 'cancerOrgan',
					error: false,
					validators: [
						{
							type: 'REQUIRED',
							message: 'Seleccione Tumor primario',
						},
					],
				},
			],
			[
				{
					label: 'Tipo histologico',
					options: {
						no_value: 'Seleccionar Tipo',
					},
					input_type: InputTypeEnum.SELECTOR,
					name: 'cancerType',
					error: false,
					validators: [
						{
							type: 'REQUIRED',
							message: 'Seleccione Tipo de cancer',
						},
					],
				},
				{
					label: 'Subtipo histologico',
					options: {
						no_value: 'Seleccionar Subtipo',
					},
					input_type: InputTypeEnum.SELECTOR,
					name: 'cancerSubtype',
					error: false,
					validators: [
						{
							type: 'REQUIRED',
							message: 'Seleccione Subtipo de cancer',
						},
					],
				},
			],
			[
				{
					label: 'T',
					input_type: InputTypeEnum.TNMFIELD,
					name: 'tumor',
					varToEvaluate: 'organ',
					options: [],
					error: false,
					validators: [
						{
							type: 'REQUIRED',
							message: 'Seleccione Tumor',
						},
					],
				},
				{
					label: 'N',
					input_type: InputTypeEnum.TNMFIELD,
					name: 'nodule',
					varToEvaluate: 'organ',
					options: [],
					error: false,
					validators: [
						{
							type: 'REQUIRED',
							message: 'Seleccione Nodulos',
						},
					],
				},
				{
					label: 'M',
					input_type: InputTypeEnum.TNMFIELD,
					name: 'metastasis',
					varToEvaluate: 'organ',
					options: [],
					error: false,
					validators: [
						{
							type: 'REQUIRED',
							message: 'Seleccione Metastasis',
						},
					],
				},
			],
			[
				{
					label: 'Estadío',
					input_type: InputTypeEnum.CONDITIONAL,
					name: 'cancerStage',
					varToEvaluate: 'organ',
					options: tnmOptions.cancerStage,
					error: false,
					validators: [
						{
							type: 'REQUIRED',
							message: 'Seleccione el estadío del paciente',
						},
					],
				},
			],
		],
	},
	biomarkers: {
		title: 'Biomarcadores',
		icon: 'biomarker',
		options: [],
		samples: [],
		fields: [
			[
				{
					label: 'Agregar biomarcador',
					type: 'text',
					input_type: InputTypeEnum.ADD_SECTION,
					handleClick: actionTypeEnum.ADD_BIOMARKER,
				},
			],
		],
	},
	setbacks: {
		// if (state.progressions.fields.length > 1) disable ADD_SETBACK button
		title: 'Recaidas',
		icon: 'setback',
		fields: [
			[
				{
					label: 'Agregar recaida',
					type: 'text',
					input_type: InputTypeEnum.ACTIONFIELD,
					handleClick: actionTypeEnum.FINISH_TREATMENT_SETBACK,
				},
			],
		],
	},
	progressions: {
		title: 'Progresiones',
		icon: 'progression',
		fields: [
			[
				{
					label: 'Agregar progresion',
					type: 'text',
					input_type: InputTypeEnum.ACTIONFIELD,
					handleClick: actionTypeEnum.FINISH_TREATMENT_PROGRESSION,
				},
			],
		],
	},
	values: {
		sex: 'Masculino',
		tumor: '',
		nodule: '',
		metastasis: '',
		cancerStage: '',
		cancerOrgan: '',
		cancerType: '',
		cancerSubtype: '',
		treatment: {
			medications: [] as any[],
		} as ITreatment,
		riskFactors: [],
		otherMedication: [],
		biomarkers: [],
		progressions: [],
		setbacks: [],
	},
	tnmOptions: [],
};

export const formSlice = createSlice({
	name: 'formSlice',
	initialState,
	reducers: {
		addBiomarkers: (state) => {
			const biomarkers = state.biomarkers.fields.slice(
				0,
				state.biomarkers.fields.length - 1,
			);
			const fixedRows = state.biomarkers.fields.slice(
				state.biomarkers.fields.length - 1,
				state.biomarkers.fields.length,
			);

			const biomarkersToAdd = {
				id: uuidv4(),
				input_type: InputTypeEnum.BIOMARKER_ROW,
				names: ['sample', 'biomarker', 'evaluation'],
				errors: {
					sample: false,
					biomarker: false,
					evaluation: false,
				},
				validators_sample: [
					{
						type: 'REQUIRED',
						message: 'Seleccione una muestra válida',
					},
				],
				validators_biomarker: [
					{
						type: 'REQUIRED',
						message: 'Seleccione un biomarcador válido',
					},
				],
				validators_evaluation: [
					{
						type: 'REQUIRED',
						message: 'Seleccione una evaluacion válida',
					},
				],
			};

			state.biomarkers.fields = [
				...biomarkers,
				[biomarkersToAdd],
				...fixedRows,
			];

			state.values.biomarkers.push({
				id: biomarkersToAdd.id,
				sample: '',
				biomarker: '',
				evaluation: '',
			});
		},
		addSetBacks: (state) => {
			const setbacks = state.setbacks.fields.slice(
				0,
				state.setbacks.fields.length - 1,
			);
			const fixedRows = state.setbacks.fields.slice(
				state.setbacks.fields.length - 1,
				state.setbacks.fields.length,
			);

			const setbacksToAdd = {
				id: uuidv4(),
				input_type: InputTypeEnum.SETBACK_ROW,
				names: ['setbackDate', 'setbackSite', 'metastasisSite'],
				errors: {
					setbackDate: false,
					setbackSite: false,
					metastasisSite: false,
				},
				validators_setbackDate: [
					{
						type: 'REQUIRED',
						message: 'La fecha es requerida',
					},
					{
						type: 'IS_DATE',
						message: 'Seleccione una fecha válida',
					},
				],
				validators_setbackSite: [
					{
						type: 'REQUIRED',
						message: 'Seleccione una evaluacion válida',
					},
				],
				validators_metastasisSite: [
					{
						type: 'REQUIRED',
						message: 'Seleccione lugar de metastasis',
					},
				],
			};

			state.setbacks.fields = [...setbacks, [setbacksToAdd], ...fixedRows];

			const idMed = uuidv4();
			state.values.setbacks.push({
				id: setbacksToAdd.id,
				setbackDate: '',
				setbackSite: '',
				metastasisSite: '',
				treatment: {
					id: setbacksToAdd.id,
					primaryTreatment: '',
					systematicTreatment: '',
					startDate: '',
					estimateFinishDate: '',
					finishDate: '',
					treatmentLine: 1, // change this
					endingMotive: '',
					medicationMotive: '',
					otherMotive: '',
					medications: [
						{
							id: idMed,
							name: '',
						},
					],
				},
			});
		},
		addProgression: (state) => {
			const progressions = state.progressions.fields.slice(
				0,
				state.progressions.fields.length - 1,
			);
			const fixedRows = state.progressions.fields.slice(
				state.progressions.fields.length - 1,
				state.progressions.fields.length,
			);

			const progressionsToAdd = {
				id: uuidv4(),
				input_type: InputTypeEnum.PROGRESSION_ROW,
				names: ['progressionDate', 'progressionSite'],
				errors: {
					progressionDate: false,
					progressionSite: false,
				},
				validators_progressionDate: [
					{
						type: 'REQUIRED',
						message: 'La fecha es requerida',
					},
					{
						type: 'IS_DATE',
						message: 'Seleccione una fecha válida',
					},
				],
				validators_progressionSite: [
					{
						type: 'REQUIRED',
						message: 'Seleccione una evaluacion válida',
					},
				],
			};

			state.progressions.fields = [
				...progressions,
				[progressionsToAdd],
				...fixedRows,
			];

			const idMed = uuidv4();
			state.values.progressions.push({
				id: progressionsToAdd.id,
				progressionDate: '',
				progressionSite: '',
				treatment: {
					id: progressionsToAdd.id,
					primaryTreatment: '',
					systematicTreatment: '',
					startDate: '',
					estimateFinishDate: '',
					finishDate: '',
					treatmentLine: 1, // change this
					endingMotive: '',
					medicationMotive: '',
					otherMotive: '',
					medications: [
						{
							id: idMed,
							name: '',
						},
					],
				},
			});
		},
		addTreatmentMedication: (state) => {
			const length = state.progressions.fields.length;
			// use id to add medication
			state.progressions.fields.forEach((field: any) => {
				field.forEach((item: any) => {
					if (item.medications)
						item.medications = [
							...item.medications,
							{
								label: 'Medicamento',
								input_type: InputTypeEnum.SELECTOR,
								name: 'medication',
								options: {
									no_value: 'Seleccione un medicamento válido',
									medication1: 'Medicación 1',
									medication2: 'Medicación 2',
									medication3: 'Medicación 3',
								},
							},
						];
				});
			});
		},
		removeBiomarker: (state, action) => {
			state.biomarkers.fields = state.biomarkers.fields?.filter((fields) =>
				fields.find(
					(item: { id?: string | number }) =>
						!item?.id || item.id !== action.payload,
				),
			);
			state.values.biomarkers = state.values.biomarkers.filter(
				(item) => item.id !== action.payload,
			);
		},
		removeSetBacks: (state, action) => {
			state.setbacks.fields = state.setbacks.fields?.filter((fields) =>
				fields.find(
					(item: { id?: string | number }) =>
						!item?.id || item.id !== action.payload,
				),
			);
			state.values.setbacks = state.values.setbacks.filter(
				(item) => item.id !== action.payload,
			);
		},
		removeProgressions: (state, action) => {
			state.progressions.fields = state.progressions.fields?.filter(
				(fields) =>
					fields.find(
						(item: { id?: string | number }) =>
							!item?.id || item.id !== action.payload,
					),
			);
			state.values.progressions = state.values.progressions.filter(
				(item) => item.id !== action.payload,
			);
		},
		removeTreatmentMedication: (state, action) => {
			state.setbacks.fields = state.setbacks.fields?.filter((fields) =>
				fields.find(
					(item: { id?: string | number }) =>
						!item?.id || item.id !== action.payload,
				),
			);
			let auxValues = state.values;
			// @ts-ignore
			delete auxValues[action.payload.setBackDateId];
			// @ts-ignore
			delete auxValues[action.payload.diagnosisDate];
			// @ts-ignore
			delete auxValues[action.payload.locaOrDistant];
			// @ts-ignore
			delete auxValues[action.payload.setBackPlace];

			state.values = auxValues;
			state.values.setbacks.filter((id) => id !== action.payload);
		},
		setValue: (state, action) => {
			state.values = {
				...state.values,
				[action.payload.name]: action.payload.value,
			};
		},
		finishTreatment: (state, action) => {
			if (action.payload.treatment) {
				const prefix = action.payload.treatment.prefix;
				switch (prefix) {
					case 'setbacks':
						state.values.setbacks = state.values.setbacks.map(
							(setback: any) => {
								if (setback.id === action.payload.treatment.id) {
									return {
										...setback,
										treatment: {
											...setback.treatment,
											finishDate: action.payload.body.finishDate,
											endingMotive: action.payload.body.endingMotive,
											sealed: true,
										},
									};
								}
								return setback;
							},
						);
						break;
					case 'progressions':
						state.values.progressions = state.values.progressions.map(
							(progression: any) => {
								if (progression.id === action.payload.treatment.id) {
									return {
										...progression,
										treatment: {
											...progression.treatment,
											finishDate: action.payload.body.finishDate,
											endingMotive: action.payload.body.endingMotive,
											sealed: true,
										},
									};
								}
								return progression;
							},
						);
						break;
					default:
						state.values.treatment = {
							...state.values.treatment,
							finishDate: action.payload.body.finishDate,
							endingMotive: action.payload.body.endingMotive,
							sealed: true,
						};
						break;
				}
			}
		},
		cleanForm: () => {
			return initialState;
		},
		setInputError: (state: any, action) => {
			const { id, fieldName, type, error } = action.payload;
			const sectionType = [
				'patients',
				'hospital',
				'biomarkers',
				'setbacks',
				'progressions',
			];
			sectionType.forEach((section) => {
				state[section].fields.forEach((section: any) => {
					const field: any = section.find(
						(input: any) =>
							input.name === fieldName || (input.id && input.id === id),
					);
					if (field) {
						if (field.names && field.errors) {
							field.errors[type] = error;
						} else {
							field.error = error;
						}
					}
				});
			});
		},
		setMedication: (
			state,
			action: {
				payload: {
					value: string;
					medications?: string[];
					name?: string;
					prefix: 'progressions' | 'setbacks' | 'treatment';
					medId: string;
					prefixId: string;
				};
			},
		) => {
			const { value, medications, name, prefix, medId, prefixId } =
				action.payload;
			if (prefix !== 'treatment') {
				const prefixValues = (state.values[prefix] as any[]).find(
					(p: any) => p.id === prefixId,
				);
				prefixValues.treatment.medications.push({
					id: medId,
					name: value ?? '',
				});
			} else {
				state.values.treatment.medications?.push({
					id: medId,
					name: value ?? '',
				});
			}
		},
		removeMedication: (
			state,
			action: {
				payload: {
					medId: string;
					prefix: 'progressions' | 'setbacks' | 'treatment';
					id: string;
					prefixId: string;
				};
			},
		) => {
			const { id, medId, prefix, prefixId } = action.payload;
			if (prefix !== 'treatment') {
				const prefixValues = (state.values[prefix] as any[]).find(
					(p: any) => p.id === prefixId,
				);
				prefixValues.treatment.medications.filter(
					(med: any) => med.id !== medId,
				);
			} else {
				state.values.treatment.medications.filter(
					(med: any) => med.id !== medId,
				);
			}
		},
		setValuePrefix: (state: any, action) => {
			const prefix = action.payload.prefix;
			state.values[prefix] = state.values[prefix].map((section: any) => {
				if (section.id === action.payload.id) {
					return {
						...section,
						...(action.payload.isTreatment
							? {
									treatment: {
										...section.treatment,
										[action.payload.name]: action.payload.value,
									},
							  }
							: { [action.payload.name]: action.payload.value }),
					};
				}
				return section;
			});
		},
		setValueBiomarker: (state: any, action) => {
			state.values.biomarkers = state.values.biomarkers.map(
				(biomarker: any) => {
					if (biomarker.id === action.payload.id) {
						return {
							...biomarker,
							[action.payload.name]: action.payload.value,
						};
					}
					return biomarker;
				},
			);
		},
		setValueProgression: (state: any, action) => {
			state.values.progressions = state.values.progressions.map(
				(progression: any) => {
					if (progression.id === action.payload.id) {
						return {
							...progression,
							...(action.payload.isTreatment === true
								? {
										treatment: {
											...progression.treatment,
											[action.payload.name]: action.payload.value,
										},
								  }
								: { [action.payload.name]: action.payload.value }),
						};
					}
					return progression;
				},
			);
		},
		setValueSetback: (state: any, action) => {
			state.values.setbacks = state.values.setbacks.map((setback: any) => {
				if (setback.id === action.payload.id) {
					return {
						...setback,
						...(action.payload.isTreatment === true
							? {
									treatment: {
										...setback.treatment,
										[action.payload.name]: action.payload.value,
									},
							  }
							: { [action.payload.name]: action.payload.value }),
					};
				}
				return setback;
			});
		},
		handleInputErrors: (state: any, action) => {
			const errors = action.payload;
			const sectionType = [
				'patients',
				'hospital',
				'biomarkers',
				'setbacks',
				'progressions',
			];

			let currentState = current(state);

			errors.forEach(
				(error: {
					id: string;
					fieldName: string;
					type: string;
					validator: { type: string; message: string };
				}) => {
					sectionType.forEach((section) => {
						const newFields: any[] = [];
						currentState[section].fields.forEach((components: any) => {
							const checkedComponents: any[] = [];
							components.forEach((component: any) => {
								if (
									(error.fieldName &&
										component.name === error.fieldName) ||
									(component.id && component.id === error.id)
								) {
									if (components.names) {
										const errors: any[] = [];
										component.names.forEach((name: string) => {
											errors.push({ ...errors, [name]: true });
										});
										checkedComponents.push({ ...component, errors });
									} else {
										checkedComponents.push({
											...component,
											error: true,
										});
									}
								} else {
									checkedComponents.push(component);
								}
							});
							newFields.push(checkedComponents);
						});
						state[section].fields = newFields;
					});
				},
			);
		},
	},
	extraReducers: (builder) => {
		builder
			.addMatcher(
				validateFormApi.endpoints.setBiomarker.matchFulfilled,
				(state, action) => {
					const currentState = current(state);
					let newField = currentState.biomarkers.fields.map((column) =>
						column.map((field) => ({ ...field, disabled: true })),
					);

					state.biomarkers.fields = newField;
				},
			)
			.addMatcher(
				validateFormApi.endpoints.setSetbacks.matchFulfilled,
				(state, action) => {
					const currentState = current(state);
					let newField = currentState.setbacks.fields.map((column) =>
						column.map((field) => ({ ...field, disabled: true })),
					);
					if (state.setbacks) state.setbacks.fields = newField;
				},
			)
			.addMatcher(
				patientApi.endpoints.getPatientMedicData.matchFulfilled,
				(state, action) => {
					state.values = {
						...state.values,
						...action.payload,
						name: CapitalizeText(action.payload.name),
						surname: CapitalizeText(action.payload.surname),
						sex: action.payload.sex === 'MALE' ? 'Masculino' : 'Femenino',
						tumor: action.payload.diseaseSpecs.tumor,
						nodule: action.payload.diseaseSpecs.nodule,
						metastasis: action.payload.diseaseSpecs.metastasis,
						cancerStage: action.payload.diseaseSpecs.cancerStage,
						cancerOrgan: action.payload.diseaseSpecs.cancerOrgan,
						cancerType: action.payload.diseaseSpecs.cancerType,
						cancerSubtype: action.payload.diseaseSpecs.cancerSubtype,
						treatment: action.payload.treatment ?? {
							medications: [],
						},
					};

					state.values.biomarkers = [];
					state.values.setbacks = [];
					state.values.progressions = [];

					// has biomarkers?
					if (action.payload.biomarkers?.length > 0) {
						const aux: any[] = [];
						action.payload.biomarkers.forEach((biomarker: IBiomarker) => {
							aux.push([
								{
									id: biomarker.id,
									input_type: InputTypeEnum.BIOMARKER_ROW,
									names: ['sample', 'biomarker', 'evaluation'],
									disabled: true,
								},
							]);
							state.values.biomarkers.push({
								id: biomarker.id,
								sample: biomarker.sample,
								biomarker: biomarker.biomarker,
								evaluation: biomarker.evaluation,
							});
						});
						state.biomarkers.fields = [
							...aux,
							...state.biomarkers.fields,
						];
					}

					// has setbacks?
					if (action.payload.setbacks?.length > 0) {
						const aux: any[] = [];
						action.payload.setbacks.forEach((setback: ISetback) => {
							aux.push([
								{
									id: setback.id,
									input_type: InputTypeEnum.SETBACK_ROW,
									names: [
										'setbackDate',
										'setbackSite',
										'metastasisSite',
									],
									disabled: true,
								},
							]);
							state.values.setbacks.push({
								id: setback.id,
								setbackDate: setback.setbackDate,
								setbackSite: setback.setbackSite,
								metastasisSite: setback.metastasisSite,
								treatment: setback.treatment,
							});
						});
						state.setbacks.fields = [...aux, ...state.setbacks.fields];
					}

					// has progressions?
					if (action.payload.progressions?.length > 0) {
						const aux: any[] = [];
						action.payload.progressions.forEach(
							(progression: IProgression) => {
								aux.push([
									{
										id: progression.id,
										input_type: InputTypeEnum.PROGRESSION_ROW,
										names: ['progressionDate', 'progressionSite'],
										disabled: true,
									},
								]);
								state.values.progressions.push({
									id: progression.id,
									progressionDate: progression.progressionDate,
									progressionSite: progression.progressionSite,
									treatment: progression.treatment,
								});
							},
						);
						state.progressions.fields = [
							...aux,
							...state.progressions.fields,
						];
					}

					if (action.payload.status === 'ACTIVE') {
						state.hospital.fields.forEach((section: any) => {
							section.forEach((field: any) => {
								field.disabled = false;
							});
						});
					}
				},
			)
			.addMatcher(
				validateFormApi.endpoints.getCancerType.matchFulfilled,
				(state, action) => {
					state.hospital.fields = state.hospital.fields.map((row) =>
						row.map((column: { name?: string }) => {
							if (column.name === 'cancerType') {
								let auxObj = {
									no_value: 'Seleccionar Tipo',
								};
								action.payload.forEach(
									(item: { id: string | number; value: string }) =>
										(auxObj = {
											...auxObj,
											[item.id]: CapitalizeText(item.value),
										}),
								);
								return {
									...column,
									options: auxObj,
								};
							} else {
								return column;
							}
						}),
					);
				},
			)
			.addMatcher(
				validateFormApi.endpoints.getCancerOrgan.matchFulfilled,
				(state, action) => {
					state.hospital.fields = state.hospital.fields.map((row) =>
						row.map((column: { name?: string }) => {
							if (column.name === 'cancerOrgan') {
								let auxObje = {
									no_value: 'Seleccionar Tumor primario',
								};
								action.payload.forEach(
									(item: { id: string | number; value: string }) =>
										(auxObje = {
											...auxObje,
											[item.id]: CapitalizeText(item.value),
										}),
								);
								return {
									...column,
									options: auxObje,
								};
							} else {
								return column;
							}
						}),
					);
				},
			)
			.addMatcher(
				validateFormApi.endpoints.getCancerSubType.matchFulfilled,
				(state, action) => {
					state.hospital.fields = state.hospital.fields.map((row) =>
						row.map((column: { name?: string }) => {
							if (column.name === 'cancerSubtype') {
								let auxObje = {
									no_value: 'Seleccionar Subtipo',
								};
								// @ts-ignore
								action.payload.forEach(
									(item: { id: string | number; value: string }) =>
										(auxObje = {
											...auxObje,
											[item.id]: CapitalizeText(item.value),
										}),
								);
								return {
									...column,
									options: auxObje,
								};
							} else {
								return column;
							}
						}),
					);
				},
			)
			.addMatcher(
				validateFormApi.endpoints.getTumor.matchFulfilled,
				(state, action) => {
					state.hospital.fields = state.hospital.fields.map((row) =>
						row.map((column: { name?: string }) => {
							if (column.name === 'tumor') {
								return {
									...column,
									options: action.payload,
								};
							} else {
								return column;
							}
						}),
					);
				},
			)
			.addMatcher(
				validateFormApi.endpoints.getNodule.matchFulfilled,
				(state, action) => {
					state.hospital.fields = state.hospital.fields.map((row) =>
						row.map((column: { name?: string }) => {
							if (column.name === 'nodule') {
								return {
									...column,
									options: action.payload,
								};
							} else {
								return column;
							}
						}),
					);
				},
			)
			.addMatcher(
				validateFormApi.endpoints.getMetastasis.matchFulfilled,
				(state, action) => {
					state.hospital.fields = state.hospital.fields.map((row) =>
						row.map((column: { name?: string }) => {
							if (column.name === 'metastasis') {
								return {
									...column,
									options: action.payload,
								};
							} else {
								return column;
							}
						}),
					);
				},
			)
			.addMatcher(
				validateFormApi.endpoints.getBiomarkers.matchFulfilled,
				(state, action) => {
					let auxObje = {
						no_value: 'Seleccionar biomarcador',
					};
					// @ts-ignore
					action.payload.forEach(
						(item: { id: string | number; value: string }) =>
							(auxObje = {
								...auxObje,
								[item.id]: CapitalizeText(item.value),
							}),
					);
					state.biomarkers.options = auxObje;
				},
			)
			.addMatcher(
				validateFormApi.endpoints.getCancerStage.matchFulfilled,
				(state, action) => {
					state.hospital.fields = state.hospital.fields.map((row) =>
						row.map((column: { name?: string }) => {
							if (column.name === 'cancerStage') {
								return {
									...column,
									options: action.payload,
								};
							} else {
								return column;
							}
						}),
					);
				},
			)
			.addMatcher(
				validateFormApi.endpoints.getSamples.matchFulfilled,
				(state, action) => {
					let auxObje = {
						no_value: 'Seleccionar muestra',
					};
					// @ts-ignore
					action.payload.forEach(
						(item: { id: string | number; value: string }) =>
							(auxObje = {
								...auxObje,
								[item.id]: CapitalizeText(item.value),
							}),
					);
					state.biomarkers.samples = auxObje;
				},
			)
			.addMatcher(
				validateFormApi.endpoints.getMedications.matchFulfilled,
				(state, action) => {
					let auxObje = {
						no_value: 'Seleccionar Medicamento',
					};
					// @ts-ignore
					action.payload.forEach(
						(item: { id: string | number; value: string }) =>
							(auxObje = {
								...auxObje,
								[item.id]: CapitalizeText(item.value),
							}),
					);
				},
			);
	},
});

const valuesForm: any = (state: RootState) => state.formSlice.values;
const setbacksForm: any = (state: RootState) => state.formSlice.setbacks;
const progressionsForm: any = (state: RootState) =>
	state.formSlice.progressions;

export const checkTreatmentEndingDate = createSelector(
	[valuesForm, setbacksForm, progressionsForm],
	(valuesForm, setbacksForm, progressionsForm) => {
		const { treatment, setbacks, progressions } = valuesForm;
		if (!treatment?.finishDate) {
			return { id: '', prefix: 'treatment' };
		}
		const lastSetBack: any =
			setbacksForm.fields[setbacksForm.fields.length - 2];
		if (lastSetBack !== undefined) {
			const setBackFinishDate: any = setbacks.find(
				(setback: ISetback) => setback.id === `${lastSetBack[0].id}`,
			);
			if (setBackFinishDate !== undefined && !setBackFinishDate.finishDate) {
				return { id: lastSetBack[0].id, prefix: 'setbacks' };
			}
		}
		const lastProgression: any =
			progressionsForm.fields[progressionsForm.fields.length - 2];
		if (lastProgression !== undefined) {
			const progressionFinishDate: any = progressions.find(
				(progression: IProgression) =>
					progression.id === `${lastProgression[0].id}`,
			);
			if (
				progressionFinishDate !== undefined &&
				!progressionFinishDate.finishDate
			) {
				return { id: lastProgression[0].id, prefix: 'progressions' };
			}
		}
		return { id: '', prefix: '' };
	},
);

export const valuesBiomarkerByIDSelector = createSelector(
	[valuesForm, (values, id: string) => id],
	(values, id) => {
		return values.biomarkers.find((biomarker: any) => biomarker.id === id);
	},
);

export const valuesSetbackByIDSelector = createSelector(
	[valuesForm, (values, id: string) => id],
	(values, id) => {
		return values.setbacks.find((setback: any) => setback.id === id);
	},
);

export const valuesProgressionByIDSelector = createSelector(
	[valuesForm, (values, id: string) => id],
	(values, id) => {
		return values.progressions.find(
			(progression: any) => progression.id === id,
		);
	},
);

export const {
	addBiomarkers,
	removeSetBacks,
	addSetBacks,
	addProgression,
	addTreatmentMedication,
	removeBiomarker,
	removeTreatmentMedication,
	removeProgressions,
	setValue,
	finishTreatment,
	cleanForm,
	setInputError,
	handleInputErrors,
	setValuePrefix,
	setValueBiomarker,
	setValueProgression,
	setValueSetback,
	removeMedication,
	setMedication,
} = formSlice.actions;
export default formSlice.reducer;
