import React from 'react';
import { StyledColumn } from '../../../../common/styledCommonComponents';
import Button from '../../../../common/components/Button';
import StyledText, {
	StyledH5,
	StyledH6,
} from '../../../../common/components/Text/styles';
import { theme } from '../../../../common/theme';
import ReloadIcon from '../../../../assets/ReloadIcon';
import LockIcon from '../../../../assets/LockIcon';

export const NotFoundMessage = () => {
	return (
		<StyledColumn
			css={{
				alignItems: 'center',
				justifyContent: 'center',
				width: '100%',
				height: '100%',
				display: 'inline-flex',
				flexDirection: 'column',
				gap: '16px',
			}}
		>
			<StyledColumn
				css={{
					display: 'flex',
					width: '583px',
					flexDirection: 'column',
					alignItems: 'center',
					gap: '16px',
				}}
			>
				<LockIcon height={55} width={46} />
				<StyledColumn
					css={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						gap: '8px',
						alignSelf: 'stretch',
					}}
				>
					<StyledH5 css={{ color: `${theme.primary500}` }}>
						No se encontró información del paciente
					</StyledH5>
					<StyledText variant="body1" css={{ color: `${theme.gray500}` }}>
						La información del paciente no está disponible o no tienes
						acceso a ella.
					</StyledText>
				</StyledColumn>
			</StyledColumn>
		</StyledColumn>
	);
};
