import { FunctionComponent } from 'react';
import PDFIcon from '../../assets/PDFIcon';
import PNGIcon from '../../assets/PNGIcon';
import JPGIcon from '../../assets/JPGIcon';
import { IconProps } from '../../assets/IconInterface';

export enum FileTypeEnum {
	PDF,
	PNG,
	JPG,
}

export const renderFileTypeIcon = (
	type: FileTypeEnum,
): FunctionComponent<IconProps> => {
	const mapIcon: Record<FileTypeEnum, FunctionComponent<IconProps>> = {
		[FileTypeEnum.PDF]: PDFIcon,
		[FileTypeEnum.PNG]: PNGIcon,
		[FileTypeEnum.JPG]: JPGIcon,
	};

	return mapIcon[type];
};
