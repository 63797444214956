import React from 'react';
import { IconProps } from './IconInterface';

const LockIcon = ({
	width = 20,
	height = 24,
	color = '#000000',
}: IconProps) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 20 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clip-path="url(#clip0_6_3)">
				<path
					d="M16.8679 19.346H14.7053V7.58324C14.7053 6.25234 14.1766 4.97596 13.2355 4.03488C12.2944 3.09379 11.0181 2.5651 9.68716 2.5651C8.35629 2.5651 7.07992 3.09379 6.13882 4.03488C5.19773 4.97596 4.66905 6.25234 4.66905 7.58324V19.346H2.50648V7.58324C2.50648 5.68194 3.26175 3.85856 4.60615 2.51415C5.95057 1.16975 7.77398 0.414474 9.67524 0.414474C11.5765 0.414474 13.3999 1.16975 14.7443 2.51415C16.0887 3.85856 16.844 5.68194 16.844 7.58324L16.8679 19.346Z"
					fill="#E0CBFD"
				/>
				<path
					d="M17.519 8.41777H1.84933C0.833133 8.41777 0.00935459 9.24156 0.00935459 10.2577V21.7457C0.00935459 22.7619 0.833133 23.5856 1.84933 23.5856H17.519C18.5352 23.5856 19.359 22.7619 19.359 21.7457V10.2577C19.359 9.24156 18.5352 8.41777 17.519 8.41777Z"
					fill="#E0CBFD"
				/>
				<path
					d="M11.1687 16.3928C11.361 16.1974 11.5128 15.9659 11.6154 15.7116C11.7179 15.4574 11.7691 15.1854 11.7661 14.9112C11.7689 14.5137 11.6606 14.1233 11.4534 13.7841C11.2462 13.4449 10.9483 13.1704 10.5934 12.9914C10.2384 12.8125 9.84055 12.7363 9.44459 12.7715C9.04867 12.8067 8.67044 12.9519 8.35259 13.1906C8.03478 13.4293 7.78998 13.7521 7.64585 14.1226C7.50173 14.493 7.464 14.8964 7.53693 15.2871C7.60985 15.6779 7.79053 16.0405 8.05857 16.334C8.32661 16.6275 8.67137 16.8403 9.05389 16.9483L8.59987 18.9675H10.6251L10.2188 16.9483C10.5725 16.8473 10.8937 16.6558 11.1508 16.3928"
					fill="#FAFAFA"
				/>
			</g>
			<defs>
				<clipPath id="clip0_6_3">
					<rect width="19.3684" height="24" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};
export default LockIcon;
