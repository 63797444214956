import React, { FunctionComponent } from 'react';
import {
	StyledColumn,
	StyledRow,
} from '../../../../common/styledCommonComponents';
import { FileTypeEnum, renderFileTypeIcon } from '../../utils';
import { IconProps } from '../../../../assets/IconInterface';
import { useTheme } from 'styled-components';
import StyledText from '../../../../common/components/Text';
import Kebab, { KebabOption } from '../../../../components/Kebab';
import { useOncoDispatch } from '../../../../hooks/redux-hook';
import { setModalOpen } from '../../../../redux/slices/utilsSlice';
import { ModalTypeEnum } from '../../../../utils/utils';
import { toastInfo } from '../../../DoctorsAcceptanceList/utils';
import { StyledPreview } from './styles';
import Moment from 'react-moment';

interface FileProps {
	id: string;
	name: string;
	date: string;
	ext: FileTypeEnum;
	author: string;
}

const File = ({ id, name, date, ext, author }: FileProps) => {
	const theme = useTheme();
	const FileIcon: FunctionComponent<IconProps> = renderFileTypeIcon(ext);
	const dispatch = useOncoDispatch();

	const options: KebabOption[] = [
		{
			label: 'Ver archivos',
			onClick: () =>
				dispatch(setModalOpen({ type: ModalTypeEnum.PREVIEW_FILE })),
		},
		{
			label: 'Copiar enlace',
			onClick: () => {
				navigator.clipboard.writeText(
					'http://www.dsgfasdfgsdfgdfs.com/' + id,
				);
				toastInfo(
					<StyledText variant="body2">
						Enlace copiado en el portapapeles
					</StyledText>,
				);
			},
		},
		{
			label: 'Eliminar archivo',
			onClick: () =>
				dispatch(setModalOpen({ type: ModalTypeEnum.CONTACT_MODAL })),
			css: {
				color: theme.oncoRed,
			},
		},
	];

	return (
		<StyledColumn
			css={{
				padding: 24,
				border: `1px solid ${theme.primary100}`,
				borderRadius: 8,
				gap: 16,
				boxShadow: '0px 4px 20px 0px rgba(224, 203, 253, 0.20)',
			}}
		>
			<StyledRow
				css={{
					justifyContent: 'space-between',
				}}
			>
				<StyledRow css={{ gap: 8, alignItems: 'center' }}>
					<FileIcon width={24} height={24} color={theme.primary500} />
					<StyledText variant={'body2'}>{name}</StyledText>
				</StyledRow>
				<Kebab options={options} />
			</StyledRow>
			<StyledPreview
				src={
					'https://media.discordapp.net/attachments/411201278031560708/1124484301401116773/peron20color.png?width=904&height=922'
				}
			/>
			<StyledRow
				css={{
					justifyContent: 'space-between',
				}}
			>
				<StyledText variant={'body3'} css={{ color: theme.gray500 }}>
					Subida por {author}
				</StyledText>
				<StyledText variant={'body3'} css={{ color: theme.gray500 }}>
					<Moment format="DD/MM/YY">{new Date(date).toUTCString()}</Moment>
				</StyledText>
			</StyledRow>
		</StyledColumn>
	);
};

export default File;
