import Modal from '../../Modal';
import {
	StyledBox,
	StyledInput,
} from '../../../../../../common/styledCommonComponents';
import React, { useEffect, useMemo } from 'react';
import { useTheme } from 'styled-components';
import { ModalTypeEnum } from '../../../../../../utils/utils';
import SearchIcon from '../../../../../../assets/SearchIcon';
import StyledText from '../../../../../../common/components/Text';
import Button from '../../../../../../common/components/Button';
import { useSearchAuxiliarQuery } from '../../../../../../redux/api/listApi';
import CircularLoader from '../../../../../Loader/CircularLoader';
import AddAuxiliaryButton from '../AddAuxiliarButton';
import AuxiliarItem from './components/AuxiliarItem';
import { useOncoDispatch } from '../../../../../../hooks/redux-hook';
import { setModalOpen } from '../../../../../../redux/slices/utilsSlice';
import { debounce } from 'lodash';

interface FindAuxiliarModalProps {
	handleOnClose: () => void;
	search?: (searchText: string) => void;
}

export interface Auxiliar {
	id: string;
	name: string;
	surname: string;
	isTeammate: boolean;
	assistantProfileId: string;
}

const FindAuxiliarModal = ({ handleOnClose }: FindAuxiliarModalProps) => {
	const theme = useTheme();
	const [searchText, setSearchText] = React.useState<string>('');
	const dispatch = useOncoDispatch();
	const { isLoading, isSuccess, data } = useSearchAuxiliarQuery({
		searchParam: searchText,
	});
	const debouncedSearch = useMemo(
		() =>
			debounce(async (searchText) => {
				setSearchText(searchText);
			}, 300),
		[setSearchText],
	);
	useEffect(() => {
		return () => {
			debouncedSearch.cancel();
		};
	}, [debouncedSearch]);
	const handleInputChange = (e: any) => {
		const value = e.target.value;
		debouncedSearch(value);
	};
	return (
		<Modal
			width={'632px'}
			header={
				<StyledBox
					css={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'flex-start',
						gap: 8,
					}}
				>
					<StyledText
						style={{
							color: '#000',
						}}
						variant="h5"
					>
						Agregar auxiliar
					</StyledText>
					<StyledText
						style={{
							color: '#000',
						}}
						variant="body2"
					>
						Busque al auxiliar que quiera agregar a su equipo ¿No aparece
						en la lista?{' '}
					</StyledText>
				</StyledBox>
			}
			body={
				<StyledBox
					css={{
						display: 'flex',
						width: '100%',
						flexDirection: 'column',
						height: 'auto',
						gap: 24,
						justifyContent: 'flex-start',
					}}
				>
					<StyledBox
						css={{
							display: 'flex',
							width: '100%',
							border: `1px solid ${theme.gray100}`,
							borderRadius: '20px',
							flexDirection: 'column',
							justifyContent: 'flex-start',
							alignItems: 'flex-start',
							padding: '16px',
							gap: '4px',
							minHeight: '303px',
						}}
					>
						<StyledBox
							css={{
								display: 'flex',

								border: `1px solid ${theme.gray100}`,
								borderRadius: '10px',
								padding: '0 16px',
								width: '100%',
								gap: 8,
								alignItems: 'center',
							}}
						>
							<SearchIcon color={theme.gray800} />
							<StyledInput
								css={{
									width: '100%',
									border: 'none',
									padding: '16px 0',
									'&:focus': {
										outline: 'none',
									},
								}}
								placeholder="Buscar por nombre de auxiliar"
								onChange={handleInputChange}
							></StyledInput>
						</StyledBox>
						<StyledBox
							css={{
								display: 'flex',
								flexDirection: 'column',
								width: '100%',
								overflowY: 'scroll',
								minHeight: '260px',
								maxHeight: '260px',
							}}
						>
							{isSuccess &&
								data.map((auxiliar: Auxiliar) => (
									<AuxiliarItem auxiliar={auxiliar} />
								))}
							{isSuccess && searchText.length > 0 && (
								<AddAuxiliaryButton
									onClick={() => {
										dispatch(
											setModalOpen({
												type: ModalTypeEnum.CREATE_AUXILIAR_MODAL,
												payload: { name: searchText },
											}),
										);
									}}
									name={searchText}
								/>
							)}
							{isLoading && (
								<StyledBox
									style={{
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										minHeight: '260px',
									}}
								>
									<CircularLoader />
								</StyledBox>
							)}
						</StyledBox>
					</StyledBox>
					<StyledBox
						css={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: ' flex-end',
							gap: 16,
							alignSelf: 'stretch',
						}}
					>
						<Button
							size="medium"
							variant="outlined"
							css={{
								borderRadius: 12,
								height: 42,
							}}
							onClick={() => {
								dispatch(
									setModalOpen({
										type: ModalTypeEnum.CREATE_AUXILIAR_MODAL,
										payload: { name: '' },
									}),
								);
							}}
						>
							Agregar nuevo auxiliar
						</Button>
						<Button
							size="medium"
							css={{
								borderRadius: 12,
								width: 100,
								height: 42,
								backgroundColor: `${theme.primary500}`,
							}}
							onClick={handleOnClose}
						>
							Hecho
						</Button>
					</StyledBox>
				</StyledBox>
			}
			onClose={handleOnClose}
		></Modal>
	);
};

export default FindAuxiliarModal;
