import React from 'react';
import Modal from '../Modal';
import {
	StyledBox,
	StyledColumn,
} from '../../../../../common/styledCommonComponents';
import StyledText from '../../../../../common/components/Text';
import { StyledButton } from '../../../../../common/components/Button/styles';
import {
	toastError,
	toastSuccess,
} from '../../../../../pages/DoctorsAcceptanceList/utils';
import { useRemovePatientMutation } from '../../../../../redux/api/validateFormApi';
import { useNavigate, useParams } from 'react-router-dom';

interface RemovePatientModalProps {
	handleOnClose: () => void;
}

const RemovePatientModal = ({ handleOnClose }: RemovePatientModalProps) => {
	const { patientId } = useParams();
	const navigation = useNavigate();

	const [removePatient, { isLoading, error, isSuccess }] =
		useRemovePatientMutation();
	const handleRemoveClick = (patientId: string) => {
		removePatient(patientId);
		handleOnClose();
		if (isSuccess) {
			toastSuccess('Paciente rechazado');
			navigation('/home');
		}
		if (error) toastError('Error al remover doctor');
	};

	return (
		<Modal
			onClose={handleOnClose}
			width={388}
			header={
				<StyledColumn
					css={{
						alignItems: 'center',
						marginTop: 16,
						width: '100%',
						gap: 16,
					}}
				>
					<StyledText variant="h5" css={{ color: '#000' }}>
						Rechazar paciente
					</StyledText>
					<StyledText
						variant="body1"
						css={{ textAlign: 'center', color: '#000' }}
					>
						¿Estás seguro que quieres rechazar este paciente?
					</StyledText>
				</StyledColumn>
			}
			body={
				<StyledBox
					css={{
						display: 'flex',
						justifyContent: 'center',
						width: '100%',
						gap: 16,
					}}
				>
					<StyledButton
						css={{ width: 130, justifyContent: 'center' }}
						variant="outlined"
						onClick={() => handleOnClose()}
					>
						Cancelar
					</StyledButton>
					<StyledButton
						css={{ width: 130, justifyContent: 'center' }}
						variant="filled"
						onClick={() => handleRemoveClick(patientId!)}
						disabled={isLoading}
					>
						Remover
					</StyledButton>
				</StyledBox>
			}
		/>
	);
};

export default RemovePatientModal;
