import React from 'react';
import Card from '../../../../components/Card';
import {
	StyledBox,
	StyledColumn,
} from '../../../../common/styledCommonComponents';
import { StyledCircularProgress } from '../../../../components/CustomCircularProgress/styles';
import StyledText from '../../../../common/components/Text';
import { useTheme } from 'styled-components';
import { StyledH6 } from '../../../../common/components/Text/styles';
import { ErrorMessage } from '../ErrorMessage';
import { useParams } from 'react-router-dom';
import { useGetPatientTreatmetsQuery } from '../../../../redux/api/patientApi';

export const CurrentTreatmentList = () => {
	const theme = useTheme();
	const { patientId } = useParams();

	const {
		data: treatmentsData,
		isLoading: isLoadingTreatments,
		isError: isErrorTreatments,
		refetch: refetchTreatments,
	} = useGetPatientTreatmetsQuery(patientId, { skip: !patientId });

	return (
		<Card title={'Tratamiento actual'} width={'325px'} height={'221px'}>
			{isLoadingTreatments && (
				<StyledBox
					css={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						height: '100%',
					}}
				>
					<StyledCircularProgress />
				</StyledBox>
			)}
			{isErrorTreatments ? (
				<ErrorMessage refetch={refetchTreatments} />
			) : treatmentsData?.medications.length > 0 ? (
				<StyledColumn css={{ gap: 4, overflowY: 'auto' }}>
					{treatmentsData.medications.map((item: any) => (
						<StyledBox
							css={{
								borderRadius: 8,
								padding: '8px 16px',
								border: `1px solid ${theme.gray50}`,
							}}
						>
							<StyledText
								variant={'body2'}
								css={{ color: theme.gray500 }}
							>
								{item.name}
							</StyledText>
						</StyledBox>
					))}
				</StyledColumn>
			) : (
				<StyledBox
					css={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						height: '100%',
						width: '100%',
					}}
				>
					<StyledH6 css={{ color: theme.gray300 }}>Sin Datos</StyledH6>
				</StyledBox>
			)}
		</Card>
	);
};
