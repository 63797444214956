import { rgba } from 'polished';
import { useNavigate } from 'react-router-dom';
import { useOncoDispatch } from '../../../../hooks/redux-hook';
import Kebab from '../../../../components/Kebab';
import { ReportIcon } from '../../../../components/TableComponent/RowTableComponent/ReportIcon';
import React from 'react';
import {
	StyledBox,
	StyledTableCell,
	StyledTableRow,
} from '../../../../common/styledCommonComponents';
import { useTheme } from 'styled-components';
import StyledText from '../../../../common/components/Text';
import moment from 'moment/moment';
import { setModalOpen, setReportId } from '../../../../redux/slices/utilsSlice';
import { ModalTypeEnum } from '../../../../utils/utils';
import { formatDate } from '../../utils';
/*
{
    "id": "11ac5e88-71b3-41fe-82ad-cc1899830d0b",
    "formId": "b7945eb2-8342-4ab3-9f6f-dfea14999e7a",
    "name": "weekly",
    "startDate": 1700179200000,
    "finishDate": 1700438399999
}
 */
interface ReportItemProps {
	row: {
		id: string;
		formId: string;
		name: string;
		startDate: string;
		finishDate: string;
		icon: string;
	};
}

const ReportItem = ({ row }: ReportItemProps) => {
	const { name, icon, id, startDate, finishDate } = row;
	const navigation = useNavigate();
	const theme = useTheme();
	const dispatch = useOncoDispatch();
	const nameReport: Record<string, string> = {
		daily: 'Estado diario del paciente',
		weekly: 'Calidad de vida',
		biometric: 'Estado general del paciente',
	};

	const options = [
		{
			label: 'Ver reporte',
			onClick: (e: any) => {
				e.stopPropagation();
				onRowClick(row);
			},
		},
	];

	const onRowClick = (row: any) => {
		let reportType: string = row.icon;
		let date: Date = new Date(row?.startDate);

		switch (reportType) {
			case 'weekly':
				dispatch(
					setModalOpen({
						open: true,
						type: ModalTypeEnum.WEEKLY_MODAL,
						id: row.id,
					}),
				);
				dispatch(setReportId(id));
				break;
			case 'daily':
				dispatch(
					setModalOpen({
						open: true,
						type: ModalTypeEnum.DAILY_MODAL,
						id: row.id,
					}),
				);
				dispatch(setReportId(id));
				break;
			case 'biometric':
				dispatch(
					setModalOpen({
						open: true,
						type: ModalTypeEnum.BIOMETRIC_MODAL,
						id: row.id,
						payload: {
							date: date,
						},
					}),
				);
				dispatch(setReportId(id));
				break;
			default:
				break;
		}
	};

	return (
		<StyledTableRow
			css={{
				backgroundColor: 'white',
				height: '76px',
				'&:hover': {
					backgroundColor: rgba(theme.gray50, 0.5),
					cursor: 'pointer',
				},
			}}
			onClick={() => onRowClick(row)}
		>
			<StyledTableCell>
				<StyledBox css={{ margin: 'auto', width: 'fit-content' }}>
					<ReportIcon type={icon} />
				</StyledBox>
			</StyledTableCell>
			<StyledTableCell>
				<StyledText variant={'body2'}>{nameReport[icon]}</StyledText>
			</StyledTableCell>
			<StyledTableCell></StyledTableCell>
			<StyledTableCell>{formatDate(startDate)}</StyledTableCell>
			<StyledTableCell>{formatDate(finishDate)}</StyledTableCell>
			<StyledTableCell>
				<StyledBox css={{ margin: 'auto', width: 'fit-content' }}>
					<Kebab options={options} />
				</StyledBox>
			</StyledTableCell>
		</StyledTableRow>
	);
};

export default ReportItem;
