import { createSlice } from '@reduxjs/toolkit';
import { homeApi } from '../api/homeApi';
import { sessionApi } from '../api/sessionApi';
import { patientApi } from '../api/patientApi';

type initialStateHomeType = {
	user: {
		avatarId?: number;
		name?: string;
		surname?: string;
	};
	weeklyGeneralPatientsReport: {
		total: number;
		active: number;
		pending: number;
		inTreatment: number;
	};
	weekly: any[];
	medicationsFilter: string[];
};

const initialState: initialStateHomeType = {
	user: {
		avatarId: undefined,
		name: undefined,
		surname: undefined,
	},
	weeklyGeneralPatientsReport: {
		total: 0,
		active: 0,
		pending: 0,
		inTreatment: 0,
	},
	weekly: [],
	medicationsFilter: [],
};

export const homeSlice = createSlice({
	name: 'homeSlice',
	initialState,
	reducers: {
		addMedication: (state, action) => {
			state.medicationsFilter = [...state.medicationsFilter, action.payload];
		},
		removeMedication: (state, action) => {
			state.medicationsFilter = state.medicationsFilter.filter(
				(medication) => medication !== action.payload,
			);
		},
	},
	extraReducers: (builder) => {
		builder
			.addMatcher(
				sessionApi.endpoints.login.matchFulfilled,
				(state, { payload }) => {
					const { avatarId, name, surname } = payload;

					state.user = { avatarId, name, surname };
				},
			)
			.addMatcher(
				homeApi.endpoints.patientsReport.matchFulfilled,
				(state, { payload }) => {
					state.weeklyGeneralPatientsReport = payload;
				},
			)

			.addMatcher(
				homeApi.endpoints.weeklyQuestions.matchFulfilled,
				(state, action) => {
					const newWeekly = action.payload.map(
						(category: {
							id: string;
							name: string;
							weeklySubCategories: {
								id: string;
								name: string;
								weeklyQuestions: { id: string; questionType: string }[];
							}[];
						}) => ({
							id: category.id,
							name: category.name,
							symptoms: category.weeklySubCategories.map((symp) => ({
								id: symp.id,
								name: symp.name,
								questions: symp.weeklyQuestions.map((question) => ({
									id: question.id,
									type: question.questionType,
									value: undefined,
									date: undefined,
								})),
							})),
						}),
					);
					state.weekly = newWeekly;
				},
			)
			.addMatcher(
				patientApi.endpoints.getWeeklyReport.matchFulfilled,
				(state, action) => {
					let newAnswerMap: { [key in string]: any } = {};
					action.payload.answers.forEach(
						(answer: {
							question: { id: string };
							answerDate: string | Date;
							value: number | string;
						}) => {
							newAnswerMap = {
								...newAnswerMap,
								[answer.question.id]: {
									date: answer.answerDate,
									idQuestion: answer.question.id,
									value: answer.value,
								},
							};
						},
					);
					state.weekly = state.weekly.map((category) => ({
						id: category.id,
						name: category.name,
						symptoms: category.symptoms.map(
							(symp: {
								id: string;
								name: string;
								questions: { id: string; type: string }[];
							}) => ({
								id: symp.id,
								name: symp.name,
								questions: symp.questions.map((question) => ({
									id: question.id,
									type: question.type,
									value: newAnswerMap[question.id]?.value,
									date: newAnswerMap[question.id]?.date,
								})),
							}),
						),
					}));
				},
			);
	},
});

export const { addMedication, removeMedication } = homeSlice.actions;

export default homeSlice.reducer;
