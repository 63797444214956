import React, { FC, Fragment, ReactElement, useRef, useState } from 'react';

import { CloneElement } from 'rdk';
import { motion } from 'framer-motion';
import { BubbleProps, ChartTooltip, ChartTooltipProps } from 'reaviz';
import { useHoverIntent } from './utils';

interface BubblePropsExtended extends Omit<BubbleProps, 'onClick'> {
	onClick: (event: any, data: any) => void;
}

export const CustomBubble: FC<Partial<BubblePropsExtended>> = ({
	id,
	data,
	fill,
	mask,
	gradient,
	glow,
	onClick,
	onMouseEnter,
	onMouseLeave,
	animated,
	tooltip = <ChartTooltip />,
}) => {
	const [internalActive, setInternalActive] = useState<boolean>(false);
	const bubbleRef = useRef<any | null>(null);

	const { pointerOut, pointerOver } = useHoverIntent({
		onPointerOver: (event) => {
			setInternalActive(true);
			onMouseEnter?.(event);
		},
		onPointerOut: (event) => {
			setInternalActive(false);
			onMouseLeave?.(event);
		},
	});

	const arcFill =
		gradient && !mask
			? `url(#gradient-${id})`
			: mask
			? `url(#mask-pattern-${id})`
			: fill;

	const glowStyles = glow
		? {
				filter: `drop-shadow(${glow.props.x}px ${glow.props.y}px ${glow.props.blur}px ${glow.props.color})`,
		  }
		: {};

	return (
		<Fragment>
			<motion.circle
				id={`${id}-bubble`}
				ref={bubbleRef}
				fill={arcFill}
				style={{ ...glowStyles }}
				initial={{
					r: data.r,
					cx: data.x,
					cy: data.y,
				}}
				animate={{
					r: data.r,
					cx: data.x,
					cy: data.y,
				}}
				transition={{ type: false, delay: 0 }}
				onClick={(e: any) => (onClick ? onClick(e, data) : undefined)}
				onPointerOver={pointerOver}
				onPointerOut={pointerOut}
			/>

			{tooltip && !tooltip.props.disabled && (
				<CloneElement<ChartTooltipProps>
					element={tooltip}
					visible={!!internalActive}
					reference={bubbleRef}
					value={{
						y: data.data.data,
						x: data.data.key,
						metadata: data.data.metadata,
					}}
				/>
			)}
		</Fragment>
	);
};
