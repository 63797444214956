import { api } from './api';
import { StudyType } from './types';

export const studiesApi = api.injectEndpoints({
	endpoints: (builder) => ({
		uploadStudy: builder.mutation({
			query: (credentials) => ({
				url: '/user/medic-login',
				method: 'POST',
				body: credentials,
			}),
		}),
		studyDetails: builder.query({
			query: () => ({
				url: '',
				method: 'GET',
			}),
		}),
		studyList: builder.query<StudyType[], string>({
			query: (id: string) => ({
				url: '',
				method: 'GET',
			}),
		}),
	}),
});

export const {
	useUploadStudyMutation,
	useStudyDetailsQuery,
	useStudyListQuery,
} = studiesApi;

export const {
	endpoints: { uploadStudy, studyDetails, studyList },
} = studiesApi;
