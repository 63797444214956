import React from 'react';
import { IconProps } from './IconInterface';

const CircleCheckIcon = ({
	color,
	width = 24,
	height = 24,
	...props
}: IconProps) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<rect x="2" y="2" width="20" height="20" rx="10" fill={color} />
			<path
				d="M10.0001 14.78L7.68676 12.4667C7.42676 12.2067 7.00676 12.2067 6.74676 12.4667C6.48676 12.7267 6.48676 13.1467 6.74676 13.4067L9.53342 16.1933C9.79342 16.4533 10.2134 16.4533 10.4734 16.1933L17.5268 9.14C17.7868 8.88 17.7868 8.46 17.5268 8.2C17.2668 7.94 16.8468 7.94 16.5868 8.2L10.0001 14.78Z"
				fill="white"
			/>
		</svg>
	);
};
export default CircleCheckIcon;
