import React, { useMemo } from 'react';
import Modal from '../Modal';
import {
	StyledBox,
	StyledColumn,
	StyledRow,
	StyledSpan,
} from '../../../../../common/styledCommonComponents';
import { useTheme } from 'styled-components';
import Body from './Body';
import {
	useGetWeeklyAnswersQuery,
	useGetWeeklyReportQuery,
} from '../../../../../redux/api/patientApi';
import { StyledCircularProgress } from '../../../../CustomCircularProgress/styles';
import { useWeeklyQuestionsQuery } from '../../../../../redux/api/homeApi';
import { parseDataWithYear } from '../../../../../utils/utils';
import { useOncoSelector } from '../../../../../hooks/redux-hook';
import StyledText from '../../../../../common/components/Text';
import ClinicalDocumentIcon from '../../../../../assets/ClinicalDocumentIcon';

interface WeeklyModalProps {
	handleOnClose: () => void;
}

interface DataProp {
	status: 'Completed' | 'Incomplete';
	endDate: string;
}

const WeeklyModal = ({ handleOnClose }: WeeklyModalProps) => {
	const theme = useTheme();
	const reportId = useOncoSelector((state) => state.utilsSlice.reportId);
	const {
		isLoading: weeklyLoading,
		isSuccess,
		error: weeklyQuestions,
	} = useWeeklyQuestionsQuery();
	const {
		data: weeklyData,
		error,
		isLoading: weekly2Loading,
	} = useGetWeeklyAnswersQuery({
		formAnswerId: reportId,
	});

	const { data, isLoading } = useGetWeeklyReportQuery(reportId, {
		skip: !reportId,
	});

	const renderState = (state: 'Completed' | 'Incomplete') => {
		switch (state) {
			case 'Completed':
				return (
					<StyledSpan
						css={{
							fontSize: '11px',
							backgroundColor: theme.patientListPillPositive,
							borderRadius: '20px',
							fontWeight: 500,
							padding: '5px 20px',
							color: '#1D6535',
						}}
					>
						Completado
					</StyledSpan>
				);
			case 'Incomplete':
				return (
					<StyledSpan
						css={{
							fontSize: '11px',
							backgroundColor: theme.oncoLightOrange4,
							borderRadius: '20px',
							fontWeight: 500,
							padding: '5px 20px',
							color: '#EA8053',
						}}
					>
						Incompleto
					</StyledSpan>
				);
		}
	};

	return (
		<Modal
			onClose={handleOnClose}
			header={
				<StyledRow>
					<StyledBox
						css={{
							display: 'flex',
							padding: 9,
							alignItems: 'flex-start',
							borderRadius: 30,
							background: ' #EAE0FF',
							waxWidth: 50,
							maxHeight: 50,
						}}
					>
						<ClinicalDocumentIcon
							width={32}
							height={32}
							color={theme.primary500}
							color2={'#EAE0FF'}
						/>
					</StyledBox>
					<StyledColumn
						css={{
							marginLeft: 15,
							justifyContent: 'center',
						}}
					>
						<StyledRow css={{ alignItems: 'center' }}>
							<StyledText
								variant="h5"
								css={{ marginRight: 6, color: `${theme.black}` }}
							>
								Calidad de vida
							</StyledText>
							{renderState(
								weeklyData?.endDate ? 'Completed' : 'Incomplete',
							)}
						</StyledRow>
						{weeklyData?.startDate && weeklyData?.endDate && (
							<StyledText variant="body2">
								Inicio {parseDataWithYear(weeklyData.startDate)} -
								Finalizado {parseDataWithYear(weeklyData.endDate)}
							</StyledText>
						)}
					</StyledColumn>
				</StyledRow>
			}
			body={<Body data={weeklyData} />}
			isLoading={
				isLoading || weeklyLoading || weekly2Loading ? (
					<StyledBox
						css={{
							display: 'flex',
							width: '100%',
							alignItems: 'center',
							justifyContent: 'center',
							height: 'calc(90vw - 90px - 100px - 100px)',
						}}
					>
						<StyledCircularProgress />
					</StyledBox>
				) : undefined
			}
		/>
	);
};

export default WeeklyModal;
