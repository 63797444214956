import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';

interface ProtectedRouteProps {
	hasAccess?: boolean;
	isLoading: boolean;
}

const ProtectedRoute = ({ isLoading, hasAccess }: ProtectedRouteProps) => {
	if (!hasAccess && !isLoading) {
		return <Navigate to="/login" replace />;
	}
	return <Outlet />;
};

export default ProtectedRoute;
