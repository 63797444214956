import { StyledCardHome } from '../../components/Card/styles';
import Header from '../Repository/components/Header';
import { StyledColumn } from '../../common/styledCommonComponents';
import { StyledGrid, StyledRepositoryScreen } from '../Repository/styles';
import Folder from '../Repository/components/Folder';
import File from '../Repository/components/File';
import React from 'react';
import { FileTypeEnum } from '../Repository/utils';
import { useOncoDispatch } from '../../hooks/redux-hook';
import { setModalOpen } from '../../redux/slices/utilsSlice';
import { ModalTypeEnum } from '../../utils/utils';
import { useStudyListQuery } from '../../redux/api/studiesApi';
import { useParams } from 'react-router';

const MedicalStudyList = () => {
	const { patientId } = useParams();

	const files = {
		name: 'Carpeta 1',
		id: '1',
		date: new Date().toString(),
		author: 'Juan Perez',
		ext: FileTypeEnum.PNG,
	};

	const { data, isLoading } = useStudyListQuery(patientId as string);
	const dispatch = useOncoDispatch();

	const openAddFile = () => {
		dispatch(setModalOpen({ type: ModalTypeEnum.ADD_STUDY }));
	};
	return (
		<StyledRepositoryScreen>
			<StyledCardHome
				css={{
					width: '1200px',
					borderRadius: 20,
					padding: '0 0 40px',
					flex: 1,
				}}
			>
				<Header handleOpenModal={openAddFile} />
				<StyledColumn
					css={{
						marginTop: 40,
						width: '100%',
						gap: 32,
					}}
				>
					<StyledGrid>
						{[files, files, files, files, files].map((item, index) => {
							return <File {...item} />;
						})}
					</StyledGrid>
				</StyledColumn>
			</StyledCardHome>
		</StyledRepositoryScreen>
	);
};

export default MedicalStudyList;
