import React from 'react';
import { StyledBox, StyledRow } from '../../../common/styledCommonComponents';
import StyledText from '../../../common/components/Text';
import Button from '../../../common/components/Button';
import DoubleChevronLeftIcon from '../../../assets/DoubleChevronLeftIcon';
import ChevronLeftIcon from '../../../assets/ChevronLeftIcon';
import ChevronRightIcon from '../../../assets/ChevronRightIcon';
import DoubleChevronRightIcon from '../../../assets/DoubleChevronRightIcon';
import { useTheme } from 'styled-components';

interface TablePaginationProps {
	page: number;
	totalPages: number;
	handleChangePage: (
		// event: React.MouseEvent<HTMLButtonElement> | null,
		newPage: number,
	) => void;
	loading: boolean;
	disabled: boolean;
}

const TablePagination = ({
	page,
	totalPages,
	handleChangePage,
	loading,
	disabled,
}: TablePaginationProps) => {
	const theme = useTheme();

	const handleFirstPage = () => {
		handleChangePage(1);
	};

	const handlePrevPage = () => {
		handleChangePage(page - 1);
	};

	const handleNextPage = () => {
		handleChangePage(page + 1);
	};

	const handleLastPage = () => {
		handleChangePage(totalPages);
	};

	return (
		<StyledRow
			css={{
				width: '100%',
				justifyContent: 'space-between',
				padding: '16px 24px',
				alignItems: 'center',
			}}
		>
			<StyledBox css={{ width: 185 }} />
			<StyledBox>
				<StyledText
					variant={'body2'}
					css={{
						color: '#000',
					}}
				>{`Página ${page} de ${totalPages}`}</StyledText>
			</StyledBox>
			<StyledRow
				css={{
					gap: 8,
				}}
			>
				<Button
					css={{ padding: '10px' }}
					icon={() => (
						<DoubleChevronLeftIcon
							height={18}
							color={
								disabled || loading || page === 1 || totalPages === 0
									? theme.gray400
									: theme.primary500
							}
							width={18}
						/>
					)}
					variant={'outlined'}
					disabled={disabled || loading || page === 1 || totalPages === 0}
					onClick={handleFirstPage}
				/>
				<Button
					css={{ padding: '10px' }}
					icon={() => (
						<ChevronLeftIcon
							height={18}
							color={
								disabled || loading || page === 1 || totalPages === 0
									? theme.gray400
									: theme.primary500
							}
							width={18}
						/>
					)}
					variant={'outlined'}
					disabled={disabled || loading || page === 1 || totalPages === 0}
					onClick={handlePrevPage}
				/>
				<Button
					css={{ padding: '10px' }}
					icon={() => (
						<ChevronRightIcon
							height={18}
							color={
								disabled ||
								loading ||
								page === totalPages ||
								totalPages === 0
									? theme.gray400
									: theme.primary500
							}
							width={18}
						/>
					)}
					variant={'outlined'}
					disabled={
						disabled || loading || page === totalPages || totalPages === 0
					}
					onClick={handleNextPage}
				/>
				<Button
					css={{ padding: '10px' }}
					icon={() => (
						<DoubleChevronRightIcon
							height={18}
							color={
								disabled ||
								loading ||
								page === totalPages ||
								totalPages === 0
									? theme.gray400
									: theme.primary500
							}
							width={18}
						/>
					)}
					variant={'outlined'}
					disabled={
						disabled || loading || page === totalPages || totalPages === 0
					}
					onClick={handleLastPage}
				/>
			</StyledRow>
		</StyledRow>
	);
};

export default TablePagination;
