import {
	StyledBox,
	StyledColumn,
} from '../../../../common/styledCommonComponents';
import React from 'react';
import { useTheme } from 'styled-components';
import { useGetPatientReportsQuery } from '../../../../redux/api/patientApi';
import { useNavigate, useParams } from 'react-router-dom';
import Card from '../../../../components/Card';
import { StyledCircularProgress } from '../../../../components/CustomCircularProgress/styles';
import _ from 'lodash';
import StyledText from '../../../../common/components/Text';
import { ReportItem } from './components/ReportItem';
import { StyledH6 } from '../../../../common/components/Text/styles';
import { formatDate } from '../../../PatientReportScreen/utils';
import { ReportComponentHeader } from './components/ReportComponentHeader';

const ReportComponent = () => {
	const theme = useTheme();
	const { patientId } = useParams();
	const {
		data: patientReports,
		isSuccess: isSuccessClosedForms,
		isLoading,
	} = useGetPatientReportsQuery({ patientId, firstPage: 0, pageSize: 8 });

	return (
		<Card
			headerComponent={<ReportComponentHeader patientId={patientId ?? ''} />}
			width={'325px'}
			// height={'201px'}
			css={{ flex: 1, overflowY: 'hidden', minHeight: 200 }}
		>
			<StyledBox
				css={{
					width: '100%',
					height: '100%',
					overflowY: 'auto',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				{isLoading ? (
					<StyledBox
						css={{
							height: '100%',
							width: '100%',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<StyledCircularProgress />
					</StyledBox>
				) : patientReports?.patients?.length > 0 ? (
					<StyledColumn
						css={{
							alignItems: 'flex-start',
							gap: '8px',
							flex: '1 0 0',
							alignSelf: 'stretch',
						}}
					>
						{patientReports.patients.map((form: any) => (
							<ReportItem
								key={form.id}
								type={form.name}
								startDate={formatDate(form.startDate)}
								id={form.id}
							/>
						))}
					</StyledColumn>
				) : (
					<StyledColumn
						css={{
							alignItems: 'center',
							gap: '8px',
						}}
					>
						<StyledH6 css={{ color: `${theme.gray300}` }}>
							Sin encuestas realizadas
						</StyledH6>
						<StyledText
							variant="body2"
							css={{ color: `${theme.gray300}` }}
						>
							¡Anime a su paciente a completar su primera encuesta!
						</StyledText>
					</StyledColumn>
				)}
			</StyledBox>
		</Card>
	);
};

export default ReportComponent;
