import * as React from 'react';
import { SVGProps } from 'react';
const FeedbackSuccessIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={79}
		height={80}
		fill="none"
		{...props}
	>
		<path
			fill="#80CA8F"
			d="M39.5.625a39.375 39.375 0 1 1 0 78.75 39.375 39.375 0 0 1 0-78.75Zm-4.905 47.143-8.747-8.752a3.377 3.377 0 0 0-4.775 4.775L32.21 54.93a3.364 3.364 0 0 0 4.776 0L60.048 31.86a3.372 3.372 0 0 0 .022-4.793 3.374 3.374 0 0 0-4.792.017L34.595 47.768Z"
		/>
	</svg>
);
export default FeedbackSuccessIcon;
