import React from 'react';
import { StyledBox } from '../../common/styledCommonComponents';
import DonutChart from '../DonutWidget/index';
import TumorProportionChart from '../../pages/Home/components/TumorProportionChart';

const WidgetPastelStats = () => {
	return (
		<StyledBox
			css={{
				width: 'inherit',
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'space-between',
				gap: 20,
			}}
		>
			<TumorProportionChart />
			<DonutChart />
		</StyledBox>
	);
};

export default WidgetPastelStats;
