import React, { useState } from 'react';
import StyledText from '../../common/components/Text';
import { StyledColumn, StyledRow } from '../../common/styledCommonComponents';
import Dropdown from '../../common/components/Dropdown';
import WeekFrequency from '../WeekFrequency';
import { TextSwitch } from '../../common/components/TextSwitch';
import { useOncoDispatch } from '../../hooks/redux-hook';
import { updateWeekFrequency, setDisable } from '../../redux/slices/utilsSlice';

interface FrequencyOptionsProps {
	data: {
		days: string[];
		week: string | number;
	};
	type: 'daily' | 'weekly';
}
export const FrequencyOptions = ({ data, type }: FrequencyOptionsProps) => {
	const [isWeekly, setIsWeekly] = useState<boolean | undefined>(undefined);
	const [week, setWeek] = useState<number | undefined>(undefined);

	const dispatch: any = useOncoDispatch();

	const weekOptions = {
		2: '2 semanas',
		3: '3 semanas',
		4: '4 semanas',
		5: '5 semanas',
		6: '6 semanas',
		7: '7 semanas',
		8: '8 semanas',
	};

	const handleUncheck = () => {
		setIsWeekly(false);
		dispatch(updateWeekFrequency({ type, item: 0 }));
		dispatch(setDisable(false));
	};

	const handleCheck = () => {
		setIsWeekly(true);
		setWeek(2);
		dispatch(updateWeekFrequency({ type, item: 2 }));
		dispatch(setDisable(false));
	};

	function handleDropdown(name: any, item: any) {
		setWeek(item);
		dispatch(updateWeekFrequency({ type, item }));
		dispatch(setDisable(false));
	}

	return (
		<StyledColumn
			css={{
				padding: '0px 8px',
				alignItems: 'flex-start',
				rowGap: '16px',
				alignSelf: 'stretch',
			}}
		>
			<StyledRow
				css={{
					justifyContent: 'space-between',
					alignItems: 'center',
					alignSelf: 'stretch',
				}}
			>
				<StyledText variant="body2">Frecuencia</StyledText>
				<TextSwitch
					checked={isWeekly ?? data.week !== 0}
					text1="Días"
					text2="Semanas"
					onCheck={handleCheck}
					onUncheck={handleUncheck}
				/>
			</StyledRow>
			{(data.week !== 0 || isWeekly) && (
				<StyledRow
					css={{
						justifyContent: 'space-between',
						alignItems: 'center',
						alignSelf: 'stretch',
					}}
				>
					<StyledText variant="body2">¿Cada cuántas semanas?</StyledText>
					<Dropdown
						placeholder="Semanas"
						width="192px"
						value={week?.toString() ?? data?.week.toString()}
						onChange={handleDropdown}
						name={''}
						options={weekOptions}
					/>
				</StyledRow>
			)}
			<StyledRow
				css={{
					justifyContent: 'space-between',
					alignItems: 'center',
					alignSelf: 'stretch',
				}}
			>
				<StyledText variant="body2">¿Qué días?</StyledText>
				{data && <WeekFrequency type={type} data={data.days ?? []} />}
			</StyledRow>
		</StyledColumn>
	);
};
