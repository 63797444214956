import { useTheme } from 'styled-components';
import { useOncoDispatch } from '../../../hooks/redux-hook';
import { useCancerPatientDistributionQuery } from '../../../redux/api/homeApi';
import { ITumor } from '../../../utils/mockedData';
import { setModalOpen } from '../../../redux/slices/utilsSlice';
import { ModalTypeEnum } from '../../../utils/utils';
import { AppDispatch } from '../../../redux/store';

export const useDonutChartOptions = () => {
	const theme = useTheme();
	const dispatch: AppDispatch = useOncoDispatch();
	const {
		data: cancerData,
		error,
		isLoading,
		refetch,
	} = useCancerPatientDistributionQuery();

	const mockedSeries =
		cancerData?.map((data: any) => data?.patients?.length) ?? [];

	const totalPatients = cancerData?.reduce(
		(acc: number, current: ITumor) => acc + current.specCount,
		0,
	);

	const series = cancerData?.map((tum: ITumor) => tum.specCount) ?? [];
	const emptyData = series?.length === 0;

	const colors = [
		theme.primary500,
		theme.pink500,
		theme.blue500,
		theme.orange500,
		theme.green500,
	].reverse();

	const options = {
		labels:
			cancerData?.map(
				(tum: ITumor) =>
					tum.specResponse === 'Otro'
						? 'Otros estadíos'
						: 'Estadío ' + tum.specResponse, // slice to be deleted
			) ?? [],
		colors,
		dataLabels: {
			enabled: false,
		},
		chart: {
			background: '#fff',
			height: '180px',
			width: '180px',
			events: {
				dataPointSelection: function (
					event: any,
					chartContext: any,
					config: any,
				) {
					if (config && config.dataPointIndex !== undefined) {
						const stage = options.labels[config.dataPointIndex];
						const stageColor = colors[config.dataPointIndex];
						const stagePatients =
							cancerData[config.dataPointIndex].patients;
						dispatch(
							setModalOpen({
								type: ModalTypeEnum.STAGE_PATIENT_MODAL,
								stageData: {
									color: stageColor,
									stage,
									patients: stagePatients,
								},
							}),
						);
					}
				},
			},
		},
		legend: {
			show: true,
			showForSingleSeries: false,
			showForNullSeries: true,
			showForZeroSeries: true,
			fontSize: '11px',
			labels: {
				colors: theme.gray850,
			},
			itemMargin: {
				vertical: 3,
			},
			onItemHover: {
				highlightDataSeries: false,
			},
			onItemClick: {
				toggleDataSeries: false,
			},
			markers: {
				width: 6,
				height: 6,
				radius: 12,
				offsetX: -5,
				offsetY: -1,
			},
		},
		tooltip: {
			fillSeriesColor: false,
			followCursor: true,
			y: {
				title: {
					formatter: (seriesName: string) => seriesName + ':',
				},
				formatter: function (
					_: unknown,
					{
						globals,
						seriesIndex,
					}: {
						globals: { series: number[] };
						seriesIndex: number;
					},
				) {
					return (
						Math.trunc(
							(globals.series[seriesIndex] / totalPatients) * 100,
						) + '%'
					);
				},
			},
			marker: {
				show: false,
			},
		},
		plotOptions: {
			pie: {
				offsetY: 10,
				offsetX: -10,
				customScale: 1.1,
				expandOnClick: false,
				donut: {
					size: '60%',
					labels: {
						show: true,
						name: {
							show: true,
							offsetY: 13,
						},
						value: {
							show: true,
							fontSize: '18px',
							fontWeight: 400,
							offsetY: -20,
						},
						total: {
							show: true,
							label: 'Pacientes',
							color: theme.black,
							showAlways: true,
							fontSize: '13px',
							fontWeight: 400,
							formatter: function () {
								return totalPatients?.toString() ?? '0';
							},
						},
					},
				},
			},
		},
	};

	return {
		options,
		mockedSeries,
		isLoading,
		error,
		emptyData,
		refetch,
	};
};
