import { PointerEvent, useCallback, useRef } from 'react';

export interface HoverIntentOptions {
	interval?: number;
	sensitivity?: number;
	timeout?: number;
	disabled?: boolean;
	onPointerOver: (event: PointerEvent<SVGElement>) => void;
	onPointerOut: (event: PointerEvent<SVGElement>) => void;
}

export interface HoverIntentResult {
	pointerOut: (event: PointerEvent<SVGElement>) => void;
	pointerOver: (event: PointerEvent<SVGElement>) => void;
}

export const useHoverIntent = ({
	sensitivity = 7,
	interval = 0,
	timeout = 150,
	disabled,
	onPointerOver,
	onPointerOut,
}: HoverIntentOptions): HoverIntentResult => {
	const mouseOver = useRef<boolean>(false);
	const timer = useRef<any | null>(null);
	const state = useRef<number>(0);
	const coords = useRef({
		x: null,
		y: null,
		px: null,
		py: null,
	});

	const onMouseMove = useCallback((event: MouseEvent) => {
		// @ts-ignore
		coords.current.x = event.clientX;
		// @ts-ignore
		coords.current.y = event.clientY;
	}, []);

	const comparePosition = useCallback(
		(event: PointerEvent<SVGElement>) => {
			timer.current = clearTimeout(timer.current);
			const { px, x, py, y } = coords.current;

			// @ts-ignore
			if (Math.abs(px - x) + Math.abs(py - y) < sensitivity) {
				state.current = 1;
				onPointerOver(event);
			} else {
				coords.current.px = x;
				coords.current.py = y;
				timer.current = setTimeout(() => comparePosition(event), interval);
			}
		},
		[interval, onPointerOver, sensitivity],
	);

	const cleanup = useCallback(() => {
		clearTimeout(timer.current);
		document.removeEventListener('mousemove', onMouseMove, false);
	}, [onMouseMove]);

	const pointerOver = useCallback(
		(event: PointerEvent<SVGElement>) => {
			if (!disabled) {
				mouseOver.current = true;
				cleanup();

				if (state.current !== 1) {
					// @ts-ignore
					coords.current.px = event.nativeEvent.x;
					// @ts-ignore
					coords.current.py = event.nativeEvent.y;
					document.addEventListener('mousemove', onMouseMove, false);
					timer.current = setTimeout(
						() => comparePosition(event),
						timeout,
					);
				}
			}
		},
		[cleanup, comparePosition, disabled, onMouseMove, timeout],
	);

	const delay = useCallback(
		(event: PointerEvent<SVGElement>) => {
			timer.current = clearTimeout(timer.current);
			state.current = 0;
			onPointerOut(event);
		},
		[onPointerOut],
	);

	const pointerOut = useCallback(
		(event: PointerEvent<SVGElement>) => {
			mouseOver.current = false;
			cleanup();

			if (state.current === 1) {
				timer.current = setTimeout(() => delay(event), timeout);
			}
		},
		[cleanup, delay, timeout],
	);

	return {
		pointerOver,
		pointerOut,
	};
};
