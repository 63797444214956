import React from 'react';
import { IconProps } from './IconInterface';

const ApetiteIcon = ({
	color = '#000000',
	width = 24,
	height = 24,
}: IconProps) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M8.05908 5.8125C7.72181 5.8125 7.49696 6.15 7.49696 6.375V9.1875H6.37272V6.375C6.37272 6.0375 6.14787 5.8125 5.8106 5.8125C5.47333 5.8125 5.24848 6.15 5.24848 6.375V9.1875H4.12424V6.375C4.12424 6.0375 3.89939 5.8125 3.56212 5.8125C3.22485 5.8125 3 6.15 3 6.375V10.0875C3 11.1 3.78697 11.8875 4.68636 12V19.875C4.68636 20.55 5.13605 21 5.8106 21C6.48514 21 6.93484 20.55 6.93484 19.875V12C7.83423 11.8875 8.6212 11.1 8.6212 10.0875V6.375C8.6212 6.15 8.39635 5.8125 8.05908 5.8125ZM10.3076 6.375V13.125H11.4318V19.875C11.4318 20.55 11.8815 21 12.556 21C13.2306 21 13.6803 20.55 13.6803 19.875V3C11.7691 3 10.3076 4.4625 10.3076 6.375ZM18.1772 5.25C16.6033 5.25 15.3666 6.9375 15.3666 8.9625C15.2542 10.3125 15.9288 11.55 17.053 12.3375V19.875C17.053 20.55 17.5027 21 18.1772 21C18.8518 21 19.3015 20.55 19.3015 19.875V12.3375C20.4257 11.55 21.1003 10.3125 20.9878 8.9625C20.9878 6.9375 19.7512 5.25 18.1772 5.25Z"
				fill={color}
			/>
		</svg>
	);
};

export default ApetiteIcon;
