import React from 'react';
import {
	StyledColumn,
	StyledRow,
} from '../../../common/styledCommonComponents';
import UserIcon from '../../../assets/UserIcon';
import TextInputField from '../../../common/components/TextInputField';
import Dropdown from '../../../common/components/Dropdown';
import HeaderValidationForm from '../components/HeaderValidationForm';
import { useOncoDispatch, useOncoSelector } from '../../../hooks/redux-hook';
import { setValue } from '../../../redux/slices/validationSlice';
import {
	useGetDiseasesQuery,
	useGetOtherMedicationsQuery,
	useHospitalsQuery,
} from '../../../redux/api/validateFormApi';
import { HospitalResponse } from '../../../redux/api/types';
import moment from 'moment';
import StyledText from '../../../common/components/Text';
import { CapitalizeText } from '../../../utils/utils';
import { useTheme } from 'styled-components';

const PersonalForm = () => {
	const values = useOncoSelector((state) => state.validationSlice.values);
	const dispatch = useOncoDispatch();
	const { data: diseases } = useGetDiseasesQuery();
	const { data: medications } = useGetOtherMedicationsQuery();
	const theme = useTheme();

	const { data: hospitals } = useHospitalsQuery('', {
		selectFromResult: (response) => {
			if (
				response.error ||
				response?.data?.length === 0 ||
				response.data === undefined
			)
				return response;
			let hospitalAux: any = {};
			response.data.forEach(
				({ id, name }: HospitalResponse) => (hospitalAux[id] = name),
			);
			return { response, data: hospitalAux };
		},
	});

	const handleOnChange = (name: string, value: string) => {
		dispatch(
			setValue({
				key: name as keyof typeof values,
				value,
			}),
		);
	};

	return (
		<StyledColumn>
			<HeaderValidationForm title={'Datos del paciente'} icon={UserIcon} />

			<StyledColumn css={{ padding: 32, gap: 16 }}>
				<StyledRow css={{ gap: 32 }}>
					<TextInputField
						value={values.name}
						label={'Nombre'}
						onChange={handleOnChange}
						name={'name'}
						disabled
					/>
					<TextInputField
						value={values.surname}
						label={'Apellido'}
						onChange={handleOnChange}
						name={'surname'}
						disabled
					/>
				</StyledRow>
				<StyledRow css={{ gap: 32 }}>
					<TextInputField
						value={moment(values.birthDate).format('YYYY-MM-DD')}
						label={'Fecha de nacimiento'}
						onChange={handleOnChange}
						name={'birthDate'}
						disabled
						type={'date'}
					/>
					<Dropdown
						value={values.gender}
						label={'Genero'}
						onChange={handleOnChange}
						name={'gender'}
						disabled
						options={{
							male: 'Masculino',
							female: 'Femenino',
						}}
					/>
				</StyledRow>
				<StyledRow css={{ gap: 32 }}>
					<TextInputField
						value={values.email}
						label={'Correo electrónico'}
						onChange={handleOnChange}
						name={'email'}
						disabled
					/>
					<TextInputField
						value={values.phone}
						label={'Número de teléfono'}
						onChange={handleOnChange}
						name={'phone'}
						disabled
					/>
				</StyledRow>
				<StyledRow css={{ gap: 32 }}>
					<Dropdown
						value={values.hospital}
						label={'Hospital'}
						onChange={handleOnChange}
						name={'hospital'}
						disabled
						options={hospitals}
					/>
				</StyledRow>
				<StyledRow css={{ gap: 32 }}>
					<TextInputField
						value={values.medicalID}
						label={'Número de Historial Médico'}
						onChange={handleOnChange}
						name={'medicalID'}
						disabled
					/>
					<TextInputField
						value={moment(values.uploadDate).format('YYYY-MM-DD')}
						label={'Fecha de carga'}
						onChange={handleOnChange}
						name={'uploadDate'}
						type={'date'}
						disabled
					/>
				</StyledRow>
				<StyledRow css={{ gap: 32 }}>
					<StyledColumn css={{ flex: 1, gap: 16 }}>
						<StyledColumn>
							<StyledText
								variant={'body2'}
								css={{ marginBottom: 8, color: theme.gray300 }}
							>
								Factores de riesgo
							</StyledText>
							<StyledColumn css={{ gap: 4 }}>
								{values?.riskFactors?.length === 0 ? (
									<StyledText
										variant={'body1'}
										css={{ color: theme.gray400 }}
									>
										{CapitalizeText('No tiene factores de riesgo')}
									</StyledText>
								) : (
									values?.riskFactors?.map((factor, index) => {
										return (
											<StyledText
												variant={'body1'}
												css={{ color: theme.gray400 }}
												key={'risk-factor-' + index}
											>
												{CapitalizeText(
													diseases?.find(
														(disease: any) =>
															disease.value === factor.response,
													)?.value,
												)}
											</StyledText>
										);
									})
								)}
							</StyledColumn>
						</StyledColumn>

						<StyledColumn css={{ gap: 8 }}>
							<StyledText
								variant={'body2'}
								css={{ color: theme.gray300 }}
							>
								Fuma?
							</StyledText>
							<StyledText
								variant={'body1'}
								css={{ color: theme.gray400 }}
							>
								{CapitalizeText(values?.smoker?.isSmoker ?? '')}
							</StyledText>
						</StyledColumn>
						{values?.smoker?.isSmoker !== 'no fumo' && (
							<>
								<StyledColumn css={{ gap: 8 }}>
									<StyledText
										variant={'body2'}
										css={{ color: theme.gray300 }}
									>
										Por cuanto tiempo?
									</StyledText>
									<StyledText
										variant={'body1'}
										css={{ color: theme.gray400 }}
									>
										{`${values?.smoker?.time} ${
											values?.smoker?.time === 1 ? 'año' : 'años'
										}`}{' '}
									</StyledText>
								</StyledColumn>
								<StyledColumn css={{ gap: 8 }}>
									<StyledText
										variant={'body2'}
										css={{ color: theme.gray300 }}
									>
										Cuantos cigarrillos fuma/fumaba?
									</StyledText>
									<StyledText
										variant={'body1'}
										css={{
											color: theme.gray400,
										}}
									>{`${values?.smoker?.quantity} por dia`}</StyledText>
								</StyledColumn>
							</>
						)}
					</StyledColumn>
					<StyledColumn css={{ flex: 1 }}>
						<StyledText
							variant={'body2'}
							css={{ marginBottom: 8, color: theme.gray300 }}
						>
							Medicación habitual
						</StyledText>
						<StyledColumn css={{ gap: 4 }}>
							{values?.otherMedication?.length === 0 ? (
								<StyledText
									variant={'body1'}
									css={{ color: theme.gray400 }}
								>
									{CapitalizeText('No tiene medicamentos habituales')}
								</StyledText>
							) : (
								values?.otherMedication?.map((medication) => {
									return (
										<StyledText
											variant={'body1'}
											css={{ color: theme.gray400 }}
										>
											{CapitalizeText(
												medications?.find(
													(med: any) =>
														med.value === medication.response,
												)?.value,
											)}
										</StyledText>
									);
								})
							)}
						</StyledColumn>
					</StyledColumn>
				</StyledRow>
			</StyledColumn>
		</StyledColumn>
	);
};

export default PersonalForm;
