import { api } from './api';
import { SummaryReportResponse } from './types';

// TODO add endpoints
export const homeApi = api.injectEndpoints({
	endpoints: (builder) => ({
		patients: builder.query({
			query: () => ({ url: '/user/doctor-patients', method: 'GET' }),
		}),
		patientsReport: builder.query<any, void>({
			query: () => ({ url: '/home/patients-statistics', method: 'GET' }),
			providesTags: ['PatientsStatistics'],
		}),
		weeklyQuestions: builder.query<any, void>({
			query: () => ({
				url: '/form/weekly/categories',
			}),
		}),
		cancerStatistics: builder.query<any, void>({
			query: () => ({
				url: '/cancer/statistics',
			}),
			providesTags: ['CancerStatistics'],
		}),
		cancerPatientDistribution: builder.query<any, void>({
			query: () => ({
				url: '/disease/cancer/patient/CANCER_STAGE/distribution',
				method: 'GET',
			}),
			providesTags: ['CancerStatistics'],
		}),
		dailySummaryStatistics: builder.query({
			query: () => ({
				url: '/log/statistics',
			}),
		}),
		patientsList: builder.query({
			query: () => ({
				url: '/home/patients-table',
			}),
			providesTags: ['UserList'],
		}),
		weeklyStatistics: builder.query<any, void>({
			query: () => ({
				url: '/log/response-rate',
			}),
		}),
		summaryReports: builder.query<SummaryReportResponse, void>({
			query: () => ({
				url: '/home/summary-reports',
			}),
		}),
		frequentMedications: builder.query<any, void>({
			query: () => ({
				url: '/treatment/medications/frequency',
			}),
		}),
		cancerOrganProportion: builder.query<any, void>({
			query: () => ({
				url: '/user/patients/cancerOrgans',
			}),
		}),
		getAnswersDistribution: builder.query({
			query: (limit) => ({
				url: `/form/patient/answer/distribution?limit=${limit}`,
				method: 'GET',
			}),
		}),
	}),
});

export const {
	usePatientsQuery,
	usePatientsReportQuery,
	useWeeklyQuestionsQuery,
	useCancerStatisticsQuery,
	useDailySummaryStatisticsQuery,
	useCancerPatientDistributionQuery,
	useFrequentMedicationsQuery,
	usePatientsListQuery,
	useWeeklyStatisticsQuery,
	useSummaryReportsQuery,
	useCancerOrganProportionQuery,
	useGetAnswersDistributionQuery,
} = homeApi;

export const {
	endpoints: {
		patients,
		patientsReport,
		weeklyQuestions,
		cancerStatistics,
		dailySummaryStatistics,
		frequentMedications,
		patientsList,
		weeklyStatistics,
		summaryReports,
		getAnswersDistribution,
	},
} = homeApi;
