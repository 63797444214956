import Modal from '../Modal';
import {
	StyledBox,
	StyledColumn,
	StyledRow,
} from '../../../../../common/styledCommonComponents';
import React from 'react';
import { useGetPatientDataQuery } from '../../../../../redux/api/patientApi';
import { useTheme } from 'styled-components';
import { useOncoSelector } from '../../../../../hooks/redux-hook';
import StyledText from '../../../../../common/components/Text';
import TextInputField from '../../../../../common/components/TextInputField';
import { StyledCircularProgress } from '../../../../CustomCircularProgress/styles';

interface ContactModalProps {
	handleOnClose: () => void;
}

const ContactModal = ({ handleOnClose }: ContactModalProps) => {
	const { patientId } = useOncoSelector((state) => state.utilsSlice);
	const { data, isLoading } = useGetPatientDataQuery(patientId);
	const theme = useTheme();

	return (
		<Modal
			width={'568px'}
			onClose={handleOnClose}
			header={
				<StyledRow>
					<StyledText variant="h5" css={{ color: theme.black }}>
						Información de Contacto
					</StyledText>
				</StyledRow>
			}
			body={
				<>
					{isLoading ? (
						<StyledBox
							css={{
								height: '154px',
								width: '100%',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<StyledCircularProgress />
						</StyledBox>
					) : (
						<StyledColumn
							css={{
								alignItems: 'flex-start',
								gap: 16,
								alignSelf: 'stretch',
							}}
						>
							<TextInputField
								value={data?.phone}
								onChange={() => {}}
								label={'Número de Teléfono/Celular'}
								name="phone"
								disabled
								placeholder="+54 911 6953 5449"
								hasError={false}
							/>
							<TextInputField
								value={data?.email}
								onChange={() => {}}
								label={'Correo electrónico'}
								name="email"
								disabled
								placeholder="ejemplo@mail.com"
								hasError={false}
							/>
						</StyledColumn>
					)}
				</>
			}
		/>
	);
};

export default ContactModal;
