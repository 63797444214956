import {
	BiomarkerInterface,
	ProgressionInterface,
	SetbackInterface,
	ValidationInterface,
} from '../../redux/slices/validationSlice';
import { validate } from '../../validations';

export type ValidateBodyType = {
	diseaseSpecs: {
		metastasis: string;
		tumor: string;
		diagnosisDate: string;
		cancerStage: string;
		nodule: string;
		cancerOrgan: string;
		cancerType: string;
		cancerSubtype: string;
	};
	treatment: {
		treatmentLine: number;
		systematicTreatment: any;
		otherMotive: undefined;
		estimateFinishDate: string | undefined;
		primaryTreatment: any;
		medications: { medicationId: string }[];
		finishDate: string | undefined;
		endingMotive: string | undefined;
		treatmentMotive: any;
		startDate: string | undefined;
	};
	biomarkers: { evaluation: string; biomarker: string; sample: string }[];
	progressions: {
		progressionDate: string | undefined;
		treatment: {
			treatmentLine: number;
			systematicTreatment: string;
			estimateFinishDate: string | undefined;
			primaryTreatment: string;
			medications: { medicationId: string }[];
			finishDate: string | undefined;
			endingMotive: string | undefined;
			treatmentMotive: string;
			startDate: string | undefined;
		};
		metastasisSite: string | undefined;
		progressionSite: string;
	}[];
	setbacks: {
		treatment: {
			treatmentLine: number;
			systematicTreatment: string;
			estimateFinishDate: string | undefined;
			primaryTreatment: string;
			medications: { medicationId: string }[];
			finishDate: string | undefined;
			endingMotive: string | undefined;
			treatmentMotive: string;
			startDate: string | undefined;
		};
		setbackSite: string;
		metastasisSite: string | undefined;
		setbackDate: any;
	}[];
	userId: string;
};

export const transformValidateBody = (
	auxiliarData: any,
	values: ValidationInterface['values'] & { userId: string },
): ValidateBodyType => {
	return {
		userId: values.userId,
		diseaseSpecs: {
			diagnosisDate: values.diagnoseDate,
			tumor: values.tumor,
			nodule: values.nodule,
			metastasis: values.metastasis,
			cancerStage: values.stage,
			cancerOrgan: values.primaryTumor,
			cancerType: values.histologyType,
			cancerSubtype: values.histologySubtype,
		},
		treatment: {
			primaryTreatment:
				auxiliarData?.treatmentOptions[values.treatment.primaryTreatment],
			systematicTreatment:
				auxiliarData?.treatmentOptions[
					values.treatment.systematicTreatment
				],
			treatmentMotive:
				auxiliarData?.treatmentOptions[values.treatment.intention],
			startDate: values.treatment.startDate,
			estimateFinishDate: values.treatment.estimateFinishDate,
			finishDate: values.treatment.finishDate,
			treatmentLine: values.treatment.treatmentLine,
			endingMotive: values.treatment.endingMotive,
			// TODO: ADD TO THE MODAL, THE OTHER OPTIONS
			otherMotive: undefined,
			medications: values.medication[values.treatment.medicationId].map(
				(medication) => ({
					medicationId: medication.medicationId,
				}),
			),
		},
		biomarkers: values.biomarkers.map((biomarker) => ({
			sample: auxiliarData?.samples[biomarker.sampleId],
			biomarker: auxiliarData?.biomarkers[biomarker.biomarkerId],
			evaluation:
				auxiliarData?.evaluations[biomarker.evaluationId] ??
				biomarker.evaluationId,
		})),
		setbacks: values.setbacks.map((setback) => ({
			// @ts-ignore
			setbackDate: new Date(setback.date),
			setbackSite: setback.localOrDistance,
			metastasisSite: setback.placeMetastasis,
			treatment: {
				primaryTreatment:
					auxiliarData?.treatmentOptions[
						setback.treatment.primaryTreatment
					],
				systematicTreatment:
					auxiliarData?.treatmentOptions[
						setback.treatment.systematicTreatment
					],
				treatmentMotive:
					auxiliarData?.treatmentOptions[setback.treatment.intention],
				startDate: setback.treatment.startDate,
				estimateFinishDate: setback.treatment.estimateFinishDate,
				finishDate: setback.treatment.finishDate,
				treatmentLine: setback.treatment.treatmentLine,
				endingMotive: setback.treatment.endingMotive,
				medications: values.medication[setback.treatment.medicationId].map(
					(medication) => ({
						medicationId: medication.medicationId,
					}),
				),
			},
		})),
		progressions: values?.progressions?.map((progression) => ({
			progressionDate: progression.date,
			progressionSite: progression.localOrDistance,
			metastasisSite: progression.placeMetastasis,
			treatment: {
				primaryTreatment:
					auxiliarData?.treatmentOptions[
						progression.treatment.primaryTreatment
					],
				systematicTreatment:
					auxiliarData?.treatmentOptions[
						progression.treatment.systematicTreatment
					],
				treatmentMotive:
					auxiliarData?.treatmentOptions[progression.treatment.intention],
				startDate: progression.treatment.startDate,
				estimateFinishDate: progression.treatment.estimateFinishDate,
				finishDate: progression.treatment.finishDate,
				treatmentLine: progression.treatment.treatmentLine,
				endingMotive: progression.treatment.endingMotive,
				medications: values.medication[
					progression.treatment.medicationId
				].map((medication) => ({
					medicationId: medication.medicationId,
				})),
			},
		})),
	};
};
type Props =
	| {
			type: 'biomarkers';
			fields: BiomarkerInterface;
			values: undefined;
	  }
	| {
			type: 'setbacks';
			fields: SetbackInterface;
			values: ValidationInterface['values'];
	  }
	| {
			type: 'progressions';
			fields: ProgressionInterface;
			values: ValidationInterface['values'];
	  };

export const transformUpdateValidation = ({
	auxiliarData,
	type,
	fields,
	values,
}: Props & { auxiliarData: any }) => {
	switch (type) {
		case 'biomarkers':
			return {
				sample: fields.sampleId,
				biomarker: fields.biomarkerId,
				evaluation: fields.evaluationId,
			};
		case 'progressions':
			return {
				progressionDate: fields.date,
				progressionSite: fields.localOrDistance,
				metastasisSite: fields.placeMetastasis,
				treatment: {
					primaryTreatment:
						auxiliarData.treatmentOptions[
							fields.treatment.primaryTreatment
						],
					systematicTreatment:
						auxiliarData.treatmentOptions[
							fields.treatment.systematicTreatment
						],
					treatmentMotive:
						auxiliarData.treatmentOptions[fields.treatment.intention],
					startDate: fields.treatment.startDate,
					estimateFinishDate: fields.treatment.estimateFinishDate,
					finishDate: fields.treatment.finishDate,
					treatmentLine: fields.treatment.treatmentLine,
					endingMotive: fields.treatment.endingMotive,
					medications: values.medication[
						fields.treatment.medicationId
					].map((medication) => ({
						medicationId: medication.medicationId,
					})),
				},
			};
		case 'setbacks':
			return {
				// @ts-ignore
				setbackDate: new Date(fields.date),
				setbackSite: fields.localOrDistance,
				metastasisSite: fields.placeMetastasis,
				treatment: {
					primaryTreatment:
						auxiliarData.treatmentOptions[
							fields.treatment.primaryTreatment
						],
					systematicTreatment:
						auxiliarData.treatmentOptions[
							fields.treatment.systematicTreatment
						],
					treatmentMotive:
						auxiliarData.treatmentOptions[fields.treatment.intention],
					startDate: fields.treatment.startDate,
					estimateFinishDate: fields.treatment.estimateFinishDate,
					finishDate: fields.treatment.finishDate,
					treatmentLine: fields.treatment.treatmentLine,
					endingMotive: fields.treatment.endingMotive,
					medications: values.medication[
						fields.treatment.medicationId
					].map((medication) => ({
						medicationId: medication.medicationId,
					})),
				},
			};
	}
};

export const validateForm = (valuesToEv: any, validationsRules: any) => {
	let isValid:
		| { key: string; error: string[] }
		| {
				key: string;
				properties: { key: string; error: string[] };
		  }[] = [];
	Object.keys(valuesToEv).forEach((key) => {
		// @ts-ignore
		if (
			typeof valuesToEv[key] !== 'string' &&
			typeof valuesToEv[key] !== 'number'
		) {
			// setbacks o progressions
			if (['setbacks', 'progressions'].includes(key)) {
				valuesToEv[key].forEach((item: any) => {
					// aca lo que tengo es, desde el setback, el item seria el nombre de la property
					// ESTO SERIA CADA UNO DE LOS SETBACKS/PROGRESSIONS
					// item: SetbackInterface | ProgressionInterface
					let auxError: {
						key: string;
						properties: { key: string; error: string[] }[];
					} = {
						key: item.id,
						properties: [],
					};
					Object.keys(item).forEach((property) => {
						/*
                        id: string
                        date: string
                        localOrDistance: string
                        placeMetastasis?: string
                        treatment: TreatmentInterface
                     */
						if (property === 'treatment') {
							let valuendo: {
								key: string;
								properties: { key: string; error: string[] }[];
							} = {
								key: item.treatment.id,
								properties: [],
							};
							Object.keys(item[property]).forEach((property2) => {
								// dentro de cada property de treatment
								valuendo = {
									...valuendo,
									properties: [
										// @ts-ignore
										...valuendo.properties,
										{
											key: property2,
											// @ts-ignore
											error: validate(
												item[property][property2],
												validationsRules[property][property2],
											),
										},
									],
								};
							});
							isValid = [...isValid, valuendo];
						} else {
							auxError = {
								...auxError,
								properties: [
									// @ts-ignore
									...auxError.properties,
									{
										key: property,
										// @ts-ignore
										error: validate(
											item[property],
											validationsRules[key][property],
										),
									},
								],
							};
						}
					});
					isValid = [
						...isValid,
						// @ts-ignore
						auxError,
					];
				});
			} else if (key === 'treatment') {
				Object.keys(valuesToEv[key]).forEach((property) => {
					// Treatment fields
					isValid = [
						...isValid,
						{
							key: property,
							// @ts-ignore
							error: validate(
								valuesToEv[key][property],
								validationsRules[key][property],
							),
						},
					];
				});
			} else if (key === 'medication') {
				/// Medications
				Object.keys(valuesToEv[key]).forEach((item: any) => {
					valuesToEv[key][item].forEach((medication: any) => {
						let auxError: {
							key: string;
							properties: { key: string; error: string[] }[];
						} = {
							key: `${item}_${medication.id}`,
							properties: [],
						};
						Object.keys(medication).forEach((property: any) => {
							auxError = {
								...auxError,
								properties: [
									// @ts-ignore
									...auxError.properties,
									{
										key: property,
										// @ts-ignore
										error: validate(
											medication[property],
											validationsRules[key][property],
										),
									},
								],
							};
						});
						isValid = [...isValid, auxError];
					});
				});
			} else if (key === 'biomarkers') {
				valuesToEv[key]?.forEach((item: any) => {
					let auxError: {
						key: string;
						properties: { key: string; error: string[] }[];
					} = {
						key: item.id,
						properties: [],
					};
					Object.keys(item).forEach((property: any) => {
						auxError = {
							...auxError,
							properties: [
								// @ts-ignore
								...auxError.properties,
								{
									key: property,
									// @ts-ignore
									error: validate(
										item[property],
										validationsRules[key][property],
									),
								},
							],
						};
					});
					isValid = [
						...isValid,
						// @ts-ignore
						auxError,
					];
				});
				// TODO: biomarkers
			}
		} else {
			// name, surname or those kind of fields
			isValid = [
				// @ts-ignore
				...isValid,
				{
					key,
					error: validate(valuesToEv[key], validationsRules[key]),
				},
			];
		}
	});

	return isValid;
};
