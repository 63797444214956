import styled from 'styled-components';

export const StyledAccordionContent = styled.div`
	width: 100%;
	transition: all 0.3s ease-in-out;
	column-gap: 32px;
	display: flex;
	padding: 0 8px 24px 8px;
	flex-direction: column;
	box-sizing: border-box;
`;

export const StyledAccordionContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 8px;
	align-self: stretch;
	transition: all 0.3s ease-in-out;
`;

export const StyledAccordionTextContainer = styled.div`
	transition: all 0.3s ease-in-out;
	cursor: pointer;
	display: flex;
	padding: 8px 0px;
	justify-content: space-between;
	align-items: center;
	align-self: stretch;
`;
