import React from 'react';
import { IconProps } from './IconInterface';

const DigestiveIcon = ({
	color = '#000000',
	width = 24,
	height = 24,
}: IconProps) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g id="Variants=gastro">
				<path
					id="Exclude"
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M11.6396 8.66137C11.2387 7.86374 11.2387 6.64316 11.6396 5H14.0793C13.5748 6.96332 13.6896 8.20858 14.4242 8.73579C14.9214 9.09218 15.2114 8.95209 15.5659 8.78092C15.9973 8.57254 16.5241 8.31812 17.6359 8.85737C19.6608 9.83958 19.1345 13.2641 17.9696 15.0269C16.8049 16.7895 14.3185 18.5708 11.9868 18.2632C10.2882 18.0391 9.36694 16.3857 8.87586 15.5044C8.69283 15.176 8.56956 14.9547 8.48809 14.9547C8.188 14.9547 7.55388 14.9639 7.42992 16.2063C7.34729 17.0345 7.58224 17.9658 8.13373 19H5.69202C5.23091 18.1106 5 17.0054 5 15.6842C5 13.7025 5.9838 12.3714 7.10614 12.3714C7.42095 12.3714 7.79044 12.4767 8.19874 12.593C9.24655 12.8916 10.5499 13.2631 11.8404 12.1146C13.2138 10.8927 12.7186 10.2187 12.1413 9.43274C11.9647 9.19237 11.7804 8.94154 11.6396 8.66137ZM16.3591 13.7321C16.4475 13.4611 16.307 13.1672 16.0454 13.0758C15.7837 12.9843 15.5 13.1298 15.4117 13.4008C15.2618 13.8606 15.0169 14.2005 14.6797 14.4449C14.3381 14.6924 13.882 14.8549 13.2831 14.9053C13.0079 14.9285 12.8029 15.1783 12.8253 15.4634C12.8476 15.7485 13.0889 15.9608 13.3641 15.9376C14.0933 15.8763 14.7319 15.6711 15.253 15.2935C15.7784 14.9128 16.1464 14.3846 16.3591 13.7321Z"
					fill={color}
				/>
			</g>
		</svg>
	);
};
export default DigestiveIcon;

// "#5F8BFF"
