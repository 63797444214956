import React, { useState } from 'react';
import { StyledRow } from '../../../../common/styledCommonComponents';
import StyledText from '../../../../common/components/Text';
import { useTheme } from 'styled-components';
import { InputCheck } from '../../../../common/components/InputCheck';

interface CheckableResultProps {
	name: string;
	checked: boolean;
	onClick: any;
}

export const CheckableResult = ({
	name,
	checked,
	onClick,
}: CheckableResultProps) => {
	const theme = useTheme();
	const [isChecked, setIsChecked] = useState<boolean>(checked);

	return (
		<StyledRow
			css={{
				width: '173px',
				cursor: 'pointer',
				padding: '8px 16px',
				alignItems: 'center',
				gap: '8px',
				alignSelf: 'stretch',
				borderRadius: '8px',
				opacity: '0.8',
				background: isChecked ? theme.gray50 : theme.white,
				'&:hover': {
					background: theme.gray50,
				},
			}}
			onClick={() => {
				onClick(!isChecked, name);
				setIsChecked(!isChecked);
			}}
		>
			<InputCheck width={18} height={18} checked={isChecked} />
			<StyledText variant="body3" css={{ color: theme.black }}>
				{name}
			</StyledText>
		</StyledRow>
	);
};
