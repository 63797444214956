import Modal from '../Modal';
import { StyledRow } from '../../../../../common/styledCommonComponents';
import React from 'react';
import { useTheme } from 'styled-components';
import { useOncoSelector } from '../../../../../hooks/redux-hook';
import StyledText from '../../../../../common/components/Text';
import { PatientListComponent } from '../../PatientListComponent';
import { FreqMedicationHeader } from './components/FreqMedicationHeader';

interface FrequentMedicationModalProps {
	handleOnClose: () => void;
}

interface Patient {
	id: string;
	name: string;
	surname: string;
}

export interface FrequentMedication {
	color: string;
	medicationName: string;
	patients: Patient[];
}

const FrequentMedicationModal = ({
	handleOnClose,
}: FrequentMedicationModalProps) => {
	const theme = useTheme();
	const { color, medicationName, patients }: FrequentMedication =
		useOncoSelector((state) => state.utilsSlice.frequentMedicationData);

	return (
		<Modal
			width={'539px'}
			onClose={handleOnClose}
			header={
				<StyledRow>
					<StyledText variant="h5" css={{ color: theme.black }}>
						Medicamentos frecuentes
					</StyledText>
				</StyledRow>
			}
			body={
				<PatientListComponent
					header={
						<FreqMedicationHeader
							color={color}
							medicationName={medicationName}
							patients={patients}
						/>
					}
					patients={patients}
				/>
			}
		/>
	);
};

export default FrequentMedicationModal;
