import React from 'react';
import { Auxiliar } from '../../index';
import {
	StyledBox,
	StyledP,
} from '../../../../../../../../common/styledCommonComponents';
import { getProfileImageFromName } from '../../../../../../../../utils/utils';
import StyledText from '../../../../../../../../common/components/Text';
import Button from '../../../../../../../../common/components/Button';
import { useTheme } from 'styled-components';
import { useAddAuxiliarMutation } from '../../../../../../../../redux/api/listApi';

interface AuxiliarItemProps {
	auxiliar: Auxiliar;
}

const AuxiliarItem = ({ auxiliar }: AuxiliarItemProps) => {
	const [addAuxiliar, { isLoading }] = useAddAuxiliarMutation({});
	const theme = useTheme();

	return (
		<StyledBox
			css={{
				display: 'flex',
				minHeight: 50,
				boxSizing: 'border-box',
				flexDirection: 'row',
				justifyContent: 'space-between',
				alignItems: 'center',
				gap: 4,
				padding: 8,
				width: '100%',
			}}
		>
			<StyledBox
				css={{
					display: 'flex',
					height: '100%',
					flexDirection: 'row',
					alignItems: 'center',
					gap: 8,
				}}
			>
				{getProfileImageFromName({
					name: auxiliar.name,
					surname: auxiliar.surname,
					size: {
						width: '28px',
						height: '28px',
						fontSize: '12px',
					},
				})}
				<StyledP
					css={{
						fontSize: '14px',
						color: `${theme.black}`,
					}}
				>
					{auxiliar.name + ' ' + auxiliar.surname}
				</StyledP>{' '}
			</StyledBox>

			{auxiliar.isTeammate ? (
				<StyledBox css={{ color: theme.gray200 }}>
					<StyledText variant="buttonSmall">
						Ya pertenece al equipo
					</StyledText>
				</StyledBox>
			) : (
				<Button
					variant="ghost"
					size="small"
					onClick={() => addAuxiliar(auxiliar.assistantProfileId)}
					css={{
						background: 'white',
						color: `${theme.primary500}`,
					}}
					disabled={isLoading}
				>
					Agregar
				</Button>
			)}
		</StyledBox>
	);
};

export default AuxiliarItem;
