import {
	StyledBox,
	StyledTableCell,
	StyledTableRow,
} from '../../../common/styledCommonComponents';
import { theme } from '../../../common/theme';
import React from 'react';
import { CapitalizeText, getProfileImageFromName } from '../../../utils/utils';
import StyledText from '../../../common/components/Text';
import { rgba } from 'polished';
import Kebab from '../../../components/Kebab';
import { useNavigate } from 'react-router-dom';
import { useOncoDispatch } from '../../../hooks/redux-hook';
import { PatientStatus } from '../../../components/PatientProfileCard/components/PatientStatus';
import 'moment/locale/es';
import moment from 'moment';

interface PatientItemProps {
	row: {
		name: string;
		principalTumor: string;
		status: string;
		lastConnection: number;
		patientProfileId: string;
	};
}
moment.locale('es'); // set the locale to Spanish

const PatientItem = ({ row }: PatientItemProps) => {
	const { name, patientProfileId, principalTumor, status, lastConnection } =
		row;
	const navigation = useNavigate();

	const lastConnectionMoment = moment(lastConnection).fromNow();

	const options = [
		{
			label: 'Ver perfil',
			onClick: (e: any) => {
				e.stopPropagation();
				navigation(`/profile/${patientProfileId}`);
			},
		},
		// {
		// 	label: 'Contactar',
		// 	onClick: (e: any) => {
		// 		e.stopPropagation();
		// 		// dispatch()
		// 	},
		// },
	];
	return (
		<StyledTableRow
			css={{
				backgroundColor: 'white',
				height: '76px',
				'&:hover': {
					backgroundColor: rgba(theme.gray50, 0.5),
					cursor: 'pointer',
				},
			}}
			onClick={() => {
				navigation(`/profile/${patientProfileId}`);
			}}
		>
			<StyledTableCell>
				<StyledBox css={{ margin: 'auto', width: 'fit-content' }}>
					{getProfileImageFromName({
						name: name,
						size: {
							width: '28px',
							height: '28px',
						},
					})}
				</StyledBox>
			</StyledTableCell>
			<StyledTableCell>
				<StyledText variant={'body2'}>{name}</StyledText>
			</StyledTableCell>
			<StyledTableCell>
				<StyledText variant={'body2'}>{principalTumor}</StyledText>
			</StyledTableCell>
			<StyledTableCell>{PatientStatus(status)}</StyledTableCell>
			<StyledTableCell></StyledTableCell>
			<StyledTableCell>
				{lastConnection ? CapitalizeText(lastConnectionMoment) : 'Nunca'}
			</StyledTableCell>
			<StyledTableCell>
				<StyledBox css={{ margin: 'auto', width: 'fit-content' }}>
					<Kebab options={options} />
				</StyledBox>
			</StyledTableCell>
		</StyledTableRow>
	);
};

export default PatientItem;
