import React from 'react';
import StyledText from '../../../../../../common/components/Text';
import { useTheme } from 'styled-components';

interface AddAuxiliaryButtonProps {
	onClick: () => void;
	name: string;
}

const AddAuxiliaryButton = ({ onClick, name }: AddAuxiliaryButtonProps) => {
	const theme = useTheme();
	return (
		<StyledText
			onClick={onClick}
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				flexDirection: 'row',
				height: '34px',
				padding: '6px 10px',
				cursor: 'pointer',
				color: theme.gray600,
			}}
			variant={'buttonSmall'}
		>
			<StyledText variant={'buttonSmall'}>Agregar </StyledText>
			<StyledText variant={'buttonSmall'} style={{ fontWeight: 'bold' }}>
				{'"' + name + '"'}{' '}
			</StyledText>
			<StyledText variant={'buttonSmall'}>como auxiliar</StyledText>
		</StyledText>
	);
};

export default AddAuxiliaryButton;
