import React from 'react';
import { IconProps } from './IconInterface';

const TrashIcon = ({
	color = '#000000',
	width = 20,
	height = 20,
}: IconProps) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g id="Variants=trash">
				<path
					id="Vector"
					d="M10.5 7.5H13.5C13.5 7.10218 13.342 6.72064 13.0607 6.43934C12.7794 6.15804 12.3978 6 12 6C11.6022 6 11.2207 6.15804 10.9393 6.43934C10.658 6.72064 10.5 7.10218 10.5 7.5ZM9 7.5C9 6.70435 9.31607 5.94129 9.87868 5.37868C10.4413 4.81607 11.2043 4.5 12 4.5C12.7957 4.5 13.5587 4.81607 14.1213 5.37868C14.6839 5.94129 15 6.70435 15 7.5H18.75C18.9489 7.5 19.1397 7.57902 19.2803 7.71967C19.421 7.86032 19.5 8.05109 19.5 8.25C19.5 8.44891 19.421 8.63968 19.2803 8.78033C19.1397 8.92098 18.9489 9 18.75 9H18.0885L17.424 16.755C17.3601 17.5038 17.0175 18.2014 16.4639 18.7098C15.9103 19.2181 15.1861 19.5001 14.4345 19.5H9.5655C8.81393 19.5001 8.08971 19.2181 7.53611 18.7098C6.98252 18.2014 6.63988 17.5038 6.576 16.755L5.9115 9H5.25C5.05109 9 4.86032 8.92098 4.71967 8.78033C4.57902 8.63968 4.5 8.44891 4.5 8.25C4.5 8.05109 4.57902 7.86032 4.71967 7.71967C4.86032 7.57902 5.05109 7.5 5.25 7.5H9ZM14.25 12C14.25 11.8011 14.171 11.6103 14.0303 11.4697C13.8897 11.329 13.6989 11.25 13.5 11.25C13.3011 11.25 13.1103 11.329 12.9697 11.4697C12.829 11.6103 12.75 11.8011 12.75 12V15C12.75 15.1989 12.829 15.3897 12.9697 15.5303C13.1103 15.671 13.3011 15.75 13.5 15.75C13.6989 15.75 13.8897 15.671 14.0303 15.5303C14.171 15.3897 14.25 15.1989 14.25 15V12ZM10.5 11.25C10.3011 11.25 10.1103 11.329 9.96967 11.4697C9.82902 11.6103 9.75 11.8011 9.75 12V15C9.75 15.1989 9.82902 15.3897 9.96967 15.5303C10.1103 15.671 10.3011 15.75 10.5 15.75C10.6989 15.75 10.8897 15.671 11.0303 15.5303C11.171 15.3897 11.25 15.1989 11.25 15V12C11.25 11.8011 11.171 11.6103 11.0303 11.4697C10.8897 11.329 10.6989 11.25 10.5 11.25Z"
					fill={color}
				/>
			</g>
		</svg>
	);
};

export default TrashIcon;
