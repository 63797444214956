import React from 'react';
import Chart from 'react-apexcharts';
import { StyledBox } from '../../../common/styledCommonComponents';
import StyledText from '../../../common/components/Text';
import { useTheme } from 'styled-components';
import ErrorComponent from '../../ErrorComponent';
import { StyledCircularProgress } from '../../CustomCircularProgress/styles';
import { useDonutChartOptions } from './useDonutChartOptions';

const DonutChart = () => {
	const { gray300 } = useTheme();
	const { isLoading, error, emptyData, mockedSeries, options, refetch } =
		useDonutChartOptions();

	return (
		<>
			{isLoading ? (
				<StyledBox
					css={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						height: '100%',
					}}
				>
					<StyledCircularProgress />
				</StyledBox>
			) : error ? (
				<StyledBox
					css={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						height: '100%',
					}}
				>
					<ErrorComponent reload={refetch} />
				</StyledBox>
			) : emptyData ? (
				<StyledBox
					css={{
						height: '100%',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<StyledText
						variant="h6"
						css={{
							color: gray300,
						}}
					>
						Sin datos
					</StyledText>
				</StyledBox>
			) : (
				<StyledBox
					css={{
						position: 'relative',
					}}
				>
					<Chart
						options={options}
						series={mockedSeries}
						type="donut"
						width={300}
					/>
				</StyledBox>
			)}
		</>
	);
};

export default DonutChart;
