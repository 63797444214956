import React from 'react';
import { IconProps } from './IconInterface';

const MemoryIcon = ({
	width = 24,
	height = 24,
	color = '#0E0E0E',
}: IconProps) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M12.5714 4C13.1397 4 13.6848 4.21053 14.0867 4.58526C14.4885 4.96 14.7143 5.46826 14.7143 5.99822L14.7132 6.05417H17.9286C18.2127 6.05417 18.4853 6.15943 18.6862 6.3468C18.8871 6.53417 19 6.7883 19 7.05328L18.9989 9.99465H17.9286C17.388 9.99449 16.8672 10.1849 16.4708 10.5277C16.0744 10.8705 15.8316 11.3403 15.7911 11.843L15.7857 11.9929C15.7855 12.497 15.9897 12.9826 16.3573 13.3522C16.7249 13.7219 17.2288 13.9483 17.7679 13.9861L17.9286 13.9911L18.9989 13.9901L19 17.0444C19 17.3093 18.8871 17.5635 18.6862 17.7508C18.4853 17.9382 18.2127 18.0435 17.9286 18.0435H14.7111L14.7089 18.1364C14.6718 18.6498 14.4236 19.1301 14.0161 19.4772C13.6087 19.8242 13.0733 20.0113 12.5216 19.9995C11.9699 19.9876 11.4444 19.7777 11.0545 19.4136C10.6646 19.0494 10.4404 18.559 10.4286 18.0445H7.21429C6.93012 18.0445 6.6576 17.9392 6.45667 17.7518C6.25574 17.5645 6.14286 17.3103 6.14286 17.0454V13.9911C5.57454 13.9911 5.02949 13.7806 4.62763 13.4058C4.22576 13.0311 4 12.5228 4 11.9929C4 11.4629 4.22576 10.9547 4.62763 10.5799C5.02949 10.2052 5.57454 9.99465 6.14286 9.99465V7.05328C6.14286 6.7883 6.25574 6.53417 6.45667 6.3468C6.6576 6.15943 6.93012 6.05417 7.21429 6.05417H10.4275L10.4286 5.99822C10.4286 5.46826 10.6543 4.96 11.0562 4.58526C11.4581 4.21053 12.0031 4 12.5714 4Z"
				fill={color}
			/>
		</svg>
	);
};

export default MemoryIcon;
