import React from 'react';

const GoogleIcon = ({
	width = 24,
	height = 24,
}: {
	width: number;
	height: number;
}) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_1847_41969)">
				<path
					d="M16.5362 10.1535C16.5362 9.70029 16.4994 9.24466 16.421 8.79883H10.1328V11.366H13.7338C13.5843 12.194 13.1042 12.9265 12.4012 13.3919V15.0576H14.5495C15.8111 13.8965 16.5362 12.1818 16.5362 10.1535Z"
					fill="#9357F7"
				/>
				<path
					d="M10.1333 16.6685C11.9313 16.6685 13.4476 16.0781 14.5524 15.0591L12.4041 13.3933C11.8064 13.8 11.0347 14.0302 10.1357 14.0302C8.39649 14.0302 6.92182 12.8569 6.3927 11.2793H4.17578V12.9965C5.30751 15.2477 7.61261 16.6685 10.1333 16.6685V16.6685Z"
					fill="#34A853"
				/>
				<path
					d="M6.39043 11.2795C6.11117 10.4515 6.11117 9.55493 6.39043 8.72696V7.00977H4.17596C3.23041 8.89353 3.23041 11.1129 4.17596 12.9967L6.39043 11.2795V11.2795Z"
					fill="#FBBC04"
				/>
				<path
					d="M10.1333 5.97268C11.0837 5.95799 12.0023 6.31563 12.6907 6.97213L14.5941 5.06877C13.3888 3.93704 11.7892 3.31484 10.1333 3.33443C7.61261 3.33443 5.30751 4.75522 4.17578 7.00888L6.39025 8.72607C6.91692 7.14606 8.39404 5.97268 10.1333 5.97268V5.97268Z"
					fill="#EA4335"
				/>
			</g>
			<defs>
				<clipPath id="clip0_1847_41969">
					<rect
						width="13.3333"
						height="13.3333"
						fill="white"
						transform="translate(3.33301 3.33398)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default GoogleIcon;
