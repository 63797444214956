import React from 'react';
import { StyledBox } from '../../common/styledCommonComponents';
import { getProfileImageFromName } from '../../utils/utils';
import { DataTable } from '../PatientList/config';
import { ConfirmationButton } from './ConfirmationButton';

export const DoctorsAcceptanceListHeaderConst: DataTable[] = [
	{
		field: 'fullname',
		label: ' ',
		sortId: '',
		css: {
			width: 60,
			maxWidth: 60,
			paddingLeft: '24px',
			paddingRight: '8px',
			boxSizing: 'border-box',
		},
		render: ({ children }: any) =>
			getProfileImageFromName({
				name: children,
				size: {
					width: '28px',
					height: '28px',
				},
			}),
	},
	{
		field: 'fullname',
		label: 'Nombre y Apellido',
		sortId: 'name',
		button: false,
		css: { width: 156 },
		render: (props: any) => <p>{props.children}</p>,
	},
	{
		field: 'email',
		label: 'Email',
		sortId: 'email',
		button: false,
		css: { width: 156 },
		render: (props: any) => <p>{props.children}</p>,
	},
	{
		type: 'blank',
		field: '',
		label: '',
		css: {
			width: 156,
		},
		sortId: '',
		render: () => <StyledBox></StyledBox>,
	},
	{
		type: 'confirmationButton',
		field: 'id',
		label: '',
		css: {
			width: 156,
		},
		sortId: '',
		render: (props: any) => {
			// @ts-ignore
			return <ConfirmationButton doctorId={props.children} />;
		},
	},
];
