import React from 'react';
import { useOncoDispatch, useOncoSelector } from '../../../../hooks/redux-hook';
import {
	useGetMetastasisQuery,
	useGetNoduleQuery,
	useGetTumorQuery,
} from '../../../../redux/api/validateFormApi';
import { setValue } from '../../../../redux/slices/validationSlice';
import TNMField from './components/TNMField';

const TNMContainer = () => {
	const values = useOncoSelector((state) => state.validationSlice.values);
	const errors = useOncoSelector((state) => state.validationSlice.errors);
	const dispatch = useOncoDispatch();
	const isEditable = useOncoSelector(
		(state) => state.validationSlice.isEditable,
	);
	const { data: tumorOptions } = useGetTumorQuery(values.primaryTumor, {
		skip: !values.primaryTumor || values.primaryTumor === 'no_value',
	});
	const { data: noduleOptions } = useGetNoduleQuery(values.primaryTumor, {
		skip: !values.primaryTumor || values.primaryTumor === 'no_value',
	});
	const { data: metastasisOptions } = useGetMetastasisQuery(
		values.primaryTumor,
		{ skip: !values.primaryTumor || values.primaryTumor === 'no_value' },
	);

	const handleOnChange = (name: string, value: string) => {
		dispatch(
			setValue({
				key: name as keyof typeof values,
				value: value,
			}),
		);
	};

	return (
		<>
			<TNMField
				value={values.tumor}
				label={'Tumor'}
				name={'tumor'}
				options={tumorOptions}
				onChange={handleOnChange}
				error={errors?.tumor?.error ? errors?.tumor?.error[0] : false}
				disabled={
					!values.primaryTumor ||
					values.primaryTumor === 'no_value' ||
					values.status === 'ACTIVE'
				}
				required
			/>
			<TNMField
				value={values.nodule}
				label={'Nodulo'}
				name={'nodule'}
				options={noduleOptions}
				onChange={handleOnChange}
				error={errors?.nodule?.error ? errors?.nodule?.error[0] : false}
				disabled={
					!values.primaryTumor ||
					values.primaryTumor === 'no_value' ||
					values.status === 'ACTIVE'
				}
				required
			/>
			<TNMField
				value={values.metastasis}
				label={'Metastasis'}
				name={'metastasis'}
				options={metastasisOptions}
				onChange={handleOnChange}
				error={
					errors?.metastasis?.error ? errors?.metastasis?.error[0] : false
				}
				disabled={
					!values.primaryTumor ||
					values.primaryTumor === 'no_value' ||
					values.status === 'ACTIVE'
				}
				required
			/>
		</>
	);
};

export default TNMContainer;
