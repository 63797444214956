import React, { useCallback, useEffect, useReducer, useState } from 'react';
import Modal from '../Modal';
import StyledText from '../../../../../common/components/Text';
import {
	StyledColumn,
	StyledRow,
} from '../../../../../common/styledCommonComponents';
import { useTheme } from 'styled-components';
import { useAuth0 } from '@auth0/auth0-react';
import Button from '../../../../../common/components/Button';
import { useMedicSignupMutation } from '../../../../../redux/api/sessionApi';
import TextInputField from '../../../../../common/components/TextInputField';
import { validate, ValidationType } from '../../../../../validations';
import {
	toastError,
	toastSuccess,
} from '../../../../../pages/DoctorsAcceptanceList/utils';

interface DoctorRegisterProps {
	handleOnClose: () => void;
}

const DoctorRegisterModal = ({ handleOnClose }: DoctorRegisterProps) => {
	const { user, logout } = useAuth0();
	const theme = useTheme();
	const [values, setValues] = useReducer(
		(prev: any, cur: any) => ({
			...prev,
			...cur,
		}),
		{
			email: '',
			firstName: '',
			lastName: '',
		},
	);

	const fieldsValidations: Record<string, ValidationType[]> = {
		email: [ValidationType.IS_EMAIL, ValidationType.IS_REQUIRED],
		firstName: [ValidationType.IS_STRING, ValidationType.IS_REQUIRED],
		lastName: [ValidationType.IS_STRING, ValidationType.IS_REQUIRED],
	};
	const [errors, setErrors] = useState<{ name: string; error: string[] }[]>(
		[],
	);

	const [mutation, { data, isLoading, isSuccess, error, isError }] =
		useMedicSignupMutation();

	const handleChange = (name: string, value: string) => {
		setValues({ ...values, [name]: value });
	};

	const handleSend = useCallback(() => {
		let errors: { name: string; error: string[] }[] = [];
		Object.keys(fieldsValidations).forEach((item, index) => {
			const error = validate(values[item], fieldsValidations[item]);
			if (error) {
				errors = [...errors, { name: item, error: error }];
			}
		});

		if (!errors.find((error) => error.error.length > 0)) {
			mutation({
				email: values.email,
				name: values.firstName,
				surname: values.lastName,
			}).then((res) => {
				logout({
					logoutParams: {
						returnTo: window.location.origin + '/login',
					},
				});
			});
		} else {
			setErrors(errors);
		}
	}, [values]);

	useEffect(() => {
		setValues({
			email: user?.email ?? '',
			firstName: user?.given_name ?? '',
			lastName: user?.family_name ?? '',
		});
	}, [user]);

	useEffect(() => {
		if (errors.length > 0) {
			setErrors([]);
		}
	}, [values]);

	useEffect(() => {
		if (isSuccess) {
			toastSuccess(
				<StyledText variant="body2">
					Solicitud enviada exitosamente
				</StyledText>,
			);
			handleOnClose();
		}
	}, [isSuccess]);

	useEffect(() => {
		if (error) {
			toastError(
				<StyledText variant="body2">
					{
						// @ts-ignore
						error?.data?.errors?.[0] ?? 'Ocurrió un error'
					}
				</StyledText>,
			);
		}
	}, [isError, error]);

	return (
		<Modal
			header={
				<StyledColumn css={{ gap: 8, color: theme.black }}>
					<StyledText variant={'h5'}>Solicitar acceso</StyledText>
					<StyledText variant={'body2'}>
						Completa tus datos y te haremos saber cuando un administrador
						te de acceso a registrarte en ICAN.
					</StyledText>
				</StyledColumn>
			}
			body={
				<StyledColumn css={{ gap: 16, width: '100%' }}>
					<StyledColumn css={{ gap: 8, color: theme.black }}>
						<TextInputField
							value={values.email}
							label={'Correo electrónico de Google'}
							onChange={(name: string, value: string) =>
								setValues({ [name]: value })
							}
							name={'email'}
							error={
								errors.find((error) => error.name === 'email')
									?.error?.[0] ?? false
							}
							disabled
							required
						/>
						<TextInputField
							value={values.firstName}
							label={'Nombre'}
							onChange={(name: string, value: string) =>
								setValues({ [name]: value })
							}
							name={'firstName'}
							error={
								errors.find((error) => error.name === 'firstName')
									?.error?.[0] ?? false
							}
							disabled={isLoading}
							required
						/>
						<TextInputField
							value={values.lastName}
							label={'Apellido'}
							onChange={(name: string, value: string) =>
								setValues({ [name]: value })
							}
							name={'lastName'}
							error={
								errors.find((error) => error.name === 'lastName')
									?.error?.[0] ?? false
							}
							disabled={isLoading}
							required
						/>
					</StyledColumn>
					<StyledRow css={{ width: '100%', justifyContent: 'flex-end' }}>
						<Button
							variant={'filled'}
							size={'medium'}
							css={{
								width: 130,
							}}
							text={'Enviar'}
							disabled={isLoading}
							onClick={handleSend}
						/>
					</StyledRow>
				</StyledColumn>
			}
			width={'568px'}
			onClose={handleOnClose}
		/>
	);
};

export default DoctorRegisterModal;
