import React, { useState, useEffect } from 'react';
import StyledText from '../../../../../../../common/components/Text';
import { useTheme } from 'styled-components';
import { daysTranslate } from '../../../../../../../utils/utils';

export const FrequencySummary = ({ data }: { data: any }) => {
	const theme = useTheme();
	const [daySummary, setDaySummary] = useState<string>('Cargando...');
	const [weekSummary, setWeekSummary] = useState<string>('');
	const { days, week } = data;

	useEffect(() => {
		if (days.length === 7) {
			setDaySummary('Todos los días');
		} else if (days.length === 0) {
			setDaySummary('No hay días seleccionados');
		} else if (days.length === 6 && week !== 0) {
			setDaySummary('6 días');
		} else {
			const translatedDays = days.map((day: any) => daysTranslate[day]);
			if (translatedDays.length === 1) {
				setDaySummary(translatedDays[0]);
			} else {
				const summaryDays = translatedDays
					.slice(0, days.length - 1)
					.join(', ');
				setDaySummary(
					`${summaryDays} y ${translatedDays[translatedDays.length - 1]}`,
				);
			}
		}

		if (days.length !== 0) {
			setWeekSummary(`, cada ${week} semanas`);
		}
	}, [data]);

	return (
		<StyledText
			variant="body3"
			css={{ color: theme.gray500, marginRight: '8px' }}
		>
			{daySummary}
			{week > 0 && weekSummary}
		</StyledText>
	);
};
