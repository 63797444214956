import React, { useEffect } from 'react';
import { useTheme } from 'styled-components';
import { StyledBox, StyledColumn } from '../../common/styledCommonComponents';
import Card from '../Card';
import { useDispatch } from 'react-redux';
import { setModalOpen } from '../../redux/slices/utilsSlice';
import { ModalTypeEnum } from '../../utils/utils';
import MedicationItem from './MedicationItem';
import { useFrequentMedicationsQuery } from '../../redux/api/homeApi';
import StyledText from '../../common/components/Text';
import { StyledCircularProgress } from '../CustomCircularProgress/styles';
import { ErrorMessage } from '../../pages/PatientProfile/components/ErrorMessage';

interface Patient {
	id: string;
	patientProfileId: string;
	name: string;
	surname: string;
}

interface Medication {
	medicationId: string;
	medicationName: string;
	patients: Patient[];
}
interface ColoredMedication extends Medication {
	color: string;
}

function FrequentMedication() {
	const theme = useTheme();
	const dispatch = useDispatch();

	const {
		data: frequentMedications,
		isError: isErrorFrequentMedications,
		isLoading: isLoadingFrequentMedications,
		refetch: refetchFrequentMedications,
	} = useFrequentMedicationsQuery();

	const colors = [
		theme.primary500,
		theme.pink500,
		theme.blue500,
		theme.orange500,
		theme.green500,
		theme.gray500,
	];

	const assignColorsToFrequentMedications = (
		frequentMedications: Medication[],
		colors: string[],
	): ColoredMedication[] => {
		return frequentMedications?.map((medication) => {
			const color = colors.shift();

			if (!color) {
				throw new Error('No hay más colores disponibles');
			}

			return {
				...medication,
				color,
			};
		});
	};

	const coloredFrequentMedications: any[] = assignColorsToFrequentMedications(
		frequentMedications,
		repeatArray([...colors], 7),
	);

	function repeatArray(originalArray: any[], times: number) {
		if (
			!Array.isArray(originalArray) ||
			!Number.isInteger(times) ||
			times <= 0
		) {
			throw new Error(
				'Invalid input. Please provide a valid array and a positive integer.',
			);
		}

		return Array.from({ length: times }, () => [...originalArray]).flat();
	}

	const totalPatients = coloredFrequentMedications?.reduce(
		(acc: any, curr: any) => acc + curr.patients.length,
		0,
	);
	const emptyFrequentMedication = coloredFrequentMedications?.length === 0;

	const showMedicamentData = (freqMedication: ColoredMedication) => {
		dispatch(
			setModalOpen({
				type: ModalTypeEnum.FREQUENT_MEDICATION_MODAL,
				frequentMedicationData: freqMedication,
			}),
		);
	};

	return (
		<Card title={'Medicamentos frecuentes'} width={'340px'} height={'260px'}>
			{isLoadingFrequentMedications ? (
				<StyledBox
					css={{
						height: '100%',
						width: '100%',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<StyledCircularProgress />
				</StyledBox>
			) : emptyFrequentMedication ? (
				<StyledBox
					css={{
						height: '100%',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<StyledText
						variant="h6"
						css={{
							color: theme.gray300,
						}}
					>
						Sin datos
					</StyledText>
				</StyledBox>
			) : isErrorFrequentMedications ? (
				<ErrorMessage refetch={refetchFrequentMedications} />
			) : (
				<StyledBox
					css={{
						width: '100%',
						height: '100%',
						display: 'flex',

						flexDirection: 'column',
					}}
				>
					<StyledBox
						css={{
							display: 'flex',
							alignitems: 'flex-start',
							marginLeft: 10,
							alignSelf: 'stretch',
							paddingBottom: 16,
						}}
					>
						{coloredFrequentMedications.map(
							(element: ColoredMedication, index: number) => (
								<StyledBox
									key={'freq-color-' + index}
									css={{
										height: 12,
										marginLeft: -10,
										width: `calc(${
											(element.patients.length / totalPatients) * 100
										}% + 10px)`,
										borderRadius: 8,
										background: element.color,
									}}
								></StyledBox>
							),
						)}
					</StyledBox>
					<StyledColumn
						css={{
							gap: 4,
						}}
					>
						{coloredFrequentMedications
							.sort((a, b) => b.patients.length - a.patients.length)
							.map((freqMedication: ColoredMedication, index) => (
								<MedicationItem
									key={index}
									name={freqMedication.medicationName}
									color={freqMedication.color}
									patients={freqMedication.patients.length}
									onClick={() => showMedicamentData(freqMedication)}
								/>
							))}
					</StyledColumn>
				</StyledBox>
			)}
		</Card>
	);
}

export default FrequentMedication;
