import React from 'react';
import {
	StyledTableCell,
	StyledTableRow,
} from '../../../common/styledCommonComponents';
import { theme } from '../../../common/theme';
import StyledText from '../../../common/components/Text/styles';
import { DataTable } from '../../../pages/PatientList/config';

interface RowTableComponentProps {
	headerStructure: any;
	row: any;
	handleRowClick?: any;
	handleOptionsClick?: any;
}

export const RowTableComponent = ({
	headerStructure,
	row,
	handleRowClick,
	handleOptionsClick,
}: RowTableComponentProps) => {
	return (
		<StyledTableRow
			css={{
				backgroundColor: 'white',
				height: '76px',
			}}
		>
			{headerStructure.map((column: DataTable, index: number) => (
				<StyledTableCell
					onClick={
						column.field !== 'kebab' && handleRowClick
							? handleRowClick
							: undefined
					}
					sx={{
						padding: '0 8px',
						borderColor: theme.gray50,
						...column.css,
					}}
					key={index}
				>
					{column &&
						column.render &&
						column.render({
							children: row[column.field],
							row,
							handleOptionsClick,
						})}
				</StyledTableCell>
			))}
		</StyledTableRow>
	);
};
