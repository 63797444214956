import React from 'react';

const ChevronLeftIcon = ({
	color = '#000',
	width = 18,
	height = 18,
}: {
	color?: string;
	width?: number;
	height?: number;
}) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M15.6002 18.8599C16.1333 18.3269 16.1333 17.4658 15.6002 16.9328L10.2973 11.6298L15.6002 6.32689C16.1333 5.79386 16.1333 4.93281 15.6002 4.39978C15.0672 3.86675 14.2062 3.86675 13.6731 4.39978L7.39977 10.6731C6.86674 11.2062 6.86674 12.0672 7.39977 12.6002L13.6731 18.8736C14.1925 19.3929 15.0672 19.3929 15.6002 18.8599Z"
				fill={color}
			/>
		</svg>
	);
};
export default ChevronLeftIcon;
