import React, { useEffect, useMemo, useState } from 'react';
import { StyledBox, StyledScreen } from '../../common/styledCommonComponents';
import TableComponent from '../../components/TableComponent';
import ResultNotFound from '../../components/ResultNotFound';
import { useGetPatientsListQuery } from '../../redux/api/patientApi';
import { CapitalizeText } from '../../utils/utils';
import { UserListQueryParams } from '../../utils/types';
import PatientItem from './PatientItem';
import { TableContainer } from '@mui/material';
import { PatientListHeaderConst } from './config';

interface PatientsQueryParams extends UserListQueryParams {}

const PatientListScreen = () => {
	const [searchValue, setSearchValue] = useState<string>('');
	const [actualPage, setActualPage] = useState<number>(1);
	const pageSize = 8;
	const [params, setParams] = useState<PatientsQueryParams>({ pageSize });

	const {
		data,
		isLoading: patientInitalLoading,
		refetch: refetchPatients,
	} = useGetPatientsListQuery(params, {
		selectFromResult: ({ data, isLoading }: any) => ({
			isLoading,
			data: {
				...data,
				patients: data?.patients.map((patient: any) => ({
					...patient,
					name: `${CapitalizeText(patient.name)} ${CapitalizeText(
						patient.surname,
					)}`,
					iconName: `${CapitalizeText(patient.name)} ${CapitalizeText(
						patient.surname,
					)}`,
				})),
				actualPage: data?.actualPage,
				maxPage: data?.maxPage,
			},
		}),
	});

	useEffect(() => {
		if (!searchValue) {
			setParams((prevParams) => {
				const newParams = { ...prevParams };
				delete newParams?.param;
				return newParams;
			});
		} else {
			setActualPage(1);
			setParams((prevParams) => {
				const newParams = { ...prevParams, param: searchValue };
				return newParams;
			});
		}
	}, [searchValue]);

	useEffect(() => {
		setParams((prevParams) => {
			const newParams = {
				...prevParams,
				firstPage: actualPage - 1,
			};
			return newParams;
		});
	}, [actualPage]);

	useEffect(() => {
		refetchPatients();
	}, [params]);

	const fetchSort = (field: string, sortingOrder: 'asc' | 'desc') => {
		setActualPage(1);
		setParams((prevParams) => {
			const newParams = {
				...prevParams,
				column: field,
				order: sortingOrder,
			};
			return newParams;
		});
	};

	// check loading props
	return (
		<>
			<StyledScreen
				css={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
				}}
			>
				<StyledBox
					css={{
						display: 'flex',
						flexDirection: 'column',
						margin: '20px',
						padding: '20px 40px',
						width: '100%',
						boxSizing: 'border-box',
						flex: 1,
					}}
				>
					<StyledBox
						as={TableContainer}
						css={{
							width: 'calc(100vw - 40px)',
							height: '100%',
							maxWidth: '1124px',
							maxHeight: '824px',
							flex: 1,
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'space-between',
						}}
					>
						<TableComponent
							headerStructure={PatientListHeaderConst}
							search={setSearchValue}
							dataList={data?.patients}
							loading={patientInitalLoading}
							fetchSort={fetchSort}
							actualPage={data?.actualPage + 1}
							totalPages={data?.maxPage + 1}
							handlePageChange={setActualPage}
							itemComponent={PatientItem}
							emptyComponent={
								<ResultNotFound
									title={'No se han encontrado resultados'}
									text={'Verifique si el paciente ha sido aprobado'}
								/>
							}
						/>
					</StyledBox>
				</StyledBox>
			</StyledScreen>
		</>
	);
};
export default PatientListScreen;
