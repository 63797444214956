import React, { ReactNode, useState, Dispatch, SetStateAction } from 'react';
import {
	StyledAccordionContainer,
	StyledAccordionContent,
	StyledAccordionTextContainer,
} from './styles';
import ChevronUpIcon from '../../../assets/ChevronUpIcon';
import ChevronDownIcon from '../../../assets/ChevronDownIcon';

interface AccordionProps {
	header: any;
	children: ReactNode;
	open?: boolean;
	setOpen?: Dispatch<SetStateAction<boolean>>;
}

const Accordion = ({
	header: Header,
	children,
	open: isOpen = true,
	setOpen: propSetOpen,
}: AccordionProps) => {
	const [open, setOpen] = useState(isOpen);

	const handleSetOpen = () => {
		if (propSetOpen) {
			propSetOpen(!open);
		} else {
			setOpen(!open);
		}
	};

	return (
		<StyledAccordionContainer>
			<StyledAccordionTextContainer onClick={handleSetOpen}>
				<Header.type open={open} {...Header.props} />
				{open ? (
					<ChevronUpIcon color={'#000000'} width={24} height={24} />
				) : (
					<ChevronDownIcon color={'#000000'} width={24} height={24} />
				)}
			</StyledAccordionTextContainer>
			{open && <StyledAccordionContent>{children}</StyledAccordionContent>}
		</StyledAccordionContainer>
	);
};

export default Accordion;
