import { useNavigate } from 'react-router-dom';
import { useOncoDispatch } from '../../../../hooks/redux-hook';
import React from 'react';
import { useTheme } from 'styled-components';
import {
	StyledBox,
	StyledRow,
	StyledTableCell,
	StyledTableRow,
} from '../../../../common/styledCommonComponents';
import { getProfileImageFromName } from '../../../../utils/utils';
import StyledText from '../../../../common/components/Text';
import { rgba } from 'polished';
import { ConfirmationButton } from '../../ConfirmationButton';

interface DoctorItemProps {
	row: {
		name: string;
		surname: string;
		email: string;
		id: string;
	};
}

const DoctorItem = ({ row }: DoctorItemProps) => {
	const { name, id, surname, email } = row;
	const navigation = useNavigate();
	const dispatch = useOncoDispatch();
	const theme = useTheme();
	return (
		<StyledTableRow
			css={{
				backgroundColor: 'white',
				height: '76px',
			}}
		>
			<StyledTableCell>
				<StyledBox css={{ margin: 'auto', width: 'fit-content' }}>
					{getProfileImageFromName({
						name,
						surname,
						size: {
							width: '28px',
							height: '28px',
						},
					})}
				</StyledBox>
			</StyledTableCell>
			<StyledTableCell>
				<StyledText variant={'body2'}>
					{name} {surname}
				</StyledText>
			</StyledTableCell>
			<StyledTableCell>
				<StyledText variant={'body2'}>{email}</StyledText>
			</StyledTableCell>
			<StyledTableCell></StyledTableCell>
			<StyledTableCell>
				<StyledRow css={{ width: '100%', justifyContent: 'flex-end' }}>
					<ConfirmationButton doctorId={id} />
				</StyledRow>
			</StyledTableCell>
		</StyledTableRow>
	);
};

export default DoctorItem;
