import styled from 'styled-components';
import { Menu } from '@mui/material';

export const StyledMenu = styled(Menu)`
	&& {
		& .MuiPaper-root {
			overflow: hidden;
		}
	}
	&.kebab-menu {
		.MuiMenu-list {
			display: flex !important;
			flex-direction: column !important;
		}
	}
	'muimenu-list': {
	}
	'.muimenuitem-root': {
		border-top: 1px solid ${({ theme }) => theme.gray100} !important;
	}
	'.muimenuitem-root:first-child': {
		border-top-style: none !important;
	}
`;
