import React from 'react';
import TreatmentIcon from '../../assets/TreatmentIcon';
import { StyledBox } from '../../common/styledCommonComponents';
import { useTheme } from 'styled-components';
import DonutChart from './donutChart/DonutChart';
import Card from '../Card';
// import { useCancerStatisticsQuery } from '../../redux/api/homeApi';
import { StyledCircularProgress } from '../CustomCircularProgress/styles';

const DonutWidget = () => {
	const theme = useTheme();
	// const { isLoading } = useCancerStatisticsQuery();
	const isLoading = false;

	return (
		<Card
			css={{
				overflow: 'hidden',
			}}
			title={'Proporción de pacientes por Estadío'}
			width={'345px'}
			height={'272px'}
			childrenCss={{
				overflow: 'visible',
			}}
		>
			<StyledBox
				css={{
					height: '100%',
					width: '100%',
					alignItems: 'center',
					justifyContent: 'center',
					overflow: 'visible',
				}}
			>
				{isLoading ? <StyledCircularProgress size={50} /> : <DonutChart />}
			</StyledBox>
		</Card>
	);
};

export default DonutWidget;
