import React from 'react';
import { StyledBox } from '../../../common/styledCommonComponents';
import PersonWithHeartIcon from '../../../assets/PersonWithHeartIcon';
import { theme } from '../../../common/theme';
import ClinicalDocumentIcon from '../../../assets/ClinicalDocumentIcon';
import RulerIcon from '../../../assets/RulerIcon';

export enum QuestionaryType {
	BIOMETRIC = 'biometric',
	PRO_CTCAE = 'weekly',
	GENERAL_STATE = 'daily',
}

interface ReportIconProps {
	type: any;
	width?: number;
	height?: number;
}

const iconStyles = {
	[QuestionaryType.GENERAL_STATE]: {
		background: theme.orange200,
		color: theme.orange500,
	},
	[QuestionaryType.PRO_CTCAE]: {
		background: '#EAE0FF',
		color: theme.primary500,
	},
	[QuestionaryType.BIOMETRIC]: {
		background: theme.success100,
		color: theme.green600,
	},
};

export const ReportIcon = ({ type, width, height }: ReportIconProps) => {
	const style = iconStyles[type as QuestionaryType] || {
		background: theme.gray200,
		color: 'black',
	};
	const handleIconRender = (icon: any) => {
		switch (icon) {
			case QuestionaryType.GENERAL_STATE:
				return (
					<PersonWithHeartIcon
						color={style.color}
						color2={style.background}
						width={20}
						height={20}
					/>
				);
			case QuestionaryType.PRO_CTCAE:
				return (
					<ClinicalDocumentIcon
						color={style.color}
						color2={style.background}
						width={20}
						height={20}
					/>
				);
			case QuestionaryType.BIOMETRIC:
				return <RulerIcon color={style.color} width={20} height={20} />;
			default:
				return <>NaN</>; // default icon?
		}
	};

	return (
		<StyledBox
			css={{
				width: width ?? 'auto',
				height: height ?? 'auto',
				borderRadius: '50%',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				padding: 8,
				...style,
			}}
		>
			{handleIconRender(type)}
		</StyledBox>
	);
};
