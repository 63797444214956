import styled, {
	css as styledComponentCss,
	DefaultTheme,
} from 'styled-components';
import { CustomCssProps, StyledBox } from '../../styledCommonComponents';
import StyledText from '../Text/styles';

const DropdownVariantStyles = (theme: DefaultTheme) => ({
	default: {
		backgroundColor: theme.white,
		outline: `1px solid ${theme.gray200}`,
	},
	disabled: {
		backgroundColor: theme.gray50,
		outline: `1px solid ${theme.gray200}`,
		color: theme.gray400,
	},
	error: {
		backgroundColor: theme.white,
		outline: `1px solid ${theme.oncoRed}`,
	},
});

interface DropdownContainerProps extends CustomCssProps {
	disabled?: boolean;
	error?: boolean;
}

export const StyledSelect = styled(StyledBox)<DropdownContainerProps>`
	padding: 12px 16px;
	font-size: 16px;
	height: 43px;
	box-sizing: border-box;
	border-style: none;
	width: 100%;
	font-weight: 400;
	border-radius: 8px;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	color: ${(props) => props.theme.black};

	${(props) => DropdownVariantStyles(props.theme).default};

	${(props) => props.disabled && DropdownVariantStyles(props.theme).disabled};
	${(props) => props.error && DropdownVariantStyles(props.theme).error};

	&:focus {
		${(props) => DropdownVariantStyles(props.theme).default};
	}

	${(props) => styledComponentCss`${props.css}`}
`;

export const StyledOption = styled(StyledText)<CustomCssProps>`
	width: 100%;
	display: block;
	cursor: default;
	padding: 8px 12px;
	/* margin:0; */
	${(props) => styledComponentCss`${props.css}`}
	&:hover {
		background-color: ${(props) => props.theme.oncoGrey};
	}
`;
