import React from 'react';
import {
	StyledBox,
	StyledTableCell,
} from '../../../common/styledCommonComponents';
import ArrowSortIcon from '../../../assets/ArrowSortIcon';
import StyledText from '../../../common/components/Text/styles';
import { theme } from '../../../common/theme';

interface HeaderButtonsProps {
	index: number;
	header: any;
	handleSort: any;
}

export const HeaderButtons = ({
	index,
	header,
	handleSort,
}: HeaderButtonsProps) => {
	return (
		<StyledTableCell
			key={index}
			sx={{
				color: theme.primary500,
				padding: '0 8px',
				borderColor: 'transparent',
				...header?.css,
			}}
		>
			{header.button ? (
				<StyledBox
					onClick={() => handleSort(header.sortId)}
					css={{
						display: 'flex',
						alignItems: 'center',
						cursor: 'pointer',
					}}
				>
					<StyledText variant="body2">{header.label}</StyledText>
					<ArrowSortIcon
						color={theme.primary500}
						color2={theme.primary500}
						width={18}
						height={18}
					/>
				</StyledBox>
			) : (
				<StyledBox
					css={{
						...header?.css,
					}}
				>
					<StyledText variant="body2">{header.label}</StyledText>
				</StyledBox>
			)}
		</StyledTableCell>
	);
};
