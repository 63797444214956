import React from 'react';
import { StyledWaitingListContainer } from './style';
import PatientWaitingItem from './components/PatientWaitingItem';
import { useWaitingListQuery } from '../../redux/api/listApi';
import Card from '../Card';
import { StyledBox } from '../../common/styledCommonComponents';
import { StyledCircularProgress } from '../CustomCircularProgress/styles';
import { StyledH6 } from '../../common/components/Text/styles';
import { useTheme } from 'styled-components';
import { ErrorMessage } from '../../pages/PatientProfile/components/ErrorMessage';

type UserResponseType = {
	id: string;
	name: string;
	patientProfileId: string;
	principalTumor: string;
	status: string;
	surname: string;
	dni: string;
};

function WaitingList() {
	const theme = useTheme();
	const { data, isLoading, isError, refetch } = useWaitingListQuery();

	return (
		<Card
			title={'Pendientes de aprobación'}
			width={'298px'}
			height={'230px'}
			css={{
				gap: 10,
			}}
		>
			<StyledWaitingListContainer>
				{isLoading ? (
					<StyledBox
						css={{
							flex: 1,
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<StyledCircularProgress />
					</StyledBox>
				) : isError ? (
					<ErrorMessage refetch={refetch} />
				) : data?.length > 0 ? (
					data?.map(
						(
							{ patientProfileId, name, surname, dni }: UserResponseType,
							index: number,
						) => (
							<PatientWaitingItem
								key={index}
								name={name}
								surname={surname}
								dni={dni}
								patientProfileId={patientProfileId}
							/>
						),
					)
				) : (
					<StyledBox
						css={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							flex: 1,
						}}
					>
						<StyledH6 css={{ color: theme.gray300 }}>Sin Datos</StyledH6>
					</StyledBox>
				)}
			</StyledWaitingListContainer>
		</Card>
	);
}

export default WaitingList;
