import styled, { keyframes } from 'styled-components';
import { StyledRow } from '../../../../common/styledCommonComponents';
import Text from '../../../../common/components/Text';
import CloseIcon from '../../../../assets/CloseIcon';

const stretch = keyframes`
  from {
    transform: scaleY(0);
  }
  to {
    transform: scaleY(1);
  }
`;

export const StyledAnimatedRow = styled(StyledRow)`
	animation: ${stretch} 0.15s ease-out both;
	transform-origin: top;
`;

export const StyledAnimatedText = styled(Text)`
	animation: ${stretch} 0.15s ease-out both;
	transform-origin: top;
`;

export const StyledAnimatedIcon = styled(CloseIcon)`
	animation: ${stretch} 0.15s ease-out both;
	transform-origin: top;
`;
