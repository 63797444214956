import React from 'react';
import { StyledBox } from '../../common/styledCommonComponents';
import KebabIcon from '../../assets/KebabIcon';
import { IconButton, MenuItem } from '@mui/material';
import { CSSObject } from 'styled-components';
import { StyledMenu } from './styles';
import StyledText from '../../common/components/Text';

export type KebabOption = {
	label: any;
	onClick: (arg?: any) => void;
	css?: CSSObject;
};

interface KebabProps {
	options: KebabOption[];
}

const Kebab = ({ options }: KebabProps) => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		event.stopPropagation();
		setAnchorEl(event.currentTarget);
	};
	const handleClose = (event: React.MouseEvent<HTMLElement>) => {
		event.stopPropagation();
		setAnchorEl(null);
	};
	return (
		<StyledBox>
			<IconButton
				aria-label="more"
				id="long-button"
				aria-controls={open ? 'long-menu' : undefined}
				aria-expanded={open ? 'true' : undefined}
				aria-haspopup="true"
				onClick={handleClick}
			>
				<KebabIcon width={18} height={18} />
			</IconButton>
			<StyledMenu
				id="long-menu"
				MenuListProps={{
					'aria-labelledby': 'long-button',
				}}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				className={'kebab-menu'}
				PaperProps={{
					style: options[0].css,
				}}
			>
				{options.map(({ label, onClick, css }) => (
					<MenuItem
						key={label}
						onClick={(e) => {
							onClick(e);
							handleClose(e);
						}}
						sx={{ ...css, padding: '8px 16px !important' }}
					>
						<StyledText css={{ textAlign: 'center' }} variant={'body3'}>
							{label}
						</StyledText>
					</MenuItem>
				))}
			</StyledMenu>
		</StyledBox>
	);
};

export default Kebab;
