import styled from 'styled-components';
import Chart from 'react-apexcharts';

export const StyledSummaryChart = styled(Chart)`
	margin-top: -30px;
	.column-class {
		border-radius: 50px;
	}
	.apexcharts-series {
		border-radius: 50px;
		&[seriesName='Q2'] {
			//pointer-events: none;
		}
		&[seriesName='Q2x2'] {
			//pointer-events: none;
		}
		&[seriesName='Q2x3'] {
			//pointer-events: none;
		}
	}
	[seriesName='Q2x3'] {
		path {
			height: 300px;
		}
	}
	.apexcharts-legend {
		margin-top: 20px !important;
	}
	.apexcharts-legend-series {
		&[seriesName='Q2'] {
			display: none !important;
		}
		&[seriesName='Q2x2'] {
			display: none !important;
		}
		&[seriesName='Q2x3'] {
			display: none !important;
		}
	}
`;
