import React from 'react';
import { IconProps } from './IconInterface';

const AlertIcon = ({
	color = '#000000',
	width = 24,
	height = 24,
}: IconProps) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M11.0826 2.62305L18.2776 15.0847C18.3874 15.2747 18.4451 15.4903 18.4451 15.7097C18.4451 15.9291 18.3874 16.1447 18.2777 16.3347C18.1679 16.5247 18.0102 16.6825 17.8201 16.7922C17.6301 16.9019 17.4146 16.9597 17.1951 16.9597H2.80514C2.58573 16.9597 2.37017 16.9019 2.18016 16.7922C1.99014 16.6825 1.83234 16.5247 1.72264 16.3347C1.61293 16.1447 1.55517 15.9291 1.55518 15.7097C1.55518 15.4903 1.61293 15.2747 1.72264 15.0847L8.91764 2.62305C9.39848 1.78971 10.601 1.78971 11.0826 2.62305ZM10.0001 12.4997C9.77913 12.4997 9.56717 12.5875 9.41089 12.7438C9.25461 12.9001 9.16681 13.112 9.16681 13.333C9.16681 13.5541 9.25461 13.766 9.41089 13.9223C9.56717 14.0786 9.77913 14.1664 10.0001 14.1664C10.2212 14.1664 10.4331 14.0786 10.5894 13.9223C10.7457 13.766 10.8335 13.5541 10.8335 13.333C10.8335 13.112 10.7457 12.9001 10.5894 12.7438C10.4331 12.5875 10.2212 12.4997 10.0001 12.4997ZM10.0001 6.66638C9.79603 6.66641 9.59903 6.74134 9.4465 6.87698C9.29397 7.01261 9.19653 7.1995 9.17264 7.40221L9.16681 7.49971V10.833C9.16705 11.0454 9.24838 11.2497 9.39418 11.4042C9.53999 11.5586 9.73927 11.6516 9.95131 11.664C10.1633 11.6765 10.3721 11.6075 10.535 11.4712C10.6979 11.3348 10.8026 11.1415 10.8276 10.9305L10.8335 10.833V7.49971C10.8335 7.2787 10.7457 7.06674 10.5894 6.91046C10.4331 6.75418 10.2212 6.66638 10.0001 6.66638Z"
				fill={color}
			/>
		</svg>
	);
};

export default AlertIcon;
