import React from 'react';
import { IconProps } from './IconInterface';

const Person2FillIcon = ({
	width = 24,
	height = 24,
	color = '#0E0E0E',
}: IconProps) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M15.8254 11.8047C17.4256 11.8047 18.7775 10.3748 18.7775 8.52479C18.7775 6.71901 17.4183 5.33334 15.8254 5.33334C14.2397 5.33334 12.8732 6.74112 12.8805 8.53953C12.8805 10.3748 14.2251 11.8047 15.8254 11.8047ZM7.37815 11.9742C8.77384 11.9742 9.95031 10.7212 9.95031 9.11444C9.95031 7.53714 8.75923 6.33574 7.37815 6.33574C5.99708 6.33574 4.80599 7.55925 4.8133 9.12918C4.8133 10.7212 5.98977 11.9742 7.37815 11.9742ZM3.35185 18.6667H8.8323C8.05042 17.539 8.89076 15.3131 10.5422 14.0011C9.7384 13.4778 8.69346 13.0945 7.37815 13.0945C4.1118 13.0945 2 15.5195 2 17.5169C2 18.2466 2.36536 18.6667 3.35185 18.6667ZM11.2729 18.6593H20.3778C21.5835 18.6593 22 18.2908 22 17.6127C22 15.7185 19.6105 13.1093 15.8254 13.1093C12.0329 13.1093 9.65071 15.7185 9.65071 17.6127C9.65071 18.2908 10.0672 18.6593 11.2729 18.6593Z"
				fill={color}
			/>
		</svg>
	);
};

export default Person2FillIcon;
