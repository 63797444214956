import React, { useState } from 'react';
import {
	StyledBox,
	StyledRow,
} from '../../../../common/styledCommonComponents';
import { StyledCircularProgress } from '../../../../components/CustomCircularProgress/styles';
import { StyledH6 } from '../../../../common/components/Text/styles';
import { useTheme } from 'styled-components';
import { ErrorMessage } from '../ErrorMessage';
import { mockedData } from './utils';
import { ProctcaeCategoryCard } from './ProctcaeCategoryCard';
import { ExtraCategoryCard } from './ExtraCategoryCard';
import { PROCTCAESummaryType } from '../../../../redux/api/types';
import { useGetPatientPROCTCAESummaryQuery } from '../../../../redux/api/patientApi';
import { useParams } from 'react-router-dom';

export const ProctcaeSummary = () => {
	const theme = useTheme();
	const { patientId } = useParams();

	const {
		data: dataPROCTCAE,
		isLoading: loadingPROCTCAE,
		refetch: refetchPROCTCAE,
		isError: errorPROCTCAE,
	} = useGetPatientPROCTCAESummaryQuery(patientId);

	const renderedData =
		dataPROCTCAE?.categories.length > 4
			? dataPROCTCAE?.categories.slice(0, 4)
			: dataPROCTCAE?.categories;

	const extraData =
		dataPROCTCAE?.categories.length > 4
			? dataPROCTCAE?.categories
					.slice(4, dataPROCTCAE?.categories.length)
					.reduce(
						(acc: any, current: any) => {
							return {
								answers: acc.answers + current.answers,
								categoriesAmount: acc.categoriesAmount + 1,
							};
						},
						{ answers: 0, categoriesAmount: 0 },
					)
			: undefined;

	return (
		<StyledBox
			css={{
				maxWidth: '878px',
				minHeight: '114px',
				height: '114px',
				display: 'flex',
				width: '820px',
				// padding: '24px',
				justifyContent: 'space-between',
				alignItems: 'center',
				borderRadius: '20px',
				background:
					loadingPROCTCAE ||
					errorPROCTCAE ||
					dataPROCTCAE?.categories.length === 0
						? '#FFF'
						: 'none',
				boxShadow:
					loadingPROCTCAE ||
					errorPROCTCAE ||
					dataPROCTCAE?.categories.length === 0
						? '0px 1px 25px 0px rgba(175, 126, 255, 0.10)'
						: 'none',
			}}
		>
			<StyledBox
				css={{
					width: '100%',
					height: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				{loadingPROCTCAE ? (
					<StyledCircularProgress size={50} />
				) : errorPROCTCAE ? (
					<ErrorMessage refetch={() => refetchPROCTCAE()} />
				) : dataPROCTCAE?.categories.length !== 0 ? (
					<StyledRow
						css={{
							display: 'flex',
							width: '878px',
							height: '114px',
							alignItems: 'flex-end',
							gap: '20px',
						}}
					>
						{renderedData.map((card: PROCTCAESummaryType) => (
							<ProctcaeCategoryCard
								key={card.category}
								id={card.categoryId}
								categoryName={card.category}
								answers={card.answers}
								formId={dataPROCTCAE.formAnswerId}
							/>
						))}
						{extraData && (
							<ExtraCategoryCard
								extraData={extraData}
								formId={dataPROCTCAE.formAnswerId}
							/>
						)}
					</StyledRow>
				) : (
					<StyledBox
						css={{
							height: '100%',
							width: '100%',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<StyledH6 css={{ color: theme.gray300 }}>Sin datos</StyledH6>
					</StyledBox>
				)}
			</StyledBox>
		</StyledBox>
	);
};
