import React from 'react';

const LogoutIcon = () => (
	<svg
		width="19"
		height="17"
		viewBox="0 0 19 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M2.9231 16.424H10.9306C12.5396 16.424 13.3742 15.5819 13.3742 13.9578V9.1458H7.90806C7.4419 9.1458 7.05092 8.75482 7.05092 8.28114C7.05092 7.80746 7.4419 7.40896 7.90806 7.40896H13.3742V2.61197C13.3742 0.995426 12.5396 0.15332 10.9306 0.15332H2.9231C1.31408 0.15332 0.479492 0.995426 0.479492 2.61197V13.9578C0.479492 15.5819 1.31408 16.424 2.9231 16.424ZM12.6073 8.28114C12.6073 8.6646 12.9156 8.97287 13.284 8.97287H15.5321L16.6524 8.92024L16.096 9.3864L14.9757 10.439C14.8404 10.5593 14.7727 10.7323 14.7727 10.9052C14.7727 11.2435 15.0208 11.5293 15.3667 11.5293C15.5547 11.5293 15.6825 11.4541 15.8103 11.3263L18.2464 8.80746C18.4193 8.62701 18.4795 8.46159 18.4795 8.28114C18.4795 8.10069 18.4193 7.93528 18.2464 7.75482L15.8103 5.22851C15.6825 5.10069 15.5547 5.03302 15.3667 5.03302C15.0208 5.03302 14.7727 5.3037 14.7727 5.64956C14.7727 5.81497 14.8404 5.99543 14.9757 6.11573L16.096 7.17588L16.6524 7.64204L15.5321 7.58189H13.284C12.9156 7.58189 12.6073 7.89768 12.6073 8.28114Z"
			fill="#555555"
		/>
	</svg>
);

export default LogoutIcon;
