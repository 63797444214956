import { StyledRow } from '../../../common/styledCommonComponents';
import ColorIcon from '../../../assets/ColorIcon';
import StyledText from '../../../common/components/Text';
import Person2FillIcon from '../../../assets/Person2FillIcon';
import React from 'react';
import { useTheme } from 'styled-components';
import { StyledMedicationItemContainer } from './styles';

interface FrequentMedicationProps {
	onClick: () => void;
	name: string;
	color: string;
	patients: number;
}

const MedicationItem = ({
	onClick,
	name,
	color,
	patients,
}: FrequentMedicationProps) => {
	const theme = useTheme();
	return (
		<StyledMedicationItemContainer onClick={onClick}>
			<StyledRow
				css={{
					gap: 8,
					alignItems: 'center',
				}}
			>
				<ColorIcon color={color} />
				<StyledText variant="body2">{name}</StyledText>
			</StyledRow>
			<StyledRow css={{ alignItems: 'center', gap: 2 }}>
				<Person2FillIcon color={theme.gray500} height={24} width={24} />

				<StyledText variant="body2">{patients}</StyledText>
			</StyledRow>
		</StyledMedicationItemContainer>
	);
};

export default MedicationItem;
