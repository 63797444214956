import { theme } from '../common/theme';

const transformMoodPain = (type: string | number) => {
	switch (type) {
		case 0.2:
		case 0.5:
		case 0.9:
			return 'No respondió';
		default:
			return type;
	}
};
const transformPhysical = (type: string | number) => {
	switch (type) {
		case 'No hice actividad fisica':
		case 'No hice actividad física':
			return 1;
		case 'Menos de 30min.':
			return 2;
		case 'Entre 30min y 1h':
			return 3;
		case 'Mas de 1h':
		case 'Más 1h':
			return 4;
		case 1:
			return 'No hice actividad fisica';
		case 2:
			return 'Menos de 30min';
		case 3:
			return 'Entre 30min y 1h';
		case 4:
			return 'Mas de 1h';
		case -1:
		case 0.4:
			return 'No respondió';
		default:
			return 0.4;
	}
};
const transformSocial = (type: string | number) => {
	switch (type) {
		case 'No vi a nadie':
			return 1;
		case 'Limitado a pocas interacciones':
			return 2;
		case 'Vi a amigos o conocidos por mas de 1h':
		case 'Vi a amigos o conocidos por más de 1h':
			return 3;
		case 'Vi a amigos o conocidos por mas de 2h':
		case 'Vi a amigos o conocidos por más de 2h':
			return 4;
		case 1:
			return 'No vi a nadie';
		case 2:
			return 'Limitado a pocas interacciones';
		case 3:
			return 'Vi a amigos o conocidos por mas de 1h';
		case 4:
			return 'Vi a amigos o conocidos por mas de 2h';
		case -1:
		case 0.4:
			return 'No respondió';
		default:
			return 0.4;
	}
};

export const SocialAndPhysicalConfig = (data: any, active: string) => {
	const colors = active === 'physical' ? ['#C6D6FE'] : ['#DCC7FF'];
	const gradient = active === 'physical' ? ['#8CACFE'] : ['#AF7EFF'];
	const categoryDates = data[active]?.categories;

	return {
		isEmpty: !Object.values(data).find((a: any) => a[1]),
		series: [
			{
				name: 'social',
				// @ts-ignore
				data:
					data?.social?.data?.map((a: string) => transformSocial(a)) ??
					([] as number[]),
			},
			{
				name: 'physical',
				// @ts-ignore
				data:
					data?.physical?.data?.map((a: string) => transformPhysical(a)) ??
					([] as number[]),
			},
		],
		options: {
			chart: {
				id: 'mychart',
				type: 'bar',
				width: 300,
				animations: {
					enabled: true,
					easing: 'easeinout',
					speed: 250,
					animateGradually: {
						enabled: true,
						delay: 100,
					},
					dynamicAnimation: {
						enabled: true,
						speed: 100,
					},
				},
				toolbar: {
					show: false,
				},
			},
			grid: {
				show: true,
				xaxis: {
					lines: {
						show: false,
					},
				},
				yaxis: {
					lines: {
						show: true,
					},
				},
			},
			plotOptions: {
				bar: {
					horizontal: false,
					columnWidth: '70%',
					endingShape: 'rounded',
					borderRadius: 4,
				},
			},
			dataLabels: {
				enabled: false,
			},
			states: {
				normal: {
					filter: {
						type: 'none',
					},
				},
				hover: {
					filter: {
						type: 'none',
					},
				},
				active: {
					filter: {
						type: 'none',
					},
				},
			},
			tooltip: {
				fillSeriesColor: false,
				followCursor: false,
				custom: function ({ series, seriesIndex, dataPointIndex, w }: any) {
					const textToAdd =
						active === 'physical'
							? transformPhysical(series[0][dataPointIndex])
							: transformSocial(series[0][dataPointIndex]);
					return (
						'<div style="padding: 5px 20px; ' +
						'border-radius: 5px; ' +
						'background-color:#393939 !important;' +
						'color: white;' +
						'border-style: none;' +
						'border-width: 0px;' +
						'">' +
						'<span>' +
						textToAdd +
						'</span>' +
						'</div>'
					);
				},
				fixed: {
					position: 'top',
				},
				onDatasetHover: {
					highlightDataSeries: false,
				},
				marker: {
					show: false,
				},
			},
			stroke: {
				show: false,
				width: 2,
				colors: ['transparent'],
			},
			xaxis: {
				categories: categoryDates ?? [],
				axisBorder: {
					show: false,
				},
				axisTicks: {
					show: false,
				},
				labels: {
					style: {
						colors: theme.gray400,
						fontFamily: 'Inter',
						fontSize: '12px',
						fontStyle: 'normal',
						fontHeight: '400',
						lineHeight: 'normal;',
					},
				},
			},
			yaxis: {
				min: 0,
				max: 4,
				range: 4,
				tickAmount: 4,
				labels: {
					show: true,
					formatter: function (val: any) {
						return val.toFixed(0);
					},
				},
			},
			fill: {
				colors: colors,
				type: 'gradient',
				gradient: {
					shade: 'light',
					shadeIntensity: 0,
					opacityFrom: 1,
					opacityTo: 0.9,
					type: 'vertical',
					stops: [0, 100],
					gradientToColors: gradient,
				},
			},
			legend: {
				show: false,
				position: 'top',
				horizontalAlign: 'left',
				inverseOrder: false,
				markers: {
					radius: 20,
				},
			},
		},
	};
};

export const MoodAndPainConfig = (data: any, active: string) => {
	const colors = active === 'mood' ? ['#8DADFF'] : ['#AF7EFF'];
	const gradient = active === 'mood' ? ['#FFFFFF'] : ['#FFFFFF'];
	const stroke = active === 'mood' ? ['#6989F7'] : ['#AF7EFF'];
	const categoryDates = data[active][0]?.categories;

	return {
		isEmpty: !Object.values(data).find((a: any) => a[1]),
		series: [
			{
				name: 'mood',
				// @ts-ignore
				data: Object.values(data).map((a) => transformSocial(a[0]) + 1),
			},
			{
				name: 'pain',
				// @ts-ignore
				data: Object.values(data).map((a) => transformPhysical(a[1]) + 1),
			},
		],
		options: {
			chart: {
				id: 'mychart',
				type: 'area',
				width: 300,
				animations: {
					enabled: true,
					easing: 'easeinout',
					speed: 250,
					animateGradually: {
						enabled: true,
						delay: 100,
					},
					dynamicAnimation: {
						enabled: true,
						speed: 100,
					},
				},
				toolbar: {
					show: false,
				},
			},
			grid: {
				show: true,
				xaxis: {
					lines: {
						show: false,
					},
				},
				yaxis: {
					lines: {
						show: true,
					},
				},
			},
			plotOptions: {
				bar: {
					horizontal: false,
					columnWidth: '25px',
					endingShape: 'rounded',
					borderRadius: 4,
				},
			},
			dataLabels: {
				enabled: false,
			},
			states: {
				normal: {
					filter: {
						type: 'none',
					},
				},
				hover: {
					filter: {
						type: 'none',
					},
				},
				active: {
					filter: {
						type: 'none',
					},
				},
			},
			tooltip: {
				fillSeriesColor: false,
				followCursor: false,
				custom: function ({ series, seriesIndex, dataPointIndex, w }: any) {
					return (
						'<div style="padding: 5px 20px; ' +
						'border-radius: 5px; ' +
						'background-color:#393939 !important;' +
						'color: white;' +
						'border-style: none;' +
						'border-width: 0px;' +
						'">' +
						'<span>' +
						transformMoodPain(series[0][dataPointIndex]) +
						'</span>' +
						'</div>'
					);
				},
				fixed: {
					position: 'top',
				},
				onDatasetHover: {
					highlightDataSeries: false,
				},
				marker: {
					show: false,
				},
			},
			stroke: {
				colors: stroke,
				curve: 'straight',
				width: 2,
			},
			markers: {
				colors: stroke,
				size: 3,
				strokeWidth: 0,
				hover: {
					size: 5,
				},
			},
			xaxis: {
				categories: categoryDates,
				axisBorder: {
					show: false,
				},
				axisTicks: {
					show: false,
				},
				labels: {
					style: {
						colors: theme.gray400,
						fontFamily: 'Inter',
						fontSize: '12px',
						fontStyle: 'normal',
						fontHeight: '400',
						lineHeight: 'normal;',
					},
				},
			},
			yaxis: {
				min: 0,
				max: 10,
				range: 10,
				tickAmount: 5,
				labels: {
					show: true,
					formatter: function (val: any) {
						return val.toFixed(0);
					},
				},
			},
			fill: {
				colors: colors,
				type: 'gradient',
				gradient: {
					shade: 'light',
					shadeIntensity: 0,
					opacityFrom: 1,
					opacityTo: 0.7,
					type: 'vertical',
					stops: [0, 100],
					gradientToColors: gradient,
				},
			},
			legend: {
				show: false,
				position: 'top',
				horizontalAlign: 'left',
				inverseOrder: false,
				markers: {
					radius: 20,
				},
			},
		},
	};
};
export const BiometricConfig = (data: any, active: string) => {
	const colors = () => {
		if (active === 'weight') {
			return ['#AD7EF9'];
		} else if (active === 'pulse') {
			return ['#80CA8F'];
		} else {
			return ['#6989F7', '#D18EEB'];
		}
	};
	const gradient = () => {
		if (active === 'weight') {
			return ['#E0CBFD'];
		} else if (active === 'pulse') {
			return ['#8BD299'];
		} else {
			return ['#BFCFFF', '#DDA7F5'];
		}
	};
	const categoryDates = data[active]?.[0]?.categories;
	const max = () => {
		if (active === 'SYS_DIA') {
			const allData = data[active]?.flatMap((item: any) =>
				item.data.map(Number),
			);
			const maxNumber = Math.max(...allData);
			return maxNumber + 10;
		} else {
			return Math.max(...data[active][0].data) + 10;
		}
	};

	let minNumber: number;

	if (active === 'SYS_DIA') {
		const allData = data[active].flatMap((item: any) =>
			item.data.filter((value: any) => value !== null).map(Number),
		);
		minNumber = Math.min(...allData) * 0.2;
	} else {
		minNumber =
			Math.min(
				...data[active][0].data.filter((value: any) => value !== null),
			) * 0.1;
	}

	for (let item of data[active]) {
		item.data = item.data.map((value: any) =>
			value === null ? minNumber : Number(value),
		);
	}

	return {
		isEmpty: !Object.values(data).find((a: any) => a[1]),
		series: [],
		options: {
			chart: {
				id: 'mychart',
				type: 'bar',
				width: 300,
				animations: {
					enabled: true,
					easing: 'easeinout',
					speed: 250,
					animateGradually: {
						enabled: true,
						delay: 100,
					},
					dynamicAnimation: {
						enabled: true,
						speed: 100,
					},
				},
				toolbar: {
					show: false,
				},
			},
			grid: {
				show: true,
				xaxis: {
					lines: {
						show: false,
					},
				},
				yaxis: {
					lines: {
						show: true,
					},
				},
			},
			plotOptions: {
				bar: {
					horizontal: false,
					columnWidth: '70%',
					endingShape: 'rounded',
					borderRadius: 4,
				},
			},
			dataLabels: {
				enabled: false,
			},
			states: {
				normal: {
					filter: {
						type: 'none',
					},
				},
				hover: {
					filter: {
						type: 'none',
					},
				},
				active: {
					filter: {
						type: 'none',
					},
				},
			},
			tooltip: {
				fillSeriesColor: false,
				followCursor: false,
				custom: function ({ series, seriesIndex, dataPointIndex, w }: any) {
					const textToAdd = () => {
						if (series[0][dataPointIndex] <= minNumber)
							return 'No respondió'; // Check this option
						if (active === 'weight')
							return `${series[0][dataPointIndex]} kg`;
						if (active === 'pulse')
							return `${series[0][dataPointIndex]} latidos por minuto`;
						if (active === 'SYS_DIA')
							return `${series[seriesIndex][dataPointIndex]} mm Hg`;
						return `${series[seriesIndex][dataPointIndex]}°C`;
					};
					return (
						'<div style="padding: 5px 20px; ' +
						'border-radius: 5px; ' +
						'background-color:#393939 !important;' +
						'color: white;' +
						'border-style: none;' +
						'border-width: 0px;' +
						'">' +
						'<span>' +
						textToAdd() +
						'</span>' +
						'</div>'
					);
				},
				fixed: {
					position: 'top',
				},
				onDatasetHover: {
					highlightDataSeries: false,
				},
				marker: {
					show: false,
				},
			},
			stroke: {
				show: active === 'SYS_DIA',
				width: 1,
				colors: ['#FFFFFF'],
			},
			xaxis: {
				categories: categoryDates ?? [],
				axisBorder: {
					show: false,
				},
				axisTicks: {
					show: false,
				},
				labels: {
					style: {
						colors: theme.gray400,
						fontFamily: 'Inter',
						fontSize: '12px',
						fontStyle: 'normal',
						fontHeight: '400',
						lineHeight: 'normal;',
					},
				},
			},
			yaxis: {
				min: 0,
				max: max,
				range: max,
				tickAmount: 5,
				labels: {
					show: true,
					formatter: function (val: any) {
						return val.toFixed(0);
					},
				},
			},
			fill: {
				colors: colors(),
				type: 'gradient',
				gradient: {
					shade: 'light',
					shadeIntensity: 0,
					opacityFrom: 1,
					opacityTo: 0.9,
					type: 'vertical',
					stops: [0, 100],
					gradientToColors: gradient(),
				},
			},
			legend: {
				show: active === 'SYS_DIA',
				position: 'top',
				horizontalAlign: 'left',
				inverseOrder: false,
				markers: {
					radius: 20,
					fillColors: gradient(),
				},
				labels: {
					color: 'black',
				},
				textAlign: 'center',
				fontFamily: 'Inter',
				fontSize: '12px',
				fontStyle: 'normal',
				fontWeight: '400',
				fontHeight: 'normal',
			},
		},
	};
};

export const MetricConfig = (data: any, active: string) => {
	const colors = () => {
		if (active === 'weight') {
			return ['#AD7EF9'];
		} else if (active === 'pulse') {
			return ['#80CA8F'];
		} else {
			return ['#6989F7', '#D18EEB'];
		}
	};
	const gradient = () => {
		if (active === 'weight') {
			return ['#E0CBFD'];
		} else if (active === 'pulse') {
			return ['#8BD299'];
		} else {
			return ['#BFCFFF', '#DDA7F5'];
		}
	};
	const categoryDates = data[active]?.[0]?.categories;
	const max = () => {
		if (active === 'SYS_DIA') {
			const allData = data[active]?.flatMap((item: any) =>
				item.data.map(Number),
			);
			const maxNumber = Math.max(...allData);
			return maxNumber + 10;
		} else {
			return Math.max(...data[active][0].data) + 10;
		}
	};

	let minNumber: number;

	if (active === 'SYS_DIA') {
		const allData = data[active].flatMap((item: any) =>
			item.data.filter((value: any) => value !== null).map(Number),
		);
		minNumber = Math.min(...allData) * 0.2;
	} else {
		minNumber =
			Math.min(
				...data[active][0].data.filter((value: any) => value !== null),
			) * 0.1;
	}

	for (let item of data[active]) {
		item.data = item.data.map((value: any) =>
			value === null ? minNumber : Number(value),
		);
	}

	return {
		isEmpty: !Object.values(data).find((a: any) => a[1]),
		series: [],
		options: {
			chart: {
				id: 'mychart',
				type: 'bar',
				width: 300,
				animations: {
					enabled: true,
					easing: 'easeinout',
					speed: 250,
					animateGradually: {
						enabled: true,
						delay: 100,
					},
					dynamicAnimation: {
						enabled: true,
						speed: 100,
					},
				},
				toolbar: {
					show: false,
				},
			},
			grid: {
				show: true,
				xaxis: {
					lines: {
						show: false,
					},
				},
				yaxis: {
					lines: {
						show: true,
					},
				},
			},
			plotOptions: {
				bar: {
					horizontal: false,
					columnWidth: '70%',
					endingShape: 'rounded',
					borderRadius: 4,
				},
			},
			dataLabels: {
				enabled: false,
			},
			states: {
				normal: {
					filter: {
						type: 'none',
					},
				},
				hover: {
					filter: {
						type: 'none',
					},
				},
				active: {
					filter: {
						type: 'none',
					},
				},
			},
			tooltip: {
				fillSeriesColor: false,
				followCursor: false,
				custom: function ({ series, seriesIndex, dataPointIndex, w }: any) {
					const textToAdd = () => {
						if (series[0][dataPointIndex] <= minNumber)
							return 'No respondió'; // Check this option
						if (active === 'weight')
							return `${series[0][dataPointIndex]} kg`;
						if (active === 'pulse')
							return `${series[0][dataPointIndex]} latidos por minuto`;
						if (active === 'SYS_DIA')
							return `${series[seriesIndex][dataPointIndex]} mm Hg`;
						return `${series[seriesIndex][dataPointIndex]}°C`;
					};
					return (
						'<div style="padding: 5px 20px; ' +
						'border-radius: 5px; ' +
						'background-color:#393939 !important;' +
						'color: white;' +
						'border-style: none;' +
						'border-width: 0px;' +
						'">' +
						'<span>' +
						textToAdd() +
						'</span>' +
						'</div>'
					);
				},
				fixed: {
					position: 'top',
				},
				onDatasetHover: {
					highlightDataSeries: false,
				},
				marker: {
					show: false,
				},
			},
			stroke: {
				show: active === 'SYS_DIA',
				width: 1,
				colors: ['#FFFFFF'],
			},
			xaxis: {
				categories: categoryDates ?? [],
				axisBorder: {
					show: false,
				},
				axisTicks: {
					show: false,
				},
				labels: {
					style: {
						colors: theme.gray400,
						fontFamily: 'Inter',
						fontSize: '12px',
						fontStyle: 'normal',
						fontHeight: '400',
						lineHeight: 'normal;',
					},
				},
			},
			yaxis: {
				min: 0,
				max: max,
				range: max,
				tickAmount: 5,
				labels: {
					show: true,
					formatter: function (val: any) {
						return val.toFixed(0);
					},
				},
			},
			fill: {
				colors: colors(),
				type: 'gradient',
				gradient: {
					shade: 'light',
					shadeIntensity: 0,
					opacityFrom: 1,
					opacityTo: 0.9,
					type: 'vertical',
					stops: [0, 100],
					gradientToColors: gradient(),
				},
			},
			legend: {
				show: active === 'SYS_DIA',
				position: 'top',
				horizontalAlign: 'left',
				inverseOrder: false,
				markers: {
					radius: 20,
					fillColors: gradient(),
				},
				labels: {
					color: 'black',
				},
				textAlign: 'center',
				fontFamily: 'Inter',
				fontSize: '12px',
				fontStyle: 'normal',
				fontWeight: '400',
				fontHeight: 'normal',
			},
		},
	};
};

const transformThirst = (type: string | number) => {
	switch (type) {
		case 'Menos de 1lt':
			return 1;
		case 'Entre 1lt y 2lts':
			return 2;
		case 'Mas de 2lts':
		case 'Más de 2 lts':
			return 3;
		case 1:
			return 'Menos de 1 lt';
		case 2:
			return 'Entre 1lt y 2lts';
		case 3:
			return 'Mas de 2lts';
		case -1:
		case 0.4:
			return 'No respondió';
		default:
			return 0.4;
	}
};
const transformHungry = (type: string | number) => {
	switch (type) {
		case 1:
			return 'Menos de lo normal';
		case 2:
			return 'Normal';
		case 3:
			return 'Mas de lo normal';
		case 'Menos de lo normal':
			return 1;
		case 'Normal':
			return 2;
		case 'Mas de lo normal':
			return 3;
		case -1:
		case 0.4:
			return 'No respondió';
		default:
			return 0.4;
	}
};

export const HungerAndThirstConfig = (data: any) => {
	const categoryDates = data[0].categories;
	return {
		isEmpty: !Object.values(data).find((a: any) => a[1]),
		series: [
			{
				name: 'Hidratación',
				// @ts-ignore
				data: Object.values(data[0].data).map((a: string) =>
					transformThirst(a),
				) as number[],
			},
			{
				name: 'Apetito',
				// @ts-ignore
				data: Object.values(data[1].data).map((a: string) =>
					transformHungry(a),
				) as number[],
			},
		],
		options: {
			chart: {
				type: 'bar',
				width: '500px',
				height: '152px',
				toolbar: {
					show: false,
				},
			},
			grid: {
				show: true,
				xaxis: {
					lines: {
						show: false,
					},
				},
				yaxis: {
					lines: {
						show: true,
					},
				},
			},
			plotOptions: {
				bar: {
					horizontal: false,
					columnWidth: '70%',
					endingShape: 'rounded',
					borderRadius: 4,
				},
			},
			dataLabels: {
				enabled: false,
			},
			states: {
				normal: {
					filter: {
						type: 'none',
					},
				},
				hover: {
					filter: {
						type: 'none',
					},
				},
				active: {
					filter: {
						type: 'none',
					},
				},
			},
			colors: ['#8CACFE', '#AF7EFF'],
			stroke: {
				show: true,
				width: 2,
				colors: ['#FFFFFF'],
			},
			xaxis: {
				categories: categoryDates,
				axisBorder: {
					show: false,
				},
				axisTicks: {
					show: false,
				},
				labels: {
					style: {
						colors: theme.gray400,
						fontFamily: 'Inter',
						fontSize: '12px',
						fontStyle: 'normal',
						fontHeight: '400',
						lineHeight: 'normal;',
					},
				},
			},
			yaxis: {
				min: 0,
				max: 3,
				tickAmount: 3,
				labels: {
					show: true,
					formatter: function (val: any) {
						return val.toFixed(0);
					},
				},
			},
			tooltip: {
				fillSeriesColor: false,
				followCursor: false,
				custom: function ({ series, seriesIndex, dataPointIndex, w }: any) {
					const textToAdd =
						seriesIndex === 0
							? transformThirst(series[seriesIndex][dataPointIndex])
							: transformHungry(series[seriesIndex][dataPointIndex]);
					return (
						'<div style="padding: 5px 20px; ' +
						'border-radius: 5px; ' +
						'background-color:#393939 !important;' +
						'color: white;' +
						'border-style: none;' +
						'border-width: 0px;' +
						'">' +
						'<span>' +
						textToAdd +
						'</span>' +
						'</div>'
					);
				},
				fixed: {
					position: 'top',
				},
				onDatasetHover: {
					highlightDataSeries: false,
				},
				marker: {
					show: false,
				},
			},
			fill: {
				colors: ['#C6D6FE', '#DCC7FF'],
				type: 'gradient',
				gradient: {
					shade: 'light',
					shadeIntensity: 0,
					opacityFrom: 1,
					opacityTo: 0.9,
					type: 'vertical',
					stops: [0, 100],
					gradientToColors: ['#8CACFE', '#AF7EFF'],
				},
			},
			legend: {
				show: true,
				position: 'top',
				horizontalAlign: 'left',
				inverseOrder: false,
				markers: {
					radius: 20,
				},
				labels: {
					color: 'black',
				},
				textAlign: 'center',
				fontFamily: 'Inter',
				fontSize: '12px',
				fontStyle: 'normal',
				fontWeight: '400',
				fontHeight: 'normal',
			},
		},
	};
};

export const DailyColumnChartConfig = (data: any, total: number) => {
	const dataAux = () => {
		let aux: any[] = [];
		let maxValue = 0;

		Object.values(data).forEach((item: any) => {
			if (item > maxValue) {
				maxValue = item;
			}
		});
		let math = maxValue / 10;

		Object.keys(data).forEach((dat) => {
			aux.push({
				x: dat,
				y: data[dat][0],
				goals: [
					{
						value: -math,
						strokeHeight: 9,
						strokeWidth: 0,
						strokeLineCap: 'round',
						strokeColor: '#949494',
						top: -10,
					},
				],
			});
		});
		return aux;
	};

	return {
		// @ts-ignore
		isEmpty: !Object.values(data).find((a) => a[1] || a[0] || a[2]),
		series: [
			{
				data: dataAux(),
			},
		],
		options: {
			chart: {
				type: 'bar',
				width: 555,
				toolbar: {
					show: false,
				},
			},
			grid: {
				show: false,
				xaxis: {
					lines: {
						show: false,
					},
				},
				yaxis: {
					lines: {
						show: false,
					},
				},
			},
			plotOptions: {
				bar: {
					horizontal: false,
					columnWidth: 75,
					endingShape: 'rounded',
					borderRadius: 5,
				},
			},
			dataLabels: {
				enabled: false,
			},
			states: {
				normal: {
					filter: {
						type: 'none',
					},
				},
				hover: {
					filter: {
						type: 'none',
					},
				},
				active: {
					filter: {
						type: 'none',
					},
				},
			},
			colors: ['#80A2F9', '#A67FE7'],
			stroke: {
				show: true,
				width: 2,
				colors: ['transparent'],
			},
			xaxis: {
				type: 'category',
				categories: Object.keys(data).map((a) => a.split('/')[0]),
				axisBorder: {
					show: false,
				},
				axisTicks: {
					show: false,
				},
				show: true,
				labels: {
					show: true,
					style: {
						colors: '#A3A3A3',
						fontFamily: 'Helvetica',
						fontWeight: 400,
						fontSize: '10px',
					},
				},
			},
			yaxis: {
				show: false,
				max: total,
			},
			tooltip: {
				fillSeriesColor: false,
				followCursor: false,
				custom: function ({ series, dataPointIndex }: any) {
					const textToAdd = series[0];
					return (
						'<div style="padding: 5px 20px; ' +
						'border-radius: 5px; ' +
						'background-color:#393939 !important;' +
						'color: white;' +
						'border-style: none;' +
						'border-width: 0px;' +
						'display:flex ' +
						'flex-direction:column ' +
						'">' +
						'<p>' +
						'Completados: ' +
						// @ts-ignore
						Object.values(data)[dataPointIndex][0] +
						'</p>' +
						'<p>' +
						'Incompletos: ' +
						// @ts-ignore
						Object.values(data)[dataPointIndex][1] +
						'</p>' +
						'<p>' +
						'Sin arrancar: ' +
						// @ts-ignore
						Object.values(data)[dataPointIndex][2] +
						'</p>' +
						'</div>'
					);
				},
				fixed: {
					position: 'top',
				},
				onDatasetHover: {
					highlightDataSeries: false,
				},
				marker: {
					show: false,
				},
			},
			fill: {
				type: 'gradient',
				gradient: {
					shade: 'light',
					type: 'vertical',
					opacityFrom: 1,
					opacityTo: 0.55,
				},
			},
			legend: {
				show: true,
				position: 'top',
				horizontalAlign: 'left',
				inverseOrder: false,
				markers: {
					radius: 20,
				},
			},
		},
	};
};
