import {
	CustomCssProps,
	StyledBox,
	StyledScreen,
} from '../../common/styledCommonComponents';
import styled, { css as styledComponentCss } from 'styled-components';

export const StyledGrid = styled(StyledBox)<CustomCssProps>`
	display: grid;
	grid-template-columns: 320px 320px 320px;
	column-gap: 32px;
	row-gap: 32px;
	justify-content: center;
	width: 100%;
	${(props) => styledComponentCss`${props.css}`}
`;

export const StyledRepositoryScreen = styled(StyledScreen)`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 32px;

	.MuiPaper-root {
		border-radius: 8px !important;
		border: 1px solid #e0e1e3;
		box-shadow: 0px 1px 10px 0px rgba(186, 144, 255, 0.2) !important;
	}

	.MuiMenuItem-root {
		border-top: 1px solid #e0e1e3 !important;
		justify-content: center !important;
	}

	.MuiMenuItem-root:first-child {
		border-top-style: none !important;
	}

	.MuiList-root {
		padding: 0px !important;
	}
`;
