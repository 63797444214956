import Modal from '../../Modal';
import {
	StyledBox,
	StyledColumn,
	StyledRow,
} from '../../../../../../common/styledCommonComponents';
import React, { useCallback, useEffect, useReducer, useState } from 'react';
import TextInputField from '../../../../../../common/components/TextInputField';
import Button from '../../../../../../common/components/Button';
import StyledText from '../../../../../../common/components/Text/styles';
import { CreateAuxiliarModalProps } from '../utils';
import { useAuth0 } from '@auth0/auth0-react';
import { useCreateAuxiliarMutation } from '../../../../../../redux/api/patientApi';
import { StyledCircularProgress } from '../../../../../CustomCircularProgress/styles';
import {
	toastError,
	toastSuccess,
} from '../../../../../../pages/DoctorsAcceptanceList/utils';
import {
	useOncoDispatch,
	useOncoSelector,
} from '../../../../../../hooks/redux-hook';
import { closeModal } from '../../../../../../redux/slices/utilsSlice';
import { useTheme } from 'styled-components';
import { validate, ValidationType } from '../../../../../../validations';

const CreateAuxiliarModal = ({ handleOnClose }: CreateAuxiliarModalProps) => {
	const theme = useTheme();

	const dispatch = useOncoDispatch();
	const [
		auxiliarMutation,
		{ isLoading: loadingMutation, isError, isSuccess },
	] = useCreateAuxiliarMutation();

	const { name } = useOncoSelector((state) => state.utilsSlice.payload);

	const [values, setValues] = useReducer(
		(prev: any, cur: any) => ({
			...prev,
			...cur,
		}),
		{
			email: '',
			firstName: name ?? '',
			lastName: '',
			phone: '',
		},
	);
	const fieldsValidations: Record<string, ValidationType[]> = {
		email: [ValidationType.IS_EMAIL, ValidationType.IS_REQUIRED],
		firstName: [ValidationType.IS_STRING, ValidationType.IS_REQUIRED],
		lastName: [ValidationType.IS_STRING, ValidationType.IS_REQUIRED],
		phone: [ValidationType.IS_REQUIRED, ValidationType.IS_NUMBER],
	};
	const [errors, setErrors] = useState<{ name: string; error: string[] }[]>(
		[],
	);

	const handleSend = useCallback(() => {
		let errors: { name: string; error: string[] }[] = [];
		Object.keys(fieldsValidations).forEach((item, index) => {
			const error = validate(values[item], fieldsValidations[item]);
			if (error) {
				errors = [...errors, { name: item, error }];
			}
		});

		if (!errors.find((error) => error.error.length > 0)) {
			auxiliarMutation({
				email: values.email,
				name: values.firstName,
				surname: values.lastName,
				phoneNumber: values.phone,
			});
		} else {
			setErrors(errors);
		}
	}, [values]);

	useEffect(() => {
		if (isError) {
			toastError(
				<>
					<StyledText variant="body1Bold">Algo salió mal </StyledText>{' '}
					<StyledText variant="body2">
						Intente de nuevo mas tarde
					</StyledText>
				</>,
			);
		}
	}, [isError]);

	useEffect(() => {
		if (isSuccess) {
			toastSuccess(
				<StyledText variant="body1">
					Auxiliar creado exitosamente
				</StyledText>,
			);
			dispatch(closeModal());
		}
	}, [isSuccess]);

	useEffect(() => {
		if (errors.length > 0) {
			setErrors([]);
		}
	}, [values]);

	return (
		<Modal
			width={'570px'}
			header={
				<StyledColumn
					css={{
						alignItems: 'flex-start',
						gap: '8px',
					}}
				>
					<StyledText variant="h5" css={{ color: theme.black }}>
						Crear auxiliar
					</StyledText>
					<StyledText variant="body2" css={{ color: theme.black }}>
						Completa este formulario para agregar un nuevo auxiliar.
					</StyledText>
				</StyledColumn>
			}
			body={
				<StyledBox
					css={{
						width: '100%',
						flexDirection: 'column',
						gap: 24,
					}}
				>
					<StyledColumn css={{ gap: 8, color: theme.black }}>
						<TextInputField
							value={values.firstName}
							label={'Nombre'}
							onChange={(name: string, value: string) =>
								setValues({ [name]: value })
							}
							placeholder={'Ingrese nombre...'}
							name={'firstName'}
							error={
								errors.find((error) => error.name === 'firstName')
									?.error?.[0] ?? false
							}
							disabled={loadingMutation}
							required
						/>
						<TextInputField
							value={values.lastName}
							label={'Apellido'}
							placeholder={'Ingrese apellido...'}
							onChange={(name: string, value: string) =>
								setValues({ [name]: value })
							}
							name={'lastName'}
							error={
								errors.find((error) => error.name === 'lastName')
									?.error?.[0] ?? false
							}
							disabled={loadingMutation}
							required
						/>
						<TextInputField
							value={values.phone}
							label={'Teléfono'}
							placeholder={'Ingrese número de telefono...'}
							onChange={(name: string, value: string) =>
								setValues({ [name]: value })
							}
							name={'phone'}
							error={
								errors.find((error) => error.name === 'phone')
									?.error?.[0] ?? false
							}
							disabled={loadingMutation}
							required
						/>
						<TextInputField
							value={values.email}
							label={'Correo electrónico de Google'}
							placeholder={'Ingrese correo electrónico de Google...'}
							onChange={(name: string, value: string) =>
								setValues({ [name]: value })
							}
							name={'email'}
							error={
								errors.find((error) => error.name === 'email')
									?.error?.[0] ?? false
							}
							disabled={loadingMutation}
							required
						/>
					</StyledColumn>

					<StyledRow
						css={{
							justifyContent: 'flex-end',

							gap: '16px',
							alignSelf: 'stretch',
							marginTop: '24px',
						}}
					>
						<Button
							css={{ width: 130 }}
							onClick={handleSend}
							disabled={loadingMutation}
						>
							{loadingMutation ? (
								<StyledCircularProgress size={30} />
							) : (
								`Crear`
							)}
						</Button>
					</StyledRow>
				</StyledBox>
			}
			onClose={handleOnClose}
		></Modal>
	);
};

export default CreateAuxiliarModal;
