import React from 'react';
import { StyledBox, StyledRow } from '../../../common/styledCommonComponents';
import Button from '../../../common/components/Button';
import PlusIcon from '../../../assets/PlusIcon';
import { useOncoDispatch } from '../../../hooks/redux-hook';
import { useTheme } from 'styled-components';
import { setModalOpen } from '../../../redux/slices/utilsSlice';
import { ModalTypeEnum } from '../../../utils/utils';
import Search from '../../../components/TableComponent/Search';

interface MyTeamHeaderProps {
	handleSearch: any;
}

export const MyTeamHeader = ({ handleSearch }: MyTeamHeaderProps) => {
	const dispatch = useOncoDispatch();
	const theme = useTheme();

	const handleClick = () => {
		dispatch(
			setModalOpen({
				type: ModalTypeEnum.FIND_AUXILIAR_MODAL,
			}),
		);
	};

	return (
		<StyledRow
			css={{
				justifyContent: 'space-between',
				alignItems: 'flex-end',
				width: '100%',
			}}
		>
			<Search onSearch={handleSearch} placeholder={'Escriba nombre...'} />
			<StyledBox
				css={{
					marginLeft: 'auto',
				}}
			>
				<Button
					css={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						width: '202px',
						height: '42px',
						padding: '10px 12px',
						background: theme.calendarPurple,
						fontSize: '14px',
					}}
					icon={PlusIcon}
					onClick={handleClick}
				>
					Agregar nuevo auxiliar
				</Button>
			</StyledBox>
		</StyledRow>
	);
};
