import React from 'react';
import { IconProps } from './IconInterface';

const MiscelaneousIcon = ({
	width = 24,
	height = 24,
	color = '#0E0E0E',
}: IconProps) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M18.9545 13.6589L15.7614 11.9967L18.8715 10.3631C19.1692 10.2165 19.4198 10.0041 19.5995 9.74619C19.8242 9.41974 19.92 9.03533 19.8716 8.65443C19.8231 8.27354 19.6333 7.91817 19.3325 7.64513C19.0317 7.3721 18.6371 7.19718 18.212 7.14834C17.7869 7.09951 17.3558 7.17958 16.9876 7.37575L13.9158 9.00364V5.71358C13.9158 5.25911 13.714 4.82325 13.3547 4.5019C12.9954 4.18054 12.5081 4 12 4C11.4919 4 11.0046 4.18054 10.6453 4.5019C10.286 4.82325 10.0842 5.25911 10.0842 5.71358V9.00364L6.98688 7.37575C6.76915 7.24444 6.52356 7.15457 6.26536 7.11172C6.00716 7.06887 5.74187 7.07395 5.48596 7.12666C5.23005 7.17936 4.98899 7.27855 4.77775 7.41808C4.5665 7.55761 4.38958 7.73449 4.25797 7.93775C4.12636 8.141 4.04287 8.36628 4.01269 8.59961C3.98251 8.83293 4.00629 9.06931 4.08253 9.29408C4.15878 9.51884 4.28587 9.72718 4.45592 9.90616C4.62597 10.0851 4.83535 10.231 5.07104 10.3345L8.26411 11.9967L5.10297 13.6303C4.80529 13.7768 4.55463 13.9892 4.37495 14.2472C4.15337 14.5736 4.06006 14.9569 4.10983 15.3362C4.15961 15.7155 4.34962 16.0691 4.6497 16.3408C4.94978 16.6124 5.34273 16.7867 5.76616 16.8358C6.18959 16.8849 6.61924 16.8061 6.98688 16.6119L10.0842 14.9897V18.2227C10.0691 18.5878 10.1849 18.9477 10.4146 19.2498C10.6444 19.5519 10.9761 19.7804 11.3614 19.902C11.6509 19.9936 11.9609 20.0215 12.265 19.9835C12.5692 19.9455 12.8587 19.8427 13.1091 19.6836C13.3596 19.5246 13.5636 19.3141 13.704 19.0697C13.8444 18.8254 13.917 18.5545 13.9158 18.2798V14.9897L17.0131 16.6176C17.2309 16.7489 17.4764 16.8388 17.7346 16.8817C17.9928 16.9245 18.2581 16.9194 18.514 16.8667C18.7699 16.814 19.011 16.7148 19.2223 16.5753C19.4335 16.4358 19.6104 16.2589 19.742 16.0556C19.8736 15.8524 19.9571 15.6271 19.9873 15.3938C20.0175 15.1605 19.9937 14.9241 19.9175 14.6993C19.8412 14.4745 19.7141 14.2662 19.5441 14.0872C19.374 13.9082 19.1647 13.7624 18.929 13.6589H18.9545Z"
				fill={color}
			/>
		</svg>
	);
};

export default MiscelaneousIcon;
