import styled from 'styled-components';
import { theme } from '../../theme';

export const StyledLabel = styled.label`
	display: flex;
	align-items: center;
	gap: 10px;
	cursor: pointer;
`;

export const StyledSwitch = styled.div`
	position: relative;
	width: 55.28px;
	height: 31px;
	background: #b3b3b3;
	border-radius: 32px;
	padding: 4px;
	transition: 300ms all;

	&:before {
		transition: 300ms all;
		content: '';
		position: absolute;
		width: 23px;
		height: 23px;
		border-radius: 35px;
		top: 50%;
		left: 4px;
		background: white;
		transform: translate(1px, -50%);
	}
`;

export const StyledInput = styled.input`
	display: none;

	&:checked + ${StyledSwitch} {
		background: ${theme.primary500};

		&:before {
			transform: translate(23px, -50%);
		}
	}
`;
