import styled from 'styled-components';
import { StyledInput } from '../../common/components/TextInputField/styles';

export const StyledSearchMedication = styled(StyledInput)`
  width: 100%;
  height: 31px;
  font-size: 12px;

  &:focus {
    outline: none;
  }

  &::placeholder {
    overflow: hidden;
    color: ${({ theme }) => theme.gray500};
    text-overflow: ellipsis;
    font-family: 'Inter';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

,
`;
