import React, { ReactNode } from 'react';
import StyledText from '../../common/components/Text';
import { StyledBox } from '../../common/styledCommonComponents';
import { theme } from '../../common/theme';
import ReloadIcon from '../../assets/ReloadIcon';

interface ErrorComponentProps {
	title?: string;
	children?: ReactNode;
	reload?: any;
}

const ErrorComponent = ({ title, children, reload }: ErrorComponentProps) => {
	return (
		<StyledBox
			css={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
				gap: 4,
			}}
		>
			<StyledText variant="h6" css={{ color: theme.gray300 }}>
				Se produjo un error
			</StyledText>
			<StyledBox
				css={{
					display: 'flex',
					flexDirection: 'row',
					gap: 4,
					padding: '6px 10px',
					justifyContent: 'center',
					alignContent: 'center',
				}}
			>
				<StyledBox
					css={{
						display: 'flex',
						height: 22,
						alignItems: 'center',
						gap: 8,
					}}
					onClick={() => reload()}
				>
					<ReloadIcon color={theme.primary500} />
					<StyledText
						variant="buttonSmall"
						css={{ color: theme.primary500 }}
					>
						Volver a cargar
					</StyledText>
				</StyledBox>
			</StyledBox>
		</StyledBox>
	);
};

export default ErrorComponent;
