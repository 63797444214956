import {
	StyledBox,
	StyledTableCell,
	StyledTableRow,
} from '../../../../common/styledCommonComponents';
import { rgba } from 'polished';
import { CSSObject, useTheme } from 'styled-components';
import React from 'react';
import {
	CapitalizeText,
	ModalTypeEnum,
	getProfileImageFromName,
} from '../../../../utils/utils';
import StyledText from '../../../../common/components/Text';
import Kebab from '../../../../components/Kebab';
import { useOncoDispatch } from '../../../../hooks/redux-hook';
import { setModalOpen } from '../../../../redux/slices/utilsSlice';
import 'moment/locale/es';
import moment from 'moment/moment';

interface PatientItemProps {
	row: {
		assistantProfileId: string;
		name: string;
		role: string;
		surname: string;
		lastConnection?: string;
		email: string;
	};
}
moment.locale('es'); // set the locale to Spanish

const TeamItem = ({ row }: PatientItemProps) => {
	const { name, surname, role, assistantProfileId, lastConnection, email } =
		row;
	const dispatch = useOncoDispatch();

	const theme = useTheme();
	const lastConnectionMoment = moment(lastConnection).fromNow();

	const options = [
		{
			label: (
				<>
					Remover de
					<br />
					mi equipo
				</>
			),
			onClick: (e: any) => {
				dispatch(
					setModalOpen({
						type: ModalTypeEnum.REMOVE_AUXILIAR,
						payload: {
							assistantProfileId,
							name,
						},
					}),
				);
			},
			css: { width: '100px', height: '46px' } as CSSObject,
		},
	];

	const getRole: Record<string, string> = {
		ASSISTANT: 'Auxiliar',
		DOCTOR: 'Doctor',
	};
	return (
		<StyledTableRow
			css={{
				backgroundColor: 'white',
				height: '76px',
				'&:hover': {
					backgroundColor: rgba(theme.gray50, 0.5),
					cursor: 'pointer',
				},
			}}
		>
			<StyledTableCell>
				<StyledBox
					css={{
						margin: 'auto',
						width: 61,
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					{getProfileImageFromName({
						name: name,
						surname: surname,
						size: {
							width: '28px',
							height: '28px',
						},
					})}
				</StyledBox>
			</StyledTableCell>
			<StyledTableCell>
				<StyledText variant={'body2'}>
					{CapitalizeText(name)} {CapitalizeText(surname)}
				</StyledText>
			</StyledTableCell>
			<StyledTableCell>{getRole[role]}</StyledTableCell>
			<StyledTableCell>{email}</StyledTableCell>
			<StyledTableCell></StyledTableCell>
			<StyledTableCell>
				{lastConnection ? CapitalizeText(lastConnectionMoment) : 'Nunca'}
			</StyledTableCell>
			<StyledTableCell>
				<StyledBox css={{ margin: 'auto', width: 'fit-content' }}>
					<Kebab options={options} />
				</StyledBox>
			</StyledTableCell>
		</StyledTableRow>
	);
};

export default TeamItem;
