import * as React from 'react';
import { FunctionComponent, ReactNode, useState } from 'react';
import Search from './Search';
import {
	StyledBox,
	StyledColumn,
	StyledRow,
	StyledTable,
	StyledTableBody,
	StyledTableHead,
	StyledTableRow,
} from '../../common/styledCommonComponents';
import { PatientListHeaderConst } from '../../pages/PatientList/config';
import { TableContainer } from '@mui/material';
import { useTheme } from 'styled-components';
import { StyledCircularProgress } from '../CustomCircularProgress/styles';
import { useOncoDispatch } from '../../hooks/redux-hook';
import { setModalOpen } from '../../redux/slices/utilsSlice';
import { ModalTypeEnum } from '../../utils/utils';
import TablePagination from './TablePagination';
import { HeaderButtons } from './HeaderButtons';
import { RowTableComponent } from './RowTableComponent';

interface TableComponentProps {
	fetchSort?: (field: string, sortingOrder: 'asc' | 'desc') => void;
	dataList?: any[];
	search?: any;
	addAuxiliarButton?: boolean;
	loading: boolean;
	actualPage?: number;
	totalPages?: number;
	handlePageChange?: any;
	headerStructure?: any[];
	headerComponent?: ReactNode;
	formsTable?: boolean;
	patientName?: string;
	emptyComponent?: ReactNode;
	itemComponent?: FunctionComponent<any>;
	onRowClick?: any;
	handleOptionsClick?: any;
}

const TableComponent = ({
	dataList = [],
	fetchSort = () => {},
	search,
	loading = false,
	headerStructure = PatientListHeaderConst,
	actualPage = 0,
	totalPages = 1,
	handlePageChange,
	addAuxiliarButton = false,
	formsTable = false,
	emptyComponent: EmptyComponent,
	itemComponent: ItemComponent,
	headerComponent,
	onRowClick,
	handleOptionsClick,
}: TableComponentProps) => {
	const theme = useTheme();
	const [sort, setSort] = useState<{ id: string; order: 'asc' | 'desc' }>({
		id: '',
		order: 'asc',
	});

	const dispatch = useOncoDispatch();

	const handleSort = (id: string) => {
		let sortObject: { id: string; order: 'asc' | 'desc' } = {
			id,
			order: 'asc',
		};
		if (sort.id === id) {
			sortObject.order = sort.order === 'asc' ? 'desc' : 'asc';
		}
		setSort(sortObject);
		fetchSort(id, sortObject.order);
	};
	const handleClick = () => {
		dispatch(
			setModalOpen({
				type: ModalTypeEnum.FIND_AUXILIAR_MODAL,
			}),
		);
	};

	return (
		<TableContainer
			sx={{
				maxWidth: '1124px',
				maxHeight: '816px',
				height: '816px',
				borderSpacing: 0,
				display: 'flex',
				flexDirection: 'column',
				background: 'white',
				borderRadius: '20px',
			}}
		>
			<StyledRow
				css={{
					justifyContent: 'space-between',
					width: '100%',
					padding: formsTable ? '27px 24px' : '24px 24px 16px',
					alignItems: 'flex-end',
					height: 82,
				}}
			>
				{headerComponent ?? (
					<Search onSearch={search} placeholder={'Escriba nombre...'} />
				)}
			</StyledRow>
			<StyledColumn
				css={{
					flex: 1,
					width: '100%',
					justifyContent: 'space-between',
				}}
			>
				<StyledTable
					css={{
						borderCollapse: 'collapse',
					}}
				>
					<StyledTableHead
						css={{
							width: '100%',
							backgroundColor: 'lightblue',
							height: '60px',
						}}
					>
						<StyledTableRow
							css={{
								width: '100%',
								height: '60px',
								backgroundColor: '#f9f1ff',
								borderSpacing: 16,
							}}
						>
							{headerStructure.map((header: any, index: any) => (
								<HeaderButtons
									key={index + '-header-structure'}
									index={index}
									header={header}
									handleSort={handleSort}
								/>
							))}
						</StyledTableRow>
					</StyledTableHead>
					{!loading && (
						<StyledTableBody>
							{dataList?.map((row: any, index: number) =>
								!ItemComponent ? (
									<RowTableComponent
										handleOptionsClick={handleOptionsClick}
										handleRowClick={() => onRowClick(row)}
										key={index + '-row-table-component'}
										row={row}
										headerStructure={headerStructure}
									/>
								) : (
									<ItemComponent row={row} />
								),
							)}
						</StyledTableBody>
					)}
				</StyledTable>
				{loading ? (
					<StyledBox
						css={{
							flex: 1,
							backgroundColor: 'white',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<StyledCircularProgress />
					</StyledBox>
				) : (
					dataList?.length === 0 && !!EmptyComponent && EmptyComponent
				)}
				{
					// TODO: IMPROVE PAGINATION
				}
				<TablePagination
					loading={loading}
					page={actualPage}
					totalPages={totalPages}
					handleChangePage={handlePageChange}
					disabled={dataList.length === 0}
					// handleChangePage={(
					// 	event: React.MouseEvent<HTMLButtonElement> | null,
					// 	newPage: number,
					// ) => {}}
				/>
			</StyledColumn>
		</TableContainer>
	);
};

export default TableComponent;
