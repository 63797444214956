import React from 'react';
import { StyledRow } from '../../../../common/styledCommonComponents';
import { useTheme } from 'styled-components';
import StyledText from '../../../../common/components/Text';
import { categoryBiometric } from '../../../../utils/proctcae';
import { useDispatch } from 'react-redux';
import { setModalOpen } from '../../../../redux/slices/utilsSlice';
import { CapitalizeText, ModalTypeEnum } from '../../../../utils/utils';
import Person2FillIcon from '../../../../assets/Person2FillIcon';

interface FrequencyComponentProps {
	type: string;
	symptom: string;
	patients: any[];
	maxPatients: number;
}

export const FrequencyComponent = ({
	type,
	symptom,
	patients,
	maxPatients,
}: FrequencyComponentProps) => {
	const theme = useTheme();
	const dispatch = useDispatch();
	const openModal = () => {
		dispatch(
			setModalOpen({
				open: true,
				type: ModalTypeEnum.FREQ_SYMPTOMS_MODAL,
				frequentSymptomData: {
					type: type,
					patients: patients,
					symptom: symptom,
				},
			}),
		);
	};
	const biometric = categoryBiometric(type, 20, 20);

	const getWidthPercentage = (): number => {
		return (patients.length / maxPatients) * 100;
	};

	return (
		<StyledRow css={{ gap: '4px', alignItems: 'center', width: '100%' }}>
			{biometric.icon}
			<StyledRow css={{ width: '240px', alignItems: 'center' }}>
				<StyledText
					variant="body3"
					css={{ color: theme.gray850, flex: '1 0 0' }}
				>
					{CapitalizeText(symptom)}
				</StyledText>
			</StyledRow>
			<StyledRow
				css={{
					height: '25px',
					width: '100%',
					borderRadius: '4px',
				}}
			>
				<StyledRow
					onClick={() => openModal()}
					css={{
						cursor: 'pointer',
						alignItems: 'center',
						justifyContent: 'end',
						maxWidth: '100%',
						minWidth: '5px',
						height: '25px',
						padding: '5px',
						width: `${getWidthPercentage()}%`,
						borderRadius: '4px',
						background: biometric.gradient,
						gap: '1px',
					}}
				>
					{getWidthPercentage() >= 5 && (
						<>
							<Person2FillIcon
								width={14}
								height={14}
								color={theme.white}
							/>
							<StyledText variant="body3" css={{ color: theme.white }}>
								{patients.length}
							</StyledText>
						</>
					)}
				</StyledRow>
				{getWidthPercentage() < 5 && (
					<StyledRow
						css={{
							alignItems: 'center',
							maxWidth: '100%',
							minWidth: '30px',
							height: '25px',
							padding: '5px',
							width: `${getWidthPercentage()}%`,
							gap: '1px',
						}}
					>
						<Person2FillIcon
							width={14}
							height={14}
							color={theme.gray800}
						/>{' '}
						<StyledText
							variant="body3"
							css={{ color: theme.gray800, flex: '1 0 0 ' }}
						>
							{patients.length}
						</StyledText>
					</StyledRow>
				)}
			</StyledRow>
		</StyledRow>
	);
};
