import React, { FunctionComponent, ReactNode } from 'react';
import {
	StyledBox,
	StyledColumn,
} from '../../../../common/styledCommonComponents';
import { StyledH6 } from '../../../../common/components/Text/styles';
import { PatientInfo } from './PatientInfo';
import { useTheme } from 'styled-components';

interface PatientListComponentProps {
	header: ReactNode;
	patients: { id: string; name: string; surname: string }[];
	patientComponent?: FunctionComponent<any>;
}

export const PatientListComponent = ({
	header,
	patients,
	patientComponent: PatientComponent = PatientInfo,
}: PatientListComponentProps) => {
	const theme = useTheme();

	return (
		<StyledColumn
			css={{
				alignItems: 'flex-start',
				alignSelf: 'stretch',
				padding: '16px',
				gap: '4px',
				borderRadius: '20px',
				border: `1px solid ${theme.gray100}`,
				maxHeight: '286px',
			}}
		>
			{header}
			<StyledColumn
				css={{
					alignItems: 'flex-start',
					gap: '4px',
					alignSelf: 'stretch',
					overflow: 'auto',
				}}
			>
				{patients !== undefined && patients?.length > 0 ? (
					patients.map((patient: any) => (
						<PatientComponent
							{...patient}
							id={patient.patientProfileId}
							name={patient.name}
							surname={patient.surname}
						/>
					))
				) : (
					<StyledBox
						css={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							height: '100%',
							minHeight: 80,
							width: '100%',
						}}
					>
						<StyledH6 css={{ color: theme.gray300 }}>Sin Datos</StyledH6>
					</StyledBox>
				)}
			</StyledColumn>
		</StyledColumn>
	);
};
