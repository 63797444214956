import styled from 'styled-components';

export const LoginWrapper = styled.main`
	display: flex;
	flex: 1;
	height: 100vh;
	overflow: hidden;
`;

export const LoginFormSection = styled.section`
	position: relative;
	display: flex;
	flex: 0.3;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	min-width: 350px;
	padding: 16px;

	& > form {
		display: inherit;
		flex-direction: column;
		align-items: center;
		justify-content: space-evenly;
		flex: 0.4;
		width: 100%;
	}
`;

export const LoginImageSection = styled.section`
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #f5f5f5;
	flex: 0.7;
	padding: 16px;
`;

export const StyledLoginSpan = styled.span`
	font-weight: bolder;
	font-size: 3rem;
	color: ${(props) => props.theme.accent};
`;
