import React from 'react';
import { IconProps } from '../IconInterface';

const CircularCardiacIcon = ({
	color = '#000000',
	width = 30,
	height = 30,
}: IconProps) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 30 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g id="Group 27543">
				<circle id="Ellipse 145" cx="15" cy="15" r="15" fill="#E3F5EA" />
				<g id="&#240;&#159;&#166;&#134; icon &#34;Heart&#34;">
					<path
						id="Vector"
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M13.0634 7.14551H14.8465V8.32406C15.4136 8.28952 15.9634 8.32255 16.4573 8.42682C14.362 9.25153 13.398 11.3709 13.2401 11.718L13.2304 11.7393C12.7184 11.6615 12.1637 11.5969 11.6388 11.6017C11.0979 11.6478 10.5731 11.7277 9.70874 12.2146C9.58686 12.3007 9.42255 12.4419 9.19926 12.6573C9.18303 12.6457 9.16565 12.6348 9.14291 12.6206C9.12473 12.6092 9.10302 12.5957 9.07574 12.5779C8.9523 12.4973 8.77207 12.3881 8.55231 12.2791C8.09959 12.0545 7.54424 11.863 7 11.863V10.1341C7.94105 10.1341 8.78714 10.4536 9.36451 10.7401C9.5648 10.8394 9.74057 10.9387 9.88602 11.027C10.0147 10.7523 10.1845 10.4968 10.3875 10.2613L9.40833 8.72477L10.9779 7.70548L11.8813 9.1231C12.2533 8.93124 12.6524 8.77204 13.0634 8.64692V7.14551ZM17.8583 11.0197C17.2085 11.321 16.646 11.8161 16.1389 12.7691C16.1389 12.7691 15.0138 12.2546 14.1011 11.9872C14.8016 10.567 15.6168 9.76787 16.8189 9.21065C17.9818 8.67163 19.369 8.53362 21.017 8.59056L20.939 10.7118C19.4184 10.6592 18.5474 10.7003 17.8583 11.0197ZM10.0681 13.0384C6.83056 15.9262 11.4556 23.1455 15.1556 23.1455C18.8557 23.1455 23.9505 15.9326 20.2432 12.6259C20.1576 12.5496 20.0827 12.481 20.0132 12.4172L20.0132 12.4172C19.7067 12.1362 19.5028 11.9493 18.9374 11.6131C17.7997 11.6131 17.114 12.7512 16.8019 13.4445C16.786 13.4798 16.7664 13.5126 16.7438 13.5427L16.0242 15.5445L15.7649 16.4754C15.7375 16.5737 15.7447 16.6784 15.7852 16.772L16.0881 17.472L17.6563 17.29L17.7591 18.1756L16.2842 18.3468L16.38 19.7518L15.4905 19.8124L15.3709 18.0597L14.967 17.1261C14.8454 16.8451 14.8239 16.531 14.906 16.2362L14.9138 16.2083L14.0071 16.6184L13.4142 18.117L12.5852 17.789L13.0547 16.6022L11.6236 16.265L11.828 15.3972L13.6136 15.8179L15.2435 15.0807L15.7917 13.5559C14.3794 12.8924 11.4794 11.7796 10.0681 13.0384Z"
						fill="#52BF7E"
					/>
				</g>
			</g>
		</svg>
	);
};
export default CircularCardiacIcon;
