import React from 'react';
import { StyledRow } from '../../../../../../../common/styledCommonComponents';
import StyledText from '../../../../../../../common/components/Text';
import { FrequencySummary } from '../FrequencySummary';

interface FrequencyHeaderProps {
	label: string;
	open?: boolean;
	configuration: {
		days: string[];
		week: number;
	};
}
export const FrequencyHeader = ({
	label,
	configuration,
	open,
}: FrequencyHeaderProps) => {
	return (
		<StyledRow
			css={{
				alignItems: 'center',
				width: '-moz-available',
				justifyContent: 'space-between',
				flex: 1,
				marginRight: 8,
			}}
		>
			<StyledText variant="body1">{label}</StyledText>
			{!open && <FrequencySummary data={configuration} />}
		</StyledRow>
	);
};
