import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { v4 } from 'uuid';
import { ValidationType } from '../../validations';
import { patientApi } from '../api/patientApi';
import { validateFormApi } from '../api/validateFormApi';

export interface TreatmentInterface {
	id: string;
	primaryTreatment: string;
	systematicTreatment: string;
	startDate: string | undefined;
	estimateFinishDate: string | undefined;
	intention: string;
	treatmentLine: number;
	finishDate?: string;
	endingMotive: string | undefined;
	medicationId: string;
	isFinished?: boolean;
	otherMotive?: string;
}

export interface BiomarkerInterface {
	id: string;
	sampleId: string;
	biomarkerId: string;
	evaluationId: string;
	value?: string;
}

export interface SetbackInterface {
	id: string;
	date: string | undefined;
	localOrDistance: string;
	placeMetastasis?: string;
	treatment: TreatmentInterface;
}

export interface ProgressionInterface {
	id: string;
	date: string | undefined;
	localOrDistance: string;
	placeMetastasis?: string;
	treatment: TreatmentInterface;
}

export interface MedicationInterface {
	id: string;
	medicationId: string;
}

export interface ValidationInterface {
	isEditable: boolean;
	values: {
		name: string;
		surname: string;
		email: string;
		phone: string;
		status: string;
		gender: string;
		birthDate: string;
		hospital: string;
		medicalID: string;
		uploadDate: string;
		diagnoseDate: string;
		primaryTumor: string;
		histologyType: string;
		histologySubtype: string;
		tumor: string;
		nodule: string;
		metastasis: string;
		stage: string;
		treatment: TreatmentInterface;
		medication: Record<string, MedicationInterface[]>;
		biomarkers: BiomarkerInterface[];
		setbacks: SetbackInterface[];
		progressions: ProgressionInterface[];
		otherMedication?: { response: string }[];
		riskFactors?: { response: string }[];
		smoker?: {
			isSmoker: string;
			time: number;
			quantity: number;
		};
	};
	validations: {
		id: ValidationType[];
		name: ValidationType[];
		surname: ValidationType[];
		email: ValidationType[];
		phone: ValidationType[];
		gender: ValidationType[];
		birthDate: ValidationType[];
		hospital: ValidationType[];
		status: ValidationType[];
		medicalID: ValidationType[];
		uploadDate: ValidationType[];
		diagnoseDate: ValidationType[];
		primaryTumor: ValidationType[];
		histologyType: ValidationType[];
		histologySubtype: ValidationType[];
		tumor: ValidationType[];
		nodule: ValidationType[];
		metastasis: ValidationType[];
		stage: ValidationType[];
		biomarkers: Record<keyof BiomarkerInterface, ValidationType[]>;
		treatment: Record<keyof TreatmentInterface, ValidationType[]>;
		smoker?: {
			isSmoker: boolean;
			time: number;
			quantity: number;
		};
		riskFactors?: {
			riskFactorId: string;
			response: string;
		}[];
		otherMedication?: {
			medicDataFieldId: string;
			response: string;
		}[];
		setbacks: Record<
			keyof Omit<SetbackInterface, 'treatment'>,
			ValidationType[]
		>;
		progressions: Record<
			keyof Omit<ProgressionInterface, 'treatment'>,
			ValidationType[]
		>;
		medication: Record<keyof MedicationInterface, ValidationType[]>;
	};
	auxiliarData?: {
		primaryTumor: Record<string, any>;
		histologyType: Record<string, any>;
		histologySubtype: Record<string, any>;
		treatmentOptions: Record<string, any>;
		samples: Record<string, any>;
		biomarkers: Record<string, any>;
		evaluations: Record<string, any>;
		stage: any;
		additionalFields: {
			prefix: string;
			id: string;
			endingMotive?: string;
			finishDate?: string;
			otherMotive?: string;
		}[];
	};
	errors: Record<
		string,
		{
			key: string;
			error?: string[];
			properties?: { key: string; error: string[] }[];
		}
	>;
}

const initialState: ValidationInterface = {
	isEditable: true,
	values: {
		name: '',
		surname: '',
		birthDate: '',
		gender: '',
		email: '',
		phone: '',
		hospital: '',
		medicalID: '',
		uploadDate: '',
		diagnoseDate: '',
		status: 'no_value',
		primaryTumor: 'no_value',
		histologyType: 'no_value',
		histologySubtype: 'no_value',
		tumor: 'no_value',
		nodule: 'no_value',
		metastasis: 'no_value',
		stage: 'no_value',
		treatment: {
			id: '1',
			primaryTreatment: 'no_value',
			systematicTreatment: 'no_value',
			startDate: '',
			estimateFinishDate: '',
			treatmentLine: 1,
			finishDate: undefined,
			intention: 'no_value',
			endingMotive: 'no_value',
			otherMotive: undefined,
			medicationId: '12',
			isFinished: false,
		},
		medication: { '12': [] },
		biomarkers: [],
		setbacks: [],
		progressions: [],
	},
	validations: {
		id: [ValidationType.IS_REQUIRED],
		name: [ValidationType.IS_REQUIRED],
		surname: [ValidationType.IS_REQUIRED],
		email: [ValidationType.IS_REQUIRED, ValidationType.IS_EMAIL],
		phone: [ValidationType.IS_REQUIRED, ValidationType.IS_NUMBER],
		birthDate: [ValidationType.IS_REQUIRED, ValidationType.IS_DATE],
		gender: [ValidationType.IS_REQUIRED],
		hospital: [ValidationType.IS_REQUIRED],
		status: [ValidationType.IS_REQUIRED],
		medicalID: [ValidationType.IS_REQUIRED],
		uploadDate: [ValidationType.IS_REQUIRED, ValidationType.IS_DATE],
		diagnoseDate: [ValidationType.IS_REQUIRED, ValidationType.IS_DATE],
		primaryTumor: [ValidationType.IS_REQUIRED],
		histologyType: [ValidationType.IS_REQUIRED],
		histologySubtype: [ValidationType.IS_REQUIRED],
		tumor: [ValidationType.IS_REQUIRED],
		nodule: [ValidationType.IS_REQUIRED],
		metastasis: [ValidationType.IS_REQUIRED],
		stage: [ValidationType.IS_REQUIRED],
		biomarkers: {
			id: [ValidationType.IS_REQUIRED],
			sampleId: [ValidationType.IS_REQUIRED],
			biomarkerId: [ValidationType.IS_REQUIRED],
			evaluationId: [ValidationType.IS_REQUIRED],
			value: [],
		},
		treatment: {
			id: [ValidationType.IS_REQUIRED],
			primaryTreatment: [ValidationType.IS_REQUIRED],
			systematicTreatment: [ValidationType.IS_REQUIRED],
			startDate: [ValidationType.IS_REQUIRED, ValidationType.IS_DATE],
			estimateFinishDate: [
				ValidationType.IS_REQUIRED,
				ValidationType.IS_DATE,
			],
			treatmentLine: [ValidationType.IS_REQUIRED],
			intention: [ValidationType.IS_REQUIRED],
			endingMotive: [],
			medicationId: [ValidationType.IS_REQUIRED],
			isFinished: [],
			otherMotive: [],
			finishDate: [
				ValidationType.IS_DATE,
				ValidationType.IS_PRESENT_PAST_DATE,
			],
		},
		setbacks: {
			id: [ValidationType.IS_REQUIRED],
			date: [ValidationType.IS_REQUIRED, ValidationType.IS_DATE],
			localOrDistance: [ValidationType.IS_REQUIRED],
			placeMetastasis: [],
		},
		progressions: {
			id: [ValidationType.IS_REQUIRED],
			date: [ValidationType.IS_REQUIRED, ValidationType.IS_DATE],
			localOrDistance: [ValidationType.IS_REQUIRED],
			placeMetastasis: [],
		},
		medication: {
			id: [ValidationType.IS_REQUIRED],
			medicationId: [ValidationType.IS_REQUIRED],
		},
	},
	auxiliarData: {
		primaryTumor: {},
		histologyType: {},
		histologySubtype: {},
		treatmentOptions: {},
		stage: {},
		samples: {},
		evaluations: {},
		biomarkers: {},
		additionalFields: [],
	},
	errors: {},
};

export const validationSlice = createSlice({
	name: 'validationSlice',
	initialState,
	reducers: {
		setValues: (state, action) => {
			state.values = action.payload;
		},
		cleanValidationForm: (state, action) => {
			return initialState;
		},
		setValue: (
			state,
			action: PayloadAction<{
				key: keyof ValidationInterface['values'];
				value: string;
			}>,
		) => {
			const key = action.payload.key as keyof ValidationInterface['values'];
			const value = action.payload.value;
			// @ts-ignore
			state.values[key] = value;

			if (key === 'stage') {
				if (value.includes('IV')) {
					state.values.treatment.treatmentLine = 0;
				} else {
					state.values.treatment.treatmentLine = 1;
				}
			}
		},
		setTreatmentValue: (
			state,
			action: PayloadAction<{
				prefix: 'treatment' | 'setbacks' | 'progressions';
				id: string;
				key: keyof TreatmentInterface;
				value: string | boolean | null;
			}>,
		) => {
			if (action.payload.prefix === 'treatment') {
				const key = action.payload.key as keyof TreatmentInterface;
				const value = action.payload?.value ?? null;
				// @ts-ignore
				state.values.treatment[key] = value;
				if (state.auxiliarData)
					state.auxiliarData.additionalFields = [
						...state.auxiliarData.additionalFields,
						{
							prefix: 'treatment',
							id: action.payload.id,
						},
					];
			} else {
				const key = action.payload.key as keyof TreatmentInterface;
				const value = action.payload.value;
				// @ts-ignore
				state.values[action.payload.prefix].find(
					(prefix: any) => prefix.id === action.payload.id,
				).treatment[key] = value;
			}
		},
		addNewMedication: (
			state,
			action: PayloadAction<{
				id: string;
			}>,
		) => {
			state.values.medication[action.payload.id] = [
				...state.values.medication[action.payload.id],
				{
					id: v4(),
					medicationId: 'no_value',
				},
			];
		},
		removeMedication: (
			state,
			action: PayloadAction<{
				id: string;
				idMed: string;
			}>,
		) => {
			state.values.medication[action.payload.id] = state.values.medication[
				action.payload.id
			].filter((med) => med.id !== action.payload.idMed);
		},
		updateMedication: (
			state,
			action: PayloadAction<{
				id: string;
				idMed: string;
				value: string;
			}>,
		) => {
			state.values.medication[action.payload.id] = state.values.medication[
				action.payload.id
			].map((med) => {
				if (med.id === action.payload.idMed) {
					return {
						...med,
						medicationId: action.payload.value,
					};
				}
				return med;
			});
		},
		addBiomarker: (state) => {
			const biomarkerId = v4();
			state.values.biomarkers = [
				...state.values.biomarkers,
				{
					id: biomarkerId,
					sampleId: 'no_value',
					biomarkerId: 'no_value',
					evaluationId: 'no_value',
				},
			];
			if (state.auxiliarData && state.values.status === 'ACTIVE')
				state.auxiliarData.additionalFields = [
					...state.auxiliarData.additionalFields,
					{
						prefix: 'biomarkers',
						id: biomarkerId,
					},
				];
		},
		removeBiomarker: (
			state,
			action: PayloadAction<{
				id: string;
			}>,
		) => {
			state.values.biomarkers = state.values.biomarkers.filter(
				(bio) => bio.id !== action.payload.id,
			);
		},
		removeAllBiomarker: (state) => {
			state.values.biomarkers = [];
		},
		updateBiomarker: (
			state,
			action: PayloadAction<{
				id: string;
				key: keyof BiomarkerInterface;
				value: string;
			}>,
		) => {
			state.values.biomarkers = state.values.biomarkers.map((bio) => {
				if (bio.id === action.payload.id) {
					return {
						...bio,
						[action.payload.key]: action.payload.value,
					};
				}
				return bio;
			});
		},
		updateSetback: (
			state,
			action: PayloadAction<{
				id: string;
				key: keyof SetbackInterface;
				value: string;
			}>,
		) => {
			state.values.setbacks = state.values.setbacks.map((setback) => {
				if (setback.id === action.payload.id) {
					return {
						...setback,
						[action.payload.key]: action.payload.value,
					};
				}
				return setback;
			});
		},
		addSetback: (state) => {
			const medicationId = v4();
			const setbackId = v4();
			state.values.setbacks = [
				...state.values.setbacks,
				{
					id: setbackId,
					date: 'no_value',
					localOrDistance: 'no_value',
					placeMetastasis: 'no_value',
					treatment: {
						id: v4(),
						primaryTreatment: 'no_value',
						systematicTreatment: 'no_value',
						startDate: 'no_value',
						estimateFinishDate: 'no_value',
						treatmentLine: 2,
						finishDate: undefined,
						intention: 'no_value',
						endingMotive: undefined,
						medicationId: medicationId,
						otherMotive: undefined,
						isFinished: false,
					},
				},
			];
			state.values.medication[medicationId] = [
				{
					id: v4(),
					medicationId: 'no_value',
				},
			];
			if (state.auxiliarData)
				state.auxiliarData.additionalFields = [
					...state.auxiliarData.additionalFields,
					{
						prefix: 'setbacks',
						id: setbackId,
					},
				];
		},
		removeSetback: (
			state,
			action: PayloadAction<{
				id: string;
			}>,
		) => {
			const setbacktoRemove = state.values.setbacks.find(
				(setback) => setback.id === action.payload.id,
			);
			const medicationId = setbacktoRemove?.treatment.medicationId;

			if (medicationId && state.values.medication[medicationId]) {
				delete state.values.medication[medicationId];
			}

			state.values.setbacks = state.values.setbacks.filter(
				(setback) => setback.id !== action.payload.id,
			);
		},
		updateProgression: (
			state,
			action: PayloadAction<{
				id: string;
				key: keyof ProgressionInterface;
				value: string;
			}>,
		) => {
			state.values.progressions = state.values.progressions.map(
				(progression) => {
					if (progression.id === action.payload.id) {
						return {
							...progression,
							[action.payload.key]: action.payload.value,
						};
					}
					return progression;
				},
			);
		},
		addProgression: (state) => {
			const medicationId = v4();
			const progressionId = v4();
			state.values.progressions = [
				...state.values.progressions,
				{
					id: progressionId,
					date: 'no_value',
					localOrDistance: 'no_value',
					placeMetastasis: 'no_value',
					treatment: {
						id: v4(),
						primaryTreatment: 'no_value',
						systematicTreatment: 'no_value',
						startDate: 'no_value',
						estimateFinishDate: 'no_value',
						treatmentLine: 2,
						finishDate: undefined,
						intention: 'no_value',
						endingMotive: undefined,
						otherMotive: undefined,
						medicationId: medicationId,
						isFinished: false,
					},
				},
			];
			state.values.medication[medicationId] = [
				{
					id: v4(),
					medicationId: 'no_value',
				},
			];
			if (state.auxiliarData)
				state.auxiliarData.additionalFields = [
					...state.auxiliarData.additionalFields,
					{
						prefix: 'progressions',
						id: progressionId,
					},
				];
		},
		removeProgression: (
			state,
			action: PayloadAction<{
				id: string;
			}>,
		) => {
			const progressionToRemove = state.values.progressions.find(
				(progression) => progression.id === action.payload.id,
			);
			const medicationId = progressionToRemove?.treatment.medicationId;

			if (medicationId && state.values.medication[medicationId]) {
				delete state.values.medication[medicationId];
			}

			state.values.progressions = state.values.progressions.filter(
				(progression) => progression.id !== action.payload.id,
			);
		},
		addAdditionalField: (
			state,
			action: PayloadAction<{
				id: string;
				prefix: string;
				finishDate?: string;
				endingMotive?: string;
				otherMotive?: string;
			}>,
		) => {
			const { id, prefix, finishDate, endingMotive, otherMotive } =
				action.payload;
			if (state.auxiliarData)
				state.auxiliarData.additionalFields = [
					...state.auxiliarData.additionalFields,
					{
						id,
						prefix,
						...(finishDate && { finishDate }),
						...(endingMotive && { endingMotive }),
						...(otherMotive && { otherMotive }),
					},
				];
		},
		removeAdditionalField: (
			state,
			action: PayloadAction<{
				id: string;
			}>,
		) => {
			if (state.auxiliarData)
				state.auxiliarData.additionalFields =
					state.auxiliarData.additionalFields.filter(
						(field) => field.id !== action.payload.id,
					);
		},
		removeAllAdditionalField: (state) => {
			if (state.auxiliarData) state.auxiliarData.additionalFields = [];
		},
		setValidationErrors: (
			state,
			action: PayloadAction<{
				validation: any;
			}>,
		) => {
			let errors: Record<string, any> = {};
			action.payload?.validation?.forEach((error: any) => {
				errors = {
					...errors,
					[error.key]: error,
				};
			});
			state.errors = errors;
		},
		setAuxiliarData: (
			state,
			action: PayloadAction<{ name: string; data: any }>,
		) => {
			// state.auxiliarData = {
			// 	...state.auxiliarData,
			// 	[action.payload.name]: action.payload.data,
			// };
		},
	},
	extraReducers: (builder) => {
		builder
			.addMatcher(
				patientApi.endpoints.getPatientMedicData.matchFulfilled,
				(state, action) => {
					let valueToSave: ValidationInterface['values'] | object = {};
					const medicationId = v4();
					let auxiliarTreatmentsIDs: string[] = [];
					const treatmentId = v4();
					valueToSave = {
						id: action.payload.id,
						name: action.payload.name,
						surname: action.payload.surname,
						email: action.payload.email,
						phone: action.payload.phoneNumber,
						status: action.payload?.status ?? 'no_value',
						gender:
							action.payload.sex === 'MALE' ? 'Masculino' : 'Femenino',
						birthDate: action.payload.birthDate,
						hospital: action.payload.institution,
						medicalID: action.payload.medicHistoryNumber,
						uploadDate: action.payload.signUpDate,
						diagnoseDate:
							action.payload?.diseaseSpecs?.diagnosisDate ?? 'no_value',
						primaryTumor:
							action.payload.diseaseSpecs.cancerOrgan ?? 'no_value',
						histologyType:
							action.payload.diseaseSpecs.cancerType ?? 'no_value',
						histologySubtype:
							action.payload.diseaseSpecs.cancerSubtype ?? 'no_value',
						tumor: action.payload.diseaseSpecs.tumor ?? 'no_value',
						nodule: action.payload.diseaseSpecs.nodule ?? 'no_value',
						metastasis:
							action.payload.diseaseSpecs.metastasis ?? 'no_value',
						stage: action.payload.diseaseSpecs.cancerStage ?? 'no_value',
						smoker: {
							isSmoker: action.payload.smoker.isSmoker,
							time: action.payload.smoker.time,
							quantity: action.payload.smoker.quantity,
						},
						riskFactors: action.payload.riskFactors.map((risk: any) => {
							return {
								riskFactorId: risk.riskFactorId,
								response: risk.response,
							};
						}),
						otherMedication: action.payload.otherMedication.map(
							(med: any) => {
								return {
									medicDataFieldId: med.medicDataFieldId,
									response: med.response,
								};
							},
						),
						treatment: {
							id: treatmentId,
							primaryTreatment:
								action.payload?.firstTreatment?.primaryTreatment ??
								'no_value',
							systematicTreatment:
								action.payload?.firstTreatment?.systematicTreatment,
							startDate:
								action.payload?.firstTreatment?.startDate ?? 'no_value',
							estimateFinishDate:
								action.payload?.firstTreatment?.estimateFinishDate ??
								'no_value',
							treatmentLine:
								action.payload?.firstTreatment?.treatmentLine ?? 1,
							finishDate: action.payload?.firstTreatment?.finishDate,
							intention: action.payload?.firstTreatment?.treatmentMotive,
							endingMotive: action.payload?.firstTreatment?.endingMotive,
							medicationId: medicationId,
							otherMotive: action.payload?.firstTreatment?.otherMotive,
							isFinished:
								action.payload?.firstTreatment?.isFinished ?? false,
						},
						medication: {
							[medicationId]:
								(
									action.payload.firstTreatment?.medications ?? [{}]
								)?.map((med: any) => {
									return {
										id: v4(),
										medicationId: med?.name ?? 'no_value',
									};
								}) ?? [],
						},
						biomarkers: action.payload.biomarkers.map((bio: any) => {
							return {
								id: v4(),
								sampleId: bio.sample,
								biomarkerId: bio.biomarker,
								evaluationId: bio.evaluation,
								value: bio.value,
							};
						}),
						setbacks: [],
						progressions: [],
					};

					auxiliarTreatmentsIDs = [...auxiliarTreatmentsIDs, treatmentId];

					action.payload.setbacks.forEach((setback: any) => {
						const setbackId = v4();
						const medicationId = v4();
						const treatmentIdSetback = v4();
						auxiliarTreatmentsIDs = [
							...auxiliarTreatmentsIDs,
							treatmentIdSetback,
						];
						valueToSave = {
							...valueToSave,
							setbacks: [
								// @ts-ignore
								...valueToSave.setbacks,
								{
									id: setbackId,
									date: setback.setbackDate,
									localOrDistance: setback.setbackSite,
									placeMetastasis: setback.metastasisSite,
									treatment: {
										id: treatmentIdSetback,
										primaryTreatment:
											setback.treatment.primaryTreatment,
										systematicTreatment:
											setback.treatment.systematicTreatment,
										startDate: setback.treatment.startDate,
										estimateFinishDate:
											setback.treatment.estimateFinishDate,
										treatmentLine: setback.treatment.treatmentLine,
										finishDate: setback.treatment.finishDate,
										intention: setback.treatment.treatmentMotive,
										endingMotive: setback.treatment.endingMotive,
										otherMotive: setback.treatment?.otherMotive,
										medicationId: medicationId,
									},
								},
							],
							medication: {
								// @ts-ignore
								...valueToSave.medication,
								[medicationId]: setback.treatment.medications.map(
									(med: any) => {
										return {
											id: v4(),
											medicationId: med.name,
										};
									},
								),
							},
						};
					});

					action.payload.progressions.forEach((progression: any) => {
						const progressionId = v4();
						const medicationId = v4();
						const treatmentIdProgression = v4();
						auxiliarTreatmentsIDs = [
							...auxiliarTreatmentsIDs,
							treatmentIdProgression,
						];
						valueToSave = {
							...valueToSave,
							progressions: [
								// @ts-ignore
								...valueToSave.progressions,
								{
									id: progressionId,
									date: progression.progressionDate,
									localOrDistance: progression.progressionSite,
									placeMetastasis: progression.metastasisSite,
									treatment: {
										id: treatmentIdProgression,
										primaryTreatment:
											progression.treatment.primaryTreatment,
										systematicTreatment:
											progression.treatment.systematicTreatment,
										startDate: progression.treatment.startDate,
										estimateFinishDate:
											progression.treatment.estimateFinishDate,
										treatmentLine:
											progression.treatment.treatmentLine,
										finishDate: progression.treatment.finishDate,
										intention: progression.treatment.treatmentMotive,
										endingMotive: progression.treatment.endingMotive,
										otherMotive: progression.treatment?.otherMotive,
										medicationId: medicationId,
									},
								},
							],
							medication: {
								// @ts-ignore
								...valueToSave.medication,
								[medicationId]: progression.treatment.medications.map(
									(med: any) => {
										return {
											id: v4(),
											medicationId: med.name,
										};
									},
								),
							},
						};
					});
					// @ts-ignore
					state.values = valueToSave;
					state.isEditable = false;
				},
			)
			.addMatcher(
				validateFormApi.endpoints.getTreatmentOptions.matchFulfilled,
				(state, action) => {
					let aux: any = {};
					action.payload?.forEach(({ id, name }: any) => (aux[id] = name));
					if (state.auxiliarData)
						state.auxiliarData.treatmentOptions = {
							...state.auxiliarData.treatmentOptions,
							...aux,
						};
				},
			)
			.addMatcher(
				validateFormApi.endpoints.getCancerOrgan.matchFulfilled,
				(state, action) => {
					if (state.auxiliarData)
						state.auxiliarData.primaryTumor = action.payload.reduce(
							(acc: any, curr: any) => ({
								...acc,
								[curr.id]: curr.value,
							}),
							{},
						);
				},
			)
			.addMatcher(
				validateFormApi.endpoints.getCancerType.matchFulfilled,
				(state, action) => {
					if (state.auxiliarData)
						state.auxiliarData.histologyType = action.payload.reduce(
							(acc: any, curr: any) => ({
								...acc,
								[curr.id]: curr.value,
							}),
							{},
						);
				},
			)
			.addMatcher(
				validateFormApi.endpoints.getCancerSubType.matchFulfilled,
				(state, action) => {
					if (state.auxiliarData)
						state.auxiliarData.histologySubtype = action.payload.reduce(
							(acc: any, curr: any) => ({
								...acc,
								[curr.id]: curr.value,
							}),
							{},
						);
				},
			)
			.addMatcher(
				validateFormApi.endpoints.getCancerStage.matchFulfilled,
				(state, action) => {
					if (state.auxiliarData)
						state.auxiliarData.stage = action.payload.reduce(
							(acc: any, curr: any) => ({
								...acc,
								[curr.id]: curr.value,
							}),
							{},
						);
				},
			)
			.addMatcher(
				validateFormApi.endpoints.getSamples.matchFulfilled,
				(state, action) => {
					if (state.auxiliarData)
						state.auxiliarData.samples = action.payload.reduce(
							(acc: any, curr: any) => ({
								...acc,
								[curr.id]: curr.value,
							}),
							{},
						);
				},
			)
			.addMatcher(
				validateFormApi.endpoints.getBiomarkers.matchFulfilled,
				(state, action) => {
					if (state.auxiliarData)
						state.auxiliarData.biomarkers = action.payload.reduce(
							(acc: any, curr: any) => ({
								...acc,
								[curr.id]: curr.value,
							}),
							{},
						);
				},
			)
			.addMatcher(
				validateFormApi.endpoints.getEvaluations.matchFulfilled,
				(state, action) => {
					if (state.auxiliarData)
						state.auxiliarData.evaluations = action.payload.reduce(
							(acc: any, curr: any) => ({
								...acc,
								[curr.id]: curr.value,
							}),
							{
								...(state.auxiliarData.evaluations ?? {}),
							},
						);
				},
			);
	},
});

/*
{
    "patientProfileId": "726b37ec-fc3a-4b9a-a135-a2152821cd34",
    "name": "John",
    "surname": "Doe",
    "email": "juan@gmail.com",
    "sex": "MALE",
    "status": "WAITING",
    "birthDate": "2002-06-08T00:00:00.000+00:00",
    "phoneNumber": "123",
    "institution": "Hospital Austral",
    "smoker": {
        "isSmoker": "fumo",
        "time": 0,
        "quantity": 0
    },
    "medicHistoryNumber": "123",
    "signUpDate": "2023-09-29T19:41:03.513+00:00",
    "riskFactors": [
        {
            "riskFactorId": "76d21a8b-fa4a-4e0f-ac26-05a917e260bb",
            "response": "Angina de pecho"
        }
    ],
    "otherMedication": [
        {
            "medicDataFieldId": "992b5d5e-27c9-4c1e-afa3-af545cbbed63",
            "response": "warfarina"
        }
    ],
    "diseaseSpecs": {
        "diagnosisDate": null,
        "tumor": null,
        "nodule": null,
        "metastasis": null,
        "cancerStage": null,
        "cancerOrgan": null,
        "cancerType": null,
        "cancerSubtype": null
    },
    "treatment": null,
    "biomarkers": [],
    "progressions": [],
    "setbacks": []
}
 */

const valuesForm: any = (state: RootState) => state.validationSlice.values;

export const valuesTreatment = createSelector(
	[valuesForm, (values, props: { id?: string; prefix: string }) => props],
	(values, props) => {
		if (props.prefix === 'treatment') {
			return values.treatment;
		}
		return values[props.prefix].find((prefix: any) => prefix.id === props?.id)
			.treatment;
	},
);

export const valuesMedication = createSelector(
	[valuesForm, (values, props: { id: string }) => props],
	(values, props) => {
		return values.medication[props.id];
	},
);

export const valuesBiomarker = createSelector(
	[valuesForm, (values, props: { id: string }) => props],
	(values, props) => {
		return values.biomarkers.find(
			(bio: BiomarkerInterface) => bio.id === props.id,
		);
	},
);

export const valuesSetback = createSelector(
	[valuesForm, (values, props: { id: string }) => props],
	(values, props) => {
		return values.setbacks.find(
			(setback: SetbackInterface) => setback.id === props.id,
		);
	},
);

export const valuesProgression = createSelector(
	[valuesForm, (values, props: { id: string }) => props],
	(values, props) => {
		return values.progressions.find(
			(progression: ProgressionInterface) => progression.id === props.id,
		);
	},
);

export const valuesLastTreatment = createSelector([valuesForm], (values) => {
	let treatment: any = {};
	let prefix = '';
	let id = '';
	let payload = {};
	if (values.setbacks.length > 0) {
		if (
			values.setbacks[0].treatment.endingMotive ||
			values.setbacks[0].treatment.finishDate
		) {
			const progressionsAmount = values.progressions.length - 1;
			treatment = values.progressions?.[progressionsAmount]?.treatment;
			prefix = 'progressions';
			id = values.progressions?.[progressionsAmount]?.id;
			payload = values.progressions?.[progressionsAmount];
		} else {
			treatment = values.setbacks[0].treatment;
			prefix = 'setbacks';
			id = values.setbacks[0].id;
			payload = values.setbacks[0];
		}
	} else {
		prefix = 'treatment';
		treatment = values.treatment;
	}
	return { prefix, treatment, id, payload };
});

export const {
	cleanValidationForm,
	setValues,
	setValue,
	setTreatmentValue,

	//
	setAuxiliarData,

	// error
	setValidationErrors,

	// Medications
	addNewMedication,
	removeMedication,
	updateMedication,

	// Biomarkers
	addBiomarker,
	removeBiomarker,
	removeAllBiomarker,
	updateBiomarker,

	// Setbacks
	updateSetback,
	addSetback,
	removeSetback,

	// Progression
	updateProgression,
	addProgression,
	removeProgression,

	// AdditionalFields
	addAdditionalField,
	removeAdditionalField,
	removeAllAdditionalField,
} = validationSlice.actions;
export default validationSlice.reducer;
