import React from 'react';
import { useTheme } from 'styled-components';
import {
	StyledBox,
	StyledColumn,
	StyledP,
} from '../../common/styledCommonComponents';
import PatientContainer from './PatientContainer';
import { StyledCircularProgress } from '../CustomCircularProgress/styles';
import { useFixedPatientsQuery } from '../../redux/api/listApi';
import { StyledCardHome } from '../Card/styles';
import { StyledH6 } from '../../common/components/Text/styles';

const PatientsList = () => {
	const theme = useTheme();

	const { data, isLoading } = useFixedPatientsQuery();

	return (
		<StyledCardHome
			css={{
				height: '100%',
				width: 298,
				color: theme.oncoBlack,
				padding: 24,
			}}
		>
			<StyledBox
				css={{
					height: '24px',
					marginBottom: '16px',
				}}
			>
				<StyledP css={{ color: theme.oncoBlack, fontWeight: 500 }}>
					Mis pacientes fijados
				</StyledP>
			</StyledBox>

			{isLoading ? (
				<StyledBox
					css={{
						width: '100%',
						height: '100%',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<StyledCircularProgress />
				</StyledBox>
			) : data?.length === 0 ? (
				<StyledBox
					css={{
						height: '100%',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<StyledH6
						css={{
							color: theme.gray300,
						}}
					>
						Sin datos
					</StyledH6>
				</StyledBox>
			) : (
				<StyledBox
					css={{
						height: '95%',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-between',
					}}
				>
					<StyledColumn css={{ rowGap: '10px' }}>
						{data?.map((patient: any, index: number) => (
							<PatientContainer
								key={index}
								name={patient.name}
								surname={patient.surname}
								primaryTumor={patient.primaryTumor}
								patientId={patient.patientProfileId}
							/>
						))}
					</StyledColumn>
				</StyledBox>
			)}
		</StyledCardHome>
	);
};

export default PatientsList;
