import React from 'react';
import { StyledColumn } from '../../../../common/styledCommonComponents';
import Button from '../../../../common/components/Button';
import { StyledH6 } from '../../../../common/components/Text/styles';
import { theme } from '../../../../common/theme';
import ReloadIcon from '../../../../assets/ReloadIcon';

interface ErrorMessageProps {
	refetch: any;
}

export const ErrorMessage: React.FC<ErrorMessageProps> = ({ refetch }) => {
	return (
		<StyledColumn
			css={{
				alignItems: 'center',
				justifyContent: 'center',
				width: '100%',
				height: '100%',
				gap: '4px',
				color: `${theme.gray300}`,
				flex: 1,
			}}
		>
			<StyledH6>Se produjo un error</StyledH6>
			<Button
				text={'Volver a cargar'}
				icon={ReloadIcon}
				width="133px"
				height="34px"
				size="small"
				variant="ghost"
				onClick={refetch}
			/>
		</StyledColumn>
	);
};
