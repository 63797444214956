import React from 'react';
import { IconProps } from './IconInterface';

interface PersonWithHeartProps extends IconProps {
	color2: string;
}

const PersonWithHeartIcon = ({
	color = '#000000',
	width = 24,
	height = 24,
	color2 = '#FFFFFF',
}: PersonWithHeartProps) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M15.6821 10.3743H13.786V9.82037H14.406C14.824 9.82037 15.2249 9.66354 15.5212 9.38412C15.8176 9.10463 15.9853 8.72532 15.9876 8.32903V7.34896C15.9876 7.38961 16.2432 7.36561 16.3661 7.35407L16.3663 7.35405L16.3727 7.35346C16.4009 7.35084 16.4211 7.34896 16.428 7.34896C16.5375 7.33228 16.6365 7.2778 16.7062 7.19599C16.7759 7.11419 16.8112 7.01084 16.8054 6.9058C16.7788 6.74768 16.7241 6.59495 16.6436 6.45416L16.428 5.93428C16.2752 5.57638 16.1224 5.21842 15.9787 4.85198C15.9787 4.85198 15.8888 3.99977 15.8079 3.71005C15.5572 2.93062 15.0507 2.24744 14.3622 1.76C13.6736 1.27256 12.839 1.0063 11.9798 1.00001C11.1483 0.998578 10.3371 1.24352 9.65967 1.70062C8.98215 2.15771 8.47216 2.80411 8.20092 3.54947C7.92969 4.29488 7.91079 5.10197 8.14685 5.85809C8.38286 6.61414 8.86212 7.28137 9.51753 7.76655V10.3743H7.86409C6.85699 10.3718 5.88884 10.7431 5.16671 11.4088C4.44458 12.0745 4.02582 12.9818 4 13.9366V18.0666C4 20.2758 5.79086 22.0666 8 22.0666H15.7978C18.007 22.0666 19.7978 20.2758 19.7978 18.0666V14.4905C19.8157 13.9632 19.7232 13.4377 19.5255 12.9446C19.3278 12.4514 19.0289 12.0004 18.6461 11.6175C18.2633 11.2346 17.8043 10.9277 17.2955 10.7142C16.7867 10.5009 16.2383 10.3853 15.6821 10.3743Z"
				fill={color}
			/>
			<path
				d="M15.2347 16.9146C15.1581 16.9573 15.0642 17 15 17C14.9358 17 14.8419 16.9573 14.7653 16.9146C13.3869 16.1462 12.5 15.2455 12.5 14.334C12.5 13.5464 13.1299 13 13.9205 13C14.4121 13 14.7826 13.4499 15 13.8C15.2223 13.4499 15.5904 13 16.082 13C16.8701 13 17.5 13.5464 17.5 14.334C17.5 15.2455 16.6156 16.1462 15.2347 16.9146Z"
				fill={color2}
			/>
		</svg>
	);
};
export default PersonWithHeartIcon;
