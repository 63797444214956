import { StyledBox } from '../../../../../../common/styledCommonComponents';
import styled from 'styled-components';

export const StyledAccordionWeekly = styled(StyledBox)`
	.Mui-expanded:before {
		opacity: 1 !important;
	}

	width: 100%;
`;
