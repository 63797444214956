import React from 'react';
import { IconProps } from './IconInterface';

const CircleCloseIcon = ({
	color,
	width = 24,
	height = 24,
	...props
}: IconProps) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<rect x="2" y="2" width="20" height="20" rx="10" fill={color} />
			<path
				d="M16.2 7.80668C15.94 7.54668 15.52 7.54668 15.26 7.80668L12 11.06L8.73998 7.80001C8.47998 7.54001 8.05998 7.54001 7.79998 7.80001C7.53998 8.06001 7.53998 8.48001 7.79998 8.74001L11.06 12L7.79998 15.26C7.53998 15.52 7.53998 15.94 7.79998 16.2C8.05998 16.46 8.47998 16.46 8.73998 16.2L12 12.94L15.26 16.2C15.52 16.46 15.94 16.46 16.2 16.2C16.46 15.94 16.46 15.52 16.2 15.26L12.94 12L16.2 8.74001C16.4533 8.48668 16.4533 8.06001 16.2 7.80668V7.80668Z"
				fill="white"
			/>
		</svg>
	);
};
export default CircleCloseIcon;
