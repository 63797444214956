import React, { useEffect, useMemo, useState } from 'react';
import { StyledBox, StyledHr } from '../../common/styledCommonComponents';
import { useTheme } from 'styled-components';
import Chart from 'react-apexcharts';
import { SocialAndPhysicalConfig } from '../../utils/chartsConfigs';
import { StyledCircularProgress } from '../CustomCircularProgress/styles';
import { StyledCardHome } from '../Card/styles';
import StyledText from '../../common/components/Text';
import { StyledH6 } from '../../common/components/Text/styles';
import { useParams } from 'react-router-dom';
import { useGetPatientAnswersQuery } from '../../redux/api/patientApi';
import { ErrorMessage } from '../../pages/PatientProfile/components/ErrorMessage';

const SocialAndPhysicalActivitiesChart = () => {
	const { patientId } = useParams();

	const {
		data: physicalData,
		isLoading: isLoadingPhysical,
		refetch: refetchPhysical,
		isError: isErrorPhysical,
	} = useGetPatientAnswersQuery(
		{
			category: 'PHYSICAL_ACTIVITY',
			patientId: patientId ?? '',
		},
		{
			skip: !patientId,
			selectFromResult: (data) => {
				const physicalData: any = {
					data: [],
					categories: [],
				};
				data.data?.forEach((item: any) => {
					const date = new Date(item.date);
					const formattedDate = `${date.getDate()}/${date.getMonth() + 1}`;
					const answer: any = item.answer;

					if (answer !== null) {
						physicalData.data.push(answer);
					} else {
						physicalData.data.push('No respondió');
					}
					physicalData.categories.push(formattedDate);
				});
				return { ...data, data: physicalData };
			},
		},
	);

	const {
		data: socialData,
		isLoading: isLoadingSocial,
		refetch: refetchSocial,
		isError: isErrorSocial,
	} = useGetPatientAnswersQuery(
		{
			category: 'SOCIAL_ACTIVITY',
			patientId: patientId ?? '',
		},
		{
			skip: !patientId,
			selectFromResult: (data) => {
				const socialData: any = {
					data: [],
					categories: [],
				};
				data.data?.forEach((item: any) => {
					const date = new Date(item.date);
					const formattedDate = `${date.getDate()}/${date.getMonth() + 1}`;
					const answer: any = item.answer;

					if (answer !== null) {
						socialData.data.push(answer);
					} else {
						socialData.data.push('No respondió');
					}
					socialData.categories.push(formattedDate);
				});
				return { ...data, data: socialData };
			},
		},
	);

	const refactorData = useMemo(() => {
		return {
			physical: {
				data: physicalData.data.reverse(),
				categories: physicalData.categories.reverse(),
			},

			social: {
				data: socialData.data.reverse(),
				categories: socialData.categories.reverse(),
			},
		};
	}, [physicalData, socialData]);

	const theme = useTheme();
	const [active, setActive] = useState('physical');

	const options = useMemo(
		() => SocialAndPhysicalConfig(refactorData ?? {}, active),
		[refactorData, active],
	);

	const activeData = options.series[active === 'physical' ? 1 : 0];

	const isEmpty = activeData?.data?.length === 0;

	const handleActividadFisica = () => {
		ApexCharts.exec('mychart', 'hideSeries', ['social']);
		ApexCharts.exec('mychart', 'showSeries', ['physical']);
		setActive('physical');
	};
	const handleActividadSocial = () => {
		ApexCharts.exec('mychart', 'hideSeries', ['physical']);
		ApexCharts.exec('mychart', 'showSeries', ['social']);
		setActive('social');
	};

	useEffect(() => {
		options && !options.isEmpty && handleActividadFisica();
	}, [options]);

	const handleRefetch = () => {
		if (active === 'social') {
			refetchSocial();
		} else {
			refetchPhysical();
		}
	};

	return (
		<StyledCardHome
			css={{
				display: 'flex',
				flexDirection: 'column',
				height: '250px',
				width: '310px',
				padding: '16px',
				alignItems: 'flex-start',
				gap: '16px',
				flexShrink: 0,
			}}
		>
			<StyledBox
				css={{
					display: 'flex',
					width: '100%',
					padding: '4px 4px 0 4px',
					justifyContent: 'space-between',
					alignItems: 'flex-end',
					alignSelf: 'center',
					borderBottom: `1px solid ${theme.gray50}`,
				}}
			>
				<StyledBox
					css={{
						display: 'flex',
						width: '100%',
						paddingBottom: '8px',
						justifyContent: 'center',
						textAlign: 'center',
						cursor: 'pointer',
						alignItems: 'flex-end',
						position: 'relative',
						flex: '1 0 0',
						borderBottom:
							active === 'physical'
								? `1px solid ${theme.primary500}`
								: 'none',
					}}
					onClick={() => setActive('physical')}
				>
					<StyledText
						variant="body2Bold"
						css={{
							color: active === 'physical' ? '#9357F7' : '#5C6B74',
						}}
					>
						Actividad Física
					</StyledText>
				</StyledBox>
				<StyledBox
					css={{
						display: 'flex',
						paddingBottom: '8px',
						justifyContent: 'center',
						textAlign: 'center',
						alignItems: 'flex-end',
						width: '100%',
						flex: '1 0 0',
						position: 'relative',
						cursor: 'pointer',
						borderBottom:
							active === 'social'
								? `1px solid ${theme.primary500}`
								: 'none',
					}}
					onClick={() => setActive('social')}
				>
					<StyledText
						variant="body2Bold"
						css={{
							color: active === 'social' ? '#9357F7' : '#5C6B74',
						}}
					>
						Actividad Social
					</StyledText>
				</StyledBox>
			</StyledBox>
			<StyledBox
				css={{
					display: 'flex',
					flex: 1,
					alignItems: 'center',
					justifyContent: 'center',
					width: '100%',
				}}
			>
				{isLoadingPhysical || isLoadingSocial ? (
					<StyledBox
						css={{
							height: '100%',
							width: '100%',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<StyledCircularProgress />
					</StyledBox>
				) : isErrorPhysical || isErrorSocial ? (
					<ErrorMessage refetch={handleRefetch} />
				) : isEmpty ? (
					<StyledBox
						css={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							height: '180px',
							width: '300px',
						}}
					>
						<StyledH6 css={{ color: theme.gray300 }}>Sin Datos</StyledH6>
					</StyledBox>
				) : (
					<Chart
						// @ts-ignore
						options={options.options}
						// @ts-ignore
						series={[activeData ?? {}]}
						type="bar"
						width={287}
						toggleSeries={'physical'}
						height={173}
					/>
				)}
			</StyledBox>
		</StyledCardHome>
	);
};

export default SocialAndPhysicalActivitiesChart;
