import React from 'react';
import { StyledBox } from '../../common/styledCommonComponents';
import CustomKebab from '../../components/TableComponent/CustomKebab';
import { DataTable } from '../PatientList/config';
import { ReportIcon } from '../../components/TableComponent/RowTableComponent/ReportIcon';

export const ReportTableHeader: DataTable[] = [
	{
		field: 'icon',
		label: ' ',
		sortId: '',
		css: {
			width: 60,
			maxWidth: 60,
			paddingLeft: '24px',
			paddingRight: '8px',
			boxSizing: 'border-box',
		},
		render: ({ children }: any) => (
			<ReportIcon type={children} height={36} width={36} />
		),
	},
	{
		field: 'name',
		label: 'Encuesta',
		sortId: 'name',
		button: false,
		css: { width: 212 },
		render: (props: any) => <p>{props.children}</p>,
	},
	{
		type: 'blank',
		field: '',
		label: '',
		css: {
			width: 212,
		},
		sortId: '',
		render: () => <StyledBox></StyledBox>,
	},
	{
		field: 'startDate',
		label: 'Fecha de inicio',
		button: false,
		sortId: 'lastSignUp',
		css: { width: 212 },
		render: ({ children }: any) => <>{children}</>,
	},
	{
		field: 'finishDate',
		label: 'Fecha de finalización',
		button: false,
		sortId: 'lastSignUp',
		css: { width: 212 },
		render: ({ children }: any) => <>{children}</>,
	},
	{
		field: 'kebab',
		label: ' ',
		sortId: 'emptyBox',
		css: {
			width: 18,
			height: 18,
			paddingLeft: '8px',
			paddingRight: '24px',
		},
		render: (props: any) => (
			<CustomKebab
				row={props.row}
				kebabOptions={['Ver encuesta']}
				handleOptionsClick={props.handleOptionsClick}
			></CustomKebab>
		),
	},
];

export const formatDate = (inputDate: string) => {
	const date = new Date(inputDate);
	const day = date.getDate().toString().padStart(2, '0');
	const month = (date.getMonth() + 1).toString().padStart(2, '0');
	const year = date.getFullYear().toString();
	let hours = date.getHours();
	const minutes = date.getMinutes().toString().padStart(2, '0');
	const ampm = hours >= 12 ? 'pm' : 'am';

	if (hours > 12) {
		hours -= 12;
	} else if (hours === 0) {
		hours = 12;
	}

	return `${day}/${month}/${year} ${hours}:${minutes}${ampm}`;
};
