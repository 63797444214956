import Modal from '../Modal';
import {
	StyledBox,
	StyledRow,
} from '../../../../../common/styledCommonComponents';
import React from 'react';
import { useGetPatientDataQuery } from '../../../../../redux/api/patientApi';
import { useTheme } from 'styled-components';
import { useOncoSelector } from '../../../../../hooks/redux-hook';
import StyledText from '../../../../../common/components/Text';
import { StyledCircularProgress } from '../../../../CustomCircularProgress/styles';
import { PatientListComponent } from '../../PatientListComponent';
import ColorIcon from '../../../../../assets/ColorIcon';
import TwoPersonIcon from '../../../../../assets/TwoPersonIcon';

interface StagePatientModalProps {
	handleOnClose: () => void;
}
interface Patient {
	id: string;
	name: string;
	surname: string;
}
interface StageData {
	color: string;
	stage: string;
	patients: Patient[];
}

const StagePatientModal = ({ handleOnClose }: StagePatientModalProps) => {
	const theme = useTheme();
	const stageData: StageData = useOncoSelector(
		(state) => state.utilsSlice.stageData,
	);

	const header = (stageData: StageData) => {
		const theme = useTheme();

		return (
			<StyledRow
				css={{
					padding: '8px',
					justifyContent: 'space-between',
					alignItems: 'center',
					alignSelf: 'stretch',
				}}
			>
				<StyledBox
					css={{
						display: 'flex',
						gap: 8,
						alignItems: 'center',
					}}
				>
					<ColorIcon color={stageData.color} />
					<StyledText variant="body2">{stageData.stage}</StyledText>
				</StyledBox>
				<StyledBox
					css={{
						display: 'flex',
						alignItems: 'center',
						gap: 2,
					}}
				>
					<StyledBox
						css={{
							display: 'flex',
							width: 18,
							height: 18,
							padding: 1.5,
							justifycontent: 'center',
							alignItems: 'center',
						}}
					>
						<TwoPersonIcon color={theme.gray500} />
					</StyledBox>
					<StyledText variant="body2">
						{stageData.patients.length}
					</StyledText>
				</StyledBox>
			</StyledRow>
		);
	};

	return (
		<Modal
			width={'539px'}
			onClose={handleOnClose}
			header={
				<StyledRow>
					<StyledText variant="h5" css={{ color: theme.black }}>
						Proporción de pacientes por estadío
					</StyledText>
				</StyledRow>
			}
			body={
				<PatientListComponent
					header={header(stageData)}
					patients={stageData.patients}
				/>
			}
		/>
	);
};

export default StagePatientModal;
