import React from 'react';
import { IconProps } from './IconInterface';

const BeatsPerMinuteIcon = ({
	color = '#000000',
	width = 24,
	height = 24,
}: IconProps) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g id="healthicons:blood-pressure">
				<g id="Group">
					<path
						id="Vector"
						d="M2 6.24101C2 4.21334 3.34533 2.33301 5.18767 2.33301C6.466 2.33301 7.41433 3.17634 8 4.37334C8.58533 3.17667 9.534 2.33301 10.8127 2.33301C12.655 2.33301 14 4.21367 14 6.24101C13.9996 6.59477 13.9605 6.94743 13.8833 7.29267C13.5555 6.88836 13.1414 6.56243 12.6713 6.33872C12.2013 6.11501 11.6872 5.99917 11.1667 5.99967C9.23367 5.99967 7.66667 7.56667 7.66667 9.49967C7.66652 10.1566 7.85125 10.8003 8.19975 11.3572C8.54825 11.9141 9.04643 12.3616 9.63733 12.6487C8.70733 13.3023 8 13.6663 8 13.6663C8 13.6663 2 10.7677 2 6.24101Z"
						fill={color}
					/>
					<path
						id="Vector_2"
						d="M12.0001 9.66646C12 9.75883 11.9807 9.85017 11.9434 9.93468C11.9061 10.0192 11.8516 10.095 11.7834 10.1574C11.7153 10.2197 11.6349 10.2672 11.5474 10.2969C11.4599 10.3265 11.3673 10.3377 11.2752 10.3296C11.1832 10.3215 11.0939 10.2944 11.0129 10.25C10.9319 10.2056 10.861 10.1448 10.8047 10.0716C10.7485 9.99833 10.708 9.91417 10.686 9.82447C10.664 9.73476 10.6608 9.64145 10.6768 9.55046L9.87012 8.74379L10.3418 8.27246L11.1081 9.03913C11.2087 9.00299 11.3166 8.99158 11.4226 9.00587C11.5285 9.02017 11.6295 9.05974 11.717 9.12125C11.8044 9.18276 11.8758 9.2644 11.9251 9.35929C11.9744 9.45418 12.0001 9.55954 12.0001 9.66646Z"
						fill={color}
					/>
					<path
						id="Vector_3"
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M14.0002 9.49935C14.0002 10.2508 13.7017 10.9715 13.1703 11.5028C12.6389 12.0342 11.9183 12.3327 11.1668 12.3327C10.4154 12.3327 9.69471 12.0342 9.16336 11.5028C8.63201 10.9715 8.3335 10.2508 8.3335 9.49935C8.3335 8.7479 8.63201 8.02723 9.16336 7.49588C9.69471 6.96453 10.4154 6.66602 11.1668 6.66602C11.9183 6.66602 12.6389 6.96453 13.1703 7.49588C13.7017 8.02723 14.0002 8.7479 14.0002 9.49935ZM13.3335 9.49935C13.3335 10.074 13.1052 10.6251 12.6989 11.0314C12.2926 11.4377 11.7415 11.666 11.1668 11.666C10.5922 11.666 10.0411 11.4377 9.63476 11.0314C9.22844 10.6251 9.00016 10.074 9.00016 9.49935C9.00016 8.92471 9.22844 8.37361 9.63476 7.96728C10.0411 7.56096 10.5922 7.33268 11.1668 7.33268C11.7415 7.33268 12.2926 7.56096 12.6989 7.96728C13.1052 8.37361 13.3335 8.92471 13.3335 9.49935Z"
						fill={color}
					/>
				</g>
			</g>
		</svg>
	);
};
export default BeatsPerMinuteIcon;
