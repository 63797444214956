import React from 'react';

const IconMemory = () => {
	return (
		<svg
			width="30"
			height="30"
			viewBox="0 0 30 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle cx="15" cy="15" r="15" fill="#E2EAFF" />
			<path
				d="M15.2286 7C15.7742 7 16.2974 7.21053 16.6832 7.58526C17.069 7.96 17.2857 8.46826 17.2857 8.99822L17.2847 9.05417H20.3714C20.6442 9.05417 20.9058 9.15943 21.0987 9.3468C21.2916 9.53417 21.4 9.7883 21.4 10.0533L21.399 12.9947H20.3714C19.8524 12.9945 19.3526 13.1849 18.972 13.5277C18.5915 13.8705 18.3584 14.3403 18.3194 14.843L18.3143 14.9929C18.3141 15.497 18.5101 15.9826 18.863 16.3522C19.2159 16.7219 19.6996 16.9483 20.2171 16.9861L20.3714 16.9911L21.399 16.9901L21.4 20.0444C21.4 20.3093 21.2916 20.5635 21.0987 20.7508C20.9058 20.9382 20.6442 21.0435 20.3714 21.0435H17.2826L17.2806 21.1364C17.2449 21.6498 17.0066 22.1301 16.6155 22.4772C16.2243 22.8242 15.7104 23.0113 15.1807 22.9995C14.6511 22.9876 14.1466 22.7777 13.7723 22.4136C13.398 22.0494 13.1827 21.559 13.1714 21.0445H10.0857C9.81292 21.0445 9.5513 20.9392 9.3584 20.7518C9.16551 20.5645 9.05714 20.3103 9.05714 20.0454V16.9911C8.51155 16.9911 7.98831 16.7806 7.60252 16.4058C7.21673 16.0311 7 15.5228 7 14.9929C7 14.4629 7.21673 13.9547 7.60252 13.5799C7.98831 13.2052 8.51155 12.9947 9.05714 12.9947V10.0533C9.05714 9.7883 9.16551 9.53417 9.3584 9.3468C9.5513 9.15943 9.81292 9.05417 10.0857 9.05417H13.1704L13.1714 8.99822C13.1714 8.46826 13.3882 7.96 13.774 7.58526C14.1597 7.21053 14.683 7 15.2286 7Z"
				fill="#5784F7"
			/>
		</svg>
	);
};

export default IconMemory;
