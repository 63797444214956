import React from 'react';
import { IconProps } from './IconInterface';

const RespiratoryIcon = ({
	color = '#000000',
	width = 24,
	height = 24,
}: IconProps) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M11.1647 11.3778C11.1794 10.0055 11.208 7.33313 9.0928 7.33313C6.19528 7.33313 4.12559 17.447 5.3674 18.4791C6.60921 19.5111 9.0928 18.8919 10.3346 17.6534C11.3372 16.6536 11.2505 14.3172 11.1882 12.64C11.1748 12.2786 11.1625 11.9478 11.1625 11.6676C11.1625 11.5798 11.1635 11.4826 11.1647 11.3778Z"
				fill={color}
			/>
			<path
				d="M9.81876 8.37818C9.98731 8.55171 10.1281 8.83078 10.227 9.22509C10.4 9.91538 10.3926 10.7096 10.3867 11.3503C10.3857 11.4611 10.3847 11.5673 10.3847 11.6676C10.3847 11.9632 10.3978 12.3171 10.4115 12.6853C10.4169 12.8303 10.4223 12.9775 10.4271 13.1242C10.4445 13.659 10.4544 14.2189 10.4286 14.7605C10.4027 15.3051 10.3416 15.8048 10.2276 16.2233C10.1123 16.6467 9.95791 16.9307 9.78539 17.1027C9.28834 17.5984 8.50346 17.9988 7.70295 18.1541C6.91154 18.3076 6.27444 18.194 5.90131 17.9101C5.89938 17.9057 5.89722 17.9007 5.89485 17.8947C5.87183 17.8372 5.8439 17.7384 5.8212 17.5879C5.77576 17.2865 5.76394 16.866 5.79459 16.348C5.85555 15.3175 6.07758 14.0102 6.42827 12.7236C6.78033 11.4321 7.24864 10.211 7.77957 9.33153C8.04534 8.89125 8.3073 8.5694 8.55033 8.36519M5.91183 17.9311C5.91183 17.9311 5.91146 17.9306 5.91079 17.9294M11.1647 11.3778C11.1794 10.0055 11.208 7.33313 9.0928 7.33313C6.19528 7.33313 4.12559 17.447 5.3674 18.4791C6.60921 19.5111 9.0928 18.8919 10.3346 17.6534C11.3372 16.6536 11.2505 14.3172 11.1882 12.64C11.1748 12.2786 11.1625 11.9478 11.1625 11.6676C11.1625 11.5798 11.1635 11.4826 11.1647 11.3778Z"
				stroke={color}
				stroke-width="0.5"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M12.8353 11.3778C12.8206 10.0055 12.792 7.33313 14.9072 7.33313C17.8047 7.33313 19.8744 17.447 18.6326 18.4791C17.3908 19.5111 14.9072 18.8919 13.6653 17.6534C12.6628 16.6536 12.7495 14.3172 12.8118 12.64C12.8252 12.2786 12.8375 11.9478 12.8375 11.6676C12.8375 11.5798 12.8364 11.4826 12.8353 11.3778Z"
				fill={color}
			/>
			<path
				d="M14.1812 8.37818C14.0127 8.55171 13.8719 8.83078 13.773 9.22509C13.6 9.91538 13.6074 10.7096 13.6133 11.3503C13.6143 11.4611 13.6153 11.5673 13.6153 11.6676C13.6153 11.9632 13.6021 12.3171 13.5885 12.6853C13.5831 12.8303 13.5776 12.9775 13.5729 13.1242C13.5554 13.659 13.5456 14.2189 13.5713 14.7605C13.5973 15.3051 13.6583 15.8048 13.7724 16.2233C13.8877 16.6467 14.0421 16.9307 14.2146 17.1027C14.7116 17.5984 15.4965 17.9988 16.297 18.1541C17.0884 18.3076 17.7255 18.194 18.0987 17.9101C18.1006 17.9057 18.1028 17.9007 18.1051 17.8947C18.1282 17.8372 18.1561 17.7384 18.1788 17.5879C18.2242 17.2865 18.236 16.866 18.2054 16.348C18.1444 15.3175 17.9224 14.0102 17.5717 12.7236C17.2197 11.4321 16.7513 10.211 16.2204 9.33153M18.0881 17.9311C18.0881 17.9311 18.0885 17.9306 18.0892 17.9294M12.8353 11.3778C12.8206 10.0055 12.792 7.33313 14.9072 7.33313C17.8047 7.33313 19.8744 17.447 18.6326 18.4791C17.3908 19.5111 14.9072 18.8919 13.6653 17.6534C12.6628 16.6536 12.7495 14.3172 12.8118 12.64C12.8252 12.2786 12.8375 11.9478 12.8375 11.6676C12.8375 11.5798 12.8364 11.4826 12.8353 11.3778Z"
				stroke={color}
				stroke-width="0.5"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M11.6109 4.99981C11.6109 4.99977 11.6109 4.99976 11.9998 4.99976C12.3887 4.99976 12.3887 4.99977 12.3887 4.99981V10.6388C12.3887 11.4564 12.0247 11.9252 11.6124 12.1657C11.4189 12.2786 11.2289 12.3333 11.0896 12.3604C11.0192 12.3741 10.9595 12.3812 10.9155 12.3848C10.8935 12.3867 10.8752 12.3877 10.8613 12.3882C10.8543 12.3885 10.8485 12.3886 10.8438 12.3887L10.8375 12.3888L10.8351 12.3888H10.834H10.8335C10.8333 12.3888 10.8331 12.3888 10.8331 11.9999C10.8331 11.611 10.8329 11.611 10.8326 11.611H10.8323H10.8315H10.8303L10.8291 11.6111C10.8291 11.6111 10.8297 11.611 10.8311 11.611C10.8343 11.6109 10.8411 11.6105 10.8509 11.6097C10.8708 11.6081 10.9022 11.6045 10.9411 11.5969C11.0206 11.5815 11.1223 11.5512 11.2205 11.4939C11.3915 11.3941 11.6109 11.1824 11.6109 10.6388V4.99981Z"
				fill={color}
				stroke={color}
				stroke-width="0.5"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M12.3891 4.99981C12.3891 4.99977 12.3891 4.99976 12.0002 4.99976C11.6113 4.99976 11.6113 4.99977 11.6113 4.99981V10.6388C11.6113 11.4564 11.9753 11.9252 12.3876 12.1657C12.5811 12.2786 12.7711 12.3333 12.9104 12.3604C12.9808 12.3741 13.0406 12.3812 13.0845 12.3848C13.1066 12.3867 13.1248 12.3877 13.1387 12.3882C13.1457 12.3885 13.1516 12.3886 13.1563 12.3887L13.1625 12.3888L13.1649 12.3888H13.166H13.1665C13.1667 12.3888 13.1669 12.3888 13.1669 11.9999C13.1669 11.611 13.1672 11.611 13.1674 11.611H13.1678H13.1685H13.1697L13.1709 11.6111C13.1709 11.6111 13.1703 11.611 13.1689 11.611C13.1658 11.6109 13.159 11.6105 13.1491 11.6097C13.1292 11.6081 13.0979 11.6045 13.0589 11.5969C12.9794 11.5815 12.8778 11.5512 12.7795 11.4939C12.6085 11.3941 12.3891 11.1824 12.3891 10.6388V4.99981Z"
				fill={color}
				stroke={color}
				stroke-width="0.5"
			/>
		</svg>
	);
};
export default RespiratoryIcon;
