import {
	StyledColumn,
	StyledRow,
	StyledScreen,
} from '../../common/styledCommonComponents';
import React, { useCallback, useEffect } from 'react';
import PersonalForm from './PersonalForm';
import HospitalForm from './HospitalForm';
import BiomarkerForm from './BiomarkerForm';
import SetbackForm from './SetbackForm';
import ProgressionForm from './ProgressionForm';
import Button from '../../common/components/Button';
import { useOncoDispatch, useOncoSelector } from '../../hooks/redux-hook';
import {
	BiomarkerInterface,
	cleanValidationForm,
	removeAdditionalField,
	setValidationErrors,
	valuesLastTreatment,
} from '../../redux/slices/validationSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetPatientMedicDataQuery } from '../../redux/api/patientApi';
import {
	transformUpdateValidation,
	transformValidateBody,
	validateForm,
} from './utils';
import { StyledCircularProgress } from '../../components/CustomCircularProgress/styles';
import {
	useCreateTreatmentFormMutation,
	usePostFormMutation,
	useUpdateTreatmentFormMutation,
	useUpdateValidatePatientMutation,
} from '../../redux/api/validateFormApi';
import { setModalOpen } from '../../redux/slices/utilsSlice';
import { ModalTypeEnum } from '../../utils/utils';

const ValidationForm = () => {
	const { patientId } = useParams();
	const { isLoading: isLoadingPatiendData } = useGetPatientMedicDataQuery(
		patientId,
		{
			refetchOnMountOrArgChange: true,
		},
	);
	const { values, validations, auxiliarData } = useOncoSelector(
		(state) => state.validationSlice,
	);

	const [
		postForm,
		{ isLoading: isLoadingPostForm, isSuccess: isSuccessPostForm },
	] = usePostFormMutation();

	const [
		createTreatment,
		{
			isLoading: isLoadingCreateTreatment,
			isSuccess: isSuccessCreateTreatment,
		},
	] = useCreateTreatmentFormMutation();

	const [
		updateTreatment,
		{
			isLoading: isLoadingUpdateTreatment,
			isSuccess: isSuccessUpdateTreatment,
		},
	] = useUpdateTreatmentFormMutation();

	const categories = ['biomarkers', 'setbacks', 'progressions'];

	// @ts-ignore
	const { prefix, treatment } = useOncoSelector((state) =>
		valuesLastTreatment(state),
	);

	const dispatch = useOncoDispatch();
	const [submit, { isLoading: isLoadingSubmit, isSuccess }] =
		useUpdateValidatePatientMutation();

	const navigation = useNavigate();

	const validateFormCallback = useCallback(validateForm, []);

	const validateSubmit = async (valuesToEv: any, validationsRules: any) => {
		const validation = validateFormCallback(valuesToEv, validationsRules);
		const userId = valuesToEv.id;

		const translatedValues = transformValidateBody(auxiliarData, {
			...valuesToEv,
			primaryTumor:
				auxiliarData?.primaryTumor[valuesToEv.primaryTumor] ??
				valuesToEv.primaryTumor,
			histologyType:
				auxiliarData?.histologyType[valuesToEv.histologyType] ??
				valuesToEv.histologyType,
			histologySubtype:
				auxiliarData?.histologySubtype[valuesToEv.histologySubtype] ??
				valuesToEv.histologySubtype,
			stage: auxiliarData?.stage[valuesToEv.stage] ?? valuesToEv.stage,
			biomarkers: valuesToEv.biomarkers.map((item: BiomarkerInterface) => ({
				...item,
				sample: auxiliarData?.samples[item.sampleId] ?? item.sampleId,
				biomarker:
					auxiliarData?.biomarkers[item.biomarkerId] ?? item.biomarkerId,
				evaluation:
					auxiliarData?.evaluations[item.evaluationId] ??
					item.evaluationId,
			})),

			userId: userId,
		});
		if (
			validation?.find(
				(item: any) =>
					item?.error?.length > 0 ||
					item?.properties?.find((prop: any) => prop?.error?.length > 0),
			)
		) {
			dispatch(setValidationErrors({ validation }));
		} else {
			if (values.status === 'ACTIVE') {
				let fieldsToPush: {
					biomarkers: any[];
					setbacks: any[];
					progressions: any[];
				} = {
					biomarkers: [],
					setbacks: [],
					progressions: [],
				};

				const shouldUpdateTheTreatment =
					auxiliarData?.additionalFields.find(
						({ prefix, id }: { prefix: string; id: string }) =>
							prefix === 'treatment',
					);
				if (shouldUpdateTheTreatment) {
					await updateTreatment({
						userId: userId,
						finishDate:
							shouldUpdateTheTreatment.finishDate ??
							values.treatment.finishDate,
						endingMotive:
							shouldUpdateTheTreatment.endingMotive ??
							values.treatment.endingMotive,
						otherMotive:
							shouldUpdateTheTreatment.otherMotive ||
							values.treatment?.otherMotive ||
							'',
					});
				}

				auxiliarData?.additionalFields.forEach(
					({ prefix, id }: { prefix: string; id: string }) => {
						if (prefix !== 'treatment') {
							const prefixTyped = prefix as
								| 'setbacks'
								| 'progressions'
								| 'biomarkers';
							// @ts-ignore
							const fieldArray: [] = valuesToEv[prefixTyped];

							const fieldToAdd: any = fieldArray?.find(
								(item: any) => item.id === id,
							);
							// @ts-ignore
							if (fieldToAdd) {
								fieldsToPush[prefixTyped] = [
									...fieldsToPush[prefixTyped],
									transformUpdateValidation({
										type: prefixTyped,
										fields: fieldToAdd,
										values: valuesToEv,
										auxiliarData,
									}),
								];
							}
						}
						dispatch(removeAdditionalField({ id }));
					},
				);

				for (const types of categories) {
					// @ts-ignore
					for (const body of fieldsToPush[types]) {
						const type = types.slice(0, types.length - 1);
						await postForm({
							specType: type.toUpperCase(),
							body: {
								userId: userId,
								[type]: body,
							},
						});
					}
				}
				// removeAllAdditionalField();
			} else {
				if (values.status === 'WAITING') {
					submit(translatedValues);
					// removeAllAdditionalField();
				}
			}
			// if (isSuccess && isSuccessPostForm && isSuccessUpdateTreatment)
			// removeAllAdditionalField();
		}
	};

	const handleRemoveUser = () =>
		dispatch(setModalOpen({ type: ModalTypeEnum.REMOVE_PATIENT }));

	useEffect(() => {
		if (isSuccess) {
			navigation('/my-patients');
			dispatch(cleanValidationForm({}));
		}
	}, [isSuccess]);

	return (
		<StyledScreen css={{ flexDirection: 'column', alignItems: 'center' }}>
			{isLoadingPatiendData ? (
				<StyledColumn
					css={{
						width: 870,
						backgroundColor: 'white',
						alignItems: 'center',
						justifyContent: 'center',
						flex: 1,
						borderRadius: 20,
						margin: '40px 0',
					}}
				>
					<StyledCircularProgress />
				</StyledColumn>
			) : (
				<>
					<StyledColumn
						css={{
							width: 870,
							gap: 24,
							backgroundColor: 'white',
							borderRadius: 20,
							margin: '40px 0',
						}}
					>
						<PersonalForm />
						<HospitalForm />
						<BiomarkerForm />
						<SetbackForm />
						<ProgressionForm />
					</StyledColumn>
					<StyledRow
						css={{
							gap: 16,
							padding: '24px 0',
							width: 870,
							justifyContent: 'flex-end',
						}}
					>
						<Button
							variant={'outlined'}
							size={'medium'}
							disabled={isLoadingSubmit || isLoadingPostForm}
							css={{
								width: 120,
							}}
							onClick={() => navigation(-1)}
						>
							Cancelar
						</Button>
						{values.status !== 'WAITING' && (
							<Button
								variant={'destructive'}
								size={'medium'}
								disabled={isLoadingSubmit || isLoadingPostForm}
								css={{
									width: 120,
								}}
								onClick={() => handleRemoveUser()}
							>
								Rechazar
							</Button>
						)}
						<Button
							variant={'filled'}
							size={'medium'}
							disabled={isLoadingSubmit || isLoadingPostForm}
							onClick={() => validateSubmit(values, validations)}
							css={{
								width: 120,
							}}
						>
							{values.status === 'WAITING' ? 'Aceptar' : 'Guardar'}
						</Button>
					</StyledRow>
				</>
			)}
		</StyledScreen>
	);
};

export default ValidationForm;
