import { ValidationType } from './index';

export const transform = (errorCode: ValidationType) => {
	switch (errorCode) {
		case ValidationType.IS_REQUIRED:
			return 'Este campo es requerido';
		case ValidationType.IS_STRING:
			return 'Este campo debe ser un texto';
		case ValidationType.IS_EMAIL:
			return 'Este campo debe ser un email válido';
		case ValidationType.IS_NUMBER:
			return 'Este campo debe ser un número';
		case ValidationType.IS_POSITIVE:
			return 'Este campo debe ser un número positivo';
		case ValidationType.IS_NEGATIVE:
			return 'Este campo debe ser un número negativo';
		case ValidationType.IS_BOOLEAN:
			return 'Este campo debe ser verdadero o falso';
		case ValidationType.IS_DATE:
			return 'Este campo debe ser una fecha válida';
		case ValidationType.IS_PRESENT_DATE:
			return 'Este campo debe ser la fecha de hoy';
		case ValidationType.IS_PAST_DATE:
			return 'Este campo debe ser una fecha pasada';
		case ValidationType.IS_FUTURE_DATE:
			return 'Este campo debe ser una fecha futura';
		case ValidationType.IS_PRESENT_PAST_DATE:
			return 'Este campo debe ser una fecha pasada o presente';
	}
};
