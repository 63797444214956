import React from 'react';
import { useTheme } from 'styled-components';
import {
	StyledRow,
	StyledBox,
} from '../../../../../../common/styledCommonComponents';
import ColorIcon from '../../../../../../assets/ColorIcon';
import StyledText from '../../../../../../common/components/Text';
import Person2FillIcon from '../../../../../../assets/Person2FillIcon';
import { FrequentMedication } from '..';

export const FreqMedicationHeader = ({
	color,
	medicationName,
	patients,
}: FrequentMedication) => {
	const theme = useTheme();
	return (
		<StyledRow
			css={{
				padding: '8px',
				justifyContent: 'space-between',
				alignItems: 'center',
				alignSelf: 'stretch',
			}}
		>
			<StyledBox
				css={{
					display: 'flex',
					gap: 8,
					alignItems: 'center',
				}}
			>
				<ColorIcon color={color} />
				<StyledText variant="body2">{medicationName}</StyledText>
			</StyledBox>
			<StyledBox
				css={{
					display: 'flex',
					alignItems: 'center',
					gap: 2,
				}}
			>
				<StyledBox
					css={{
						display: 'flex',
						width: 18,
						height: 18,
						padding: 1.5,
						justifycontent: 'center',
						alignItems: 'center',
					}}
				>
					<Person2FillIcon color={theme.gray500} />
				</StyledBox>

				<StyledText variant="body2">{patients.length}</StyledText>
			</StyledBox>
		</StyledRow>
	);
};
