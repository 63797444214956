import {
	LoginFormSection,
	LoginImageSection,
	LoginWrapper,
} from '../Login/StyledLoginScreen';
import { LoginImage } from '../../assets/LoginImage';
import React, { useEffect } from 'react';
import {
	StyledBox,
	StyledColumn,
	StyledRow,
} from '../../common/styledCommonComponents';
import ChevronLeftIcon from '../../assets/ChevronLeftIcon';
import { useTheme } from 'styled-components';
import StyledText from '../../common/components/Text';
import Button from '../../common/components/Button';
import { useOncoDispatch } from '../../hooks/redux-hook';
import { setModalOpen } from '../../redux/slices/utilsSlice';
import { ModalTypeEnum } from '../../utils/utils';
import { useAuth0 } from '@auth0/auth0-react';
import { withModal } from '../../components/HOC/withModal';

const Register = () => {
	const theme = useTheme();
	const dispatch = useOncoDispatch();
	const { logout } = useAuth0();

	const handleGoBack = async () => {
		await logout({
			openUrl() {
				logout({
					logoutParams: {
						returnTo: window.location.origin + '/login',
					},
				});
			},
		});
	};

	const handleOpenAuxiliar = () => {
		dispatch(
			setModalOpen({
				open: true,
				type: ModalTypeEnum.AUXILIAR_MODAL,
			}),
		);
	};

	const handleOpenDoctor = () => {
		dispatch(
			setModalOpen({
				open: true,
				type: ModalTypeEnum.CREATE_DOCTOR_MODAL,
			}),
		);
	};
	return (
		<LoginWrapper>
			<LoginFormSection>
				<StyledRow
					css={{
						alignItems: 'flex-start',
						gap: 4,
						maxWidth: '300px',
						width: '100%',
					}}
				>
					<StyledBox
						css={{
							borderRight: `1px solid ${theme.gray100}`,
							paddingRight: 4,
							cursor: 'pointer',
						}}
						onClick={handleGoBack}
					>
						<ChevronLeftIcon
							color={theme.gray800}
							width={24}
							height={24}
						/>
					</StyledBox>
					<StyledColumn
						css={{ gap: 16, width: '100%', maxWidth: '350px' }}
					>
						<StyledText
							variant={'h5'}
							css={{ marginBottom: 16, textAlign: 'center' }}
						>
							Elige tu rol
						</StyledText>
						<Button
							variant={'outlined'}
							size={'medium'}
							css={{ width: '100%' }}
							onClick={handleOpenDoctor}
						>
							Soy Médico
						</Button>
						<Button
							variant={'outlined'}
							size={'medium'}
							css={{ width: '100%' }}
							onClick={handleOpenAuxiliar}
						>
							Soy Auxiliar
						</Button>
					</StyledColumn>
				</StyledRow>
			</LoginFormSection>
			<LoginImageSection>
				<LoginImage />
			</LoginImageSection>
		</LoginWrapper>
	);
};

export default withModal(Register);
