import Modal from '../Modal';
import {
	StyledBox,
	StyledRow,
	StyledColumn,
} from '../../../../../common/styledCommonComponents';
import React, { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { useOncoSelector } from '../../../../../hooks/redux-hook';
import StyledText from '../../../../../common/components/Text';
import { StyledCircularProgress } from '../../../../CustomCircularProgress/styles';
import { StyledH5 } from '../../../../../common/components/Text/styles';
import { biometricMockedData } from '../../../../../utils/mockedData';
import { categoryBiometric } from '../../../../../utils/proctcae';
import { PatientListComponent } from '../../PatientListComponent';
import TwoPersonIcon from '../../../../../assets/TwoPersonIcon';
import HeaderBiometric from './components/HeaderBiometric';

interface FreqSymtomsModalProps {
	handleOnClose: () => void;
}

export type BiometricData = {
	type: string;
	symptom: string;
	patients: {
		id: string;
		patientProfileId: string;
		name: string;
		surname: string;
	}[];
};

const FreqSymptomsModal = ({ handleOnClose }: FreqSymtomsModalProps) => {
	const { medicationsFilter } = useOncoSelector(
		(state: any) => state.homeSlice,
	);
	const { symptom } = useOncoSelector((state: any) => state.utilsSlice);
	const frequentSymptomData = useOncoSelector(
		(state: any) => state.utilsSlice.frequentSymptomData,
	);
	const [medicationText, setMedicationText] = useState('Cargando...');
	const [isLoading, setIsLoading] = useState(false);
	// const [biometricData, setBiometricData] = useState<
	// 	BiometricData | undefined
	// >(undefined);

	// const filterBySymptom = () =>
	// 	biometricMockedData.find((obj: BiometricData) => obj.symptom === symptom);
	// useEffect(() => {
	// 	setBiometricData(filterBySymptom);
	// }, [filterBySymptom]);

	useEffect(() => {
		if (medicationsFilter.length === 0) {
			setMedicationText('No hay filtros');
		} else {
			if (medicationsFilter.length === 1) {
				setMedicationText(medicationsFilter[0]);
			} else {
				const medications = medicationsFilter
					.slice(0, medicationsFilter.length - 1)
					.join(', ');
				setMedicationText(
					`${medications} y ${
						medicationsFilter[medicationsFilter.length - 1]
					}`,
				);
			}
		}
	}, [medicationsFilter]);

	return (
		<Modal
			width={539}
			// height={'420'}
			onClose={handleOnClose}
			header={
				<StyledColumn
					css={{
						alignItems: 'flex-start',
						gap: '8px',
						alignSelf: 'stretch',
					}}
				>
					<StyledH5>Síntomas frecuentes Calidad de Vida</StyledH5>
					<StyledText variant="body2">
						Medicamentos: {medicationText}
					</StyledText>
				</StyledColumn>
			}
			body={
				isLoading ? (
					<StyledBox
						css={{
							height: '154px',
							width: '100%',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<StyledCircularProgress />
					</StyledBox>
				) : (
					<PatientListComponent
						header={<HeaderBiometric data={frequentSymptomData} />}
						patients={frequentSymptomData?.patients ?? []}
					/>
				)
			}
		/>
	);
};

export default FreqSymptomsModal;
