import React from 'react';
import { IconProps } from './IconInterface';

const GenitalIcon = ({
	width = 24,
	height = 24,
	color = '#0E0E0E',
}: IconProps) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M18.9994 11.8027C18.8955 11.8816 18.8003 12.0091 18.6883 12.0324C17.4862 12.2771 16.5162 12.9598 15.6451 13.8024C14.509 14.9025 13.6006 16.1671 13.1377 17.7351C13.1078 17.8373 13.0879 17.9428 13.0636 18.047C13.0083 18.2845 12.8645 18.4269 12.6281 18.4353C12.2133 18.4457 11.7985 18.4472 11.3837 18.4398C11.1162 18.434 10.9911 18.2515 10.9196 18.0004C10.1829 15.4202 8.59068 13.5921 6.31532 12.3793C6.01791 12.2208 5.67073 12.1645 5.35092 12.0493C5.23026 11.9974 5.11303 11.9373 5 11.8694V11.5458C5.12444 10.8986 5.25448 10.2586 5.38078 9.61987H18.618C18.6429 9.73248 18.669 9.83537 18.6889 9.93956C18.7934 10.497 18.8971 11.0539 19 11.6105L18.9994 11.8027Z"
				fill={color}
			/>
			<path
				d="M18.4867 8.87507H5.51709C5.57931 8.53014 5.64153 8.19686 5.70064 7.86423C5.73672 7.67009 5.84623 7.56072 6.0416 7.55878C6.13493 7.55878 6.22826 7.55554 6.32159 7.55554H17.6667C18.2198 7.55554 18.2211 7.55554 18.3368 8.10885C18.3891 8.35865 18.4351 8.61233 18.4867 8.87507Z"
				fill={color}
			/>
		</svg>
	);
};

export default GenitalIcon;
