import styled from 'styled-components';
import { StyledBox } from '../../../../common/styledCommonComponents';

export const StyledPatientWaitingContainer = styled(StyledBox)`
	justify-content: flex-start;
	display: flex;
	flex-direction: row;
	border-radius: 8px;
	cursor: pointer;
	border: solid 1px ${(props) => props.theme.gray50};
	margin-top: 6px;
	padding: 7px 12px 8px;
	background-color: white;
	height: 45px;
	box-sizing: border-box;
	&:hover {
		background-color: ${(props) => props.theme.gray50};
	}
`;
