import React from 'react';
import Modal from '../Modal';
import StyledText from '../../../../../common/components/Text';
import { StyledColumn } from '../../../../../common/styledCommonComponents';
import { useTheme } from 'styled-components';
import Body from './components/Body';
import { useOncoSelector } from '../../../../../hooks/redux-hook';

interface SummaryQuestionariesModalProps {
	handleOnClose: () => void;
}

const SummaryQuestionariesModal = ({
	handleOnClose,
}: SummaryQuestionariesModalProps) => {
	const theme = useTheme();
	const date = useOncoSelector((state) => state.utilsSlice.payload.date);

	return (
		<Modal
			width={'539px'}
			header={
				<StyledColumn css={{ gap: 8 }}>
					<StyledText variant="h5" css={{ color: theme.black }}>
						Proporción de pacientes por estadío
					</StyledText>
					<StyledText variant={'body2'} css={{ color: theme.black }}>
						Fecha: {date.getDate()}/{date.getMonth() + 1}/
						{date.getFullYear()}
					</StyledText>
				</StyledColumn>
			}
			body={<Body />}
			onClose={handleOnClose}
		/>
	);
};

export default SummaryQuestionariesModal;
