import {
	StyledColumn,
	StyledRow,
} from '../../../../../../../common/styledCommonComponents';
import React from 'react';
import PatientItem from '../../../SummaryQuestionariesModal/components/PatientItem';
import { PatientListComponent } from '../../../../PatientListComponent';
import { useTheme } from 'styled-components';
import { useOncoSelector } from '../../../../../../../hooks/redux-hook';
import ColorIcon from '../../../../../../../assets/ColorIcon';
import StyledText from '../../../../../../../common/components/Text';
import TwoPersonIcon from '../../../../../../../assets/TwoPersonIcon';

const Body = () => {
	const theme = useTheme();
	const payload = useOncoSelector((state) => state.utilsSlice.payload);
	return (
		<StyledColumn css={{ width: '100%' }}>
			<PatientListComponent
				header={
					<StyledRow
						css={{
							alignItems: 'center',
							justifyContent: 'space-between',
							width: '100%',
							height: 34,
							padding: '0 8px',
						}}
					>
						<StyledRow css={{ gap: 8, alignItems: 'center' }}>
							<ColorIcon color={payload.color} />
							<StyledText variant={'body2'}>
								{payload.data.key}
							</StyledText>
						</StyledRow>
						<StyledRow css={{ gap: 2, alignItems: 'center' }}>
							<TwoPersonIcon
								width={18}
								height={18}
								color={theme.gray500}
							/>
							<StyledText variant={'body2'}>
								{payload.data.metadata.malesQnt +
									payload.data.metadata.femalesQnt}
							</StyledText>
						</StyledRow>
					</StyledRow>
				}
				patients={[
					...payload.data.metadata.males,
					...payload.data.metadata.females,
				]}
				patientComponent={PatientItem}
			/>
		</StyledColumn>
	);
};

export default Body;
