import React from 'react';
import Modal from '../Modal';
import StyledText from '../../../../../common/components/Text';
import { StyledColumn } from '../../../../../common/styledCommonComponents';
import { useTheme } from 'styled-components';
import { useOncoSelector } from '../../../../../hooks/redux-hook';
import Body from './components/Body';

interface TumorProportionModalProps {
	handleOnClose: () => void;
}

const TumorProportionModal = ({ handleOnClose }: TumorProportionModalProps) => {
	const theme = useTheme();
	return (
		<Modal
			width={'539px'}
			header={
				<StyledColumn css={{ gap: 8 }}>
					<StyledText variant="h5" css={{ color: theme.black }}>
						Proporción de tumores primarios
					</StyledText>
				</StyledColumn>
			}
			body={<Body />}
			onClose={handleOnClose}
		/>
	);
};

export default TumorProportionModal;
