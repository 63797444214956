import React from 'react';
import { StyledColumn } from '../../../../../../../common/styledCommonComponents';
import { PatientListComponent } from '../../../../PatientListComponent';
import { useTheme } from 'styled-components';
import PatientItem from '../PatientItem';
import { useOncoSelector } from '../../../../../../../hooks/redux-hook';
import CardHeader from '../CardHeader';
import { ReportStatus } from '../../../../../../../redux/api/types';

const Body = () => {
	const theme = useTheme();
	const forms = useOncoSelector((state) => state.utilsSlice.payload.patients);

	let sortOrder = ['COMPLETE', 'EMPTY', 'INCOMPLETE'];
	return (
		<StyledColumn css={{ gap: 16, width: 475, padding: '24px 0' }}>
			<PatientListComponent
				header={
					<CardHeader
						patients={forms.daily}
						title={'Estado General'}
						color={theme.pink500}
					/>
				}
				patients={[...forms.daily]?.sort(
					(a: { status: ReportStatus }, b: { status: ReportStatus }) => {
						return (
							sortOrder.indexOf(a.status) - sortOrder.indexOf(b.status)
						);
					},
				)}
				patientComponent={PatientItem}
			/>
			<PatientListComponent
				header={
					<CardHeader
						patients={forms.weekly}
						title={'Calidad de vida'}
						color={theme.blue500}
					/>
				}
				patients={[...forms.weekly]?.sort(
					(a: { status: ReportStatus }, b: { status: ReportStatus }) => {
						return (
							sortOrder.indexOf(a.status) - sortOrder.indexOf(b.status)
						);
					},
				)}
				patientComponent={PatientItem}
			/>
			<PatientListComponent
				header={
					<CardHeader
						patients={forms.biometric}
						title={'Datos biométricos'}
						color={theme.green500}
					/>
				}
				patients={[...forms.biometric]?.sort(
					(a: { status: ReportStatus }, b: { status: ReportStatus }) => {
						return (
							sortOrder.indexOf(a.status) - sortOrder.indexOf(b.status)
						);
					},
				)}
				patientComponent={PatientItem}
			/>
		</StyledColumn>
	);
};
export default Body;
