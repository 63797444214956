import React from 'react';
import Modal from '../Modal';
import {
	StyledBox,
	StyledColumn,
} from '../../../../../common/styledCommonComponents';
import { StyledCircularProgress } from '../../../../CustomCircularProgress/styles';
import { useOncoSelector } from '../../../../../hooks/redux-hook';
import StyledText from '../../../../../common/components/Text';
import { useRemoveAuxiliarDoctorMutation } from '../../../../../redux/api/listApi';
import { StyledButton } from '../../../../../common/components/Button/styles';
import {
	toastError,
	toastSuccess,
} from '../../../../../pages/DoctorsAcceptanceList/utils';

interface RemoveAuxiliarDoctorModalProps {
	handleOnClose: () => void;
}

const RemoveAuxiliarDoctorModal = ({
	handleOnClose,
}: RemoveAuxiliarDoctorModalProps) => {
	const { name, assistantProfileId } = useOncoSelector(
		(state) => state.utilsSlice.payload,
	);

	const [removeAuxiliarDoctor, { isLoading, error, isSuccess }] =
		useRemoveAuxiliarDoctorMutation();
	const handleRemoveClick = (doctorId: string) => {
		removeAuxiliarDoctor(doctorId);
		handleOnClose();
		if (isSuccess) toastSuccess('Doctor removido');
		if (error) toastError('Error al remover doctor');
	};

	return (
		<Modal
			onClose={handleOnClose}
			width={388}
			header={
				<StyledColumn
					css={{
						alignItems: 'center',
						marginTop: 16,
						width: '100%',
						gap: 16,
					}}
				>
					<StyledText variant="h5" css={{ color: '#000' }}>
						Remover auxiliar
					</StyledText>
					<StyledText
						variant="body1"
						css={{ textAlign: 'center', color: '#000' }}
					>
						¿Estás seguro que quieres remover a {name} de tu equipo?
					</StyledText>
				</StyledColumn>
			}
			body={
				<StyledBox
					css={{
						display: 'flex',
						justifyContent: 'center',
						width: '100%',
						gap: 16,
					}}
				>
					<StyledButton
						css={{ width: 130, justifyContent: 'center' }}
						variant="outlined"
						onClick={() => handleOnClose()}
					>
						Cancelar
					</StyledButton>
					<StyledButton
						css={{ width: 130, justifyContent: 'center' }}
						variant="filled"
						onClick={() => handleRemoveClick(assistantProfileId)}
						disabled={isLoading}
					>
						Remover
					</StyledButton>
				</StyledBox>
			}
		/>
	);
};

export default RemoveAuxiliarDoctorModal;
