import React from 'react';
import {
	StyledBox,
	StyledColumn,
} from '../../../../common/styledCommonComponents';
import StyledText from '../../../../common/components/Text';
import OralIcon from '../../../../assets/OralIcon';
import { useTheme } from 'styled-components';
import { categoryBiometric } from '../../../../utils/proctcae';
import { useOncoDispatch } from '../../../../hooks/redux-hook';
import { setModalOpen } from '../../../../redux/slices/utilsSlice';
import { ModalTypeEnum } from '../../../../utils/utils';

interface ExtraCategoryCardProps {
	extraData: {
		answers: number;
		categoriesAmount: number;
	};
	formId: string;
}

export const ExtraCategoryCard = ({
	extraData,
	formId,
}: ExtraCategoryCardProps) => {
	const theme = useTheme();
	const dispatch = useOncoDispatch();

	const handleOnClick = () => {
		dispatch(
			setModalOpen({
				type: ModalTypeEnum.WEEKLY_MODAL,
				id: formId,
			}),
		);
	};

	return (
		<StyledColumn
			onClick={handleOnClick}
			css={{
				cursor: 'pointer',
				minWidth: '140px',
				minHeight: '114px',
				padding: '16px',
				justifyContent: 'flex-end',
				alignItems: 'flex-start',
				gap: '8px',
				flex: '1 0 0',
				alignSelf: 'stretch',
				borderRadius: '16px',
				background: theme.white,
				boxShadow: '0px 1px 25px 0px rgba(175, 126, 255, 0.10)',
			}}
		>
			<StyledBox
				css={{
					display: ' flex',
					justifyContent: ' space-between',
					alignItems: ' flex-start',
					flex: '1 0 0',
					alignSelf: ' stretch',
				}}
			>
				<StyledColumn
					css={{
						justifyContent: 'flex-end',
						alignItems: 'flex-start',
						gap: '4px',
						alignSelf: 'stretch',
					}}
				>
					<StyledColumn
						css={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'flex-end',
							alignItems: 'flex-start',
							gap: '2px',
						}}
					>
						<StyledText
							variant="body1Bold"
							css={{ color: theme.gray700 }}
						>
							+{extraData.categoriesAmount}
						</StyledText>
						<StyledText variant="body3" css={{ color: theme.gray700 }}>
							Categorías
						</StyledText>
					</StyledColumn>
				</StyledColumn>
				<StyledBox
					css={{
						display: 'flex',
						width: '24px',
						height: '24px',
						padding: '2px 4px',
						justifyContent: 'center',
						alignItems: 'center',
						gap: '8px',
						borderRadius: '24px',
						background: theme.gray400,
					}}
				>
					<StyledBox
						css={{
							display: 'flex',
							alignItems: 'center',
							gap: '4px',
						}}
					>
						<StyledText variant="body3Bold" css={{ color: theme.white }}>
							{extraData.answers}
						</StyledText>
					</StyledBox>
				</StyledBox>
			</StyledBox>
		</StyledColumn>
	);
};
