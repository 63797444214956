import { combineReducers, configureStore } from '@reduxjs/toolkit';
// import { persistStore, persistReducer } from "redux-persist";
// import storage from "redux-persist/lib/storage";
// import sessionMiddleware from "./Session/session.middleware";
import homeSliceReducer from './slices/homeSlice';
import formSliceReducer from './slices/formSlice';
import listSliceReducer from './slices/listSlice';
import validationSliceReducer from './slices/validationSlice';
import treatmentSliceReducer from './slices/treatmentSlice';
import { api } from './api/api';
import utilsSliceReducer from './slices/utilsSlice';
// const middlewares = [sessionMiddleware];

// const persistConfig = {
//   key: "root",
//   storage,
// };

// const persistedReducer = persistReducer(persistConfig, rootReducers);

const reducers = combineReducers({
	[api.reducerPath]: api.reducer,
	homeSlice: homeSliceReducer,
	formSlice: formSliceReducer,
	listSlice: listSliceReducer,
	// reportsSlice: resporstSliceReducer,
	utilsSlice: utilsSliceReducer,
	treatmentSlice: treatmentSliceReducer,
	validationSlice: validationSliceReducer,
});

export const store = configureStore({
	reducer: reducers,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		}).concat(api.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

// export const persistor = persistStore(store);
