import React, { useMemo } from 'react';
import Chart from 'react-apexcharts';
import Card from '../Card';
import { HungerAndThirstConfig } from '../../utils/chartsConfigs';
import { StyledCircularProgress } from '../CustomCircularProgress/styles';
import { StyledBox } from '../../common/styledCommonComponents';
import { useTheme } from 'styled-components';
import { useParams } from 'react-router-dom';
import { useGetPatientAnswersQuery } from '../../redux/api/patientApi';
import { StyledH6 } from '../../common/components/Text/styles';
import { ErrorMessage } from '../../pages/PatientProfile/components/ErrorMessage';

const HungerAndThirstChart = () => {
	const theme = useTheme();
	const { patientId } = useParams();

	const {
		data: hydrationData,
		isLoading: isLoadingHydration,
		refetch: refetchHydration,
		isError: isErrorHydration,
	} = useGetPatientAnswersQuery(
		{
			category: 'HYDRATION',
			patientId: patientId ?? '',
		},
		{
			skip: !patientId,
			selectFromResult: (data) => {
				const hydrationData: any = {
					data: [],
					categories: [],
				};
				data.data?.forEach((item: any) => {
					const date = new Date(item.date);
					const formattedDate = `${date.getDate()}/${date.getMonth() + 1}`;
					const answer: any = item.answer;
					if (answer !== null) {
						hydrationData.data.push(answer);
					} else {
						hydrationData.data.push('No respondió');
					}
					hydrationData.categories.push(formattedDate);
				});
				return { ...data, data: hydrationData };
			},
		},
	);

	const {
		data: hungerData,
		isLoading: isLoadingHunger,
		refetch: refetchHunger,
		isError: isErrorHunger,
	} = useGetPatientAnswersQuery(
		{
			category: 'HUNGER',
			patientId: patientId ?? '',
		},
		{
			skip: !patientId,
			selectFromResult: (data) => {
				const hungerData: any = {
					data: [],
					categories: [],
				};
				data.data?.forEach((item: any) => {
					const date = new Date(item.date);
					const formattedDate = `${date.getDate()}/${date.getMonth() + 1}`;
					const answer: any = item.answer;

					if (answer !== null) {
						hungerData.data.push(answer);
					} else {
						hungerData.data.push('No respondió');
					}
					hungerData.categories.push(formattedDate);
				});
				return { ...data, data: hungerData };
			},
		},
	);

	const refactorData = useMemo(() => {
		return [
			{
				name: 'Hidratación',
				data: hydrationData.data.reverse(),
				categories: hydrationData.categories.reverse(),
			},
			{
				name: 'Apetito',
				data: hungerData.data.reverse(),
				categories: hungerData.categories.reverse(),
			},
		];
	}, [hydrationData, hungerData]);

	const isEmpty =
		!hydrationData ||
		!hungerData ||
		hydrationData?.data?.data?.length === 0 ||
		hungerData?.data?.data?.length === 0;

	const options = useMemo(
		() => HungerAndThirstConfig(refactorData ?? {}),
		[refactorData],
	);

	const handleRefetch = () => {
		refetchHunger();
		refetchHydration();
	};

	return (
		<Card
			title={'Apetito e Hidratación'}
			width={'490px'}
			height={'250px'}
			childrenCss={{ overflowY: 'hidden' }}
		>
			{isLoadingHunger || isLoadingHydration ? (
				<StyledBox
					css={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						height: '100%',
					}}
				>
					<StyledCircularProgress />
				</StyledBox>
			) : isErrorHunger || isErrorHydration ? (
				<ErrorMessage refetch={handleRefetch} />
			) : isEmpty ? (
				<StyledBox
					css={{
						height: '100%',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<StyledH6 css={{ color: theme.gray300 }}>Sin Datos</StyledH6>
				</StyledBox>
			) : (
				<Chart
					// @ts-ignore
					options={options.options}
					series={options.series}
					type="bar"
					width={440}
					height={173}
				/>
			)}
		</Card>
	);
};
export default HungerAndThirstChart;
