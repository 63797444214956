import React from 'react';
import { IconProps } from '../IconInterface';

const CircularOralIcon = ({
	color = '#000000',
	width = 30,
	height = 30,
}: IconProps) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 30 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g id="Group 27543">
				<circle id="Ellipse 145" cx="15" cy="15" r="15" fill="#E2EAFF" />
				<g id="Group">
					<path
						id="Vector"
						d="M14.9978 19.4234C14.9978 19.4234 18.2483 19.3618 20.0691 17.8464C21.8898 16.3309 24.2971 14.1009 25.9957 13.5958C16.7453 9.08642 14.9978 11.8339 14.9978 11.8339C14.9978 11.8339 13.2504 9.0741 4 13.5958C5.69855 14.1009 8.10586 16.3309 9.92662 17.8464C11.7474 19.3495 14.9978 19.4234 14.9978 19.4234Z"
						fill="#5784F7"
						stroke="#E2EAFF"
						stroke-width="2"
						stroke-miterlimit="10"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						id="Vector_2"
						d="M25.9957 13.5947C20.5823 15.5044 14.9978 15.221 14.9978 15.221C14.9978 15.221 9.41338 15.5044 4 13.5947"
						stroke="#E2EAFF"
						stroke-width="0.75"
						stroke-miterlimit="10"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
				</g>
			</g>
		</svg>
	);
};
export default CircularOralIcon;
