import React, { useEffect } from 'react';
import {
	LoginFormSection,
	LoginImageSection,
	LoginWrapper,
} from './StyledLoginScreen';
import Button from '../../common/components/Button';
import { LoginImage } from '../../assets/LoginImage';
import { StyledText } from '../../common/components/Text/styles';
import { useAuth0 } from '@auth0/auth0-react';
import { useTheme } from 'styled-components';
import { StyledColumn } from '../../common/styledCommonComponents';
import GoogleIcon from '../../assets/GoogleIcon';

function LoginScreen() {
	const { loginWithRedirect } = useAuth0();
	const theme = useTheme();

	useEffect(() => {
		localStorage.removeItem('token');
	}, []);

	return (
		<LoginWrapper>
			<LoginFormSection>
				<StyledColumn css={{ padding: '0 16px', gap: 16 }}>
					<StyledText
						variant={'h4'}
						css={{ color: theme.gray800, textAlign: 'center' }}
					>
						Te damos la bienvenida
					</StyledText>
					<Button
						size={'medium'}
						variant={'outlined'}
						onClick={() => loginWithRedirect()}
						css={{ width: '100%' }}
					>
						{' '}
						<GoogleIcon width={20} height={20} /> Iniciar sesión con
						Google
					</Button>
				</StyledColumn>
			</LoginFormSection>
			<LoginImageSection>
				<LoginImage />
			</LoginImageSection>
		</LoginWrapper>
	);
}

export default LoginScreen;
