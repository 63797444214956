import styled, {
	css as styledComponentCss,
	CSSObject,
} from 'styled-components';

export type TextVariants =
	| 'h1'
	| 'h2'
	| 'h3'
	| 'h4'
	| 'h5'
	| 'h6'
	| 'body1'
	| 'body1SemiBold'
	| 'body1Bold'
	| 'body2'
	| 'body2Bold'
	| 'body3'
	| 'body3Bold'
	| 'body4'
	| 'body4Bold'
	| 'body5'
	| 'body5Bold'
	| 'helperText'
	| 'buttonSmall'
	| 'buttonMedium'
	| 'buttonLarge'
	| 'filterTag';

const validTags: Record<TextVariants, keyof JSX.IntrinsicElements> = {
	h1: 'h1',
	h2: 'h2',
	h3: 'h3',
	h4: 'h4',
	h5: 'h5',
	h6: 'h6',
	body1: 'p',
	body1SemiBold: 'p',
	body1Bold: 'p',
	body2: 'p',
	body2Bold: 'p',
	body3: 'p',
	body3Bold: 'p',
	body4: 'p',
	body4Bold: 'p',
	body5: 'p',
	body5Bold: 'p',
	helperText: 'p',
	buttonSmall: 'p',
	buttonMedium: 'p',
	buttonLarge: 'p',
	filterTag: 'p',
};

const commonTextStyles = styledComponentCss`
  font-family: 'Inter';
  font-style: normal;
  line-height: normal;
  
  a {
    color: ${(props) => props.theme.primary400};
	decoration: none;
	&:hover {
		color: ${(props) => props.theme.primary500};
	}
  }
`;

export const StyledText = styled.p<{ variant: TextVariants; css?: CSSObject }>`
	${(props) =>
		styledComponentCss`${props.theme.typographyVariant[props.variant]}`}
	${commonTextStyles}
   ${(props) => styledComponentCss`${props.css}`}
`;

export const StyledH1 = styled.h1<{ css?: CSSObject }>`
	${(props) => styledComponentCss`${props.css}`}
	${(props) => styledComponentCss`${props.theme.typographyVariant.h1}`}
	${commonTextStyles}
`;

export const StyledH2 = styled.h2<{ css?: CSSObject }>`
	${(props) => styledComponentCss`${props.css}`}
	${(props) => styledComponentCss`${props.theme.typographyVariant.h2}`}
	${commonTextStyles}
`;

export const StyledH3 = styled.h3<{ css?: CSSObject }>`
	${(props) => styledComponentCss`${props.css}`}
	${(props) => styledComponentCss`${props.theme.typographyVariant.h3}`}
	${commonTextStyles}
`;

export const StyledH4 = styled.h4<{ css?: CSSObject }>`
	${(props) => styledComponentCss`${props.css}`}
	${(props) => styledComponentCss`${props.theme.typographyVariant.h4}`}
	${commonTextStyles}
`;

export const StyledH5 = styled.h5<{ css?: CSSObject }>`
	${(props) => styledComponentCss`${props.css}`}
	${(props) => styledComponentCss`${props.theme.typographyVariant.h5}`}
	${commonTextStyles}
`;

export const StyledH6 = styled.h6<{ css?: CSSObject }>`
	${(props) => styledComponentCss`${props.css}`}
	${(props) => styledComponentCss`${props.theme.typographyVariant.h6}`}
	${commonTextStyles}
`;

export default StyledText;
