import React from 'react';

const IconBrain = (props) => {
	return (
		<svg
			width="30"
			height="30"
			viewBox="0 0 30 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle cx="15" cy="15" r="15" fill="#FFEDE6" />
			<path
				d="M8.0392 19.4766C8.3848 20.5422 9.4352 21.2223 10.556 21.2223H10.6C10.7028 21.7242 10.9758 22.1752 11.3727 22.4991C11.7696 22.823 12.2661 22.9999 12.7784 23C13.144 23 13.4856 22.908 13.788 22.7496C13.9142 22.6804 14.0192 22.5782 14.0918 22.4539C14.1644 22.3296 14.2018 22.1879 14.2 22.0439V7.8206C14.2016 7.63731 14.1401 7.45905 14.0257 7.31582C13.9113 7.17259 13.7511 7.07312 13.572 7.03415C13.1706 6.95619 12.7548 7.01271 12.3888 7.19496C11.7936 7.47978 11.4448 8.11582 11.4448 8.77746V8.82147C10.9994 8.73055 10.5368 8.77843 10.1194 8.95865C9.70203 9.13887 9.34997 9.44277 9.1107 9.82934C8.87142 10.2159 8.75645 10.6666 8.78129 11.1205C8.80613 11.5745 8.96958 12.0099 9.2496 12.3681C8.62247 12.4664 8.0511 12.7856 7.63859 13.2681C7.22609 13.7506 6.9996 14.3646 7 14.9995C7 15.9419 7.4888 16.7684 8.2264 17.2492C7.8824 17.8661 7.7696 18.6461 8.0392 19.4766ZM20.7504 12.3681C21.0304 12.0099 21.1939 11.5745 21.2187 11.1205C21.2435 10.6666 21.1286 10.2159 20.8893 9.82934C20.65 9.44277 20.298 9.13887 19.8806 8.95865C19.4632 8.77843 19.0006 8.73055 18.5552 8.82147V8.77746C18.5552 8.11662 18.2056 7.47978 17.6104 7.19496C17.2447 7.01265 16.8291 6.95613 16.428 7.03415C16.249 7.07326 16.0888 7.17276 15.9745 7.31596C15.8601 7.45916 15.7985 7.63735 15.8 7.8206V22.0431C15.8 22.3352 15.9536 22.6128 16.2128 22.7488C16.5152 22.908 16.856 23 17.2216 23C17.734 22.9999 18.2306 22.8228 18.6276 22.4988C19.0245 22.1747 19.2973 21.7235 19.4 21.2215H19.4448C20.5648 21.2215 21.616 20.5414 21.9616 19.4758C22.2312 18.6453 22.1184 17.8653 21.7744 17.2484C22.1501 17.0056 22.4591 16.6727 22.6732 16.2798C22.8873 15.887 22.9996 15.4468 23 14.9995C23.0004 14.3646 22.7739 13.7506 22.3614 13.2681C21.9489 12.7856 21.3775 12.4664 20.7504 12.3681V12.3681Z"
				fill="#ECA485"
			/>
		</svg>
	);
};

export default IconBrain;
