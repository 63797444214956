import React, { useEffect } from 'react';

const useOutsideAlerter = (ref: any, callback: () => void) => {
	const handleClick = (e: {
		target: any;
		clientX: number;
		clientY: number;
	}) => {
		// @ts-ignore
		const { x, y, width, height } =
			ref?.current?.getBoundingClientRect() ?? {};
		if (
			// @ts-ignore
			ref.current &&
			x &&
			!(
				e.clientX > x &&
				e.clientX < x + width &&
				e.clientY > y &&
				e.clientY < y + height
			)
		) {
			callback();
		}
	};

	useEffect(() => {
		document.addEventListener('click', handleClick);

		return () => {
			document.removeEventListener('click', handleClick);
		};
	});
};

export default useOutsideAlerter;
