import React from 'react';
import HeaderValidationForm from '../components/HeaderValidationForm';
import BiomarkerIcon from '../../../assets/BiomarkerIcon';
import { StyledColumn } from '../../../common/styledCommonComponents';
import { useOncoDispatch, useOncoSelector } from '../../../hooks/redux-hook';
import {
	ProgressionInterface,
	addProgression,
	addSetback,
	setValidationErrors,
	valuesLastTreatment,
} from '../../../redux/slices/validationSlice';
import Button from '../../../common/components/Button';
import PlusCircleIcon from '../../../assets/PlusCircleIcon';
import { setModalOpen } from '../../../redux/slices/utilsSlice';
import { ModalTypeEnum } from '../../../utils/utils';
import ProgressionContainer from '../components/ProgressionContainer';
import { validateForm } from '../utils';
import ReactTooltip from 'react-tooltip';

const ProgressionForm = () => {
	const progressions = useOncoSelector(
		(state) => state.validationSlice.values.progressions,
	);
	const setbacks = useOncoSelector(
		(state) => state.validationSlice.values.setbacks,
	);
	const isEditable = useOncoSelector(
		(state) => state.validationSlice.isEditable,
	);

	const stage = useOncoSelector((state) => state.validationSlice.values.stage);

	const dispatch = useOncoDispatch();
	const {
		treatment: values,
		prefix,
		id,
		payload,
	} = useOncoSelector((state) => valuesLastTreatment(state));
	const validationsRules = useOncoSelector(
		(state) => state.validationSlice.validations,
	);
	const medication = useOncoSelector(
		(state) => state.validationSlice.values.medication,
	);

	const handleAddProgression = () => {
		let valuesToTest = {};
		// if(values) go to addSetback/addProgression
		const { isFinished, endingMotive, ...treatmentValues } = values;
		const { treatment, ...others }: any = payload;
		if (prefix === 'treatment') {
			valuesToTest = {
				treatment: treatmentValues,
				medication,
			};
		} else {
			valuesToTest = {
				[prefix]: [
					{
						...others,
						treatment: treatmentValues,
					},
				],
				medication,
			};
		}

		const validation = validateForm(valuesToTest, validationsRules);
		if (
			validation.find(
				(item: any) =>
					(item?.error?.length ?? 0) ||
					item?.properties?.find((prop: any) => prop?.error?.length ?? 0),
			)
		) {
			dispatch(setValidationErrors({ validation }));
		} else if (isFinished || values.finishDate || values.endingMotive) {
			if (prefix === 'treatment' && stage !== 'IV') {
				dispatch(addSetback());
			} else {
				dispatch(addProgression());
			}
		} else {
			dispatch(
				setModalOpen({
					type: ModalTypeEnum.TREATMENT_MODAL,
					prefix: 'progressions',
				}),
			);
		}
	};
	const disableAddProgression =
		(prefix === 'treatment' && stage !== 'IV') || setbacks.length === 0;

	return (
		<StyledColumn>
			<HeaderValidationForm title={'Progresiones'} icon={BiomarkerIcon} />
			<StyledColumn css={{ padding: 32, gap: 16 }}>
				{progressions?.map((progression: ProgressionInterface) => (
					<ProgressionContainer
						id={progression.id}
						progression={progression}
						key={progression.id}
					/>
				))}
				{
					<Button
						css={{ width: '100%' }}
						onClick={handleAddProgression}
						variant={'outlined'}
						size={'medium'}
						icon={PlusCircleIcon}
						disabled={disableAddProgression}
					>
						Agregar progresión
					</Button>
				}
			</StyledColumn>
		</StyledColumn>
	);
};

export default ProgressionForm;
