import React from 'react';
import { theme } from '../common/theme';

const IconLungs = (props) => {
	const { color = theme.oncoGreenPrimary } = props;
	return (
		<svg
			width="30"
			height="30"
			viewBox="0 0 30 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle cx="15" cy="15" r="15" fill="#E3F5EA" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M14.023 13.6569C14.0374 12.3144 14.0654 9.7002 11.9962 9.7002C9.16171 9.7002 7.13704 19.5941 8.35184 20.6037C9.56663 21.6132 11.9962 21.0075 13.211 19.796C14.1918 18.8179 14.1069 16.5323 14.046 14.8917C14.0329 14.5381 14.0208 14.2145 14.0208 13.9404C14.0208 13.8545 14.0219 13.7594 14.023 13.6569Z"
				fill="#52BF7E"
			/>
			<path
				d="M12.7064 10.7225C12.8712 10.8923 13.009 11.1653 13.1057 11.551C13.2749 12.2263 13.2677 13.0032 13.262 13.63C13.2609 13.7384 13.26 13.8423 13.26 13.9404C13.26 14.2295 13.2728 14.5757 13.2862 14.9359C13.2915 15.0778 13.2968 15.2217 13.3015 15.3652C13.3185 15.8884 13.3282 16.4362 13.303 16.966C13.2776 17.4987 13.2179 17.9876 13.1063 18.397C12.9935 18.8112 12.8425 19.0889 12.6737 19.2572C12.1875 19.7422 11.4197 20.1339 10.6366 20.2858C9.86239 20.4359 9.23914 20.3248 8.87413 20.0471C8.87224 20.0428 8.87013 20.0378 8.86781 20.032C8.84529 19.9758 8.81797 19.8791 8.79576 19.7318C8.75131 19.437 8.73975 19.0257 8.76973 18.519C8.82937 17.5109 9.04656 16.232 9.38963 14.9734C9.73403 13.71 10.1922 12.5155 10.7115 11.6551C10.9715 11.2244 11.2278 10.9096 11.4655 10.7098M8.88442 20.0676C8.88442 20.0676 8.88406 20.0671 8.8834 20.0659M14.023 13.6569C14.0374 12.3144 14.0654 9.7002 11.9962 9.7002C9.16171 9.7002 7.13704 19.5941 8.35184 20.6037C9.56663 21.6132 11.9962 21.0075 13.211 19.796C14.1918 18.8179 14.1069 16.5323 14.046 14.8917C14.0329 14.5381 14.0208 14.2145 14.0208 13.9404C14.0208 13.8545 14.0219 13.7594 14.023 13.6569Z"
				stroke="#52BF7E"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M15.6578 13.6569C15.6434 12.3144 15.6154 9.7002 17.6846 9.7002C20.5191 9.7002 22.5437 19.5941 21.3289 20.6037C20.1141 21.6132 17.6846 21.0075 16.4698 19.796C15.489 18.8179 15.5739 16.5323 15.6348 14.8917C15.6479 14.5381 15.6599 14.2145 15.6599 13.9404C15.6599 13.8545 15.6589 13.7594 15.6578 13.6569Z"
				fill="#52BF7E"
			/>
			<path
				d="M16.9744 10.7225C16.8095 10.8923 16.6718 11.1653 16.5751 11.551C16.4059 12.2263 16.413 13.0032 16.4188 13.63C16.4199 13.7384 16.4208 13.8423 16.4208 13.9404C16.4208 14.2295 16.4079 14.5757 16.3946 14.9359C16.3893 15.0778 16.384 15.2217 16.3793 15.3652C16.3623 15.8884 16.3526 16.4362 16.3778 16.966C16.4032 17.4987 16.4629 17.9876 16.5745 18.397C16.6873 18.8112 16.8383 19.0889 17.0071 19.2572C17.4933 19.7421 18.2611 20.1339 19.0442 20.2858C19.8184 20.4359 20.4416 20.3248 20.8067 20.0471C20.8085 20.0428 20.8107 20.0378 20.813 20.032C20.8355 19.9758 20.8628 19.8791 20.885 19.7318C20.9295 19.437 20.941 19.0257 20.9111 18.519C20.8514 17.5109 20.6342 16.232 20.2912 14.9734C19.9468 13.71 19.4886 12.5155 18.9693 11.6551M20.7964 20.0676C20.7964 20.0676 20.7967 20.0671 20.7974 20.0659M15.6578 13.6569C15.6434 12.3144 15.6154 9.7002 17.6846 9.7002C20.5191 9.7002 22.5437 19.5941 21.3289 20.6037C20.1141 21.6132 17.6846 21.0075 16.4698 19.796C15.489 18.8179 15.5739 16.5323 15.6348 14.8917C15.6479 14.5381 15.6599 14.2145 15.6599 13.9404C15.6599 13.8545 15.6589 13.7594 15.6578 13.6569Z"
				stroke="#52BF7E"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M14.4601 7.41802C14.4601 7.41798 14.4601 7.41797 14.8405 7.41797C15.221 7.41797 15.221 7.41798 15.221 7.41802V12.9343C15.221 13.7341 14.8649 14.1928 14.4616 14.4281C14.2723 14.5384 14.0864 14.592 13.9502 14.6185C13.8813 14.6319 13.8228 14.6388 13.7798 14.6424C13.7583 14.6442 13.7404 14.6452 13.7268 14.6457C13.72 14.646 13.7143 14.6461 13.7097 14.6462L13.7036 14.6463L13.7012 14.6463H13.7001H13.6996C13.6994 14.6463 13.6992 14.6463 13.6992 14.2659C13.6992 13.8854 13.699 13.8854 13.6988 13.8854H13.6984H13.6977H13.6965L13.6953 13.8855C13.6953 13.8855 13.6959 13.8854 13.6973 13.8854C13.7004 13.8853 13.707 13.8849 13.7167 13.8841C13.7361 13.8825 13.7668 13.879 13.8049 13.8716C13.8826 13.8565 13.9821 13.8269 14.0782 13.7708C14.2455 13.6732 14.4601 13.4661 14.4601 12.9343V7.41802Z"
				fill="#52BF7E"
				stroke="#52BF7E"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M15.2218 7.41802C15.2218 7.41798 15.2218 7.41797 14.8414 7.41797C14.4609 7.41797 14.4609 7.41798 14.4609 7.41802V12.9343C14.4609 13.7341 14.817 14.1928 15.2204 14.4281C15.4096 14.5384 15.5955 14.592 15.7318 14.6185C15.8007 14.6319 15.8591 14.6388 15.9021 14.6424C15.9236 14.6442 15.9415 14.6452 15.9551 14.6457C15.9619 14.646 15.9677 14.6461 15.9723 14.6462L15.9784 14.6463L15.9808 14.6463H15.9818H15.9823C15.9825 14.6463 15.9827 14.6463 15.9827 14.2659C15.9827 13.8854 15.9829 13.8854 15.9831 13.8854H15.9835H15.9843H15.9854L15.9866 13.8855C15.9866 13.8855 15.986 13.8854 15.9847 13.8854C15.9816 13.8853 15.9749 13.8849 15.9653 13.8841C15.9458 13.8825 15.9151 13.879 15.877 13.8716C15.7993 13.8565 15.6998 13.8269 15.6037 13.7708C15.4364 13.6732 15.2218 13.4661 15.2218 12.9343V7.41802Z"
				fill="#52BF7E"
				stroke="#52BF7E"
			/>
		</svg>
	);
};

export default IconLungs;
