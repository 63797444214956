import React from 'react';
import { theme } from '../common/theme';

const IconSad = (props) => {
	return (
		<svg
			width="30"
			height="30"
			viewBox="0 0 30 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle cx="15" cy="15" r="15" fill="#F9E3FF" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M23 15C23 19.4183 19.4183 23 15 23C10.5817 23 7 19.4183 7 15C7 10.5817 10.5817 7 15 7C19.4183 7 23 10.5817 23 15Z"
				fill="#CD7DE3"
			/>
			<path
				d="M15 22.2C11.0236 22.2 7.8 18.9764 7.8 15C7.8 11.0236 11.0236 7.8 15 7.8M23 15C23 19.4183 19.4183 23 15 23C10.5817 23 7 19.4183 7 15C7 10.5817 10.5817 7 15 7C19.4183 7 23 10.5817 23 15Z"
				stroke="#CD7DE3"
				strokeWidth="0.75"
			/>
			<path
				d="M12.1189 18.8401L12.1185 18.8406C11.9859 19.0171 11.7351 19.0531 11.5585 18.9206C11.3817 18.788 11.3459 18.5373 11.4785 18.3606L11.7985 18.6006C11.4785 18.3606 11.4784 18.3607 11.4785 18.3606L11.4789 18.36L11.4794 18.3593L11.4809 18.3574L11.485 18.3519C11.4884 18.3475 11.493 18.3416 11.4988 18.3343C11.5104 18.3197 11.5266 18.2996 11.5476 18.2747C11.5894 18.2249 11.6499 18.1557 11.7284 18.0738C11.8851 17.9103 12.1153 17.6937 12.4132 17.4771C13.0074 17.0449 13.8882 16.6006 14.9985 16.6006C16.1087 16.6006 16.9896 17.0449 17.5837 17.4771C17.8816 17.6937 18.1118 17.9103 18.2685 18.0738C18.347 18.1557 18.4076 18.2249 18.4494 18.2747C18.4703 18.2996 18.4866 18.3197 18.4981 18.3343L18.5056 18.3439L18.5119 18.3519L18.5161 18.3574L18.5175 18.3593L18.518 18.36C18.5181 18.3601 18.5185 18.3606 18.2126 18.59L18.5185 18.3606C18.651 18.5373 18.6152 18.788 18.4385 18.9206C18.262 19.0529 18.0118 19.0174 17.8791 18.8414L17.8788 18.841C17.8787 18.8409 17.8785 18.8406 18.1985 18.6006L17.8785 18.8406L17.878 18.8401L17.8709 18.8309C17.8639 18.822 17.8524 18.8078 17.8366 18.789C17.805 18.7513 17.7562 18.6954 17.6909 18.6273C17.5601 18.4909 17.3653 18.3075 17.1132 18.1241C16.6074 17.7562 15.8882 17.4006 14.9985 17.4006C14.1087 17.4006 13.3896 17.7562 12.8837 18.1241C12.6316 18.3075 12.4368 18.4909 12.306 18.6273C12.2408 18.6954 12.192 18.7513 12.1603 18.789C12.1445 18.8078 12.1331 18.822 12.126 18.8309L12.1189 18.8401Z"
				fill="#F9E3FF"
				stroke="#F9E3FF"
				strokeWidth="0.75"
			/>
			<path
				d="M12.4469 13.4291C12.652 13.3471 12.8848 13.4468 12.9668 13.6519C13.0489 13.8571 12.9491 14.0899 12.744 14.1719L11.7442 14.5718L11.7439 14.5719L10.744 14.9719C10.5389 15.0539 10.3061 14.9542 10.224 14.7491C10.142 14.5439 10.2417 14.3112 10.4469 14.2291L12.4469 13.4291Z"
				fill="#F9E3FF"
				stroke="#F9E3FF"
				strokeWidth="0.75"
			/>
			<path
				d="M17.0287 13.6519C17.1108 13.4468 17.3436 13.3471 17.5487 13.4291L19.5487 14.2291C19.7538 14.3112 19.8536 14.5439 19.7715 14.7491C19.6894 14.9542 19.4567 15.0539 19.2516 14.9719L17.2516 14.1719C17.0464 14.0899 16.9467 13.8571 17.0287 13.6519Z"
				fill="#F9E3FF"
				stroke="#F9E3FF"
				strokeWidth="0.75"
			/>
		</svg>
	);
};

export default IconSad;
