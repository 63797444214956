import React from 'react';
import { IconProps } from '../IconInterface';

const CircularMoodIcon = ({
	color = '#000000',
	width = 30,
	height = 30,
}: IconProps) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 30 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g id="Group 27542">
				<circle id="Ellipse 145" cx="15" cy="15" r="15" fill="#FFEDE6" />
				<g id="&#240;&#159;&#166;&#134; icon &#34;Smile&#34;">
					<g id="Group">
						<g id="Vector">
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M15 23C19.4183 23 23 19.4183 23 15C23 10.5817 19.4183 7 15 7C10.5817 7 7 10.5817 7 15C7 19.4183 10.5817 23 15 23Z"
								fill="#ECA485"
							/>
							<path
								d="M15 22.2C11.0236 22.2 7.8 18.9764 7.8 15C7.8 11.0236 11.0236 7.8 15 7.8M15 23C19.4183 23 23 19.4183 23 15C23 10.5817 19.4183 7 15 7C10.5817 7 7 10.5817 7 15C7 19.4183 10.5817 23 15 23Z"
								stroke="#ECA485"
								stroke-width="0.75"
							/>
						</g>
						<path
							id="Vector_2"
							d="M13.0004 13.8C13.0004 14.2418 12.6422 14.6 12.2004 14.6C11.7586 14.6 11.4004 14.2418 11.4004 13.8C11.4004 13.3582 11.7586 13 12.2004 13C12.6422 13 13.0004 13.3582 13.0004 13.8Z"
							fill="#FFEDE5"
							stroke="#FFEDE5"
							stroke-width="0.75"
						/>
						<path
							id="Vector_3"
							d="M18.6 13.8C18.6 14.2418 18.2418 14.6 17.8 14.6C17.3582 14.6 17 14.2418 17 13.8C17 13.3582 17.3582 13 17.8 13C18.2418 13 18.6 13.3582 18.6 13.8Z"
							fill="#FFEDE5"
							stroke="#FFEDE5"
							stroke-width="0.75"
						/>
						<path
							id="Vector_4"
							fill-rule="evenodd"
							clip-rule="evenodd"
							d="M17.4002 17.8004L17.683 18.0832C17.8392 17.927 17.8392 17.6738 17.683 17.5176C17.5275 17.362 17.2757 17.3614 17.1193 17.5156L17.1172 17.5176C17.1142 17.5204 17.1082 17.5259 17.0993 17.5338C17.0814 17.5494 17.0517 17.5743 17.0102 17.6054C16.9272 17.6677 16.7975 17.7545 16.6213 17.8426C16.2702 18.0182 15.7311 18.2004 15.0002 18.2004C14.2693 18.2004 13.7301 18.0182 13.3791 17.8426C13.2029 17.7545 13.0732 17.6677 12.9902 17.6054C12.9487 17.5743 12.919 17.5494 12.9011 17.5338C12.8921 17.5259 12.8861 17.5204 12.8831 17.5176L12.881 17.5156C12.7246 17.3614 12.4729 17.362 12.3173 17.5176C12.1611 17.6738 12.1611 17.927 12.3173 18.0832L12.6002 17.8004C12.3173 18.0832 12.3176 18.0835 12.3176 18.0835L12.3179 18.0838L12.3186 18.0845L12.3201 18.086L12.3242 18.09C12.3273 18.093 12.3312 18.0968 12.336 18.1012C12.3455 18.1101 12.3582 18.1218 12.3743 18.1358C12.4064 18.1639 12.4517 18.2015 12.5102 18.2454C12.6272 18.3331 12.7975 18.4463 13.0213 18.5582C13.4702 18.7826 14.1311 19.0004 15.0002 19.0004C15.8693 19.0004 16.5301 18.7826 16.9791 18.5582C17.2029 18.4463 17.3732 18.3331 17.4902 18.2454C17.5487 18.2015 17.594 18.1639 17.6261 18.1358C17.6421 18.1218 17.6549 18.1101 17.6644 18.1012C17.6691 18.0968 17.6731 18.093 17.6761 18.09L17.6802 18.086L17.6818 18.0845L17.6824 18.0838L17.6827 18.0835C17.6827 18.0835 17.683 18.0832 17.4002 17.8004Z"
							fill="#FFEDE5"
							stroke="#FFEDE5"
							stroke-width="0.75"
						/>
					</g>
				</g>
			</g>
		</svg>
	);
};
export default CircularMoodIcon;
