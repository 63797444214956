import React from 'react';

const IconSmile = (props) => {
	return (
		<svg
			width="30"
			height="30"
			viewBox="0 0 30 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle cx="15" cy="15" r="15" fill="#FFEDE6" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M15 23C19.4183 23 23 19.4183 23 15C23 10.5817 19.4183 7 15 7C10.5817 7 7 10.5817 7 15C7 19.4183 10.5817 23 15 23Z"
				fill="#ECA485"
			/>
			<path
				d="M15 22.2C11.0236 22.2 7.8 18.9764 7.8 15C7.8 11.0236 11.0236 7.8 15 7.8M15 23C19.4183 23 23 19.4183 23 15C23 10.5817 19.4183 7 15 7C10.5817 7 7 10.5817 7 15C7 19.4183 10.5817 23 15 23Z"
				stroke="#ECA485"
				strokeWidth="0.75"
			/>
			<path
				d="M12.9984 13.8C12.9984 14.2418 12.6403 14.6 12.1984 14.6C11.7566 14.6 11.3984 14.2418 11.3984 13.8C11.3984 13.3582 11.7566 13 12.1984 13C12.6403 13 12.9984 13.3582 12.9984 13.8Z"
				fill="#FFEDE5"
				stroke="#FFEDE5"
				strokeWidth="0.75"
			/>
			<path
				d="M18.6 13.8C18.6 14.2418 18.2418 14.6 17.8 14.6C17.3582 14.6 17 14.2418 17 13.8C17 13.3582 17.3582 13 17.8 13C18.2418 13 18.6 13.3582 18.6 13.8Z"
				fill="#FFEDE5"
				stroke="#FFEDE5"
				strokeWidth="0.75"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M17.4031 17.8004L17.686 18.0832C17.8422 17.927 17.8422 17.6738 17.686 17.5176C17.5304 17.362 17.2787 17.3614 17.1223 17.5156L17.1202 17.5176C17.1172 17.5204 17.1112 17.5259 17.1022 17.5338C17.0843 17.5494 17.0546 17.5743 17.0131 17.6054C16.9301 17.6677 16.8004 17.7545 16.6242 17.8426C16.2732 18.0182 15.734 18.2004 15.0031 18.2004C14.2722 18.2004 13.7331 18.0182 13.382 17.8426C13.2058 17.7545 13.0761 17.6677 12.9931 17.6054C12.9516 17.5743 12.9219 17.5494 12.904 17.5338C12.8951 17.5259 12.8891 17.5204 12.8861 17.5176L12.884 17.5156C12.7276 17.3614 12.4758 17.362 12.3203 17.5176C12.1641 17.6738 12.1641 17.927 12.3203 18.0832L12.6031 17.8004C12.3203 18.0832 12.3206 18.0835 12.3206 18.0835L12.3209 18.0838L12.3215 18.0845L12.3231 18.086L12.3272 18.09C12.3302 18.093 12.3342 18.0968 12.3389 18.1012C12.3484 18.1101 12.3612 18.1218 12.3772 18.1358C12.4093 18.1639 12.4546 18.2015 12.5131 18.2454C12.6301 18.3331 12.8004 18.4463 13.0242 18.5582C13.4732 18.7826 14.134 19.0004 15.0031 19.0004C15.8722 19.0004 16.5331 18.7826 16.982 18.5582C17.2058 18.4463 17.3761 18.3331 17.4931 18.2454C17.5516 18.2015 17.5969 18.1639 17.629 18.1358C17.6451 18.1218 17.6578 18.1101 17.6673 18.1012C17.6721 18.0968 17.676 18.093 17.6791 18.09L17.6832 18.086L17.6847 18.0845L17.6854 18.0838L17.6857 18.0835C17.6857 18.0835 17.686 18.0832 17.4031 17.8004Z"
				fill="#FFEDE5"
				stroke="#FFEDE5"
				strokeWidth="0.75"
			/>
		</svg>
	);
};

export default IconSmile;
