import React from 'react';
import Button from '../../../../common/components/Button';
import { CSSObject, useTheme } from 'styled-components';
import TrashIcon from '../../../../assets/TrashIcon';

interface RemoveButtonProps {
	onClick: () => void;
	disabled?: boolean;
	css?: CSSObject;
}

const RemoveButton = ({ onClick, disabled, css }: RemoveButtonProps) => {
	const theme = useTheme();
	return (
		<Button
			css={{
				border: `1px solid ${theme.error400}`,
				width: 44,
				height: 44,
				'&:hover': {
					backgroundColor: theme.error50,
					border: `1px solid ${theme.error400}`,
				},
				...css,
			}}
			variant={'outlined'}
			onClick={onClick}
			disabled={disabled}
			icon={() => (
				<TrashIcon height={20} width={20} color={theme.error400} />
			)}
		/>
	);
};

export default RemoveButton;
