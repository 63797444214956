import React from 'react';
import { IconProps } from './IconInterface';

const ChevronUpIcon = ({ color, width, height }: IconProps) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M17.5481 16.6002L11.9928 11.2973L6.43758 16.6002C5.87919 17.1333 4.97718 17.1333 4.41879 16.6002C3.8604 16.0672 3.8604 15.2062 4.41879 14.6731L10.9906 8.39977C11.549 7.86674 12.451 7.86674 13.0094 8.39977L19.5812 14.6731C20.1396 15.2062 20.1396 16.0672 19.5812 16.6002C19.0228 17.1196 18.1065 17.1333 17.5481 16.6002Z"
				fill={color}
			/>
		</svg>
	);
};
export default ChevronUpIcon;
