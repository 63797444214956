import React from 'react';
import { StyledBox } from '../../common/styledCommonComponents';
import StyledText, { StyledH6 } from '../../common/components/Text/styles';
import Button from '../../common/components/Button';
import FeedbackSuccessIcon from '../../assets/FeedbackSuccessIcon';

export const FeedbackSuccess = ({
	handleClose,
}: {
	handleClose: () => void;
}) => {
	return (
		<StyledBox
			css={{
				flex: 1,
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
				alignItems: 'center',
				paddingTop: '16px',
			}}
		>
			<StyledBox
				css={{
					padding: '36px',
					textAlign: 'center',
				}}
			>
				<FeedbackSuccessIcon />
				<StyledH6 css={{ marginTop: '16px', marginBottom: '4px' }}>
					Comentario enviado
				</StyledH6>
				<StyledText variant="body2">
					Gracias por tu colaboración. Estamos trabajando para mejorar.
				</StyledText>
			</StyledBox>
			<Button width="100%" onClick={handleClose}>
				Cerrar
			</Button>
		</StyledBox>
	);
};
