import React, { ComponentType, ReactNode } from 'react';
import { StyledBox } from '../../../common/styledCommonComponents';
import { NavLink } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { StyledNavbarItemContainer } from './styles';
import { IconProps } from '../../../assets/IconInterface';
import ReactTooltip from 'react-tooltip';

interface NavItemProps {
	icon: ComponentType<IconProps>;
	title: string;
	pathName: string[];
	selected: boolean;
	onClick: () => void;
}

const NavItem = ({
	icon: Icon,
	title,
	pathName,
	selected,
	onClick,
}: NavItemProps) => {
	const theme = useTheme();
	return (
		<StyledNavbarItemContainer
			onClick={onClick}
			selected={selected}
			data-tip
			data-for={pathName[0] + '-tooltip-name'}
		>
			<NavLink
				to={pathName[0]}
				style={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<Icon
					width={24}
					height={24}
					color={selected ? theme.primary500 : theme.gray800}
				/>
			</NavLink>

			{selected && (
				<StyledBox
					css={{
						position: 'absolute',
						width: '4px',
						height: '40px',
						top: '0',
						right: '0px',
						background: '#AF7EFF',
						borderRadius: '10px 0px 0px 10px',
					}}
				/>
			)}
			<ReactTooltip
				id={pathName[0] + '-tooltip-name'}
				place="top"
				effect="solid"
				delayShow={1000}
			>
				{title}
			</ReactTooltip>
		</StyledNavbarItemContainer>
	);
};

export default NavItem;
