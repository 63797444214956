import React from 'react';
import { IconProps } from '../IconInterface';

const CircularPainIcon = ({
	color = '#000000',
	width = 30,
	height = 30,
}: IconProps) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 30 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g id="Group 27542">
				<circle id="Ellipse 145" cx="15" cy="15" r="15" fill="#F9E3FF" />
				<g id="&#240;&#159;&#166;&#134; icon &#34;Sad&#34;">
					<g id="Group">
						<g id="Vector">
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M23 15C23 19.4183 19.4183 23 15 23C10.5817 23 7 19.4183 7 15C7 10.5817 10.5817 7 15 7C19.4183 7 23 10.5817 23 15Z"
								fill="#CD7DE3"
							/>
							<path
								d="M15 22.2C11.0236 22.2 7.8 18.9764 7.8 15C7.8 11.0236 11.0236 7.8 15 7.8M23 15C23 19.4183 19.4183 23 15 23C10.5817 23 7 19.4183 7 15C7 10.5817 10.5817 7 15 7C19.4183 7 23 10.5817 23 15Z"
								stroke="#CD7DE3"
								stroke-width="0.75"
							/>
						</g>
						<path
							id="Vector_2"
							d="M12.1218 18.8401L12.1214 18.8406C11.9888 19.0171 11.738 19.0531 11.5614 18.9206C11.3846 18.788 11.3488 18.5373 11.4814 18.3606L11.8014 18.6006C11.4814 18.3606 11.4813 18.3607 11.4814 18.3606L11.4818 18.36L11.4824 18.3593L11.4838 18.3574L11.488 18.3519C11.4914 18.3475 11.496 18.3416 11.5017 18.3343C11.5133 18.3197 11.5296 18.2996 11.5505 18.2747C11.5923 18.2249 11.6528 18.1557 11.7313 18.0738C11.888 17.9103 12.1182 17.6937 12.4161 17.4771C13.0103 17.0449 13.8912 16.6006 15.0014 16.6006C16.1116 16.6006 16.9925 17.0449 17.5866 17.4771C17.8845 17.6937 18.1147 17.9103 18.2714 18.0738C18.35 18.1557 18.4105 18.2249 18.4523 18.2747C18.4732 18.2996 18.4895 18.3197 18.501 18.3343L18.5085 18.3439L18.5148 18.3519L18.519 18.3574L18.5204 18.3593L18.521 18.36C18.521 18.3601 18.5214 18.3606 18.2155 18.59L18.5214 18.3606C18.6539 18.5373 18.6181 18.788 18.4414 18.9206C18.2649 19.0529 18.0147 19.0174 17.882 18.8414L17.8817 18.841C17.8816 18.8409 17.8814 18.8406 18.2014 18.6006L17.8814 18.8406L17.881 18.8401L17.8738 18.8309C17.8668 18.822 17.8553 18.8078 17.8395 18.789C17.8079 18.7513 17.7591 18.6954 17.6938 18.6273C17.563 18.4909 17.3682 18.3075 17.1161 18.1241C16.6103 17.7562 15.8912 17.4006 15.0014 17.4006C14.1116 17.4006 13.3925 17.7562 12.8866 18.1241C12.6345 18.3075 12.4397 18.4909 12.3089 18.6273C12.2437 18.6954 12.1949 18.7513 12.1632 18.789C12.1474 18.8078 12.136 18.822 12.129 18.8309L12.1218 18.8401Z"
							fill="#F9E3FF"
							stroke="#F9E3FF"
							stroke-width="0.75"
						/>
						<path
							id="Vector_3"
							d="M12.4478 13.4291C12.653 13.3471 12.8858 13.4468 12.9678 13.6519C13.0498 13.8571 12.9501 14.0899 12.745 14.1719L11.7452 14.5718L11.7448 14.5719L10.745 14.9719C10.5398 15.0539 10.3071 14.9542 10.225 14.7491C10.143 14.5439 10.2427 14.3112 10.4478 14.2291L12.4478 13.4291Z"
							fill="#F9E3FF"
							stroke="#F9E3FF"
							stroke-width="0.75"
						/>
						<path
							id="Vector_4"
							d="M17.0287 13.6519C17.1108 13.4468 17.3436 13.3471 17.5487 13.4291L19.5487 14.2291C19.7538 14.3112 19.8536 14.5439 19.7715 14.7491C19.6894 14.9542 19.4567 15.0539 19.2516 14.9719L17.2516 14.1719C17.0464 14.0899 16.9467 13.8571 17.0287 13.6519Z"
							fill="#F9E3FF"
							stroke="#F9E3FF"
							stroke-width="0.75"
						/>
					</g>
				</g>
			</g>
		</svg>
	);
};
export default CircularPainIcon;
