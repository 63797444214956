import React from 'react';
import { StyledRow } from '../../../../common/styledCommonComponents';
import Dropdown from '../../../../common/components/Dropdown';
import RemoveButton from '../RemoveButton';
import { useGetMedicationsQuery } from '../../../../redux/api/validateFormApi';
import { useGetMedicationQuery } from '../../../../redux/api/treatmentApi';
import { ValidationType } from '../../../../validations';

interface MedicationFieldProps {
	name: string;
	onChange: (name: string, value: string) => void;
	onRemove: (idMed: string) => void;
	value: string;
	hasRemove?: boolean;
	disabled?: boolean;
	error?: { key: string; error: string[] };
}

const MedicationField = ({
	name,
	value,
	onChange,
	onRemove,
	hasRemove = false,
	disabled = false,
	error,
}: MedicationFieldProps) => {
	const { data: medicationOptions } = useGetMedicationQuery('', {
		selectFromResult: (response) => {
			if (
				response.error ||
				response?.data?.length === 0 ||
				response.data === undefined
			)
				return response;
			let aux: any = {};
			response.data.forEach(({ id, name }: any) => (aux[id] = name));
			return { response, data: aux };
		},
	});

	const handleOnChange = (name: string, value: string) => {
		onChange(name, value);
	};

	return (
		<StyledRow css={{ alignItems: 'flex-end', gap: 16 }}>
			<Dropdown
				label={'Medicamento'}
				name={name}
				onChange={handleOnChange}
				options={medicationOptions}
				hasSearchbar
				required
				value={value}
				placeholder={'Seleccione un medicamento'}
				error={error?.error?.[0] ?? false}
				disabled={disabled}
			/>
			{hasRemove && !disabled && (
				<RemoveButton
					onClick={() => onRemove(name)}
					css={{ marginBottom: 23 }}
				/>
			)}
		</StyledRow>
	);
};

export default MedicationField;
