import styled, { css as styledComponentCss } from 'styled-components';
import {
	CustomCssProps,
	StyledBox,
	StyledColumn,
} from '../../common/styledCommonComponents';

type StyledCardHomeProps = {
	width?: string | number;
	height?: string | number;
} & CustomCssProps;
export const StyledCardHome = styled(StyledColumn)<StyledCardHomeProps>`
	border-radius: 20px;
	background-color: white;
	box-shadow: 0 2px 24px rgba(214, 203, 252, 0.3);
	box-sizing: border-box;
	transition: all 0.4s ease-out;
	padding: 24px;
	gap: 16px;

	&.open {
		width: ${(props) => props.width} !important;
		height: ${(props) => props.height};
		transition: all 0.4s ease-out;
		opacity: 1 !important;
		margin-left: 0px;
	}

	&.openList {
		width: ${(props) => props.width} !important;
		height: ${(props) => props.height};
		transition: all 0.4s ease-out;
		opacity: 1 !important;
	}

	&.close {
		width: 0px;
		transition: all 0.4s ease-out;
		opacity: 0 !important;
		margin-left: 0px;
	}

	//padding: 24px 20px;
	${(props) => styledComponentCss`${props.css}`}
`;
