import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
	StyledBox,
	StyledRow,
	StyledColumn,
} from '../../styledCommonComponents';
import { StyledOption, StyledSelect } from './styles';
import { createPortal } from 'react-dom';
import useOutsideAlerter from '../../../hooks/useOutsiderAlerter';
import { CSSObject, DefaultTheme, useTheme } from 'styled-components';
import StyledText from '../Text/styles';
import ChevronDownIcon from '../../../assets/ChevronDownIcon';
import ChevronIcon from '../../../assets/ChevronIcon';
import { StyledInput } from '../TextInputField/styles';
import { theme } from '../../theme';
import { transform } from '../../../validations/transform';
import { ValidationType } from '../../../validations';
import { capitalize } from 'lodash';

interface SelectorInputFieldProps {
	id?: string;
	required?: boolean;
	height?: string;
	width?: string;
	value: string;
	label?: string;
	onChange: (name: string, value: string) => void;
	name: string;
	options: { [key: string]: any };
	disabled?: boolean;
	type?: string;
	error?: boolean | string;
	placeholder?: string;
	css?: CSSObject;
	hasSearchbar?: boolean;
}

const Dropdown = ({
	id,
	required,
	height,
	width = '100%',
	value = 'no_value',
	label,
	onChange,
	name,
	options = { loading: 'Cargando...' },
	disabled,
	type,
	placeholder,
	error,
	css,
	hasSearchbar = false,
}: SelectorInputFieldProps) => {
	const theme = useTheme() as DefaultTheme;
	const [open, setOpen] = useState(false);
	const ref = useRef(null);
	const [search, setSearch] = useState('');
	// const [filteredOptions, setFilteredOptions] = useState(options);
	const filteredOptions = useMemo(() => {
		if (search === '') {
			return options;
		}
		let newOptions: Record<string, string> = {};
		Object.keys(options).forEach((key) => {
			if (options[key].toLowerCase().includes(search.toLowerCase())) {
				newOptions[key] = options[key];
			}
		});
		if (Object.keys(newOptions).length === 0) {
			newOptions.no_results = 'No se encontraron resultados';
		}
		return newOptions;
	}, [search, options]);

	//
	// useEffect(() => {
	// 	if(hasSearchbar){
	// 		const optionsToSave = search === '' ? options :
	// 			options?.filter(({ id, name }: { id: string; name: string }) =>
	// 				name.toLowerCase().includes(search.toLowerCase()),
	// 			)
	// 		setFilteredOptions(
	// 			optionsToSave
	// 		);
	// 	}
	//
	// }, [search]);

	useEffect(() => {
		if (ref.current) {
			// @ts-ignore
		}
	}, [ref.current]);

	useOutsideAlerter(ref, () => setOpen(false));

	return (
		<>
			<StyledBox
				css={{
					width,
					height,
					display: 'flex',
					flexDirection: 'column',
					boxSizing: 'border-box',
					pointerEvents: disabled
						? 'none'
						: // @ts-ignore
						  ('default' as React.CSSProperties['pointerEvents']),
					...css,
				}}
			>
				{label && (
					<StyledRow css={{ gap: '4px' }}>
						<StyledText
							variant="body2"
							css={{
								marginBottom: '8px',
								color: disabled ? theme.gray400 : 'black',
							}}
						>
							{label}
						</StyledText>
						{required && (
							<StyledText
								variant="body2"
								css={{ marginBottom: '8px', color: theme.error400 }}
							>
								*
							</StyledText>
						)}
					</StyledRow>
				)}
				<StyledBox
					css={{
						position: 'relative',
					}}
				>
					<StyledSelect
						ref={ref}
						disabled={disabled}
						// @ts-ignore
						onClick={() => setOpen(!open)}
						onBlur={() => setOpen(false)}
						error={!!error}
					>
						{options[value] ||
							(value !== 'no_value' && value) ||
							placeholder ||
							`${capitalize(label)}`}
					</StyledSelect>
					<StyledBox
						style={{
							position: 'absolute',
							top: 0,
							right: '10px',
							display: 'flex',
							alignItems: 'center',
							height: '100%',
						}}
						onClick={() => setOpen(!open)}
					>
						{open ? (
							<ChevronIcon
								width={18}
								height={18}
								color={disabled ? theme.gray400 : 'black'}
							/>
						) : (
							<ChevronDownIcon
								width={18}
								height={18}
								color={disabled ? theme.gray400 : 'black'}
							/>
						)}
					</StyledBox>
				</StyledBox>
				<StyledText
					variant={'helperText'}
					css={{ color: theme.error400, height: 15, marginTop: 8 }}
				>
					{!!error && transform(error as ValidationType)}
				</StyledText>
			</StyledBox>
			{createPortal(
				open && (
					<StyledColumn
						css={{
							zIndex: 200000,
							padding: '8px 0',
							backgroundColor: 'white',
							borderRadius: '0 0 8px 8px',
							maxHeight: 400,
							position: 'absolute',
							boxSizing: 'border-box',
							border: `1px solid ${theme.oncoGrey300}`,
							top:
								// @ts-ignore
								ref.current?.getBoundingClientRect()?.top +
									// @ts-ignore
									ref.current?.getBoundingClientRect()?.height +
									5 ?? 0,
							// @ts-ignore
							left: ref.current?.getBoundingClientRect()?.left ?? 0,
							// @ts-ignore
							width: ref.current?.getBoundingClientRect()?.width ?? 100,
						}}
					>
						{hasSearchbar && (
							<StyledInput
								css={{
									width: 'fill-available',
									margin: '0 8px 8px',
									'&:focus': {
										outline: 'none',
									},
								}}
								onClick={(e) => e.stopPropagation()}
								onChange={(e) => setSearch(e.target.value)}
							/>
						)}
						<StyledColumn css={{ overflowY: 'auto' }}>
							{Object.keys(filteredOptions)?.map(
								(item: string, index: number) => (
									<StyledOption
										variant="body1"
										key={index}
										onClick={(e) => {
											e.stopPropagation();
											onChange(name, item);
										}}
									>
										{filteredOptions[item].label ??
											filteredOptions[item]}
									</StyledOption>
								),
							)}
						</StyledColumn>
					</StyledColumn>
				),
				document.body,
			)}
		</>
	);
};

export default Dropdown;
