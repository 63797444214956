import React from 'react';
import { useNavigate } from 'react-router-dom';
import { StyledRow } from '../../../../../common/styledCommonComponents';
import StyledText from '../../../../../common/components/Text';
import {
	CapitalizeText,
	getProfileImageFromName,
} from '../../../../../utils/utils';
import Button from '../../../../../common/components/Button';
import { ButtonVariantStyles } from '../../../../../common/components/Button/styles';
import { useOncoDispatch } from '../../../../../hooks/redux-hook';
import { closeModal } from '../../../../../redux/slices/utilsSlice';

interface PatientInfoProps {
	id: string;
	name: string;
	surname: string;
}

export const PatientInfo = ({ id, name, surname }: PatientInfoProps) => {
	const navigate = useNavigate();
	const dispatch = useOncoDispatch();

	const viewProfile = (id: string) => {
		navigate(`/profile/${id}`);
		dispatch(closeModal());
	};

	return (
		<StyledRow
			css={{
				padding: '8px',
				alignItems: 'center',
				alignSelf: 'stretch',
				justifyContent: 'space-between',
			}}
		>
			<StyledRow
				css={{
					alignItems: 'center',
					gap: '8px',
				}}
			>
				{getProfileImageFromName({
					name,
					surname,
					size: {
						width: '28px',
						height: '28px',
						fontSize: '12px',
					},
				})}
				<StyledText variant="body2">
					{CapitalizeText(name)} {CapitalizeText(surname)}
				</StyledText>
			</StyledRow>
			<Button
				onClick={() => viewProfile(id)}
				text={'Ver Perfil'}
				size={'small'}
				variant={'ghost'}
			/>
		</StyledRow>
	);
};
