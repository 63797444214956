import React from 'react';
import {IconProps} from "./IconInterface";

const HeartIcon = ({color = '#000000', width = 24, height = 24}: IconProps) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12 20C12.2312 20 12.5692 19.8292 12.8449 19.6585C17.8162 16.5848 21 12.9819 21 9.33618C21 6.1857 18.7322 4 15.8953 4C14.1255 4 12.8004 4.93917 12 6.33938C11.2174 4.93917 9.8834 4 8.11364 4C5.26779 4 3 6.1857 3 9.33618C3 12.9819 6.19269 16.5848 11.1551 19.6585C11.4308 19.8292 11.7688 20 12 20ZM9.13636 12.794C9.13636 12.7001 9.18083 12.6147 9.26087 12.5293L12.8893 8.18356C13.1917 7.82497 13.6897 8.07257 13.5119 8.508L12.3379 11.5304H14.5524C14.7302 11.5304 14.8903 11.667 14.8903 11.8378C14.8903 11.9317 14.8459 12.0256 14.7569 12.1025L11.1285 16.4482C10.8261 16.8068 10.3281 16.5592 10.5059 16.1153L11.6887 13.0928H9.47431C9.28755 13.0928 9.13636 12.9648 9.13636 12.794Z"
                fill={color}/>
        </svg>

    );
};
export default HeartIcon;
