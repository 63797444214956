import React from 'react';
import { IconProps } from './IconInterface';

interface ArrowSortProps extends IconProps {
	color2: string;
}

const ArrowSortIcon = ({
	color = '#000000',
	width = 20,
	height = 20,
	color2 = '#000000',
}: ArrowSortProps) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g id="Variants=sort">
				<path
					id="Vector"
					d="M11.3453 6.26591L8.10261 10.1714C8.03575 10.2549 8.00038 10.3496 8 10.4461C7.99963 10.5425 8.03427 10.6374 8.10048 10.7212C8.16668 10.805 8.26215 10.8748 8.37738 10.9237C8.49261 10.9726 8.62359 10.9989 8.75728 11H15.2427C15.3764 10.9989 15.5074 10.9726 15.6226 10.9237C15.7378 10.8748 15.8333 10.805 15.8995 10.7212C15.9657 10.6374 16.0004 10.5425 16 10.4461C15.9996 10.3496 15.9642 10.2549 15.8974 10.1714L12.6547 6.26591C12.5864 6.18474 12.4903 6.11762 12.3756 6.07105C12.261 6.02447 12.1316 6 12 6C11.8684 6 11.739 6.02447 11.6244 6.07105C11.5097 6.11762 11.4136 6.18474 11.3453 6.26591Z"
					fill={color}
				/>
				<path
					id="Vector_2"
					d="M11.3453 17.7341L8.10261 13.8286C8.03575 13.7451 8.00038 13.6504 8 13.5539C7.99963 13.4575 8.03427 13.3626 8.10048 13.2788C8.16668 13.195 8.26215 13.1252 8.37738 13.0763C8.49261 13.0274 8.62359 13.0011 8.75728 13H15.2427C15.3764 13.0011 15.5074 13.0274 15.6226 13.0763C15.7378 13.1252 15.8333 13.195 15.8995 13.2788C15.9657 13.3626 16.0004 13.4575 16 13.5539C15.9996 13.6504 15.9642 13.7451 15.8974 13.8286L12.6547 17.7341C12.5864 17.8153 12.4903 17.8824 12.3756 17.929C12.261 17.9755 12.1316 18 12 18C11.8684 18 11.739 17.9755 11.6244 17.929C11.5097 17.8824 11.4136 17.8153 11.3453 17.7341Z"
					fill={color2}
				/>
			</g>
		</svg>
	);
};

export default ArrowSortIcon;
