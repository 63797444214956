import React from 'react';
import { IconProps } from './IconInterface';

const RulerIcon = ({
	color = '#000000',
	width = 24,
	height = 24,
}: IconProps) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g id="tabler:ruler-measure">
				<path
					id="Vector"
					d="M12 16V18.6667M8 16V20M16 16V20M24 16V20M20 16V18.6667M4 4V9.33333M4 6.66667H28M28 4V9.33333M26.5 16C27.328 16 28 16.6827 28 17.524V25.1427C28 25.984 27.328 26.6667 26.5 26.6667H5.33333C4.97971 26.6667 4.64057 26.5262 4.39052 26.2761C4.14048 26.0261 4 25.687 4 25.3333V17.524C4 16.6827 4.672 16 5.5 16H26.5Z"
					stroke={color}
					stroke-width="2"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
			</g>
		</svg>
	);
};
export default RulerIcon;
