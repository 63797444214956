import React, { useState } from 'react';
import { StyledBox, StyledColumn } from '../../common/styledCommonComponents';
import NavItem from './NavItem';
import { useLocation } from 'react-router-dom';
import { CapitalizeText, getProfileImageFromName } from '../../utils/utils';
import { useTheme } from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { useAuth0 } from '@auth0/auth0-react';
import LogoutIcon from '../../assets/IconLogout';
import { NavbarConfig } from './config';

const Navbar = () => {
	let location = useLocation();

	const [navbarList] = useState(NavbarConfig);

	const { user, logout } = useAuth0();

	const theme = useTheme();

	return (
		<StyledColumn
			css={{
				width: '70px',
				minWidth: '70px',
				zIndex: 1,
				backgroundColor: theme.white,
				overflowX: 'hidden',
				height: '100vh',
				padding: '16px 0',
				borderRight: theme.gray100,
				position: 'relative',
				boxSizing: 'border-box',
				justifyContent: 'space-between',
			}}
		>
			<StyledColumn css={{ gap: 10 }}>
				<StyledBox
					css={{
						width: '100%',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						height: '109px',
					}}
				></StyledBox>
				{navbarList.map((item, index) => {
					const roles =
						user?.[
							(process.env.REACT_APP_AUTH0_AUDIENCE as string) + '/roles'
						];
					if (item?.roles.find((role) => roles?.includes(role))) {
						return (
							<NavItem
								key={'navbar-item-' + index}
								icon={item.icon}
								pathName={item.path}
								title={item.name}
								selected={
									!!item.path.find((path) =>
										location.pathname.includes(path),
									)
								}
								onClick={() => {}}
							/>
						);
					}
					return null;
				})}
			</StyledColumn>

			<StyledColumn css={{ gap: 10 }}>
				<NavItem
					key={'logout'}
					icon={LogoutIcon}
					pathName={['/login']}
					title={'Cerrar sesión'}
					selected={false}
					onClick={() => {
						logout({
							logoutParams: {
								returnTo: window.location.origin + '/login',
							},
						});
					}}
				/>
				<StyledBox
					id={'medic-tooltip-name'}
					css={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						width: '100%',
						marginBottom: '20px',
						cursor: 'default',
					}}
					data-tip
					data-for={'medic-tooltip-name'}
				>
					{getProfileImageFromName({
						name: user?.given_name ?? user?.nickname ?? '',
						surname: user?.family_name ?? '',
						size: { width: 32, height: 32, fontSize: '12px' },
					})}
					<ReactTooltip
						id={'medic-tooltip-name'}
						place="top"
						effect="solid"
					>
						{user?.nickname
							? CapitalizeText(user.nickname)
							: `${CapitalizeText(
									user?.given_name ?? '',
							  )} ${CapitalizeText(user?.family_name ?? '')}`}
					</ReactTooltip>
				</StyledBox>
			</StyledColumn>
		</StyledColumn>
	);
};
export default Navbar;
