import React from 'react';
import {
	StyledBox,
	StyledColumn,
} from '../../../../common/styledCommonComponents';
import StyledText from '../../../../common/components/Text';
import OralIcon from '../../../../assets/OralIcon';
import { useTheme } from 'styled-components';
import { categoryBiometric } from '../../../../utils/proctcae';
import { setModalOpen } from '../../../../redux/slices/utilsSlice';
import { CapitalizeText, ModalTypeEnum } from '../../../../utils/utils';
import { useOncoDispatch } from '../../../../hooks/redux-hook';
import ReactTooltip from 'react-tooltip';

interface ProctcaeCategoryCardProps {
	id: string;
	categoryName: string;
	answers: number;
	formId: string;
}
export const ProctcaeCategoryCard = ({
	id,
	categoryName,
	answers,
	formId,
}: ProctcaeCategoryCardProps) => {
	const theme = useTheme();
	const dispatch = useOncoDispatch();

	const category = categoryBiometric(categoryName, 40, 40);

	const handleOnClick = () => {
		dispatch(
			setModalOpen({
				type: ModalTypeEnum.WEEKLY_MODAL,
				categoryId: id,
				id: formId,
			}),
		);
	};

	return (
		<StyledColumn
			onClick={handleOnClick}
			css={{
				cursor: 'pointer',
				position: 'relative',
				minWidth: '140px',
				minHeight: '114px',
				padding: '16px',
				justifyContent: 'flex-end',
				alignItems: 'flex-start',
				gap: '8px',
				flex: '1 0 0',
				alignSelf: 'stretch',
				borderRadius: '16px',
				background: theme.white,
				boxShadow: '0px 1px 25px 0px rgba(175, 126, 255, 0.10)',
			}}
		>
			<StyledBox
				css={{
					display: ' flex',
					justifyContent: ' space-between',
					alignItems: ' flex-start',
					flex: '1 0 0',
					alignSelf: ' stretch',
				}}
			>
				<StyledColumn
					css={{
						justifyContent: 'flex-end',
						alignItems: 'flex-start',
						gap: '4px',
						alignSelf: 'stretch',
					}}
				>
					{category.icon}
					<StyledColumn
						css={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'flex-end',
							alignItems: 'flex-start',
							gap: '2px',
						}}
					>
						<StyledText variant="body3" css={{ color: theme.gray700 }}>
							Categoría
						</StyledText>
						<StyledText
							variant="body1Bold"
							css={{ color: theme.gray700 }}
						>
							{category.name}
						</StyledText>
					</StyledColumn>
				</StyledColumn>
				<StyledBox
					css={{
						position: 'absolute',
						right: '16px',
						display: 'flex',
						width: '24px',
						height: '24px',
						padding: '2px 4px',
						justifyContent: 'center',
						alignItems: 'center',
						gap: '8px',
						borderRadius: '24px',
						background: category.color,
					}}
				>
					<StyledBox
						css={{
							display: 'flex',
							alignItems: 'center',
							gap: '4px',
							cursor: 'pointer',
						}}
						data-tip
						data-for={'proctcae-card-' + category.name.toLowerCase()}
					>
						<StyledText variant="body3Bold" css={{ color: theme.white }}>
							{answers}
						</StyledText>
					</StyledBox>
					<ReactTooltip
						id={'proctcae-card-' + category.name.toLowerCase()}
						place="top"
						effect="solid"
						delayShow={1000}
					>
						{answers} síntomas reportados
					</ReactTooltip>
				</StyledBox>
			</StyledBox>
		</StyledColumn>
	);
};
