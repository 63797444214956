import React, { useEffect, useMemo, useState } from 'react';
import { useTheme } from 'styled-components';
import Chart from 'react-apexcharts';
import { useParams } from 'react-router-dom';
import { useGetPatientAnswersQuery } from '../../redux/api/patientApi';
import { MoodAndPainConfig } from '../../utils/chartsConfigs';
import { StyledCardHome } from '../Card/styles';
import { StyledBox, StyledHr } from '../../common/styledCommonComponents';
import StyledText from '../../common/components/Text';
import { StyledCircularProgress } from '../CustomCircularProgress/styles';
import { StyledH6 } from '../../common/components/Text/styles';
import { ErrorMessage } from '../../pages/PatientProfile/components/ErrorMessage';

const MoodAndPainChart = () => {
	const { patientId } = useParams();

	const {
		data: painData,
		isLoading: isLoadingPain,
		refetch: refetchPain,
		isError: isErrorPain,
	} = useGetPatientAnswersQuery(
		{
			category: 'PAIN',
			patientId: patientId ?? '',
		},
		{
			skip: !patientId,
			selectFromResult: (data) => {
				const painData: any = {
					data: [],
					categories: [],
				};
				data.data?.forEach((item: any) => {
					const date = new Date(item.date);
					const formattedDate = `${date.getDate()}/${date.getMonth() + 1}`;
					const answer: any = item.answer;

					if (answer !== null) {
						painData.data.push(answer);
					} else {
						painData.data.push(0.2);
					}
					painData.categories.push(formattedDate);
				});
				return { ...data, data: painData };
			},
		},
	);

	const {
		data: moodData,
		isLoading: isLoadingMood,
		refetch: refetchMood,
		isError: isErrorMood,
	} = useGetPatientAnswersQuery(
		{
			category: 'MOOD',
			patientId: patientId ?? '',
		},
		{
			skip: !patientId,
			selectFromResult: (data) => {
				const moodData: any = {
					data: [],
					categories: [],
				};
				data.data?.forEach((item: any) => {
					const date = new Date(item.date);
					const formattedDate = `${date.getDate()}/${date.getMonth() + 1}`;
					const answer: any = item.answer;

					if (answer !== null) {
						moodData.data.push(answer);
					} else {
						moodData.data.push(0.2);
					}
					moodData.categories.push(formattedDate);
				});
				return { ...data, data: moodData };
			},
		},
	);

	const refactorData = useMemo(() => {
		return {
			mood: [
				{
					data: moodData.data.reverse(),
					categories: moodData.categories.reverse(),
				},
			],
			pain: [
				{
					data: painData.data.reverse(),
					categories: painData.categories.reverse(),
				},
			],
		};
	}, [moodData, painData]);

	const theme = useTheme();
	const [active, setActive] = useState('mood');

	const options = useMemo(
		() => MoodAndPainConfig(refactorData ?? {}, active),
		[refactorData, active],
	);

	const activeData = refactorData[active as keyof typeof refactorData];

	const isEmpty = activeData[0]?.data?.length === 0;

	const handleMood = () => {
		ApexCharts.exec('mychart', 'hideSeries', ['pain']);
		ApexCharts.exec('mychart', 'showSeries', ['mood']);
		setActive('mood');
	};
	const handlePain = () => {
		ApexCharts.exec('mychart', 'hideSeries', ['mood']);
		ApexCharts.exec('mychart', 'showSeries', ['pain']);
		setActive('pain');
	};

	useEffect(() => {
		options && !options.isEmpty && handleMood();
	}, [options]);

	const handleRefetch = () => {
		if (active === 'mood') {
			refetchMood();
		} else {
			refetchPain();
		}
	};

	return (
		<StyledCardHome
			css={{
				display: 'flex',
				flexDirection: 'column',
				height: '280px',
				width: '490px',
				padding: '16px',
				alignItems: 'flex-start',
				gap: '8px',
				flexShrink: 0,
			}}
		>
			<StyledBox
				css={{
					display: 'flex',
					width: '100%',
					padding: '4px 4px 0 4px',
					justifyContent: 'space-between',
					alignItems: 'flex-end',
					alignSelf: 'center',
					borderBottom: `1px solid ${theme.gray50}`,
				}}
			>
				<StyledBox
					css={{
						display: 'flex',
						width: '100%',
						paddingBottom: '8px',
						justifyContent: 'center',
						textAlign: 'center',
						cursor: 'pointer',
						alignItems: 'flex-end',
						position: 'relative',
						flex: '1 0 0',
						borderBottom:
							active === 'mood'
								? `1px solid ${theme.primary500}`
								: 'none',
					}}
					onClick={() => setActive('mood')}
				>
					<StyledText
						variant="body2Bold"
						css={{
							color: active === 'mood' ? '#9357F7' : '#5C6B74',
						}}
					>
						Ánimo
					</StyledText>
				</StyledBox>
				<StyledBox
					css={{
						display: 'flex',
						paddingBottom: '8px',
						justifyContent: 'center',
						textAlign: 'center',
						alignItems: 'flex-end',
						width: '100%',
						flex: '1 0 0',
						position: 'relative',
						cursor: 'pointer',
						borderBottom:
							active === 'pain'
								? `1px solid ${theme.primary500}`
								: 'none',
					}}
					onClick={() => setActive('pain')}
				>
					<StyledText
						variant="body2Bold"
						css={{
							color: active === 'pain' ? '#9357F7' : '#5C6B74',
						}}
					>
						Dolor
					</StyledText>
				</StyledBox>
			</StyledBox>
			<StyledBox
				css={{
					display: 'flex',
					flex: 1,
					alignItems: 'center',
					justifyContent: 'center',
					width: '100%',
				}}
			>
				{isLoadingMood || isLoadingPain ? (
					<StyledBox
						css={{
							height: '100%',
							width: '100%',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<StyledCircularProgress />
					</StyledBox>
				) : isErrorMood || isErrorPain ? (
					<ErrorMessage refetch={handleRefetch} />
				) : isEmpty ? (
					<StyledBox
						css={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							height: '147px',
							width: '287px',
						}}
					>
						<StyledH6 css={{ color: theme.gray300 }}>Sin Datos</StyledH6>
					</StyledBox>
				) : (
					<Chart
						// @ts-ignore
						options={options.options}
						series={activeData}
						type="area"
						width={'440px'}
						height={220}
						toggleSeries={'mood'}
					/>
				)}
			</StyledBox>
		</StyledCardHome>
	);
};

export default MoodAndPainChart;
