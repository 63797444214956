import React from 'react';
const DailyModalIcon = () => {
	return (
		<svg
			width="32"
			height="32"
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M20.9096 13.8321H18.3815V13.0935H19.2082C19.7655 13.0935 20.3 12.8844 20.6951 12.5118C21.0903 12.1392 21.3139 11.6334 21.317 11.105V9.79829C21.317 9.85248 21.6578 9.82049 21.8216 9.80511L21.8219 9.80508L21.8303 9.8043C21.868 9.8008 21.895 9.79829 21.9041 9.79829C22.0501 9.77605 22.1822 9.70341 22.2751 9.59433C22.3681 9.48526 22.4151 9.34746 22.4073 9.20741C22.3719 8.99659 22.299 8.79294 22.1916 8.60522L21.9041 7.91204C21.7004 7.43485 21.4968 6.95757 21.3051 6.46898C21.3051 6.46898 21.1852 5.33271 21.0774 4.94641C20.7431 3.90716 20.0678 2.99627 19.1498 2.34635C18.2317 1.69642 17.1188 1.34141 15.9732 1.33302C14.8646 1.33111 13.783 1.65771 12.8797 2.26717C11.9764 2.87662 11.2964 3.73849 10.9347 4.73231C10.5731 5.72618 10.5479 6.8023 10.8626 7.81046C11.1773 8.81853 11.8163 9.70817 12.6902 10.3551V13.8321H10.4856C9.14281 13.8287 7.85194 14.3238 6.8891 15.2114C5.92627 16.099 5.36792 17.3087 5.3335 18.5818V25.4219C5.3335 27.631 7.12435 29.4219 9.33349 29.4219H22.3973C24.6064 29.4219 26.3973 27.631 26.3973 25.4219V19.3203C26.4211 18.6173 26.2978 17.9167 26.0342 17.2591C25.7706 16.6016 25.3721 16.0002 24.8617 15.4897C24.3513 14.9792 23.7392 14.5699 23.0608 14.2853C22.3824 14.0008 21.6513 13.8468 20.9096 13.8321Z"
				fill="#ECA485"
			/>
			<path
				d="M20.3132 22.5525C20.2111 22.6094 20.086 22.6663 20.0003 22.6663C19.9147 22.6663 19.7895 22.6094 19.6874 22.5525C17.8495 21.528 16.667 20.327 16.667 19.1117C16.667 18.0616 17.5069 17.333 18.5609 17.333C19.2164 17.333 19.7105 17.9329 20.0003 18.3997C20.2968 17.9329 20.7875 17.333 21.443 17.333C22.4937 17.333 23.3337 18.0616 23.3337 19.1117C23.3337 20.327 22.1545 21.528 20.3132 22.5525Z"
				fill="#FEE6DD"
			/>
		</svg>
	);
};

export default DailyModalIcon;
