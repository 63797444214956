import React from 'react';
import { StyledBox } from '../../common/styledCommonComponents';
import FrequentMedication from '../FrequentMedication';
import SummaryQuestionaries from '../../pages/Home/components/SummaryQuestionaries';

const WidgetHome = () => {
	return (
		<StyledBox
			css={{
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'space-between',
				gap: 20,
			}}
		>
			<SummaryQuestionaries />
			<FrequentMedication />
		</StyledBox>
	);
};

export default WidgetHome;
