import { api } from './api';

export const treatmentApi = api.injectEndpoints({
	endpoints: (builder) => ({
		endTreatment: builder.mutation({
			query: ({ body, treatmentId }) => ({
				url: `/treatment/end/${treatmentId}`,
				body,
				method: 'PUT',
			}),
			invalidatesTags: ['TreatmentList'],
		}),
		getMedication: builder.query<any, any>({
			query: () => ({
				url: '/medication',
				method: 'GET',
			}),
		}),
		getTreatmentIntentions: builder.query<any, any>({
			query: () => ({
				url: '/treatment/spec/TREATMENT_INTENSION',
				method: 'GET',
			}),
		}),
	}),
});

export const {
	useEndTreatmentMutation,
	useGetMedicationQuery,
	useLazyGetMedicationQuery,
} = treatmentApi;

export const {
	endpoints: { endTreatment, getMedication },
} = treatmentApi;
