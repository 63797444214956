import React from 'react';
import { StyledBox } from '../../common/styledCommonComponents';
import StyledText from '../../common/components/Text';
import { useTheme } from 'styled-components';
interface RoundCheckboxProps {
	checked: boolean;
	text: string;
	onClick: () => void;
}

export const RoundCheckbox: React.FC<RoundCheckboxProps> = ({
	checked,
	text,
	onClick,
}) => {
	const theme = useTheme();

	return (
		<StyledBox
			onClick={onClick}
			css={{
				cursor: 'pointer',
				width: '24px',
				height: '24px',
				borderRadius: '50%',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				background: checked ? theme.primary600 : theme.gray100,
			}}
		>
			<StyledText
				variant="body3"
				css={{ color: checked ? theme.white : theme.black }}
			>
				{text}
			</StyledText>
		</StyledBox>
	);
};
