import styled from 'styled-components';
import { StyledRow } from '../../../../common/styledCommonComponents';

export const StyledTumorProportionChart = styled(StyledRow)`
	.legend-circle {
		width: 10px !important;
		height: 10px !important;
		min-width: 10px;
		min-height: 10px;
		border-radius: 50px;
	}
	overflow-y: hidden;
`;
