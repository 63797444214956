import styled from 'styled-components';
import { StyledImg } from '../../../../common/styledCommonComponents';

export const StyledPreview = styled(StyledImg)`
	border-radius: 8px;
	border: 1px solid ${({ theme }) => theme.primary100};
	width: 267px;
	height: 163px;
	object-fit: cover;
`;
