import React from 'react';
import { IconProps } from '../IconInterface';

const CircularGenitalIcon = ({
	color = '#000000',
	width = 30,
	height = 30,
}: IconProps) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 30 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g id="Group 27543">
				<circle id="Ellipse 145" cx="15" cy="15" r="15" fill="#F9E3FF" />
				<g id="Group 345">
					<path
						id="Vector"
						d="M22.9993 14.666C22.8805 14.7528 22.7717 14.8929 22.6437 14.9185C21.2699 15.1873 20.1614 15.9375 19.1659 16.8633C17.8674 18.0721 16.8293 19.4616 16.3002 21.1845C16.2661 21.2969 16.2433 21.4128 16.2156 21.5273C16.1523 21.7882 15.988 21.9447 15.7178 21.9539C15.2438 21.9653 14.7697 21.9669 14.2957 21.9589C13.9899 21.9525 13.847 21.752 13.7652 21.4761C12.9233 18.641 11.1036 16.6322 8.50322 15.2996C8.16333 15.1254 7.76654 15.0635 7.40105 14.937C7.26315 14.88 7.12918 14.814 7 14.7393V14.3838C7.14222 13.6727 7.29083 12.9694 7.43518 12.2676H22.5634C22.5918 12.3913 22.6217 12.5044 22.6445 12.6189C22.7639 13.2313 22.8824 13.8433 23 14.4549L22.9993 14.666Z"
						fill="#CD7DE3"
					/>
					<path
						id="Vector_2"
						d="M22.4143 11.4499H7.5918C7.6629 11.0709 7.73401 10.7047 7.80157 10.3392C7.84281 10.1259 7.96796 10.0057 8.19124 10.0036C8.2979 10.0036 8.40456 10 8.51122 10H21.477C22.1092 10 22.1106 10 22.2429 10.608C22.3026 10.8824 22.3552 11.1612 22.4143 11.4499Z"
						fill="#CD7DE3"
					/>
				</g>
			</g>
		</svg>
	);
};
export default CircularGenitalIcon;
