import React, { useEffect, useState } from 'react';
import {
	StyledColumn,
	StyledScreen,
} from '../../common/styledCommonComponents';
import { TableContainer } from '@mui/material';
import { useGetDoctorsQuery } from '../../redux/api/listApi';
import TableComponent from '../../components/TableComponent';
import { DoctorsAcceptanceListHeaderConst } from './config';
import { UserListQueryParams } from '../../utils/types';
import ResultNotFound from '../../components/ResultNotFound';
import DoctorItem from './components/DoctorItem';

const DoctorsAcceptanceListScreen = () => {
	const pageSize = 8;
	const [params, setParams] = useState<UserListQueryParams>({ pageSize });

	const {
		isLoading: doctorInitalLoading,
		data,
		refetch,
	} = useGetDoctorsQuery(params);
	const [searchValue, setSearchValue] = useState<string>('');
	const [actualPage, setActualPage] = useState<number>(1);

	useEffect(() => {
		refetch();
	}, [params]);

	useEffect(() => {
		if (!searchValue) {
			setParams((prevParams) => {
				const newParams = { ...prevParams };
				delete newParams?.param;
				return newParams;
			});
		} else {
			setActualPage(1);
			setParams((prevParams) => {
				const newParams = { ...prevParams, param: searchValue };
				return newParams;
			});
		}
	}, [searchValue]);

	useEffect(() => {
		setParams((prevParams) => {
			const newParams = {
				...prevParams,
				firstPage: actualPage - 1,
			};
			return newParams;
		});
	}, [actualPage]);

	return (
		<>
			<StyledScreen
				css={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
				}}
			>
				<StyledColumn
					css={{
						margin: '20px',
						padding: '20px 40px',
						width: '100%',
						boxSizing: 'border-box',
						flex: 1,
					}}
				>
					<StyledColumn
						as={TableContainer}
						css={{
							width: 'calc(100vw - 40px)',
							height: '100%',
							maxWidth: '1124px',
							maxHeight: '824px',
							flex: 1,
							justifyContent: 'space-between',
						}}
					>
						<TableComponent
							search={setSearchValue}
							headerStructure={DoctorsAcceptanceListHeaderConst}
							dataList={data?.doctors}
							loading={doctorInitalLoading}
							actualPage={data ? data.actualPage + 1 : 0}
							totalPages={data ? data.maxPage + 1 : 0}
							handlePageChange={setActualPage}
							itemComponent={DoctorItem}
							emptyComponent={
								<ResultNotFound
									title={'No se han encontrado resultados'}
									text={'No hay ningún médico para aprobar'}
								/>
							}
						/>
					</StyledColumn>
				</StyledColumn>
			</StyledScreen>
		</>
	);
};
export default DoctorsAcceptanceListScreen;
