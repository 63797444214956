import React, { FC, ReactNode } from 'react';
import { CircularProgress } from '@mui/material';
import { StyledButton } from './styles';
import { CSSObject, useTheme } from 'styled-components';
import StyledText, { TextVariants } from '../Text/styles';
import { StyledTextContainer } from '../../styledCommonComponents';
import { IconProps } from '../../../assets/IconInterface';

export type ButtonVariant =
	| 'filled'
	| 'outlined'
	| 'ghost'
	| 'white'
	| 'destructive';
export type ButtonSize = 'small' | 'medium' | 'large';

interface ButtonProps {
	onClick?: () => void;
	text?: string;
	pending?: boolean;
	className?: string;
	icon?: FC<IconProps>;
	children?: ReactNode;
	disabled?: boolean;
	variant?: ButtonVariant;
	size?: ButtonSize;
	css?: CSSObject;
	width?: string;
	height?: string;
}

const Button = ({
	onClick,
	text,
	children,
	pending = false,
	className,
	icon: Icon,
	disabled,
	variant = 'filled',
	css,
	size = 'medium',
	width,
	height,
}: ButtonProps) => {
	const theme = useTheme();

	const iconSize = {
		small: 18,
		medium: 20,
		large: 20,
	};

	const iconColor = {
		filled: theme.white,
		outlined: theme.primary500,
		ghost: theme.primary500,
		white: theme.primary500,
		destructive: theme.oncoRed,
	};

	const sizeToClassMap = {
		small: 'buttonSmall',
		medium: 'buttonMedium',
		large: 'buttonLarge',
	};

	const sizeVariant =
		(sizeToClassMap[size] as TextVariants) || ('buttonSmall' as TextVariants);

	return (
		<StyledButton
			className={className}
			onClick={onClick}
			disabled={disabled}
			variant={variant}
			size={size}
			css={{
				width,
				height,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				...css,
			}}
		>
			{pending ? (
				// @ts-ignore
				<CircularProgress color={theme.white} size="0.95rem" />
			) : (
				<StyledTextContainer
					css={{ gap: '8px', alignItems: 'center', height: 22 }}
				>
					{Icon && (
						<Icon
							width={iconSize[size]}
							height={iconSize[size]}
							color={disabled ? theme.gray400 : iconColor[variant]}
						/>
					)}
					{children ??
						(text && (
							<StyledText variant={sizeVariant}>{text}</StyledText>
						))}
				</StyledTextContainer>
			)}
		</StyledButton>
	);
};

export default Button;
