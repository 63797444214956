import React, { ReactNode } from 'react';
import Card from '../../../../components/Card';
import { useGetPatientMedicDataSummaryQuery } from '../../../../redux/api/patientApi';
import { useParams } from 'react-router-dom';
import {
	StyledBox,
	StyledColumn,
	StyledRow,
} from '../../../../common/styledCommonComponents';
import StyledText from '../../../../common/components/Text';
import { theme } from '../../../../common/theme';
import { StyledCircularProgress } from '../../../../components/CustomCircularProgress/styles';
import { CapitalizeText } from '../../../../utils/utils';
import { StyledH6 } from '../../../../common/components/Text/styles';
import { ErrorMessage } from '../ErrorMessage';

export const OncologicalSummaryCard = () => {
	const prefixArray: string[] = ['tumor', 'nodule', 'metastasis'];
	const { patientId } = useParams();

	const {
		data: summaryData,
		refetch,
		isLoading,
		isError,
	} = useGetPatientMedicDataSummaryQuery(patientId, { skip: !patientId });

	return (
		<Card title={'Resumen oncológico'} width={'325px'} height={'199px'}>
			{isLoading ? (
				<StyledBox
					css={{
						height: '100%',
						width: '100%',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<StyledCircularProgress />
				</StyledBox>
			) : isError ? (
				<ErrorMessage refetch={refetch} />
			) : !summaryData.cancerOrgan ? (
				<StyledColumn
					css={{
						justifyContent: 'center',
						alignItems: 'center',
						height: '100%',
						width: '100%',
					}}
				>
					<StyledH6 css={{ color: theme.gray300 }}>Sin Datos</StyledH6>
					<StyledText variant="body3" css={{ color: theme.gray300 }}>
						Complete el{' '}
						<a href={`/validate-patient/${patientId}`}>
							formulario de aceptacion
						</a>
					</StyledText>
				</StyledColumn>
			) : (
				<StyledColumn
					css={{
						padding: '0px 4px',
						alignItems: 'flex-start',
						alignSelf: 'stretch',
					}}
				>
					<StyledColumn
						css={{
							alignItems: 'flex-start',
							gap: '8px',
							alignSelf: 'stretch',
							borderRadius: '8px',
							height: '100%',
						}}
					>
						<StyledColumn
							css={{
								display: 'flex',
								gap: '4px',
							}}
						>
							<StyledText
								variant={'body2Bold'}
								css={{ color: theme.primary500 }}
							>
								{CapitalizeText(summaryData.cancerOrgan)}
							</StyledText>
							<StyledText
								variant={'body3'}
								css={{ color: theme.textGrey }}
							>
								Tipo: {summaryData.cancerType}
							</StyledText>
							{summaryData.cancerSubtype && (
								<StyledText
									variant={'body3'}
									css={{ color: theme.textGrey }}
								>
									Subtipo: {summaryData.cancerSubtype}
								</StyledText>
							)}
						</StyledColumn>
						<StyledBox
							css={{
								display: 'flex',
								alignItems: 'flex-start',
								gap: '8px',
							}}
						>
							{prefixArray.map((prefix: string) => (
								<StyledBox
									css={{
										display: 'flex',
										padding: '4px',
										alignItems: 'center',
										gap: '4px',
										borderRadius: '8px',
										border: `1px solid ${theme.gray50}`,
									}}
								>
									{summaryData[prefix]}
								</StyledBox>
							))}
							<StyledBox
								css={{
									display: 'flex',
									padding: '4px',
									alignItems: 'center',
									gap: '4px',
									borderRadius: '8px',
									background: theme.gray50,
								}}
							>
								Estadío {summaryData.cancerStage}
							</StyledBox>
						</StyledBox>
					</StyledColumn>
				</StyledColumn>
			)}
		</Card>
	);
};
