import React from 'react';
import Modal from '../Modal';
import { StyledBox } from '../../../../../common/styledCommonComponents';
import TreatmentBody from './TreatmentBody';
import { useDispatch } from 'react-redux';
import { clearTreatmentModal } from '../../../../../redux/slices/treatmentSlice';
import StyledText, {
	StyledH5,
} from '../../../../../common/components/Text/styles';
import { useOncoSelector } from '../../../../../hooks/redux-hook';
import { valuesLastTreatment } from '../../../../../redux/slices/validationSlice';

interface TreatmentModalProps {
	handleOnClose: () => void;
}

const TreatmentModal = ({ handleOnClose }: TreatmentModalProps) => {
	const dispatch = useDispatch();

	const { prefix } = useOncoSelector((state) => valuesLastTreatment(state));
	const stage = useOncoSelector((state) => state.validationSlice.values.stage);

	const OnClose = async () => {
		await dispatch(clearTreatmentModal());
		handleOnClose();
	};

	const isSetback = prefix === 'treatment' && stage !== 'IV';

	return (
		<Modal
			width={'662'}
			onClose={OnClose}
			header={
				<StyledBox
					css={{
						display: 'flex',
						flexDirection: 'column',
						width: '100%',
					}}
				>
					<>
						<StyledH5 css={{ color: '#000', marginBottom: 4 }}>
							Finalizar tratamiento
						</StyledH5>
						<StyledText variant="body2" css={{ color: '#000' }}>
							Al agregar una nueva {isSetback ? 'recaida' : 'progresión'}
							, se finalizara el tratamiento actual y se debera iniciar
							uno nuevo
						</StyledText>
					</>
				</StyledBox>
			}
			body={<TreatmentBody onClose={OnClose} />}
		/>
	);
};

export default TreatmentModal;
