import React from 'react';
import { IconProps } from '../IconInterface';

const CircularVisualIcon = ({
	color = '#000000',
	width = 30,
	height = 30,
}: IconProps) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 30 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g id="Group 27543">
				<circle id="Ellipse 145" cx="15" cy="15" r="15" fill="#E3F5EA" />
				<path
					id="Vector"
					d="M15 11.5C14.7056 11.5052 14.4134 11.5578 14.1322 11.6562C14.2623 11.9135 14.3315 12.204 14.3333 12.5C14.3333 12.7298 14.2931 12.9574 14.2149 13.1697C14.1367 13.382 14.0222 13.5749 13.8777 13.7374C13.7333 13.8999 13.5618 14.0288 13.3731 14.1168C13.1843 14.2047 12.9821 14.25 12.7778 14.25C12.5147 14.2479 12.2565 14.1701 12.0278 14.0237C11.8473 14.7277 11.8684 15.4778 12.0879 16.1676C12.3074 16.8574 12.7143 17.452 13.2509 17.8672C13.7875 18.2824 14.4266 18.4972 15.0777 18.481C15.7288 18.4649 16.3589 18.2187 16.8787 17.7773C17.3986 17.3359 17.7818 16.7217 17.9741 16.0217C18.1664 15.3217 18.158 14.5713 17.9502 13.877C17.7424 13.1826 17.3456 12.5794 16.8161 12.1528C16.2866 11.7262 15.6513 11.4978 15 11.5ZM22.9033 14.5437C21.397 11.2372 18.4147 9 15 9C11.5853 9 8.60221 11.2388 7.09665 14.5441C7.03311 14.6855 7 14.8417 7 15.0002C7 15.1586 7.03311 15.3148 7.09665 15.4563C8.60304 18.7628 11.5853 21 15 21C18.4147 21 21.3978 18.7612 22.9033 15.4559C22.9669 15.3145 23 15.1583 23 14.9998C23 14.8414 22.9669 14.6852 22.9033 14.5437ZM15 19.5C12.2597 19.5 9.74749 17.7812 8.39082 15C9.74749 12.2188 12.2594 10.5 15 10.5C17.7406 10.5 20.2525 12.2188 21.6092 15C20.2528 17.7812 17.7406 19.5 15 19.5Z"
					fill="#52BF7E"
				/>
			</g>
		</svg>
	);
};
export default CircularVisualIcon;
