import styled from 'styled-components';
import { StyledRow } from '../../../common/styledCommonComponents';

export const StyledMedicationItemContainer = styled(StyledRow)`
  cursor: pointer;
  padding: 8px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.gray50};,
  background-color: white;
  
  &:hover {
    background-color: ${({ theme }) => theme.gray50};
  }
`;
