import { createSlice, current } from '@reduxjs/toolkit';
import { ModalTypeEnum, sortDays } from '../../utils/utils';
import { patientApi } from '../api/patientApi';

type initialStateUtilsType = {
	modalOpen: boolean;
	modalType?: ModalTypeEnum;
	reportId?: string;
	patientId?: string;
	treatment?: { id: string; prefix: '' | 'setbacks' | 'progressions' };
	prefix?: '' | 'setbacks' | 'progressions';
	frequentMedicationData?: any;
	medicalStatus?: string;
	symptom?: string;
	frequentSymptomData?: any;
	disable?: boolean;
	dailyConfiguration?: any;
	weeklyConfiguration?: any;
	stageData?: any;
	categoryId?: string;
	payload?: any;
};

const initialState: initialStateUtilsType = {
	modalOpen: false,
	modalType: undefined,
	reportId: '',
	patientId: '',
	treatment: { id: '', prefix: '' },
	prefix: '',
	medicalStatus: '',
	symptom: '',
	frequentSymptomData: undefined,
	disable: true,
	dailyConfiguration: {
		days: [],
		week: 0,
	},
	weeklyConfiguration: {
		days: [],
		week: 0,
	},
	categoryId: '',
};

export const utilsSlice = createSlice({
	name: 'utilsSlice',
	initialState,
	reducers: {
		setModalOpen: (state, action) => {
			state.modalOpen = action.payload.open;
			state.modalType = action.payload.type;
			state.reportId = action.payload?.id;
			state.patientId = action.payload?.patientId;
			state.prefix = action.payload?.prefix;
			state.frequentMedicationData = action.payload?.frequentMedicationData;
			state.stageData = action.payload?.stageData;
			state.medicalStatus = action.payload?.medicalStatus;
			state.symptom = action.payload?.symptom;
			state.frequentSymptomData = action.payload?.frequentSymptomData;
			state.disable = action.payload?.disable;
			state.payload = action.payload?.payload;
			if (action.payload?.dailyConfiguration) {
				state.dailyConfiguration = {
					days: sortDays(action.payload?.dailyConfiguration.days),
					week: action.payload?.dailyConfiguration.week,
				};
			}
			if (action.payload?.weeklyConfiguration) {
				state.weeklyConfiguration = {
					days: sortDays(action.payload?.weeklyConfiguration.days),
					week: action.payload?.weeklyConfiguration.week,
				};
			}
			state.categoryId = action.payload?.categoryId;
		},
		closeModal: (state) => {
			return initialState;
		},
		setReportId: (state, action) => {
			state.reportId = action.payload;
		},
		setTreatment: (state, action) => {
			state.treatment = action.payload;
		},
		setPrefix: (state, action) => {
			state.prefix = action.payload;
		},
		setMedicalStatus: (state, action) => {
			state.medicalStatus = action.payload;
		},
		setFrequentSymptomData: (state, action) => {
			state.frequentSymptomData = action.payload;
		},
		setDisable: (state, action) => {
			state.disable = action.payload;
		},
		updateWeekFrequency: (state, action) => {
			const type = action.payload.type;
			const week = action.payload.item;
			if (type === 'daily') {
				state.dailyConfiguration = {
					...state.dailyConfiguration,
					week: week,
				};
			} else {
				state.weeklyConfiguration = {
					...state.weeklyConfiguration,
					week: week,
				};
			}
		},
		updateDailyConfiguration: (state, action) => {
			const day = action.payload;
			const index = state.dailyConfiguration.days.indexOf(day);
			if (index >= 0) {
				state.dailyConfiguration.days.splice(index, 1);
			} else {
				state.dailyConfiguration.days.push(day);
			}
			sortDays(state.dailyConfiguration.days);
		},
		updateWeeklyConfiguration: (state, action) => {
			const day = action.payload;
			const index = state.weeklyConfiguration.days.indexOf(day);
			if (index >= 0) {
				state.weeklyConfiguration.days.splice(index, 1);
			} else {
				state.weeklyConfiguration.days.push(day);
			}
			sortDays(state.weeklyConfiguration.days);
		},
	},
	extraReducers: (builder) =>
		builder
			.addMatcher(
				patientApi.endpoints.getPatientData.matchFulfilled,
				(state: any, action: any) => {
					state.medicalStatus = action.payload.medicalStatus;
				},
			)
			.addMatcher(
				patientApi.endpoints.updatePatientStatus.matchFulfilled,
				(state: any, action: any) => {
					state.medicalStatus = action.meta.arg.originalArgs.newStatus;
				},
			)
			.addMatcher(
				patientApi.endpoints.getPatientSchedule.matchFulfilled,
				(state: any, action: any) => {
					const dailyConfig = action.payload.find(
						(item: any) =>
							item.formId === '22677030-b1ab-4ec3-8b6c-5cbeb2e39a15',
					);
					if (dailyConfig) {
						state.dailyConfiguration = JSON.parse(
							dailyConfig.configuration,
						);
					}
					const weeklyConfig = action.payload.find(
						(item: any) =>
							item.formId === 'b7945eb2-8342-4ab3-9f6f-dfea14999e7a',
					);
					if (weeklyConfig) {
						state.weeklyConfiguration = JSON.parse(
							weeklyConfig.configuration,
						);
					}
				},
			),
});

export const {
	setModalOpen,
	closeModal,
	setReportId,
	setTreatment,
	setPrefix,
	setMedicalStatus,
	setFrequentSymptomData,
	setDisable,
	updateWeekFrequency,
	updateDailyConfiguration,
	updateWeeklyConfiguration,
} = utilsSlice.actions;
export default utilsSlice.reducer;
