import React from 'react';

const DoubleChevronLeftIcon = ({
	color,
	width,
	height,
}: {
	color: string;
	width: number;
	height: number;
}) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M12.6002 18.8599C13.1333 18.3269 13.1333 17.4658 12.6002 16.9328L7.29727 11.6298L12.6002 6.32689C13.1333 5.79386 13.1333 4.93281 12.6002 4.39978C12.0672 3.86675 11.2062 3.86675 10.6731 4.39978L4.39977 10.6731C3.86674 11.2062 3.86674 12.0672 4.39977 12.6002L10.6731 18.8736C11.1925 19.3929 12.0672 19.3929 12.6002 18.8599Z"
				fill={color}
			/>
			<path
				d="M20.6002 18.8599C21.1333 18.3269 21.1333 17.4658 20.6002 16.9328L15.2973 11.6298L20.6002 6.32689C21.1333 5.79386 21.1333 4.93281 20.6002 4.39978C20.0672 3.86675 19.2062 3.86675 18.6731 4.39978L12.3998 10.6731C11.8667 11.2062 11.8667 12.0672 12.3998 12.6002L18.6731 18.8736C19.1925 19.3929 20.0672 19.3929 20.6002 18.8599Z"
				fill={color}
			/>
		</svg>
	);
};
export default DoubleChevronLeftIcon;
