import { StyledBox, StyledP } from '../../../../common/styledCommonComponents';
import React from 'react';
import { StyledPatientWaitingContainer } from './styles';
import {
	CapitalizeText,
	getProfileImageFromName,
} from '../../../../utils/utils';
import { useNavigate } from 'react-router-dom';

interface PatientWaitingItemProps {
	name: string;
	surname: string;
	dni: string;
	css?: any;
	patientProfileId: string;
}

const PatientWaitingItem = ({
	name,
	surname,
	dni,
	css,
	patientProfileId,
}: PatientWaitingItemProps) => {
	const navigation = useNavigate();
	return (
		<StyledPatientWaitingContainer
			onClick={() => navigation(`/validate-patient/${patientProfileId}`)}
		>
			{getProfileImageFromName({
				name: name + ' ' + surname,
				size: {
					width: 28,
					height: 28,
					fontSize: '12px',
				},
			})}
			<StyledBox
				css={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					marginLeft: '6px',
				}}
			>
				<StyledP
					css={{
						fontWeight: 500,
						margin: '0',
						fontSize: '11px',
						maxHeight: '12px',
						textOverflow: 'ellipsis',
						overflowY: 'clip',
						whiteSpace: 'nowrap',
						maxWidth: '120px',
					}}
				>
					{CapitalizeText(name) + ' ' + CapitalizeText(surname)}
				</StyledP>
				<StyledP css={{ fontWeight: 400, margin: '0', fontSize: '10px' }}>
					{dni}
				</StyledP>
			</StyledBox>
		</StyledPatientWaitingContainer>
	);
};

export default PatientWaitingItem;
