import { createSlice, current } from '@reduxjs/toolkit';
import {
	actionTypeEnum,
	InputTypeEnum,
	TreatmentStatusType,
} from '../../utils/utils';
import { v4 as uuidv4 } from 'uuid';
import { patientApi } from '../api/patientApi';
import { FieldType } from './formSlice';

type initialStateTreatmentType = {
	fields: FieldType[][] | object[][];
	values: {
		finishDate?: string;
		endingMotive?: string;
	};
	edit: boolean;
	medications: {
		[key in string]: { grammages: { id: string; label: string }[] };
	};
	status: TreatmentStatusType;
};

const initialState: initialStateTreatmentType = {
	fields: [
		[
			{
				label: 'Fecha de finalización',
				placeholder: 'XX/XX/XX',
				input_type: InputTypeEnum.DATEFIELD,
				name: 'finishDate',
				required: true,
			},
			{
				label: 'Razon de finalización',
				options: {
					no_value: 'Seleccione una razon',
					Reason1: 'Razon 1',
					Reason2: 'Razon 2',
					Reason3: 'Razon 3',
				},
				input_type: InputTypeEnum.SELECTOR,
				name: 'endingMotive',
				required: true,
			},
		],
	],
	values: {
		finishDate: '',
		endingMotive: '',
	},
	edit: false,
	medications: {},
	status: TreatmentStatusType.WITHOUT_TREATMENT,
};

const treatmentSlice = createSlice({
	name: 'treatmentSlice',
	initialState,
	reducers: {
		setValue: (state, action) => {
			state.values = {
				...state.values,
				[action.payload.name]: action.payload.value,
			};
			const aux = current(state.fields);
		},
		addNewMedication: (
			state,
			action: {
				payload: {
					edited?: boolean;
					medicationValue?: string;
					grammageValue?: string;
				};
			},
		) => {
			const indexCorrection: number =
				state.fields.length -
				(state.edit
					? 4
					: state.status === TreatmentStatusType.IS_FINISHED
					? 5
					: 3);
			const medications: FieldType[][] | object[][] = state.fields.slice(
				0,
				indexCorrection,
			);
			const fixedRows: FieldType[][] | object[][] = state.fields.slice(
				indexCorrection,
				state.fields.length,
			);

			const newID = uuidv4();

			const medicationToAdd: FieldType = {
				id: newID,
				input_type: InputTypeEnum.MEDICATION_TREATMENT_ROW,
				names: ['medication', 'grammage'],
				disabled: !action.payload.edited,
			};

			state.fields = [...medications, [medicationToAdd], ...fixedRows];

			state.values = {
				...state.values,
				[`medication${medicationToAdd.id}`]:
					action.payload?.medicationValue ?? '',
				[`grammage${medicationToAdd.id}`]:
					action.payload?.grammageValue ?? '',
				// @ts-ignore
				medicationIds: [...state.values.medicationIds, medicationToAdd.id],
			};
		},
		switchToEdit: (state) => {
			state.fields = state.fields.map((column) =>
				column.map((field: { disabled?: boolean }) => ({
					...field,
					disabled: !field.disabled,
				})),
			);
			state.edit = !state.edit;
			if (state.edit) {
				const medications = state.fields.slice(0, state.fields.length - 3);
				const fixedRows = state.fields.slice(
					state.fields.length - 3,
					state.fields.length,
				);

				state.fields = [
					...medications,
					[
						{
							label: 'Añadir Medicación',
							type: 'text',
							input_type: InputTypeEnum.ADD_SECTION,
							// @ts-ignore
							handleClick: () =>
								actionTypeEnum.ADD_MEDICATION_TREATMENT_MODAL,
						},
					],
					...fixedRows,
				];
			}
		},
		clearTreatmentModal: (state) => {
			state.fields = initialState.fields;
			state.values = initialState.values;
			state.status = initialState.status;
			state.edit = initialState.edit;
		},
	},
	extraReducers: (builder) => {
		builder.addMatcher(
			patientApi.endpoints.getTreatmentById.matchFulfilled,
			(state, action) => {
				let currentState = current(state);
				state.fields = currentState.fields.slice(1);

				state.values = {
					...state.values,
					...action.payload,
					medicationIds: [],
				};
				state.status = action.payload.finishDate
					? TreatmentStatusType.IS_FINISHED
					: TreatmentStatusType.HAS_TREATMENT;

				if (action.payload.finishDate) {
					state.fields = [
						state.fields[0],
						[
							{
								label: 'Fecha de Finalización',
								placeholder: 'XX/XX/XX',
								input_type: InputTypeEnum.DATEFIELD,
								name: 'finishDate',
								disabled: true,
							},
							{},
						],
						...state.fields.slice(1),
						[
							{
								label: 'Progreso',
								// placeholder: 'El objetivo de este tratamiento es reducir los sintomas de caracter cutaneo presentes en el paciente..',
								type: 'text',
								input_type: InputTypeEnum.SELECTOR,
								disabled: true,
								options: {
									true: 'Si',
									false: 'No',
								},
								name: 'progress',
							},
							{
								label: 'Razon de finalización',
								// placeholder: 'El objetivo de este tratamiento es reducir los sintomas de caracter cutaneo presentes en el paciente..',
								type: 'text',
								input_type: InputTypeEnum.SELECTOR,
								disabled: true,
								options: {
									Toxicity: 'Toxicidad',
									DiseaseProgression: 'Progresión de la enfermedad',
									DoctorOrPatientDecision:
										'Decisión de paciente/médico',
									CompletedTreatment: 'Completó el tratamiento',
									Other: 'Otros',
								},
								name: 'endingMotive',
							},
						],
					];
					if (action.payload.otherMotive) {
						state.fields = [
							...state.fields,
							[
								{
									label: 'Otros motivos',
									// placeholder: 'El objetivo de este tratamiento es reducir los sintomas de caracter cutaneo presentes en el paciente..',
									type: 'text',
									input_type: InputTypeEnum.TEXTFIELD,
									name: 'otherMotive',
									disabled: true,
								},
							],
						];
					}
				}

				action.payload?.medications?.forEach(
					(medication: { medicationId: string; grammageId: string }) => {
						treatmentSlice.caseReducers.addNewMedication(state, {
							payload: {
								medicationValue: medication.medicationId,
								grammageValue: medication.grammageId,
							},
						});
					},
				);

				let medications: {
					[key in string]: { grammages: { id: string; label: string }[] };
				} = {};
				action.payload.medications?.forEach(
					(medication: {
						typeOfMedication: string;
						medicationId: string;
						medication: string;
						grammageId: string;
						grammage: string;
					}) => {
						medications[medication.typeOfMedication] = {
							...medications[medication.typeOfMedication],
							[medication.medicationId]: {
								id: medication.medicationId,
								label: medication.medication,
								grammages: [
									...(medications[medication.medicationId]
										?.grammages ?? []),
									{
										id: medication.grammageId,
										label: medication.grammage,
									},
								],
							},
						};
					},
				);
				state.medications = medications;
			},
		);
	},
});

export const { setValue, addNewMedication, switchToEdit, clearTreatmentModal } =
	treatmentSlice.actions;
export default treatmentSlice.reducer;
