import { api } from './api';

export const sessionApi = api.injectEndpoints({
	endpoints: (builder) => ({
		login: builder.mutation({
			query: (credentials) => ({
				url: '/user/medic-login',
				method: 'POST',
				body: credentials,
			}),
		}),
		medicSignup: builder.mutation({
			query: (credentials) => ({
				url: '/user/medic-signup',
				method: 'POST',
				body: {
					...credentials,
					institutionId: '10ed72f8-631f-4304-812e-4a687c921138',
					diseaseId: '50d8d95e-2fde-11ee-be56-0242ac120002',
				},
			}),
		}),
		roleCheck: builder.query<any, void>({
			query: () => ({
				url: '/user/validated',
			}),
		}),
	}),
});

export const {
	useLoginMutation,
	useLazyRoleCheckQuery,
	useRoleCheckQuery,
	useMedicSignupMutation,
} = sessionApi;

export const {
	endpoints: { login, roleCheck, medicSignup },
} = sessionApi;
