import styled from 'styled-components';
import {
	CustomCssProps,
	StyledBox,
} from '../../../common/styledCommonComponents';

interface StyledNavbarItemProps extends CustomCssProps {
	selected: boolean;
}

export const StyledNavbarItemContainer = styled(
	StyledBox,
)<StyledNavbarItemProps>`
	height: 40px;
	width: 70px;
	text-align: center;
	background-color: ${(props) =>
		props.selected ? props.theme.primary100 : props.theme.white};
	display: flex;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	margin-bottom: 4px;
	position: relative;
	&:hover {
		background-color: ${(props) => props.theme.primary100};
		svg {
			path {
				fill: ${(props) => props.theme.primary500} !important;
			}
		}
	}
`;
