import HomeIcon from '../../assets/HomeIcon';
import PatientsListIcon from '../../assets/PatientsListIcon';
import HeartIcon from '../../assets/HeartIcon';
import { ComponentType } from 'react';
import { UserRole } from '../../utils/types';
import { IconProps } from '../../assets/IconInterface';
import DoctorIcon from '../../assets/DoctorIcon';

type NavbarConfigType = {
	name: string;
	path: string[];
	icon: ComponentType<IconProps>;
	roles: UserRole[];
};

export const NavbarConfig: NavbarConfigType[] = [
	{
		name: 'Inicio',
		path: ['/home'],
		icon: HomeIcon,
		roles: [
			UserRole.ADMIN,
			UserRole.AUXILIAR,
			UserRole.DOCTOR,
			UserRole.DOCTOR_ADMIN,
		],
	},
	{
		name: 'Mis Pacientes',
		path: ['/my-patients', '/profile', '/validate-patient'],
		icon: PatientsListIcon,
		roles: [
			UserRole.ADMIN,
			UserRole.AUXILIAR,
			UserRole.DOCTOR,
			UserRole.DOCTOR_ADMIN,
		],
	},
	{
		name: 'Aceptar Médico',
		path: ['/aprobar-medico'],
		icon: DoctorIcon,
		roles: [UserRole.ADMIN, UserRole.DOCTOR_ADMIN],
	},
	{
		name: 'Mis Auxiliares',
		path: ['/mi-equipo'],
		icon: HeartIcon,
		roles: [UserRole.ADMIN, UserRole.DOCTOR, UserRole.DOCTOR_ADMIN],
	},
	// {
	// 	name: 'Archivos',
	// 	path: ['/archivos'],
	// 	icon: ArchiveIcon,
	// 	roles: [UserRole.ADMIN, UserRole.BETA],
	// },
];
