import React from 'react';
import { CSSObject } from 'styled-components';

const ChevronRightIcon = ({
	color,
	width,
	height,
	styles,
}: {
	color: string;
	width: number;
	height: number;
	styles?: CSSObject;
}) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			style={styles}
		>
			<path
				d="M8.39977 4.4015C7.86674 4.93453 7.86674 5.79557 8.39977 6.3286L13.7027 11.6316L8.39977 16.9345C7.86674 17.4676 7.86674 18.3286 8.39977 18.8616C8.9328 19.3947 9.79385 19.3947 10.3269 18.8616L16.6002 12.5883C17.1333 12.0553 17.1333 11.1942 16.6002 10.6612L10.3269 4.38783C9.80752 3.86847 8.9328 3.86847 8.39977 4.4015Z"
				fill={color}
			/>
		</svg>
	);
};
export default ChevronRightIcon;
