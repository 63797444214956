import React, { useCallback } from 'react';
import Modal from '../Modal';
import {
	StyledColumn,
	StyledInput,
	StyledRow,
} from '../../../../../common/styledCommonComponents';
import StyledText from '../../../../../common/components/Text';
import Button from '../../../../../common/components/Button';
import { useDropzone } from 'react-dropzone';
import { StyledH5 } from '../../../../../common/components/Text/styles';
import { StyledDragAndDrop } from './styles';
import { theme } from '../../../../../common/theme';

interface AddFileProps {
	handleOnClose: () => void;
}

const AddStudyModal = ({ handleOnClose }: AddFileProps) => {
	const onDrop = useCallback((acceptedFiles) => {
		// Do something with the files
	}, []);
	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
	});
	return (
		<Modal
			width={'560px'}
			header={
				<StyledColumn css={{ gap: 8 }}>
					<StyledH5>Agregar nuevo estudio</StyledH5>
					<StyledText variant={'body2'}>
						Carga el estudio que posees en formato PDF o DOC y,
						posteriormente, selecciona el tipo de estudio correspondiente
					</StyledText>
				</StyledColumn>
			}
			body={
				<StyledColumn
					css={{
						maxHeight: 'inherit',
						gap: 24,
						width: '100%',
					}}
				>
					<StyledColumn
						css={{
							overflowY: 'auto',
							flex: 1,
						}}
					>
						<StyledDragAndDrop
							{...getRootProps()}
							css={{
								backgroundColor: isDragActive
									? theme.primary200
									: theme.primary100,
							}}
						>
							<StyledInput {...getInputProps()} />
							<svg
								width="40"
								height="40"
								viewBox="0 0 40 40"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<circle cx="20" cy="20" r="20" fill="#9357F7" />
								<path
									d="M22 10H14C12.89 10 12 10.89 12 12V28C12 29.11 12.89 30 14 30H21.81C21.28 29.09 21 28.05 21 27C21 26.67 21.03 26.33 21.08 26H14V24H21.81C22.27 23.2 22.91 22.5 23.68 22H14V20H26V21.08C26.33 21.03 26.67 21 27 21C27.33 21 27.67 21.03 28 21.08V16L22 10ZM21 17V11.5L26.5 17H21ZM26 23V26H23V28H26V31H28V28H31V26H28V23H26Z"
									fill="#F9F1FF"
								/>
							</svg>
							<StyledColumn css={{ alignItems: 'center', gap: 4 }}>
								<StyledRow css={{ gap: 4 }}>
									<StyledText
										variant={'body1'}
										css={{
											color: theme.primary500,
										}}
									>
										Arrastra y suelte
									</StyledText>
									<StyledText
										variant={'body1Bold'}
										css={{
											color: theme.primary500,
										}}
									>
										o elige el archivo
									</StyledText>
								</StyledRow>
								<StyledText variant={'body3'}>
									Formatos permitidos: PDF, JPG, PNG
								</StyledText>
							</StyledColumn>
						</StyledDragAndDrop>
					</StyledColumn>

					<StyledRow
						css={{
							flexDirection: 'row',
							justifyContent: 'flex-end',
							width: '100%',
							gap: 16,
						}}
					>
						<Button
							variant={'outlined'}
							size={'medium'}
							css={{
								width: 100,
							}}
							onClick={handleOnClose}
						>
							Cerrar
						</Button>
						<Button
							variant={'filled'}
							size={'medium'}
							css={{
								width: 100,
							}}
							onClick={handleOnClose}
						>
							Agregar
						</Button>
					</StyledRow>
				</StyledColumn>
			}
			onClose={handleOnClose}
		/>
	);
};

export default AddStudyModal;
