import React from 'react';
import { IconProps } from './IconInterface';

const VisualIcon = ({
	color = '#000000',
	width = 24,
	height = 24,
}: IconProps) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 41 40"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M20.7943 15.1382C20.3036 15.1454 19.8165 15.2185 19.348 15.3553C19.5647 15.7126 19.6801 16.1161 19.6832 16.5271C19.6832 16.8463 19.6161 17.1624 19.4858 17.4573C19.3555 17.7521 19.1645 18.0201 18.9238 18.2458C18.6831 18.4715 18.3973 18.6505 18.0827 18.7727C17.7682 18.8948 17.431 18.9577 17.0906 18.9577C16.6521 18.9548 16.2217 18.8466 15.8406 18.6434C15.5398 19.6212 15.5749 20.6629 15.9407 21.621C16.3066 22.579 16.9847 23.4049 17.8791 23.9816C18.7734 24.5582 19.8386 24.8565 20.9238 24.8341C22.009 24.8117 23.0591 24.4697 23.9255 23.8567C24.7919 23.2436 25.4306 22.3906 25.7511 21.4184C26.0716 20.4461 26.0576 19.404 25.7113 18.4396C25.3649 17.4752 24.7036 16.6374 23.8212 16.0449C22.9387 15.4524 21.8797 15.1352 20.7943 15.1382ZM33.9665 19.3657C31.4559 14.7732 26.4855 11.666 20.7943 11.666C15.1031 11.666 10.1313 14.7754 7.62202 19.3661C7.51612 19.5625 7.46094 19.7795 7.46094 19.9996C7.46094 20.2196 7.51612 20.4366 7.62202 20.633C10.1327 25.2255 15.1031 28.3327 20.7943 28.3327C26.4855 28.3327 31.4573 25.2233 33.9665 20.6326C34.0724 20.4362 34.1276 20.2192 34.1276 19.9991C34.1276 19.7791 34.0724 19.5621 33.9665 19.3657ZM20.7943 26.2493C16.2271 26.2493 12.0401 23.8622 9.77897 19.9993C12.0401 16.1365 16.2267 13.7493 20.7943 13.7493C25.3619 13.7493 29.5485 16.1365 31.8096 19.9993C29.5489 23.8622 25.3619 26.2493 20.7943 26.2493Z"
				fill={color}
			/>
		</svg>
	);
};
export default VisualIcon;
