import { api } from './api';
import { clearPatientList, saveParams } from '../slices/listSlice';

export const listApi = api.injectEndpoints({
	endpoints: (builder) => ({
		waitingList: builder.query<any, void>({
			query: () => ({
				url: '/user/patients/waiting',
			}),
		}),
		filterPatient: builder.query({
			query: (arg) => ({
				url: `/user/patients`,
				params: arg.params,
			}),
			async onQueryStarted(arg, { dispatch }) {
				dispatch(clearPatientList());
				dispatch(saveParams(arg));
			},
			providesTags: ['UserList'],
		}),
		orderPatients: builder.query({
			query: (arg) => ({
				url: `/home/order-patients/${arg.column}`,
				params: arg.params,
			}),
			async onQueryStarted(arg, { dispatch }) {
				dispatch(clearPatientList());
				dispatch(saveParams(arg));
			},
			providesTags: ['UserList'],
		}),
		patientsList: builder.query<any, { skip?: number; take?: number } | void>(
			{
				query: (params) => ({
					url: '/home/patients-table' + params,
				}),
				providesTags: ['UserList'],
			},
		),
		fixedPatients: builder.query<any, void>({
			query: () => ({
				url: '/user/patients/fixed',
			}),
			providesTags: ['UserList'],
		}),
		patientsListWithParams: builder.query({
			query: (params) => ({
				url: '/home/patients-table',
				params,
			}),
			providesTags: ['UserList'],
			async onQueryStarted(arg, { dispatch }) {
				dispatch(clearPatientList());
				dispatch(saveParams(arg));
			},
		}),

		/// AUXILIAR
		searchAuxiliar: builder.query({
			query: (params) => ({
				url: '/user/assistants/search',
				params,
			}),
			providesTags: ['AssistantsList'],
		}),
		getAuxiliar: builder.query({
			query: (params) => ({
				url: '/user/assistants/team',
				params,
			}),
			providesTags: ['AssistantsList'],
		}),
		getAuxiliarAdmin: builder.query({
			query: (params) => ({
				url: '/user/admin/doctors',
				params,
			}),
			providesTags: ['AssistantsList'],
		}),
		addAuxiliar: builder.mutation({
			query: (assistantId: string) => ({
				url: `/user/assistants/team/${assistantId}`,
				method: 'POST',
			}),
			invalidatesTags: ['AssistantsList'],
		}),
		removeAuxiliar: builder.mutation<void, string>({
			query: (assistantId) => ({
				url: `/user/assistants/team/${assistantId}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['AssistantsList'],
		}),
		removeAuxiliarDoctor: builder.mutation<void, string>({
			query: (doctorId) => ({
				url: `/user/admin/doctors/${doctorId}/INACTIVE}`,
				method: 'PATCH',
			}),
			invalidatesTags: ['AssistantsList'],
		}),

		// DOCTOR
		getDoctors: builder.query<any, any>({
			query: (params) => ({
				url: '/user/waiting/doctors',
				params,
			}),
			providesTags: ['DoctorList'],
		}),
	}),
});

export const {
	useWaitingListQuery,
	// AUXILIAR
	useSearchAuxiliarQuery,
	useLazyGetAuxiliarQuery,
	useLazyGetAuxiliarAdminQuery,
	useAddAuxiliarMutation,
	useRemoveAuxiliarMutation,
	useRemoveAuxiliarDoctorMutation,
	useFilterPatientQuery,
	useFixedPatientsQuery,
	useLazyOrderPatientsQuery,
	usePatientsListQuery,
	useLazyPatientsListWithParamsQuery,
	useGetDoctorsQuery,
	useLazyGetDoctorsQuery,
} = listApi;
