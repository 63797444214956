import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';

interface PublicRouteProps {
	hasAccess?: boolean;
}

const PublicRoute = ({ hasAccess }: PublicRouteProps) => {
	if (hasAccess) {
		return <Navigate to="/home" replace />;
	}
	return <Outlet />;
};

export default PublicRoute;
