import styled from 'styled-components';
import { StyledRow } from '../../../../../../common/styledCommonComponents';

export const StyledReportItemContainer = styled(StyledRow)`
	display: flex;
	padding: 16px;
	align-items: center;
	gap: 10px;
	align-self: stretch;
	cursor: pointer;
	border: 1px solid ${({ theme }) => theme.gray50};
	border-radius: 8px;
	&:hover {
		background-color: ${({ theme }) => theme.gray50};
	}
`;
