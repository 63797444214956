import { StyledBox } from '../../common/styledCommonComponents';
import React from 'react';
import { CSSObject, DefaultTheme, useTheme } from 'styled-components';
import { StyledCardHome } from './styles';
import StyledText from '../../common/components/Text';

interface CardProps {
	title?: string;
	children: React.ReactNode;
	headerComponent?: React.ReactNode;
	width: string;
	height?: string;
	css?: CSSObject;
	childrenCss?: CSSObject;
}

const Card = ({
	title,
	children,
	headerComponent,
	width,
	height,
	css,
	childrenCss,
}: CardProps) => {
	const theme = useTheme() as DefaultTheme;
	return (
		<StyledCardHome
			css={{
				minWidth: `${width} !important`,
				minHeight: `${height} !important`,
				width: `${width} !important`,
				height: `${height} !important`,

				// padding: '16px',
				...css,
			}}
		>
			{headerComponent ?? (
				<StyledBox
					css={{
						display: 'flex',
						paddingBottom: 8,
						justifyContent: 'space-between',
						alignItems: 'flex-end',
						alignSelf: 'stretch',
						borderBottom: `1px solid ${theme.gray50}`,
					}}
				>
					<StyledText
						variant="body2Bold"
						css={{
							color: theme.gray700,
							height: '17px',
						}}
					>
						{title}
					</StyledText>
				</StyledBox>
			)}
			<StyledBox
				css={{
					overflow: 'auto',
					width: '100%',
					justifyContent: 'center',
					alignItems: 'center',
					flex: 1,
					gap: 16,
					flexDirection: 'column',
					...childrenCss,
				}}
			>
				{children}
			</StyledBox>
		</StyledCardHome>
	);
};

export default Card;
