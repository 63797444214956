import React, { useEffect, useRef, useState } from 'react';
import { useTheme } from 'styled-components';
import FeedbackCloseIcon from '../../assets/FeedbackCloseIcon';
import FeedbackIcon from '../../assets/FeedbackIcon';
import Button from '../../common/components/Button';
import StyledText from '../../common/components/Text';
import { StyledBox, StyledRow } from '../../common/styledCommonComponents';
import { useOnClickOutside } from 'usehooks-ts';
import { useCreateFeedbackMutation } from '../../redux/api/feedbackApi';
import { StyledCircularProgress } from '../CustomCircularProgress/styles';
import { FeedbackSuccess } from './FeedbackSuccess';
import { toastError } from '../../pages/DoctorsAcceptanceList/utils';

const reportTypeOptions = [
	{
		name: 'GENERAL',
		label: 'General',
	},
	{
		name: 'ERROR',
		label: 'Error',
	},
	{
		name: 'IDEA',
		label: 'Idea',
	},
];

export const Feedback = () => {
	const theme = useTheme();

	const [isOpen, setIsOpen] = useState(false);
	const [selectedReportType, setSelectedReportType] = useState('GENERAL');
	const [comment, setComment] = useState('');

	const handleOpen = () => {
		setIsOpen(true);
	};

	const handleClose = () => {
		setIsOpen(false);
		reset();
		setSelectedReportType('GENERAL');
		setComment('');
	};

	const ref = useRef(null);

	useOnClickOutside(ref, handleClose);

	const [
		createFeedback,
		{ isLoading, isError, isSuccess, isUninitialized, reset },
	] = useCreateFeedbackMutation();

	const handleSubmit = () => {
		createFeedback({
			type: selectedReportType,
			comment,
		});
	};

	useEffect(() => {
		if (isError) {
			toastError(
				<>
					<StyledText variant="body1Bold">Algo salió mal </StyledText>{' '}
					<StyledText variant="body2">
						Intente de nuevo mas tarde
					</StyledText>
				</>,
			);
		}
	}, [isError]);

	return (
		<StyledBox
			ref={ref}
			css={{
				width: '56px',
				height: '56px',
				borderRadius: '100rem',
				backgroundColor: theme.primary500,
				position: 'fixed',
				bottom: '24px',
				right: '24px',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			}}
		>
			{isOpen ? (
				<FeedbackCloseIcon onClick={handleClose} cursor="pointer" />
			) : (
				<FeedbackIcon onClick={handleOpen} cursor="pointer" />
			)}
			{isOpen && (
				<StyledBox
					css={{
						minWidth: '350px',
						minHeight: '340px',
						padding: '16px',
						position: 'absolute',
						bottom: '110%',
						right: 0,
						backgroundColor: '#fff',
						boxShadow: '0px 0px 20px 0px #0000002B',
						borderRadius: '12px',
						borderWidth: '1px',
						zIndex: 100,
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					{(isUninitialized || isLoading || isError) && (
						<>
							<StyledText variant="body2" css={{ marginBottom: '8px' }}>
								Tipo de reporte
							</StyledText>
							<StyledRow
								css={{
									justifyContent: 'space-between',
									borderRadius: '8px',
									border: `1px solid ${theme.gray100}`,
									overflow: 'hidden',
									marginBottom: '16px',
								}}
							>
								{reportTypeOptions.map((reportType, i, arr) => {
									return (
										<Button
											onClick={() =>
												setSelectedReportType(reportType.name)
											}
											disabled={isLoading}
											className={isLoading ? 'disabled' : undefined}
											css={{
												borderRadius: 0,
												flex: 1,
												textAlign: 'center',
												padding: '12px',
												backgroundColor:
													reportType.name === selectedReportType
														? theme.primary500
														: 'transparent',
												color:
													reportType.name === selectedReportType
														? theme.white
														: theme.black,
												borderRight:
													i !== arr.length - 1
														? `1px solid ${theme.gray100}`
														: 'none',
											}}
										>
											{reportType.label}
										</Button>
									);
								})}
							</StyledRow>

							<StyledText variant="body2" css={{ marginBottom: '8px' }}>
								Comentario
							</StyledText>
							<textarea
								disabled={isLoading}
								value={comment}
								onChange={(e) => setComment(e.target.value)}
								placeholder="Escribe tu comentario..."
								style={{
									minHeight: '140px',
									borderColor: theme.gray200,
									borderRadius: '8px',
									borderWidth: '1px',
									padding: '12px 16px 12px 16px',
									resize: 'none',
									marginBottom: '16px',
								}}
							/>

							<Button
								width="100%"
								disabled={comment.length === 0 || isLoading}
								onClick={handleSubmit}
							>
								{isLoading ? (
									<StyledCircularProgress size={18} />
								) : (
									'Enviar'
								)}
							</Button>
						</>
					)}

					{isSuccess && <FeedbackSuccess handleClose={handleClose} />}
				</StyledBox>
			)}
		</StyledBox>
	);
};
