import React from 'react';
import { useOncoDispatch, useOncoSelector } from '../../../../hooks/redux-hook';
import {
	addNewMedication,
	removeMedication,
	updateMedication,
	valuesMedication,
} from '../../../../redux/slices/validationSlice';
import { StyledColumn } from '../../../../common/styledCommonComponents';
import Button from '../../../../common/components/Button';
import MedicationField from '../MedicationField';
import StyledText from '../../../../common/components/Text';
import PlusCircleIcon from '../../../../assets/PlusCircleIcon';

interface MedicationContainerProps {
	id: string;
	editable: boolean;
	disabled: boolean;
}

const MedicationContainer = ({
	id,
	editable,
	disabled,
}: MedicationContainerProps) => {
	const medications = useOncoSelector((state) =>
		valuesMedication(state, { id }),
	);
	const errors = useOncoSelector((state) => state.validationSlice.errors);
	const dispatch = useOncoDispatch();

	const handleAddNewMedication = () => {
		dispatch(addNewMedication({ id }));
	};

	const handleRemoveMedication = (idMed: string) => {
		dispatch(removeMedication({ id, idMed }));
	};

	const handleOnChange = (name: string, value: string) => {
		dispatch(updateMedication({ id, idMed: name, value: value }));
	};

	return (
		<StyledColumn css={{ flex: 1, gap: 24 }}>
			<StyledText variant={'body1Bold'}>Medicamentos o esquemas</StyledText>
			{medications?.map(
				(medication: { id: string; medicationId: string }) => (
					<MedicationField
						name={medication.id}
						key={medication.id}
						value={medication.medicationId}
						onRemove={handleRemoveMedication}
						onChange={handleOnChange}
						hasRemove={medications.length > 1 && editable}
						error={errors?.[`${id}_${medication.id}`]?.properties?.[1]}
						disabled={disabled}
					/>
				),
			)}
			{editable && !disabled && (
				<Button
					css={{ width: '100%' }}
					onClick={handleAddNewMedication}
					variant={'outlined'}
					size={'medium'}
					icon={PlusCircleIcon}
				>
					Agregar Medicamento
				</Button>
			)}{' '}
		</StyledColumn>
	);
};
export default MedicationContainer;
