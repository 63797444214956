import React from 'react';

const IconUnderWear = (props) => {
	return (
		<svg
			width="30"
			height="30"
			viewBox="0 0 30 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle cx="15" cy="15" r="15" fill="#F9E3FF" />
			<path
				d="M22.9993 14.666C22.8805 14.7528 22.7717 14.8929 22.6437 14.9185C21.2699 15.1873 20.1614 15.9375 19.1659 16.8633C17.8674 18.0721 16.8293 19.4616 16.3002 21.1845C16.2661 21.2969 16.2433 21.4128 16.2156 21.5273C16.1523 21.7882 15.988 21.9447 15.7178 21.9539C15.2438 21.9653 14.7697 21.9669 14.2957 21.9589C13.9899 21.9525 13.847 21.752 13.7652 21.4761C12.9233 18.641 11.1036 16.6322 8.50322 15.2996C8.16333 15.1254 7.76654 15.0635 7.40105 14.937C7.26315 14.88 7.12918 14.814 7 14.7393V14.3838C7.14222 13.6727 7.29083 12.9694 7.43518 12.2676H22.5634C22.5918 12.3913 22.6217 12.5044 22.6445 12.6189C22.7639 13.2313 22.8824 13.8433 23 14.4549L22.9993 14.666Z"
				fill="#CD7DE3"
			/>
			<path
				d="M22.4162 11.4499H7.59375C7.66486 11.0709 7.73597 10.7047 7.80352 10.3392C7.84476 10.1259 7.96991 10.0057 8.19319 10.0036C8.29985 10.0036 8.40651 10 8.51318 10H21.479C22.1112 10 22.1126 10 22.2448 10.608C22.3046 10.8824 22.3572 11.1612 22.4162 11.4499Z"
				fill="#CD7DE3"
			/>
		</svg>
	);
};

export default IconUnderWear;
