import React, { useMemo, useState } from 'react';
import { useTheme } from 'styled-components';
import Chart from 'react-apexcharts';
import { useParams } from 'react-router-dom';
import { useMetricsByTypeQuery } from '../../redux/api/patientApi';
import { StyledCardHome } from '../Card/styles';
import { StyledBox } from '../../common/styledCommonComponents';
import StyledText from '../../common/components/Text';
import { StyledCircularProgress } from '../CustomCircularProgress/styles';
import { StyledH6 } from '../../common/components/Text/styles';
import { ErrorMessage } from '../../pages/PatientProfile/components/ErrorMessage';
import { MetricConfig } from '../../utils/chartsConfigs';

const MetricChart = () => {
	const { patientId } = useParams();

	const {
		data: pulseData,
		isLoading: isLoadingPulse,
		refetch: refetchPulse,
		isError: isErrorPulse,
	} = useMetricsByTypeQuery(
		{
			metric: 'HEART_RATE',
			patientId: patientId ?? '',
		},
		{
			skip: !patientId,
			selectFromResult: (data) => {
				const pulseData: any = {
					data: [],
					categories: [],
				};
				data.data?.forEach((item: any) => {
					const date = new Date(item.measurementDate);
					const formattedDate = `${date.getDate()}/${date.getMonth() + 1}`;
					const answer: any = item.value;
					pulseData.data.push(answer);
					pulseData.categories.push(formattedDate);
				});
				return { ...data, data: pulseData };
			},
		},
	);

	const {
		data: weightData,
		isLoading: isLoadingWeight,
		refetch: refetchWeight,
		isError: isErrorWeight,
	} = useMetricsByTypeQuery(
		{
			metric: 'WEIGHT',
			patientId: patientId ?? '',
		},
		{
			skip: !patientId,
			selectFromResult: (data) => {
				const weightData: any = {
					data: [],
					categories: [],
				};
				data.data?.forEach((item: any) => {
					const date = new Date(item.measurementDate);
					const formattedDate = `${date.getDate()}/${date.getMonth() + 1}`;
					const answer: any = item.value;
					weightData.data.push(answer);
					weightData.categories.push(formattedDate);
				});
				return { ...data, data: weightData };
			},
		},
	);

	const {
		data: SYSData,
		isLoading: isLoadingSys,
		refetch: refetchSYS,
		isError: isErrorSys,
	} = useMetricsByTypeQuery(
		{
			metric: 'SYSTOLIC_PRESSURE',
			patientId: patientId ?? '',
		},
		{
			skip: !patientId,
			selectFromResult: (data) => {
				const SYSData: any = {
					data: [],
					categories: [],
				};
				data.data?.forEach((item: any) => {
					const date = new Date(item.measurementDate);
					const formattedDate = `${date.getDate()}/${date.getMonth() + 1}`;
					const answer: any = item.value;
					SYSData.data.push(answer);
					SYSData.categories.push(formattedDate);
				});
				return { ...data, data: SYSData };
			},
		},
	);

	const {
		data: DIAData,
		isLoading: isLoadingDia,
		refetch: refetchDIA,
		isError: isErrorDia,
	} = useMetricsByTypeQuery(
		{
			metric: 'DIASTOLIC_PRESSURE',
			patientId: patientId ?? '',
		},
		{
			skip: !patientId,
			selectFromResult: (data) => {
				const DIAData: any = {
					data: [],
					categories: [],
				};
				data.data?.forEach((item: any) => {
					const date = new Date(item.measurementDate);
					const formattedDate = `${date.getDate()}/${date.getMonth() + 1}`;
					const answer: any = item.value;
					DIAData.data.push(answer);
					DIAData.categories.push(formattedDate);
				});
				return { ...data, data: DIAData };
			},
		},
	);

	const {
		data: TEMPData,
		isLoading: isLoadingTEMP,
		refetch: refetchTEMP,
		isError: isErrorTEMP,
	} = useMetricsByTypeQuery(
		{
			metric: 'TEMPERATURE',
			patientId: patientId ?? '',
		},
		{
			skip: !patientId,
			selectFromResult: (data) => {
				const TEMPData: any = {
					data: [],
					categories: [],
				};
				data.data?.forEach((item: any) => {
					const date = new Date(item.measurementDate);
					const formattedDate = `${date.getDate()}/${date.getMonth() + 1}`;
					const answer: any = item.value;
					TEMPData.data.push(answer);
					TEMPData.categories.push(formattedDate);
				});
				return { ...data, data: TEMPData };
			},
		},
	);

	const refactorData = useMemo(() => {
		return {
			weight: [
				{
					data: weightData.data.reverse(),
					categories: weightData.categories.reverse(),
				},
			],
			pulse: [
				{
					data: pulseData.data.reverse(),
					categories: pulseData.categories.reverse(),
				},
			],
			SYS_DIA: [
				{
					name: 'SYS',
					data: SYSData.data.reverse(),
					categories: SYSData.categories.reverse(),
				},
				{
					name: 'DIA',
					data: DIAData.data.reverse(),
					categories: DIAData.categories.reverse(),
				},
			],
			TEMP: [
				{
					data: TEMPData.data.reverse(),
					categories: TEMPData.categories.reverse(),
				},
			],
		};
	}, [weightData, pulseData, SYSData, DIAData, TEMPData]);

	const theme = useTheme();
	const [active, setActive] = useState('weight');

	const options = useMemo(
		() =>
			refactorData.pulse?.length
				? MetricConfig(refactorData ?? {}, active)
				: {},
		[refactorData, active],
	);

	const activeData = refactorData[active as keyof typeof refactorData];

	const isEmpty = activeData[0]?.data?.length === 0;

	const handleRefetch = () => {
		if (active === 'weight') {
			refetchWeight();
		} else if (active === 'pulse') {
			refetchPulse();
		} else if (active === 'SYS_DIA') {
			refetchSYS();
			refetchDIA();
		} else {
			refetchTEMP();
		}
	};

	const isLoading =
		isLoadingDia ||
		isLoadingSys ||
		isLoadingPulse ||
		isLoadingWeight ||
		isLoadingTEMP;
	const isError =
		(active === 'SYS_DIA' && (isErrorDia || isErrorSys)) ||
		(active === 'pulse' && isErrorPulse) ||
		(active === 'weight' && isErrorWeight) ||
		(active === 'TEMP' && isErrorTEMP);

	return (
		<StyledCardHome
			css={{
				display: 'flex',
				flexDirection: 'column',
				height: '280px',
				width: '310px',
				padding: '16px',
				alignItems: 'flex-start',
				gap: '8px',
				flexShrink: 0,
			}}
		>
			<StyledBox
				css={{
					display: 'flex',
					width: '100%',
					minHeight: 33,
					padding: '4px 4px 0 4px',
					justifyContent: 'space-between',
					alignItems: 'flex-end',
					overflowX: 'auto',
					alignSelf: 'center',
					overflowY: 'hidden',
					borderBottom: `1px solid ${theme.gray50}`,
					'&::-webkit-scrollbar': {
						height:
							'2px' /* height of horizontal scrollbar ← You're missing this */,
						// width: '4px',               /* width of vertical scrollbar */
						// border: '1px solid #d5d5d5',
					},
				}}
			>
				<StyledBox
					css={{
						display: 'flex',
						paddingBottom: '8px',
						justifyContent: 'center',
						textAlign: 'center',
						cursor: 'pointer',
						alignItems: 'flex-end',
						paddingLeft: 8,
						paddingRight: 8,
						minWidth: 80,
						position: 'relative',
						borderBottom:
							active === 'weight'
								? `1px solid ${theme.primary500}`
								: 'none',
					}}
					onClick={() => setActive('weight')}
				>
					<StyledText
						variant="body2Bold"
						css={{
							color:
								active === 'weight' ? theme.primary500 : theme.gray700,
						}}
					>
						Peso
					</StyledText>
				</StyledBox>
				<StyledBox
					css={{
						display: 'flex',
						paddingBottom: '8px',
						justifyContent: 'center',
						textAlign: 'center',
						cursor: 'pointer',
						alignItems: 'flex-end',
						position: 'relative',
						paddingLeft: 8,
						paddingRight: 8,
						minWidth: 80,
						borderBottom:
							active === 'SYS_DIA'
								? `1px solid ${theme.blue500}`
								: 'none',
					}}
					onClick={() => setActive('SYS_DIA')}
				>
					<StyledText
						variant="body2Bold"
						css={{
							color:
								active === 'SYS_DIA' ? theme.blue500 : theme.gray700,
						}}
					>
						P.A.
					</StyledText>
				</StyledBox>
				<StyledBox
					css={{
						display: 'flex',
						paddingLeft: 8,
						paddingRight: 8,
						minWidth: 80,
						paddingBottom: '8px',
						justifyContent: 'center',
						textAlign: 'center',
						cursor: 'pointer',
						alignItems: 'flex-end',
						position: 'relative',
						borderBottom:
							active === 'pulse'
								? `1px solid ${theme.green500}`
								: 'none',
					}}
					onClick={() => setActive('pulse')}
				>
					<StyledText
						variant="body2Bold"
						css={{
							color: active === 'pulse' ? theme.green500 : theme.gray700,
						}}
					>
						F.C
					</StyledText>
				</StyledBox>
				<StyledBox
					css={{
						display: 'flex',
						paddingBottom: '8px',
						justifyContent: 'center',
						textAlign: 'center',
						cursor: 'pointer',
						position: 'relative',
						alignItems: 'flex-end',
						paddingLeft: 8,
						paddingRight: 8,
						borderBottom:
							active === 'TEMP' ? `1px solid ${theme.pink500}` : 'none',
					}}
					onClick={() => setActive('TEMP')}
				>
					<StyledText
						variant="body2Bold"
						css={{
							color: active === 'TEMP' ? theme.pink500 : theme.gray700,
						}}
					>
						Temperatura
					</StyledText>
				</StyledBox>
			</StyledBox>
			<StyledBox
				css={{
					display: 'flex',
					flex: 1,
					alignItems: 'center',
					justifyContent: 'center',
					width: '100%',
				}}
			>
				{isLoading ? (
					<StyledBox
						css={{
							height: '100%',
							width: '100%',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<StyledCircularProgress />
					</StyledBox>
				) : isError ? (
					<ErrorMessage refetch={handleRefetch} />
				) : isEmpty ? (
					<StyledBox
						css={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							height: '147px',
							width: '287px',
						}}
					>
						<StyledH6 css={{ color: theme.gray300 }}>Sin Datos</StyledH6>
					</StyledBox>
				) : (
					<Chart
						// @ts-ignore
						options={options.options}
						series={activeData}
						type="bar"
						width={287}
						height={210}
						toggleSeries={'weight'}
					/>
				)}
			</StyledBox>
		</StyledCardHome>
	);
};

export default MetricChart;
