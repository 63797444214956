import React from 'react';
import { StyledBox } from '../../../../common/styledCommonComponents';
import StyledText from '../../../../common/components/Text';
import { theme } from '../../../../common/theme';

export const statusMap: { [key: string]: string } = {
	Activo: 'Activo',
	Inactivo: 'Inactivo',
	Rechazado: 'Rechazado',
	Muerto: 'Muerto',
	'En aprobación': 'En aprobación',
	Urgencia: 'Urgencia',
	'Bajo observación': 'Bajo observación',
	'Esperando cirugia': 'Esperando cirugia',
	'Esperando resultados': 'Esperando resultados',
	Recuperado: 'Recuperado',
};

export const PatientStatus = (status: string) => {
	let statusStyling;

	switch (status) {
		case 'Activo':
		case 'Esperando cirugia':
		case 'Esperando resultados':
			statusStyling = {
				background: theme.info300,
				color: theme.info700,
			};
			break;
		case 'Requiere atención':
		case 'Urgencia':
			statusStyling = {
				background: theme.error300,
				color: theme.error700,
			};
			break;
		case 'Bajo observación':
			statusStyling = {
				background: theme.warning300,
				color: theme.warning700,
			};
			break;
		case 'Recuperado':
			statusStyling = {
				background: theme.success200,
				color: theme.success700,
			};
			break;
		default:
			statusStyling = {
				background: theme.gray100,
				color: theme.gray800,
			};
			break;
	}

	return (
		<StyledBox
			css={{
				display: 'inline-flex',
				padding: '4px 12px',
				alignItems: 'flex-start',
				gap: '10px',
				borderRadius: '20px',
				...statusStyling,
			}}
		>
			<StyledText variant="body3" css={{ width: 'max-content' }}>
				{' '}
				{status}{' '}
			</StyledText>
		</StyledBox>
	);
};
