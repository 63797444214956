import React from 'react';
import { StyledRow } from '../../../../../../../common/styledCommonComponents';
import {
	CapitalizeText,
	getProfileImageFromName,
} from '../../../../../../../utils/utils';
import StyledText from '../../../../../../../common/components/Text';
import Button from '../../../../../../../common/components/Button';
import { useNavigate } from 'react-router-dom';
import CircleInprogressIcon from '../../../../../../../assets/CircleInprogressIcon';
import { useTheme } from 'styled-components';
import CircleCheckIcon from '../../../../../../../assets/CircleCheckIcon';
import CircleCloseIcon from '../../../../../../../assets/CircleCloseIcon';
import { useOncoDispatch } from '../../../../../../../hooks/redux-hook';
import { closeModal } from '../../../../../../../redux/slices/utilsSlice';

interface PatientItemProps {
	name: string;
	surname: string;
	id: string;
	formStatus: 'EMPTY' | 'COMPLETE' | 'INCOMPLETE';
}

const PatientItem = ({ name, surname, id, formStatus }: PatientItemProps) => {
	const navigate = useNavigate();
	const theme = useTheme();
	const dispatch = useOncoDispatch();

	const colorStatus = {
		EMPTY: theme.gray500,
		COMPLETE: theme.green500,
		INCOMPLETE: theme.error400,
	};

	const handleViewProfile = (id: string) => {
		navigate(`/profile/${id}`);
		dispatch(closeModal());
	};
	return (
		<StyledRow
			css={{
				padding: 8,
				justifyContent: 'space-between',
				width: '100%',
				alignItems: 'center',
			}}
		>
			<StyledRow css={{ gap: 8, alignItems: 'center' }}>
				{getProfileImageFromName({
					name,
					surname,
					size: {
						width: '28px',
						height: '28px',
						fontSize: '12px',
					},
					color: colorStatus[formStatus],
				})}
				<StyledText variant="body2">
					{CapitalizeText(name)} {CapitalizeText(surname)}
				</StyledText>
			</StyledRow>
			<Button
				onClick={() => handleViewProfile(id)}
				size={'small'}
				variant={'ghost'}
			>
				Ver Perfil
			</Button>
		</StyledRow>
	);
};

export default PatientItem;
