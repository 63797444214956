import React from 'react';
import Modal from '../Modal';
import {
	StyledColumn,
	StyledRow,
} from '../../../../../common/styledCommonComponents';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import Button from '../../../../../common/components/Button';
import { useTheme } from 'styled-components';
import { StyledH5 } from '../../../../../common/components/Text/styles';

interface PreviewFileProps {
	handleOnClose: () => void;
}

const PreviewFileModal = ({ handleOnClose }: PreviewFileProps) => {
	const theme = useTheme();
	const docs = [
		{
			uri: 'https://testbucket-32432534534.s3.amazonaws.com/BOO-205_Bug_Hunting.pdf',
		},
	];

	const LoadingComponent = () => {
		return (
			<StyledColumn
				css={{
					flex: 1,
					backgroundColor: 'red',
					height: 100,
					width: '100%',
				}}
			></StyledColumn>
		);
	};

	return (
		<Modal
			header={<StyledH5>Archivo.tsx</StyledH5>}
			body={
				<StyledColumn
					css={{
						'#pdf-controls': {
							display: 'none',
						},
						padding: 16,
						// height: 'calc(90vh - 90px)'
						maxHeight: 'inherit',
						gap: 24,
					}}
				>
					<StyledColumn
						css={{
							overflowY: 'auto',
							flex: 1,
							border: `1px solid ${theme.gray100}`,
							borderRadius: 8,
						}}
					>
						<DocViewer
							documents={docs}
							pluginRenderers={DocViewerRenderers}
							config={{
								header: {
									disableHeader: true,
								},
								csvDelimiter: ',', // "," as default,
								pdfZoom: {
									defaultZoom: 1.1, // 1 as default,
									zoomJump: 0.2, // 0.1 as default,
								},
								pdfVerticalScrollByDefault: true, // false as default
								loadingRenderer: {
									overrideComponent: LoadingComponent,
								},
							}}
						/>
					</StyledColumn>
					<StyledRow
						css={{
							flexDirection: 'row-reverse',
							width: '100%',
						}}
					>
						<Button
							variant={'filled'}
							size={'medium'}
							css={{
								width: 100,
							}}
							onClick={handleOnClose}
						>
							Hecho
						</Button>
					</StyledRow>
				</StyledColumn>
			}
			onClose={handleOnClose}
		/>
	);
};

export default PreviewFileModal;
