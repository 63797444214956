export enum ValidationType {
	IS_REQUIRED = 'IS_REQUIRED',
	IS_STRING = 'IS_STRING',
	IS_EMAIL = 'IS_EMAIL',
	IS_NUMBER = 'IS_NUMBER',
	IS_POSITIVE = 'IS_POSITIVE',
	IS_NEGATIVE = 'IS_NEGATIVE',
	IS_BOOLEAN = 'IS_BOOLEAN',
	IS_DATE = 'IS_DATE',
	IS_PRESENT_DATE = 'IS_PRESENT_DATE',
	IS_PAST_DATE = 'IS_PAST_DATE',
	IS_PRESENT_PAST_DATE = 'IS_PRESENT_PAST_DATE',
	IS_FUTURE_DATE = 'IS_FUTURE_DATE',
}

export const validate = (
	value: string | number | boolean,
	validations: ValidationType[],
): string[] => {
	const isValid = (
		value: string | number | boolean,
		validation: ValidationType,
	) => {
		switch (validation) {
			case ValidationType.IS_REQUIRED:
				return validateRequired(value);
			case ValidationType.IS_STRING:
				return validateString(value);
			case ValidationType.IS_EMAIL:
				return validateEmail(value);
			case ValidationType.IS_NUMBER:
				return validateNumber(value);
			case ValidationType.IS_POSITIVE:
				return validatePositiveNumber(value);
			case ValidationType.IS_NEGATIVE:
				return validateNegativeNumber(value);
			case ValidationType.IS_BOOLEAN:
				return validateBoolean(value);
			case ValidationType.IS_DATE:
				return validateDate(value);
			case ValidationType.IS_PRESENT_DATE:
				return validatePresentDate(value);
			case ValidationType.IS_PAST_DATE:
				return validatePastDate(value);
			case ValidationType.IS_FUTURE_DATE:
				return validateFutureDate(value);
			case ValidationType.IS_PRESENT_PAST_DATE:
				return validatePresentOrPastDate(value);
			default:
				return false;
		}
	};

	let errorList: string[] = [];

	validations.forEach((validation) => {
		const valid = isValid(value, validation);
		if (valid) {
			errorList = [...errorList, validation.toString()];
		}
	});

	return errorList;
};

export const validateRequired = (value: any) => {
	if (
		value === '' ||
		value === null ||
		value === undefined ||
		value === 'no_value' ||
		value === '0'
	) {
		return true;
	}
	return false;
};

export const validateString = (value: any): boolean => {
	if (
		typeof value !== 'string' ||
		value === null ||
		value === undefined ||
		!isNaN(parseInt(value, 10))
	) {
		return true;
	}
	return false;
};

export const validateNumber = (value: any) => {
	const number = parseInt(value, 10);
	if (typeof number !== 'number' || value === null || value === undefined) {
		return true;
	}
	return false;
};

export const validateFloat = (value: any) => {
	const number = parseFloat(value);
	if (typeof number !== 'number' || value === null || value === undefined) {
		return true;
	}
	return false;
};

export const validatePositiveNumber = (value: any) => {
	const number = parseInt(value, 10);
	if (
		typeof number !== 'number' ||
		value === null ||
		value === undefined ||
		number < 0
	) {
		return true;
	}
	return false;
};

export const validateNegativeNumber = (value: any) => {
	const number = parseInt(value, 10);
	if (
		typeof number !== 'number' ||
		value === null ||
		value === undefined ||
		number > 0
	) {
		return true;
	}
	return false;
};

export const validateBoolean = (value: any) => {
	if (
		value !== 'true' ||
		value !== 'false' ||
		value === null ||
		value === undefined
	) {
		return true;
	}
	return false;
};

export const validateEmail = (value: any) => {
	const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
	if (!emailRegex.test(value)) {
		return true;
	}
	return false;
};

export const validateGender = (value: any) => {
	if (
		value === 'Masculino' ||
		value === 'Femenino' ||
		value === '' ||
		value === null ||
		value === undefined
	) {
		return true;
	}
	return false;
};

export const validateDate = (value: any) => {
	const date = new Date(value);
	if (date === null || date === undefined) {
		return true;
	}
	return false;
};

export const validatePresentDate = (value: any) => {
	const date = new Date(value);
	const today = new Date();

	date.setHours(0, 0, 0, 0);
	today.setHours(0, 0, 0, 0);

	if (date.getTime() === today.getTime()) {
		return true;
	}
	return false;
};

export const validatePastDate = (value: any) => {
	const date = new Date(value);
	const today = new Date();
	if (date === null || date === undefined || today < date) {
		return true;
	}
	return false;
};

export const validateFutureDate = (value: any) => {
	const date = new Date(value);
	const today = new Date();
	if (date === null || date === undefined || today > date) {
		return true;
	}
	return false;
};

export const validatePresentOrPastDate = (value: any) => {
	return validatePastDate(value) || validatePresentDate(value);
};
