import React from 'react';
import { IconProps } from './IconInterface';

interface ClinicalDocumentProps extends IconProps {
	color2: string;
}

const ClinicalDocumentIcon = ({
	color = '#000000',
	width = 24,
	height = 24,
	color2 = '#FFFFFF',
}: ClinicalDocumentProps) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			fill="none"
			viewBox="0 0 24 24"
		>
			<path
				fill={color}
				fill-rule="evenodd"
				d="M10 2a1.5 1.5 0 0 0-1.5 1.5h-2A1.5 1.5 0 0 0 5 5v15.5A1.5 1.5 0 0 0 6.5 22h11a1.5 1.5 0 0 0 1.5-1.5V5a1.5 1.5 0 0 0-1.5-1.5h-2A1.5 1.5 0 0 0 14 2h-4Zm-.5 1.5A.5.5 0 0 1 10 3h4a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-4a.5.5 0 0 1-.5-.5v-1Zm0 4V9H8v1h1.5v1.5h1V10H12V9h-1.5V7.5h-1Zm-1 5.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1h-7ZM8 16a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 8 16Zm.5 2a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1h-7Z"
				clip-rule="evenodd"
			/>
		</svg>
	);
};
export default ClinicalDocumentIcon;
