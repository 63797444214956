import React from 'react';
import { IconProps } from './IconInterface';
const CallIconOutline = ({
	width = 24,
	height = 24,
	color = '#171717',
}: IconProps) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M16.6659 13.3146V15.3218C16.6667 15.5081 16.6284 15.6926 16.5536 15.8633C16.4788 16.034 16.3691 16.1873 16.2316 16.3133C16.094 16.4392 15.9316 16.5351 15.7547 16.5948C15.5778 16.6545 15.3904 16.6767 15.2045 16.6599C13.1415 16.4362 11.16 15.7327 9.41894 14.6059C7.79915 13.5786 6.42585 12.2081 5.39657 10.5915C4.26358 8.84602 3.5585 6.85875 3.33845 4.79066C3.3217 4.60565 3.34373 4.41917 3.40314 4.24312C3.46256 4.06706 3.55805 3.90528 3.68355 3.76808C3.80904 3.63087 3.96179 3.52125 4.13206 3.44619C4.30233 3.37113 4.4864 3.33227 4.67254 3.3321H6.68373C7.00907 3.3289 7.32449 3.44388 7.57117 3.65561C7.81786 3.86734 7.97898 4.16137 8.02452 4.48289C8.10941 5.12524 8.26683 5.75595 8.4938 6.36297C8.58399 6.60245 8.60352 6.86271 8.55005 7.11292C8.49658 7.36313 8.37236 7.5928 8.19212 7.77471L7.34072 8.62442C8.29506 10.2995 9.68473 11.6864 11.3631 12.6388L12.2145 11.7891C12.3968 11.6092 12.6269 11.4853 12.8776 11.4319C13.1283 11.3785 13.3891 11.398 13.629 11.488C14.2373 11.7145 14.8692 11.8717 15.5128 11.9564C15.8385 12.0022 16.1359 12.1659 16.3485 12.4164C16.5611 12.6668 16.6741 12.9865 16.6659 13.3146Z"
				stroke={color}
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
};
export default CallIconOutline;
