import React from 'react';
const WeeklyIcon = () => {
	return (
		<svg
			width="34"
			height="34"
			viewBox="0 0 34 34"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle cx="17" cy="17" r="17" fill="#EAE0FF" />
			<path
				d="M10.8916 25.4268H22.9941C24.9014 25.4268 25.8945 24.4424 25.8945 22.5615V11.8652C25.8945 9.98438 24.9014 9 22.9941 9H10.8916C8.99316 9 8 9.97559 8 11.8652V22.5615C8 24.4424 8.99316 25.4268 10.8916 25.4268ZM10.8828 23.6777C10.1533 23.6777 9.74902 23.2998 9.74902 22.5264V14.4404C9.74902 13.667 10.1533 13.2891 10.8828 13.2891H23.0029C23.7324 13.2891 24.1367 13.667 24.1367 14.4404V22.5264C24.1367 23.2998 23.7324 23.6777 23.0029 23.6777H10.8828Z"
				fill="#9357F7"
			/>
			<circle cx="17" cy="17" r="17" fill="#EAE0FF" />
			<rect x="11" y="17" width="12" height="3" rx="1" fill="#9357F7" />
			<path
				d="M10.8916 25.4268H22.9941C24.9014 25.4268 25.8945 24.4424 25.8945 22.5615V11.8652C25.8945 9.98438 24.9014 9 22.9941 9H10.8916C8.99316 9 8 9.97559 8 11.8652V22.5615C8 24.4424 8.99316 25.4268 10.8916 25.4268ZM10.8828 23.6777C10.1533 23.6777 9.74902 23.2998 9.74902 22.5264V14.4404C9.74902 13.667 10.1533 13.2891 10.8828 13.2891H23.0029C23.7324 13.2891 24.1367 13.667 24.1367 14.4404V22.5264C24.1367 23.2998 23.7324 23.6777 23.0029 23.6777H10.8828Z"
				fill="#9357F7"
			/>
			<path
				d="M12.8926 19.1514H12.3828C12.0664 19.1514 11.9609 19.0547 11.9609 18.7383V18.2197C11.9609 17.9121 12.0664 17.8154 12.3828 17.8154H12.8926C13.209 17.8154 13.3145 17.9121 13.3145 18.2197V18.7383C13.3145 19.0547 13.209 19.1514 12.8926 19.1514Z"
				fill="#EAE0FF"
			/>
			<path
				d="M15.7666 19.1514H15.248C14.9316 19.1514 14.8262 19.0547 14.8262 18.7383V18.2197C14.8262 17.9121 14.9316 17.8154 15.248 17.8154H15.7666C16.083 17.8154 16.1885 17.9121 16.1885 18.2197V18.7383C16.1885 19.0547 16.083 19.1514 15.7666 19.1514Z"
				fill="#EAE0FF"
			/>
			<path
				d="M18.6406 19.1514H18.1221C17.8057 19.1514 17.7002 19.0547 17.7002 18.7383V18.2197C17.7002 17.9121 17.8057 17.8154 18.1221 17.8154H18.6406C18.9482 17.8154 19.0537 17.9121 19.0537 18.2197V18.7383C19.0537 19.0547 18.9482 19.1514 18.6406 19.1514Z"
				fill="#EAE0FF"
			/>
			<path
				d="M21.5059 19.1514H20.9873C20.6797 19.1514 20.5742 19.0547 20.5742 18.7383V18.2197C20.5742 17.9121 20.6797 17.8154 20.9873 17.8154H21.5059C21.8223 17.8154 21.9277 17.9121 21.9277 18.2197V18.7383C21.9277 19.0547 21.8223 19.1514 21.5059 19.1514Z"
				fill="#EAE0FF"
			/>
			<rect
				x="9.875"
				y="6.875"
				width="2.25"
				height="3.25"
				rx="1.125"
				fill="#9357F7"
				stroke="#EAE0FF"
				strokeWidth="0.25"
			/>
			<rect
				x="21.875"
				y="6.875"
				width="2.25"
				height="3.25"
				rx="1.125"
				fill="#9357F7"
				stroke="#EAE0FF"
				strokeWidth="0.25"
			/>
			<rect
				x="17.875"
				y="6.875"
				width="2.25"
				height="3.25"
				rx="1.125"
				fill="#9357F7"
				stroke="#EAE0FF"
				strokeWidth="0.25"
			/>
			<rect
				x="13.875"
				y="6.875"
				width="2.25"
				height="3.25"
				rx="1.125"
				fill="#9357F7"
				stroke="#EAE0FF"
				strokeWidth="0.25"
			/>
		</svg>
	);
};

export default WeeklyIcon;
