import React from 'react';
import { IconProps } from './IconInterface';

const PainIcon = ({
	color = '#000000',
	width = 24,
	height = 24,
}: IconProps) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clip-path="url(#clip0_629_41231)">
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2ZM12 13C10.429 12.9985 8.92047 13.6149 7.8 14.716C7.70405 14.8074 7.62723 14.917 7.57401 15.0384C7.52078 15.1597 7.4922 15.2905 7.48995 15.423C7.48769 15.5555 7.51179 15.6871 7.56085 15.8102C7.60991 15.9333 7.68295 16.0454 7.77573 16.1401C7.8685 16.2347 7.97916 16.3099 8.10128 16.3614C8.22339 16.4129 8.35452 16.4396 8.48704 16.44C8.61957 16.4404 8.75084 16.4144 8.87323 16.3636C8.99563 16.3128 9.1067 16.2381 9.2 16.144C9.94682 15.4098 10.9527 14.9992 12 15.001C13.09 15.001 14.077 15.436 14.8 16.144C14.8933 16.2381 15.0044 16.3128 15.1268 16.3636C15.2492 16.4144 15.3804 16.4404 15.513 16.44C15.6455 16.4396 15.7766 16.4129 15.8987 16.3614C16.0208 16.3099 16.1315 16.2347 16.2243 16.1401C16.317 16.0454 16.3901 15.9333 16.4391 15.8102C16.4882 15.6871 16.5123 15.5555 16.5101 15.423C16.5078 15.2905 16.4792 15.1597 16.426 15.0384C16.3728 14.917 16.2959 14.8074 16.2 14.716C15.0794 13.6151 13.5709 12.9991 12 13.001V13ZM8.5 8C8.10218 8 7.72064 8.15804 7.43934 8.43934C7.15804 8.72064 7 9.10218 7 9.5C7 9.89782 7.15804 10.2794 7.43934 10.5607C7.72064 10.842 8.10218 11 8.5 11C8.89782 11 9.27936 10.842 9.56066 10.5607C9.84196 10.2794 10 9.89782 10 9.5C10 9.10218 9.84196 8.72064 9.56066 8.43934C9.27936 8.15804 8.89782 8 8.5 8ZM15.5 8C15.1022 8 14.7206 8.15804 14.4393 8.43934C14.158 8.72064 14 9.10218 14 9.5C14 9.89782 14.158 10.2794 14.4393 10.5607C14.7206 10.842 15.1022 11 15.5 11C15.8978 11 16.2794 10.842 16.5607 10.5607C16.842 10.2794 17 9.89782 17 9.5C17 9.10218 16.842 8.72064 16.5607 8.43934C16.2794 8.15804 15.8978 8 15.5 8Z"
					fill={color}
				/>
			</g>
			<defs>
				<clipPath id="clip0_629_41231">
					<rect width="24" height="24" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};
export default PainIcon;
