import Modal from '../Modal';
import {
	StyledBox,
	StyledColumn,
	StyledRow,
} from '../../../../../common/styledCommonComponents';
import React from 'react';
import { useGetFormAnswersQuery } from '../../../../../redux/api/patientApi';
import { useTheme } from 'styled-components';
import { useOncoSelector } from '../../../../../hooks/redux-hook';
import StyledText from '../../../../../common/components/Text';
import { StyledCircularProgress } from '../../../../CustomCircularProgress/styles';
import {
	StyledH5,
	StyledH6,
} from '../../../../../common/components/Text/styles';
import {
	BiometricDataField,
	BiometricDataType,
} from './components/BiometricDataField';
import RulerIcon from '../../../../../assets/RulerIcon';
import { parseDataWithYear } from '../../../../../utils/utils';
import { ErrorMessage } from '../../../../../pages/PatientProfile/components/ErrorMessage';

interface BiometricModalProps {
	handleOnClose: () => void;
}

const BiometricModal = ({ handleOnClose }: BiometricModalProps) => {
	const { reportId } = useOncoSelector((state) => state.utilsSlice);
	const theme = useTheme();

	const {
		data: formAnswers,
		isLoading,
		isError,
		refetch: refetchFormAnswers,
	} = useGetFormAnswersQuery(
		{
			formType: 'BIOMETRIC',
			formId: reportId ?? '',
		},
		{ skip: !reportId },
	);

	const categories = ['height', 'weight', 'SYS', 'DIA', 'cardiac'];

	return (
		<Modal
			width={'850px'}
			onClose={handleOnClose}
			header={
				<StyledRow
					css={{
						gap: '16px',
						alignSelf: 'stretch',
						alignItems: 'center',
					}}
				>
					<StyledRow
						css={{
							height: '50px',
							width: '50px',
							alignItems: 'center',
							justifyContent: 'center',
							background: theme.success100,
							borderRadius: '50%',
						}}
					>
						<RulerIcon width={32} height={32} color={theme.green500} />
					</StyledRow>
					<StyledColumn
						css={{
							alignItems: 'flex-start',
							gap: '8px',
							alignSelf: 'stretch',
							justifyContent: 'center',
						}}
					>
						<StyledH5>Datos biométricos</StyledH5>
						{formAnswers !== undefined && (
							<StyledText variant="body2">
								Realizada el {parseDataWithYear(formAnswers.endDate)}
							</StyledText>
						)}
					</StyledColumn>
				</StyledRow>
			}
			body={
				<>
					{isLoading ? (
						<StyledBox
							css={{
								height: '266px',
								width: '100%',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<StyledCircularProgress />
						</StyledBox>
					) : isError ? (
						<StyledBox
							css={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								height: '266px',
								width: '100%',
							}}
						>
							<ErrorMessage refetch={refetchFormAnswers} />
						</StyledBox>
					) : !formAnswers ? (
						<StyledBox
							css={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								height: '266px',
								width: '100%',
							}}
						>
							<StyledH6 css={{ color: theme.gray300 }}>
								Sin Datos
							</StyledH6>
						</StyledBox>
					) : (
						<StyledColumn
							css={{
								alignItems: 'flex-start',
								gap: 4,
								alignSelf: 'stretch',
							}}
						>
							{categories.map((type: string) => (
								<BiometricDataField
									type={type as BiometricDataType}
									data={formAnswers}
								/>
							))}
						</StyledColumn>
					)}
				</>
			}
		/>
	);
};

export default BiometricModal;
