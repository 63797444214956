import React, { FunctionComponent } from 'react';
import { StyledBox } from '../../common/styledCommonComponents';
import { ModalTypeEnum } from '../../utils/utils';
import WeeklyModal from './components/modals/WeeklyModal';
import { useDispatch } from 'react-redux';
import { closeModal } from '../../redux/slices/utilsSlice';
import DailyModal from './components/modals/DailyModal';
import ContactModal from './components/modals/ContactModal';
import ProfileConfigModal from './components/modals/ProfileConfigModal';
import TreatmentModal from './components/modals/TreatmentModal';
import { useOncoSelector } from '../../hooks/redux-hook';
import FindAuxiliarModal from './components/modals/AuxiliarModal/FindAuxiliarModal';
import CreateAuxiliarModal from './components/modals/AuxiliarModal/CreateAuxiliarModal';
import AddFileModal from './components/modals/AddFileModal';
import PreviewFileModal from './components/modals/PreviewFileModal';
import FrequentMedicationModal from './components/modals/FrequentMedicationModal';
import StagePatientModal from './components/modals/StagePatientModal';
import FreqSymptomsModal from './components/modals/FreqSymptomsModal';
import SummaryQuestionariesModal from './components/modals/SummaryQuestionariesModal';
import DoctorRegisterModal from './components/modals/DoctorRegisterModal';
import TumorProportionModal from './components/modals/TumorProportionModal';
import AuxiliarRegister from './components/modals/AuxiliarRegister';
import BiometricModal from './components/modals/BiometricModal';
import RemoveAuxiliarModal from './components/modals/RemoveAuxiliarModal';
import RemoveAuxiliarDoctorModal from './components/modals/RemoveDoctorModal';
import AddStudyModal from './components/modals/AddStudyModal';
import RemovePatientModal from './components/modals/RemovePatientModal';

export const withModal = (Component: FunctionComponent) => (props: any) => {
	const type = useOncoSelector((state) => state.utilsSlice.modalType);
	const open = !!type;

	const dispatch = useDispatch();

	const handleOnClose = () => {
		dispatch(closeModal());
	};

	const renderModal = () => {
		switch (type) {
			case ModalTypeEnum.WEEKLY_MODAL:
				return <WeeklyModal handleOnClose={handleOnClose} />;
			case ModalTypeEnum.DAILY_MODAL:
				return <DailyModal handleOnClose={handleOnClose} />;
			case ModalTypeEnum.CONTACT_MODAL:
				return <ContactModal handleOnClose={handleOnClose} />;
			case ModalTypeEnum.BIOMETRIC_MODAL:
				return <BiometricModal handleOnClose={handleOnClose} />;
			case ModalTypeEnum.FREQ_SYMPTOMS_MODAL:
				return <FreqSymptomsModal handleOnClose={handleOnClose} />;
			case ModalTypeEnum.CONFIG_MODAL:
				return <ProfileConfigModal handleOnClose={handleOnClose} />;
			case ModalTypeEnum.TREATMENT_MODAL:
				return <TreatmentModal handleOnClose={handleOnClose} />;
			case ModalTypeEnum.FIND_AUXILIAR_MODAL:
				return <FindAuxiliarModal handleOnClose={handleOnClose} />;
			case ModalTypeEnum.CREATE_AUXILIAR_MODAL:
				return <CreateAuxiliarModal handleOnClose={handleOnClose} />;
			case ModalTypeEnum.CREATE_DOCTOR_MODAL:
				return <DoctorRegisterModal handleOnClose={handleOnClose} />;
			case ModalTypeEnum.ADD_FILE:
				return <AddFileModal handleOnClose={handleOnClose} />;
			case ModalTypeEnum.ADD_STUDY:
				return <AddStudyModal handleOnClose={handleOnClose} />;
			case ModalTypeEnum.PREVIEW_FILE:
				return <PreviewFileModal handleOnClose={handleOnClose} />;
			case ModalTypeEnum.FREQUENT_MEDICATION_MODAL:
				return <FrequentMedicationModal handleOnClose={handleOnClose} />;
			case ModalTypeEnum.STAGE_PATIENT_MODAL:
				return <StagePatientModal handleOnClose={handleOnClose} />;
			case ModalTypeEnum.SUMMARY_QUESTIONARY_MODAL:
				return <SummaryQuestionariesModal handleOnClose={handleOnClose} />;
			case ModalTypeEnum.TUMOR_PROPORTION_MODAL:
				return <TumorProportionModal handleOnClose={handleOnClose} />;
			case ModalTypeEnum.AUXILIAR_MODAL:
				return <AuxiliarRegister handleOnClose={handleOnClose} />;
			case ModalTypeEnum.REMOVE_AUXILIAR:
				return <RemoveAuxiliarModal handleOnClose={handleOnClose} />;
			case ModalTypeEnum.REMOVE_DOCTOR:
				return <RemoveAuxiliarDoctorModal handleOnClose={handleOnClose} />;
			case ModalTypeEnum.REMOVE_PATIENT:
				return <RemovePatientModal handleOnClose={handleOnClose} />;
		}
	};

	const ModalContainer = () => {
		return (
			<>
				<StyledBox
					css={{
						position: 'fixed',
						top: 0,
						left: 0,
						width: '100vw',
						height: '100vh',
						background: 'rgba(10, 0, 58, 0.80)',
						backdropFilter: 'blur(3px)',
						zIndex: 10,
					}}
				/>
				{renderModal()}
			</>
		);
	};

	return (
		<StyledBox
			css={{
				position: 'relative',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<Component {...props} />
			{open && ModalContainer()}
		</StyledBox>
	);
};
