import React from 'react';
import {
	ModalTypeEnum,
	ProfileConfigButtonType,
} from '../../../../utils/utils';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setModalOpen } from '../../../../redux/slices/utilsSlice';
import Button, { ButtonVariant } from '../../../../common/components/Button';

interface ProfileButtonProps {
	icon: any;
	text: string;
	type: ProfileConfigButtonType;
	variant: ButtonVariant;
}

const ProfileButton = ({ icon, text, type, variant }: ProfileButtonProps) => {
	const { patientId } = useParams();
	const dispatch = useDispatch();
	const navigation = useNavigate();
	const handleAction = (type: ProfileConfigButtonType) => {
		switch (type) {
			case ProfileConfigButtonType.FORM:
				navigation(`/validate-patient/${patientId}`);
				break;
			case ProfileConfigButtonType.CONTACT:
				dispatch(
					setModalOpen({
						open: true,
						type: ModalTypeEnum.CONTACT_MODAL,
						patientId: patientId,
					}),
				);
				break;
			case ProfileConfigButtonType.CONFIG:
				dispatch(
					setModalOpen({
						open: true,
						type: ModalTypeEnum.CONFIG_MODAL,
						patientId: patientId,
					}),
				);
				break;
			default:
				break;
		}
	};

	return (
		<Button
			onClick={() => handleAction(type)}
			width="150px"
			text={text}
			icon={icon}
			size={'small'}
			variant={variant as ButtonVariant}
		/>
	);
};

export default ProfileButton;
