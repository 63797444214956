import { api } from './api';
import { MetricResponse } from './types';

export const patientApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getPatientMedicData: builder.query({
			query: (patientProfileId) => ({
				url: `/user/patients/medic-data/${patientProfileId}`,
				method: 'GET',
			}),
		}),
		getPatientDataForm: builder.query({
			query: (patientId) => ({
				url: `/home/patient-form/${patientId}`,
				method: 'GET',
			}),
		}),
		getPatientData: builder.query({
			query: (patientId) => ({
				url: `/user/patients/data/${patientId}`,
				method: 'GET',
			}),
		}),
		createAuxiliar: builder.mutation({
			query: (body) => ({
				url: '/user/assistants',
				method: 'POST',
				body: body,
			}),
		}),
		getPatientReports: builder.query({
			query: ({ patientId, firstPage, pageSize }) => ({
				url: `/form/patient/${patientId}/all`,
				method: 'GET',
				params: { firstPage, pageSize },
			}),
		}),
		getWeeklyAnswers: builder.query({
			query: ({ formAnswerId }) => ({
				url: `/form/patient/formanswer/WEEKLY/${formAnswerId}`,
				method: 'GET',
			}),
		}),
		getPatientAnswers: builder.query({
			query: ({
				category,
				patientId,
			}: {
				category: string;
				patientId: string;
			}) => ({
				url: `/form/patient/answers/${category}/${patientId}`,
				method: 'GET',
			}),
		}),
		getPatientMedicDataSummary: builder.query({
			query: (patientID) => ({
				url: `/user/patients/medic-data-summary/${patientID}`,
				method: 'GET',
			}),
		}),
		getPatientsList: builder.query<any, any>({
			query: (params) => ({
				url: `user/patients`,
				method: 'GET',
				params,
			}),
		}),
		getAppetiteHydration: builder.query({
			query: (patientID) => ({
				url: `/log/appetite-hydration/${patientID}`,
				method: 'GET',
			}),
		}),
		getSocialPhysical: builder.query({
			query: (patientID) => ({
				url: `/log/social-physical/${patientID}`,
				method: 'GET',
			}),
		}),
		getCalendar: builder.query({
			query: (patientId) => ({
				url: `/home/calendar/${patientId}`,
				method: 'GET',
			}),
		}),
		getDailyReport: builder.query({
			query: (reportId) => ({
				url: `/log/report/${reportId}`,
			}),
		}),
		getWeeklyReport: builder.query({
			query: (reportId) => ({
				url: `/weekly/report/${reportId}`,
			}),
		}),
		getPatientSchedule: builder.query({
			query: (patientId) => ({
				url: `/form/schedule/${patientId}`,
			}),
		}),
		updatePatientSchedule: builder.mutation({
			query: ({ patientId, body }) => ({
				url: `/form/schedule/${patientId}`,
				body,
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json',
				},
			}),
		}),
		getPatientTreatmets: builder.query({
			query: (patientID) => ({
				url: `/user/patients/treatment/${patientID}`,
				method: 'GET',
			}),
			providesTags: ['TreatmentList'],
		}),
		getPollResults: builder.query({
			query: ({ patientId, params }) => ({
				url: '/home/patient-reports/' + patientId,
				method: 'GET',
				params: params,
			}),
		}),
		getPollTable: builder.query({
			query: ({ userId, params }) => ({
				url: '/home/patient-reports/' + userId,
				method: 'GET',
				params: params,
			}),
		}),
		getTreatmentById: builder.query({
			query: ({ reportId }) => ({
				url: `/treatment/${reportId}`,
				method: 'GET',
			}),
			// invalidatesTags: ['TreatmentList'],
			keepUnusedDataFor: 0.1,
		}),
		fixPatient: builder.mutation({
			query: (patientId: string) => ({
				url: `/user/patients/fix/${patientId}`,
				method: 'POST',
			}),
			invalidatesTags: ['UserList'],
		}),
		unfixPatient: builder.mutation({
			query: (patientId: string) => ({
				url: `/user/patients/fix/${patientId}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['UserList'],
		}),
		updatePatientStatus: builder.mutation({
			query: ({
				patientId,
				newStatus,
			}: {
				patientId: string;
				newStatus: string;
			}) => ({
				url: `/user/patients/status/${patientId}/${newStatus}`,
				method: 'PATCH',
			}),
			invalidatesTags: ['UserList'],
		}),
		getPROCTCAESummary: builder.query({
			query: (medications) => {
				let url = '/form/weekly/categories/answers';
				if (medications && medications.length > 0) {
					url += '?';
					url += medications
						.map((med: string) => `medications=${med}`)
						.join('&');
				}
				return {
					url: url,
					method: 'GET',
				};
			},
		}),

		getPatientPROCTCAESummary: builder.query({
			query: (patientId) => ({
				url: `/form/weekly/categories/answers/${patientId}`,
				method: 'GET',
			}),
		}),
		getFormAnswers: builder.query({
			query: ({
				formType,
				formId,
			}: {
				formType: string;
				formId: string;
			}) => ({
				url: `/form/patient/formanswer/${formType}/${formId}`,
				method: 'GET',
			}),
		}),
		validateDoctor: builder.mutation({
			query: (userId) => ({
				url: `/user/validate/doctor/${userId}`,
				method: 'POST',
			}),
			invalidatesTags: ['DoctorList'],
		}),
		denyPatient: builder.mutation({
			query: (userId) => ({
				url: `/user/deny/patient/${userId}`,
				method: 'POST',
			}),
		}),
		denyDoctor: builder.mutation({
			query: (userId) => ({
				url: `/user/deny/doctor/${userId}`,
				method: 'POST',
			}),
			invalidatesTags: ['DoctorList'],
		}),

		// Metrics
		metricsByType: builder.query<
			MetricResponse,
			{ metric: string; patientId: string }
		>({
			query: ({ metric, patientId }) => ({
				url: `/metric/patient/${metric}/${patientId}`,
				method: 'GET',
			}),
		}),
	}),
});

export const {
	useGetPatientMedicDataQuery,
	useLazyGetPatientMedicDataQuery,
	useGetTreatmentByIdQuery,
	useLazyGetPatientDataFormQuery,
	useLazyGetPatientDataQuery,
	useGetPatientReportsQuery,
	useLazyGetPatientReportsQuery,
	useGetPatientMedicDataSummaryQuery,
	useGetPatientDataQuery,
	useGetPatientsListQuery,
	useLazyGetPatientsListQuery,
	useLazyGetAppetiteHydrationQuery,
	useLazyGetSocialPhysicalQuery,
	useLazyGetCalendarQuery,
	useGetPollResultsQuery,
	useLazyGetPollTableQuery,
	useGetDailyReportQuery,
	useGetWeeklyReportQuery,
	useGetPatientTreatmetsQuery,
	useCreateAuxiliarMutation,
	useGetPatientAnswersQuery,
	useLazyGetPatientAnswersQuery,
	useFixPatientMutation,
	useUnfixPatientMutation,
	useUpdatePatientStatusMutation,
	useLazyGetPatientMedicDataSummaryQuery,
	useUpdatePatientScheduleMutation,
	useGetPatientScheduleQuery,
	useLazyGetPatientScheduleQuery,
	useGetPROCTCAESummaryQuery,
	useGetFormAnswersQuery,
	useValidateDoctorMutation,
	useDenyPatientMutation,
	useDenyDoctorMutation,
	useLazyGetPROCTCAESummaryQuery,
	useGetPatientPROCTCAESummaryQuery,
	useGetWeeklyAnswersQuery,

	// Metrics
	useMetricsByTypeQuery,
} = patientApi;

export const {
	endpoints: {
		getPatientMedicData,
		getPatientDataForm,
		getPatientData,
		getPatientReports,
		getPatientMedicDataSummary,
		getAppetiteHydration,
		getSocialPhysical,
		getCalendar,
		getPatientsList,
		getWeeklyAnswers,
		getDailyReport,
		getPatientSchedule,
		updatePatientSchedule,
		createAuxiliar,
		getWeeklyReport,
		getPollResults,
		getPollTable,
		fixPatient,
		unfixPatient,
		updatePatientStatus,
		getPatientAnswers,
		metricsByType,
	},
} = patientApi;
