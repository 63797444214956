import React, { useEffect, useMemo } from 'react';
import HeaderValidationForm from '../components/HeaderValidationForm';
import UserIcon from '../../../assets/UserIcon';
import {
	StyledColumn,
	StyledRow,
} from '../../../common/styledCommonComponents';
import TextInputField from '../../../common/components/TextInputField';
import { useOncoDispatch, useOncoSelector } from '../../../hooks/redux-hook';
import {
	removeAllBiomarker,
	setValue,
} from '../../../redux/slices/validationSlice';
import Dropdown from '../../../common/components/Dropdown';
import {
	useGetCancerOrganQuery,
	useGetCancerStageQuery,
	useGetCancerSubTypeQuery,
	useGetCancerTypeQuery,
} from '../../../redux/api/validateFormApi';
import { SpecResponse } from '../../../redux/api/types';
import TNMContainer from '../components/TNMContainer';
import { evaluateTNMTWO } from '../../../utils/tnm_options';
import TreatmentContainer from '../components/TreatmentContainer';
import moment from 'moment/moment';

const HospitalForm = () => {
	const values = useOncoSelector((state) => state.validationSlice.values);
	const isEditable = values.status === 'WAITING';
	const errors = useOncoSelector((state) => state.validationSlice.errors);
	const dispatch = useOncoDispatch();

	const { data: primaryTumorOptions } = useGetCancerOrganQuery('', {
		selectFromResult: (response) => {
			if (
				response.error ||
				response?.data?.length === 0 ||
				response.data === undefined
			)
				return response;
			let aux: any = {};
			response.data.forEach(
				({ id, value }: SpecResponse) => (aux[id] = value),
			);

			// dispatch(setAuxiliarData({name:'primaryTumor',data:aux}))
			return { response, data: aux };
		},
	});

	const { data: histologyTypeOption } = useGetCancerTypeQuery(
		values.primaryTumor,
		{
			skip: !values.primaryTumor || values.primaryTumor === 'no_value',
			refetchOnMountOrArgChange: true,
			selectFromResult: (response) => {
				if (
					response.error ||
					response?.data?.length === 0 ||
					response.data === undefined
				)
					return response;
				let aux: any = {};
				response.data.forEach(
					({ id, value }: SpecResponse) => (aux[id] = value),
				);
				// dispatch(setAuxiliarData({name:'histologyType',data:aux}))
				return { response, data: aux };
			},
		},
	);

	const { data: histologySubtypeOption } = useGetCancerSubTypeQuery(
		values.histologyType,
		{
			skip: !values.primaryTumor || values.primaryTumor === 'no_value',
			refetchOnMountOrArgChange: true,
			selectFromResult: (response) => {
				if (
					response.error ||
					response?.data?.length === 0 ||
					response.data === undefined
				)
					return response;
				let aux: any = {};
				response.data.forEach(
					({ id, value }: SpecResponse) => (aux[id] = value),
				);
				// dispatch(setAuxiliarData({name:'histologySubtype',data:aux}))
				return { response, data: aux };
			},
		},
	);

	const { data: optionStage } = useGetCancerStageQuery(values.primaryTumor, {
		refetchOnMountOrArgChange: true,
		skip: !values.primaryTumor || values.primaryTumor === 'no_value',
	});

	const optionsStage = useMemo(() => {
		const response = evaluateTNMTWO(
			optionStage,
			values.tumor,
			values.nodule,
			values.metastasis,
		);

		if (Object.keys(response).length === 0) {
			return {
				no_value: 'No se encontró ningún estadío para esta combinación',
			};
		}

		return response;
	}, [values.tumor, values.nodule, values.metastasis]);

	useEffect(() => {
		isEditable && dispatch(setValue({ key: 'stage', value: 'no_value' }));
	}, [optionsStage, values.tumor, values.nodule, values.metastasis]);

	const handleOnChange = (name: string, value: string) => {
		const fieldsToReset = getFieldsToReset(name);
		dispatch(setValue({ key: name as keyof typeof values, value }));

		if (fieldsToReset) {
			resetFields(fieldsToReset);
			dispatch(removeAllBiomarker());
		}
	};

	const getFieldsToReset = (name: string) => {
		const fields = {
			primaryTumor: [
				'histologyType',
				'histologySubtype',
				'tumor',
				'nodule',
				'metastasis',
				'stage',
			],
			histologyType: [
				'histologySubtype',
				'tumor',
				'nodule',
				'metastasis',
				'stage',
			],
		};

		// @ts-ignore
		return fields[name];
	};

	const resetFields = (fields: string[]) => {
		fields.forEach((name: string) => {
			dispatch(
				setValue({ key: name as keyof typeof values, value: 'no_value' }),
			);
		});
	};

	return (
		<StyledColumn>
			<HeaderValidationForm title={'Datos Hospitalarios'} icon={UserIcon} />
			<StyledColumn css={{ padding: 32, gap: 16 }}>
				<StyledRow css={{ gap: 32 }}>
					<TextInputField
						value={moment(values.diagnoseDate).format('YYYY-MM-DD')}
						label={'Fecha de Diagnostico'}
						onChange={handleOnChange}
						name={'diagnoseDate'}
						error={
							errors?.diagnoseDate?.error
								? errors?.diagnoseDate?.error[0]
								: false
						}
						disabled={values.status === 'ACTIVE'}
						type={'date'}
						required
						max="today"
					/>
					<Dropdown
						value={values.primaryTumor}
						label={'Tumor Primario'}
						onChange={handleOnChange}
						name={'primaryTumor'}
						options={primaryTumorOptions}
						error={
							errors?.primaryTumor?.error
								? errors?.primaryTumor?.error[0]
								: false
						}
						disabled={values.status === 'ACTIVE'}
						required
					/>
				</StyledRow>
				<StyledRow css={{ gap: 32 }}>
					<Dropdown
						value={values.histologyType}
						label={'Tipo histologico'}
						onChange={handleOnChange}
						name={'histologyType'}
						options={histologyTypeOption}
						error={
							errors?.histologyType?.error
								? errors?.histologyType?.error[0]
								: false
						}
						disabled={
							!values.primaryTumor ||
							values.primaryTumor === 'no_value' ||
							values.status === 'ACTIVE'
						}
						required
					/>
					<Dropdown
						value={values.histologySubtype}
						label={'Subtipo histologico'}
						onChange={handleOnChange}
						name={'histologySubtype'}
						options={histologySubtypeOption}
						hasSearchbar
						error={
							errors?.histologySubtype?.error
								? errors?.histologySubtype?.error[0]
								: false
						}
						disabled={
							!values.histologyType ||
							values.histologyType === 'no_value' ||
							values.status === 'ACTIVE'
						}
						required
					/>
				</StyledRow>
				<StyledRow css={{ gap: 32 }}>
					<TNMContainer />
				</StyledRow>
				<StyledRow css={{ gap: 32 }}>
					<Dropdown
						value={values.stage}
						label={'Estadío'}
						onChange={handleOnChange}
						name={'stage'}
						options={optionsStage}
						error={errors?.stage?.error ? errors?.stage?.error[0] : false}
						disabled={
							!values.primaryTumor ||
							values.primaryTumor === 'no_value' ||
							!values.tumor ||
							values.tumor === 'no_value' ||
							!values.nodule ||
							values.nodule === 'no_value' ||
							!values.metastasis ||
							values.metastasis === 'no_value' ||
							values.status === 'ACTIVE'
						}
						required
					/>
				</StyledRow>
				<TreatmentContainer
					id={''}
					prefix={'treatment'}
					isEditable={isEditable}
				/>
			</StyledColumn>
		</StyledColumn>
	);
};

export default HospitalForm;
