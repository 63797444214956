import React from 'react';
import Modal from '../Modal';
import {
	StyledBox,
	StyledColumn,
	StyledRow,
	StyledSpan,
} from '../../../../../common/styledCommonComponents';
import { useTheme } from 'styled-components';
import DailyBody from './DailyBody';
import DailyModalIcon from '../../../../../assets/DailyModalIcon';
import { useGetFormAnswersQuery } from '../../../../../redux/api/patientApi';
import { StyledCircularProgress } from '../../../../CustomCircularProgress/styles';
import { parseDataWithYear } from '../../../../../utils/utils';
import { useOncoSelector } from '../../../../../hooks/redux-hook';
import StyledText from '../../../../../common/components/Text';
import { ErrorMessage } from '../../../../../pages/PatientProfile/components/ErrorMessage';

interface DailyModalProps {
	handleOnClose: () => void;
}

export type DailyDataTypes =
	| 'mood'
	| 'pain'
	| 'hunger'
	| 'hydration'
	| 'physical'
	| 'social';

const DailyModal = ({ handleOnClose }: DailyModalProps) => {
	const theme = useTheme();
	const reportId = useOncoSelector((state) => state.utilsSlice.reportId);

	const {
		data: formAnswers,
		isLoading,
		isError,
		refetch: refetchFormAnswers,
	} = useGetFormAnswersQuery(
		{
			formType: 'DAILY',
			formId: reportId ?? '',
		},
		{ skip: !reportId },
	);

	const renderState = (state: 'COMPLETE' | 'INCOMPLETE' | 'EMPTY') => {
		switch (state) {
			case 'COMPLETE':
				return (
					<StyledSpan
						css={{
							fontSize: '11px',
							backgroundColor: theme.patientListPillPositive,
							borderRadius: '20px',
							fontWeight: 500,
							padding: '5px 20px',
							color: '#1D6535',
						}}
					>
						Completado
					</StyledSpan>
				);
			case 'INCOMPLETE':
				return (
					<StyledSpan
						css={{
							fontSize: '11px',
							backgroundColor: theme.oncoLightOrange4,
							borderRadius: '20px',
							fontWeight: 500,
							padding: '5px 20px',
							color: '#EA8053',
						}}
					>
						Incompleto
					</StyledSpan>
				);
			case 'EMPTY':
				return (
					<StyledSpan
						css={{
							fontSize: '11px',
							backgroundColor: theme.gray200,
							borderRadius: '20px',
							fontWeight: 500,
							padding: '5px 20px',
							color: theme.gray800,
						}}
					>
						Vacio
					</StyledSpan>
				);
		}
	};
	// styledrow -> icon + styled column -> styledRow (titulo y pill) y fecha
	return (
		<Modal
			onClose={handleOnClose}
			header={
				<StyledRow>
					<StyledBox
						css={{
							display: 'flex',
							padding: 9,
							alignItems: 'flex-start',
							borderRadius: 30,
							background: `${theme.orange200}`,
						}}
					>
						<DailyModalIcon />
					</StyledBox>
					<StyledColumn
						css={{
							marginLeft: '15px',
							justifyContent: 'center',
						}}
					>
						<StyledRow css={{ alignItems: 'center' }}>
							<StyledText
								variant="h5"
								css={{ marginRight: 10, color: `${theme.black}` }}
							>
								Estado general del paciente
							</StyledText>
							{renderState(formAnswers?.status)}
						</StyledRow>
						<StyledText
							variant="body2"
							css={{ marginTop: '5px', color: `${theme.black}` }}
						>
							Realizada el {parseDataWithYear(formAnswers?.endDate)}
						</StyledText>
					</StyledColumn>
				</StyledRow>
			}
			body={
				isError ? (
					<StyledBox
						css={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							height: '266px',
							width: '100%',
						}}
					>
						<ErrorMessage refetch={refetchFormAnswers} />
					</StyledBox>
				) : (
					<DailyBody data={formAnswers} />
				)
			}
			isLoading={
				isLoading ? (
					<StyledBox
						css={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							height: '370px',
							width: '100%',
						}}
					>
						<StyledCircularProgress />
					</StyledBox>
				) : undefined
			}
		/>
	);
};

export default DailyModal;
