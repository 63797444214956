import CustomKebab from '../../components/TableComponent/CustomKebab';
import React from 'react';
import { getProfileImageFromName } from '../../utils/utils';
import { StyledBox } from '../../common/styledCommonComponents';

export type DataTable = {
	field: string;
	label: string;
	sortId: string;
	css?: React.CSSProperties;
	render?: (props: any) => JSX.Element;
	button?: boolean;
	type?: string;
	requiredFields?: string[];
};

export const PatientListHeaderConst: DataTable[] = [
	{
		field: 'iconName',
		label: ' ',
		sortId: '',
		css: {
			width: 60,
			maxWidth: 60,
			paddingLeft: '24px',
			paddingRight: '8px',
			boxSizing: 'border-box',
		},
		render: ({ children }: any) =>
			getProfileImageFromName({
				name: children,
				size: {
					width: '28px',
					height: '28px',
				},
			}),
	},
	{
		field: 'name',
		label: 'Nombre y apellido',
		button: true,
		sortId: 'name',
		css: { width: 153 },
		render: (props: any) => <p>{props.children}</p>,
	},
	{
		field: 'principalTumor',
		label: 'Tumor Principal',
		button: true,
		sortId: 'principal_tumor',
		css: { width: 153 },
		render: ({ children }: any) => <>{children}</>,
	},
	{
		field: 'status',
		label: 'Estado',
		button: true,
		sortId: 'status',
		css: { width: 153 },
		render: (props: any) => <p>{props.children}</p>,
	},
	{
		type: 'blank',
		field: '',
		label: '',
		css: {
			width: 322,
		},
		sortId: '',
		render: () => <StyledBox></StyledBox>,
	},
	{
		field: 'lastActivity',
		label: 'Última actividad',
		sortId: '',
		css: { width: 153 },
		render: ({ children }: any) => <>{children}</>,
	},
	{
		field: 'kebab',
		label: ' ',
		sortId: 'emptyBox',
		css: {
			width: 28,
			paddingLeft: '8px',
			paddingRight: '24px',
		},
		render: (props: any) => (
			<CustomKebab kebabOptions={['Ver perfil', 'Contactar']}></CustomKebab>
		),
	},
];

export const AuxiliarListHeaderConst: DataTable[] = [
	{
		field: 'fullname',
		label: ' ',
		sortId: '',
		css: {
			width: 60,
			maxWidth: 60,
			paddingLeft: '24px',
			paddingRight: '8px',
			boxSizing: 'border-box',
		},
		render: ({ children }: any) =>
			getProfileImageFromName({
				name: children,
				size: {
					width: '28px',
					height: '28px',
				},
			}),
	},
	{
		field: 'fullname',
		label: 'Nombre y Apellido',
		button: true,
		sortId: 'name',
		css: { width: 186 },
		render: (props: any) => <p>{props.children}</p>,
	},
	{
		field: 'rol',
		label: 'Rol',
		sortId: 'rol',
		css: { color: ' #9357f7 !important', fontSize: '12px', width: 166 },
		render: ({ children }: any) => <>{children}</>,
	},
	{
		field: 'email',
		label: 'Correo electrónico',
		sortId: 'email',
		css: { color: ' #9357f7 !important', fontSize: '12px', width: 206 },
		render: ({ children }: any) => <>{children}</>,
	},
	{
		type: 'blank',
		field: '',
		label: '',
		css: {
			width: 214,
		},
		sortId: '',
		render: () => <StyledBox></StyledBox>,
	},
	{
		field: 'lastSignUp',
		label: 'Último inicio de sesión',
		sortId: 'lastSignUp',
		css: { color: ' #9357f7 !important', fontSize: '12px' },
		render: ({ children }: any) => <>{children}</>,
	},
	{
		field: 'kebab',
		label: ' ',
		sortId: 'emptyBox',
		css: {
			width: 28,
			paddingLeft: '8px',
			paddingRight: '24px',
		},
		render: (props: any) => (
			<CustomKebab kebabOptions={['Ver perfil', 'Contactar']}></CustomKebab>
		),
	},
];
