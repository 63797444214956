export enum UserRole {
	ADMIN = 'Admin',
	AUXILIAR = 'Auxiliar',
	DOCTOR = 'Doctor',
	DOCTOR_ADMIN = 'DoctorAdmin',
	PATIENT = 'Patient',
	BETA = 'Beta',
}

export interface UserListQueryParams {
	param?: string;
	firstPage?: number;
	pageSize?: number;
	column?: string;
	order?: string;
}
