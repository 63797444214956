import React, { useMemo } from 'react';
import Accordion from '../../../../common/components/Accordion';
import {
	StyledColumn,
	StyledRow,
} from '../../../../common/styledCommonComponents';
import Dropdown from '../../../../common/components/Dropdown';
import { useOncoDispatch, useOncoSelector } from '../../../../hooks/redux-hook';
import {
	setTreatmentValue,
	TreatmentInterface,
	valuesLastTreatment,
	valuesTreatment,
} from '../../../../redux/slices/validationSlice';
import TextInputField from '../../../../common/components/TextInputField';
import MedicationContainer from '../MedicationContainer';
import { NumberToWord } from '../../../../utils/utils';
import StyledText from '../../../../common/components/Text';
import moment from 'moment';
import {
	useGetCancerStageQuery,
	useGetTreatmentOptionsQuery,
} from '../../../../redux/api/validateFormApi';

interface TreatmentContainerProps {
	id: string;
	prefix: 'treatment' | 'setbacks' | 'progressions';
	isEditable: boolean;
}

const TreatmentContainer = ({
	id,
	prefix,
	isEditable,
}: TreatmentContainerProps) => {
	const primaryTumor = useOncoSelector(
		(state) => state.validationSlice.values.primaryTumor,
	);
	const { data: stages } = useGetCancerStageQuery(primaryTumor, {
		skip: !primaryTumor || primaryTumor === 'no_value',
	});
	const treatment = useOncoSelector((state) =>
		valuesTreatment(state, { id, prefix }),
	);
	const stageValue = useOncoSelector(
		(state) => state?.validationSlice?.values?.stage,
	);

	const { id: LastTreatmentId } = useOncoSelector((state) =>
		valuesLastTreatment(state),
	);

	const disabled =
		!isEditable || treatment.finishDate || treatment.endingMotive;

	const errors = useOncoSelector((state) => state.validationSlice.errors);
	const errorsTreatment = useMemo<Record<string, any> | undefined>(() => {
		if (!errors) return undefined;
		let errorToReturn = {};
		if (prefix === 'treatment') {
			errorToReturn = errors;
		} else {
			errors[treatment.id]?.properties?.forEach((proper) => {
				errorToReturn = {
					...errorToReturn,
					[proper.key]: proper,
				};
			});
		}
		return errorToReturn;
	}, [errors, prefix, treatment.id]);
	const dispatch = useOncoDispatch();

	const { data: intentionOptions } = useGetTreatmentOptionsQuery(
		'TREATMENT_INTENTION',
		{
			selectFromResult: (response: any) => {
				let aux: any = {};
				response?.data?.forEach(({ id, name }: any) => (aux[id] = name));
				return { response, data: aux };
			},
		},
	);

	const { data: primaryTreatmentOptions } = useGetTreatmentOptionsQuery(
		'PRIMARY_TUMOR_TREATMENT',
		{
			selectFromResult: (response: any) => {
				let aux: any = {};
				response?.data?.forEach(({ id, name }: any) => (aux[id] = name));
				return { response, data: aux };
			},
		},
	);

	const { data: systematicTreatmentOptions } = useGetTreatmentOptionsQuery(
		'SYSTEMATIC_TREATMENT',
		{
			selectFromResult: (response: any) => {
				let aux: any = {};
				response?.data?.forEach(({ id, name }: any) => (aux[id] = name));
				return { response, data: aux };
			},
		},
	);

	const endingMotiveOptions = {
		Toxicity: 'Toxicidad',
		DiseaseProgression: 'Progresión de enfermedad',
		DoctorOrPatientDecision: 'Decisión del médico o paciente',
		CompletedTreatment: 'Completó tratamiento',
		Other: 'Otro',
	};

	const handleOnChange = (name: string, value: string) => {
		dispatch(
			setTreatmentValue({
				id,
				prefix,
				key: name as keyof TreatmentInterface,
				value,
			}),
		);
	};

	const treatmentTitle = useMemo(() => {
		const isStageFour = ['iv', 'IV', 'Iv', '4'].some((valor) =>
			(
				stages?.find((stg: any) => stg.id === stageValue)?.value ?? ''
			).includes(valor),
		);
		if (
			stageValue !== 'no_value' &&
			treatment.treatmentLine === 1 &&
			isStageFour
		) {
			return 'Linea base de tratamiento';
		} else {
			const treatmentLineUpdated = isStageFour
				? treatment.treatmentLine - 1
				: treatment.treatmentLine;
			return `${NumberToWord[treatmentLineUpdated]} linea de tratamiento`;
		}
	}, [treatment.treatmentLine, stageValue]);

	return (
		<Accordion
			header={
				<StyledText variant="body1Bold" css={{ color: '#000000' }}>
					{treatmentTitle}
				</StyledText>
			}
		>
			<StyledColumn css={{ padding: 8, gap: 16 }}>
				<StyledRow css={{ gap: 32 }}>
					<Dropdown
						value={treatment.primaryTreatment}
						label={'Tratamiento del tumor primario'}
						onChange={handleOnChange}
						name={'primaryTreatment'}
						options={primaryTreatmentOptions}
						error={
							errorsTreatment?.primaryTreatment?.error
								? errorsTreatment?.primaryTreatment?.error[0]
								: false
						}
						disabled={disabled}
						required
					/>
					<Dropdown
						value={treatment.systematicTreatment}
						label={'Tratamiento asociado'}
						onChange={handleOnChange}
						name={'systematicTreatment'}
						options={systematicTreatmentOptions}
						error={
							errorsTreatment?.systematicTreatment?.error
								? errorsTreatment?.systematicTreatment?.error[0]
								: false
						}
						disabled={disabled}
						required
					/>
				</StyledRow>
				<StyledRow css={{ gap: 32 }}>
					<TextInputField
						value={moment(treatment?.startDate).format('YYYY-MM-DD')}
						label={'Fecha de comienzo'}
						onChange={handleOnChange}
						name={'startDate'}
						error={
							errorsTreatment?.startDate?.error
								? errorsTreatment?.startDate?.error[0]
								: false
						}
						disabled={disabled}
						type={'date'}
						required
						max="today"
					/>
					<TextInputField
						value={moment(treatment.estimateFinishDate).format(
							'YYYY-MM-DD',
						)}
						label={'Fecha estimada de finalización'}
						onChange={handleOnChange}
						name={'estimateFinishDate'}
						error={
							errorsTreatment?.estimateFinishDate?.error
								? errorsTreatment?.estimateFinishDate?.error[0]
								: false
						}
						disabled={disabled}
						type={'date'}
						required
						min="today"
					/>
				</StyledRow>
				<StyledRow css={{ gap: 32 }}>
					<Dropdown
						value={treatment.intention}
						label={'Intención del tratamiento'}
						onChange={handleOnChange}
						name={'intention'}
						options={intentionOptions}
						error={
							errorsTreatment?.intention?.error
								? errorsTreatment?.intention?.error[0]
								: false
						}
						disabled={disabled}
						required
					/>
				</StyledRow>
				<StyledRow css={{ gap: 32 }}>
					<MedicationContainer
						id={treatment.medicationId}
						editable={!treatment.isFinished}
						disabled={disabled}
					/>
				</StyledRow>
				{(treatment.finishDate || treatment.endingMotive) && (
					<>
						<StyledRow css={{ gap: 32 }}>
							<TextInputField
								value={moment(treatment?.finishDate).format(
									'YYYY-MM-DD',
								)}
								label={'Fecha de finalización'}
								onChange={handleOnChange}
								name={'finishDate'}
								disabled={disabled}
								type={'date'}
								required
							/>
							<TextInputField
								value={
									// @ts-ignore
									endingMotiveOptions[treatment.endingMotive] ??
									'Cargando...'
								}
								label={'Motivo de finalización'}
								onChange={handleOnChange}
								name={'endingMotive'}
								disabled={disabled}
								required
							/>
						</StyledRow>
						{treatment.otherMotive && (
							<StyledRow css={{ gap: 32 }}>
								<TextInputField
									value={treatment.otherMotive}
									label={'Otros motivos'}
									onChange={handleOnChange}
									name={'otherMotive'}
									type={'text'}
									disabled={disabled}
									required
								/>
							</StyledRow>
						)}
					</>
				)}
			</StyledColumn>
		</Accordion>
	);
};

export default TreatmentContainer;
