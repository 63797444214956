import React from 'react';
import { IconProps } from './IconInterface';

const QuestionIcon = ({
	color = '#000000',
	width = 24,
	height = 24,
	...props
}: IconProps) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 14 15"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M6.99984 13.3337C3.77809 13.3337 1.1665 10.7221 1.1665 7.50033C1.1665 4.27858 3.77809 1.66699 6.99984 1.66699C10.2216 1.66699 12.8332 4.27858 12.8332 7.50033C12.8332 10.7221 10.2216 13.3337 6.99984 13.3337ZM6.4165 9.25033V10.417H7.58317V9.25033H6.4165ZM7.58317 8.29074C8.05198 8.14945 8.45444 7.84446 8.71726 7.43134C8.98008 7.01822 9.08578 6.52444 9.0151 6.03993C8.94442 5.55542 8.70207 5.11242 8.33218 4.79159C7.96229 4.47077 7.48947 4.29347 6.99984 4.29199C6.52781 4.29185 6.07031 4.45526 5.70519 4.75444C5.34008 5.05361 5.08991 5.47006 4.99725 5.93291L6.14175 6.16216C6.17423 5.99966 6.25219 5.84973 6.36655 5.7298C6.48091 5.60988 6.62698 5.52489 6.78775 5.48474C6.94853 5.44458 7.1174 5.45091 7.27472 5.50297C7.43204 5.55504 7.57134 5.65071 7.67641 5.77886C7.78148 5.907 7.848 6.06235 7.86824 6.22682C7.88847 6.39129 7.86158 6.55813 7.7907 6.70792C7.71983 6.85771 7.60787 6.98429 7.46786 7.07293C7.32785 7.16158 7.16555 7.20865 6.99984 7.20866C6.84513 7.20866 6.69675 7.27012 6.58736 7.37951C6.47796 7.48891 6.4165 7.63728 6.4165 7.79199V8.66699H7.58317V8.29074Z"
				fill={color}
			/>
		</svg>
	);
};

export default QuestionIcon;
