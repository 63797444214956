import React, { useMemo } from 'react';
import {
	StyledColumn,
	StyledRow,
} from '../../../../../../common/styledCommonComponents';
import StyledText from '../../../../../../common/components/Text';
import { theme } from '../../../../../../common/theme';
import {
	QuestionaryType,
	ReportIcon,
} from '../../../../../../components/TableComponent/RowTableComponent/ReportIcon';
import { useOncoDispatch } from '../../../../../../hooks/redux-hook';
import { ModalTypeEnum } from '../../../../../../utils/utils';
import { setModalOpen } from '../../../../../../redux/slices/utilsSlice';
import { StyledReportItemContainer } from './styles';

interface ReportItemProps {
	type: QuestionaryType;
	startDate: string;
	id: string;
}

export const ReportItem = ({ type, startDate, id }: ReportItemProps) => {
	const dispatch = useOncoDispatch();

	const title = useMemo(() => {
		switch (type) {
			case QuestionaryType.GENERAL_STATE:
				return 'Estado general del paciente';

			case QuestionaryType.PRO_CTCAE:
				return 'Calidad de vida';

			case QuestionaryType.BIOMETRIC:
				return 'Estado diario del paciente';

			default:
				return 'Reporte desconocido';
		}
	}, [type]);

	const handleOnClick = () => {
		const modalType = {
			[QuestionaryType.GENERAL_STATE]: ModalTypeEnum.DAILY_MODAL,
			[QuestionaryType.PRO_CTCAE]: ModalTypeEnum.WEEKLY_MODAL,
			[QuestionaryType.BIOMETRIC]: ModalTypeEnum.BIOMETRIC_MODAL,
		};
		dispatch(setModalOpen({ type: modalType[type], open: true, id: id }));
	};

	const onRowClick = (row: any) => {
		let reportType: string = row.icon;
		let reportId: string = row.id;

		switch (reportType) {
			case 'weekly':
				dispatch(
					setModalOpen({
						open: true,
						type: ModalTypeEnum.WEEKLY_MODAL,
					}),
				);
				// dispatch(setReportId(reportId));
				break;
			case 'daily':
				dispatch(
					setModalOpen({
						open: true,
						type: ModalTypeEnum.DAILY_MODAL,
					}),
				);
				// dispatch(setReportId(reportId));
				break;
			case 'biometric':
				dispatch(
					setModalOpen({
						open: true,
						type: ModalTypeEnum.SUMMARY_QUESTIONARY_MODAL,
					}),
				);
				// dispatch(setReportId(reportId));
				break;
			default:
				break;
		}
	};

	return (
		<StyledReportItemContainer onClick={handleOnClick}>
			<ReportIcon type={type} />
			<StyledColumn>
				<StyledText variant="body2" css={{ color: 'black' }}>
					{title}
				</StyledText>
				<StyledText variant="body3" css={{ color: `${theme.gray400}` }}>
					{startDate.split(' ')[0]}
				</StyledText>
			</StyledColumn>
		</StyledReportItemContainer>
	);
};
