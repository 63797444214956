import React, { useMemo, useState } from 'react';
import Card from '../Card';
import { StyledBox, StyledColumn } from '../../common/styledCommonComponents';
import { useGetMedicationQuery } from '../../redux/api/treatmentApi';
import { CheckableResult } from './components/CheckableResult';
import { useDispatch } from 'react-redux';
import { addMedication, removeMedication } from '../../redux/slices/homeSlice';
import { useOncoSelector } from '../../hooks/redux-hook';
import { useTheme } from 'styled-components';
import StyledText from '../../common/components/Text';
import { StyledSearchMedication } from './styles';

export const FilterCard = ({ ref }: { ref: any }) => {
	const [search, setSearch] = useState<string>('');
	const dispatch = useDispatch();
	const theme = useTheme();
	const { medicationsFilter } = useOncoSelector(
		(state: any) => state.homeSlice,
	);

	const { data: medicationOptions } = useGetMedicationQuery('', {
		selectFromResult: (response: any) => {
			let aux: any = {};
			response?.data?.forEach(({ id, name }: any) => (aux[id] = name));
			return { response, data: aux };
		},
	});

	const filteredOptions = useMemo(() => {
		if (search === '') {
			return medicationOptions;
		}
		let newOptions: Record<string, string> = {};
		Object.keys(medicationOptions).forEach((key) => {
			if (
				medicationOptions[key].toLowerCase().includes(search.toLowerCase())
			) {
				newOptions[key] = medicationOptions[key];
			}
		});
		if (Object.keys(newOptions).length === 0) {
			newOptions.no_results = 'No se encontraron resultados';
		}
		return newOptions;
	}, [search, medicationOptions]);

	function handleCheck(checked: any, medicationName: any) {
		checked
			? dispatch(addMedication(medicationName))
			: dispatch(removeMedication(medicationName));
	}

	return (
		<StyledBox
			ref={ref}
			style={{ display: 'inline-block', position: 'relative' }}
		>
			<StyledBox
				style={{
					position: 'absolute',
					top: -276,
					left: 883,
					zIndex: 9,
					marginLeft: 10,
				}}
			>
				<Card
					width={'185px'}
					height={'271px'}
					headerComponent={
						<StyledSearchMedication
							placeholder="Buscar..."
							onChange={(e) => setSearch(e.target.value)}
						/>
					}
					css={{
						display: 'inline-flex',
						gap: '4px',
						padding: '6px',
						alignItems: 'flex-start',
						borderRadius: '10px',
						backgroundColor: 'white',
						boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
					}}
				>
					{filteredOptions && (
						<StyledColumn
							css={{
								gap: '4px',
								alignItems: 'flex-start',
								overflow: 'auto',
							}}
						>
							{Object.keys(filteredOptions).map((med: any) => {
								if (med === 'no_results') {
									return (
										<StyledBox css={{ padding: '8px 16px' }}>
											<StyledText
												variant="body3"
												css={{ color: theme.gray700 }}
											>
												{filteredOptions[med]}
											</StyledText>
										</StyledBox>
									);
								} else {
									return (
										<CheckableResult
											name={filteredOptions[med]}
											checked={medicationsFilter.includes(
												filteredOptions[med],
											)}
											onClick={handleCheck}
										/>
									);
								}
							})}
						</StyledColumn>
					)}
				</Card>
			</StyledBox>
		</StyledBox>
	);
};
