import React from 'react';
import { StyledBox } from '../../common/styledCommonComponents';
import Button from '../../common/components/Button';
import { useAuth0 } from '@auth0/auth0-react';

function SettingScreen() {
	const { logout } = useAuth0();

	return (
		<StyledBox
			css={{
				textAlign: 'center',
				fontSize: '23px',
				padding: '100px',
			}}
		>
			{' '}
			Settings
			<Button
				onClick={() => {
					logout({
						logoutParams: {
							returnTo: window.location.origin + '/login',
						},
					});
				}}
				text="Cerrar sesión"
			/>
		</StyledBox>
	);
}

export default SettingScreen;
