import React from 'react';
import { AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { StyledCustomAccordion } from './styles';
import {
	StyledColumn,
	StyledGrid,
	StyledP,
	StyledRow,
} from '../../../../../../common/styledCommonComponents';
import { DefaultTheme, useTheme } from 'styled-components';
import {
	CapitalizeText,
	CategoryList,
	getIconByCategory,
	parseDataWithYear,
	parseNewDate,
} from '../../../../../../utils/utils';
import StyledText from '../../../../../../common/components/Text';
import Moment from 'react-moment';
import { useOncoSelector } from '../../../../../../hooks/redux-hook';
import { PatientStatus } from '../../../../../PatientProfileCard/components/PatientStatus';
import AlertIcon from '../../../../../../assets/AlertIcon';
import CircleCheckIcon from '../../../../../../assets/CircleCheckIcon';
import ReactTooltip from 'react-tooltip';
import QuestionIcon from '../../../../../../assets/QuestionIcon';

interface CustomAccordionWeeklyProps {
	category: CategoryList;
	symptoms: any[];
	endDate?: string | Date;
	id: string;
}

const urgentAnswers = [
	'Severo',
	'Muy severo',
	'Si',
	'Bastante',
	'Mucho',
	'Muy intensa',
	'Intensa',
	'Casi siempre',
	'Siempre',
];

// Cariologico, dolor o respiratorio

const CustomAccordionWeekly = ({
	category,
	symptoms,
	id,
}: CustomAccordionWeeklyProps) => {
	const theme = useTheme() as DefaultTheme;
	const categoryId = useOncoSelector((state) => state.utilsSlice.categoryId);

	function getLastDate(symptoms: any) {
		let dates = symptoms
			.map((symptom: any) =>
				symptom.questions.map((question: any) => new Date(question.date)),
			)
			.flat();
		if (dates.length === 0) {
			return undefined;
		}
		let maxDate = dates.reduce(
			(max: any, current: any) => (current > max ? current : max),
			new Date(0),
		);
		return maxDate;
	}

	return (
		<StyledCustomAccordion defaultExpanded={categoryId === id}>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls={`panel${category}-content`}
				id={`panel${category}-header`}
				style={{ padding: 0 }}
			>
				<StyledRow
					css={{
						justifyContent: 'space-between',
						alignItems: 'center',
						width: '100%',
						paddingRight: 8,
					}}
				>
					<StyledRow>
						{getIconByCategory(category)}
						<StyledColumn
							css={{
								justifyContent: 'center',
								marginLeft: 18,
							}}
						>
							<StyledText variant="body1Bold">{category}</StyledText>
							{symptoms.every((symp) =>
								symp.questions.every((ans: any) => ans.value),
							) && (
								<StyledText
									variant="body2"
									css={{
										color: theme.oncoGrey4,
									}}
								>
									Completado{' '}
									{parseDataWithYear(getLastDate(symptoms).toString())}
								</StyledText>
							)}
						</StyledColumn>
					</StyledRow>
					{symptoms.find((symp) =>
						symp.questions.find((ans: any) =>
							urgentAnswers.includes(ans.value),
						),
					) && PatientStatus('Requiere atención')}
				</StyledRow>
			</AccordionSummary>
			<AccordionDetails
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: 24,
				}}
			>
				{symptoms.map((symp) => {
					const requiresAttention = symp.questions.find((ans: any) =>
						urgentAnswers.includes(ans.value),
					);
					return (
						<StyledColumn
							css={{
								padding: '0px 48px',
								alignItems: 'flex-start',
								gap: '16px',
								alignSelf: 'stretch',
							}}
						>
							<StyledRow css={{ gap: 4 }}>
								{requiresAttention && (
									<AlertIcon
										width={20}
										height={20}
										color={theme.error400}
									/>
								)}
								<StyledText
									variant="body1Bold"
									css={{ color: requiresAttention && theme.error400 }}
								>
									{CapitalizeText(symp.name)}{' '}
								</StyledText>
							</StyledRow>

							<StyledColumn
								css={{
									padding: 32,
									alignItems: 'flex-start',
									gap: 24,
									display: 'flex',
									flexDirection: 'column',
									alignSelf: 'stretch',
									borderRadius: 20,
									background: `${theme.gray50}`,
									outlineWidth: requiresAttention ? 1 : 0,
									outlineColor: theme.error400,
									outlineStyle: 'solid',
								}}
							>
								{symp.questions.map((ans: any) => (
									<StyledGrid
										css={{
											gridTemplateColumns: '126px auto 250px',
											gridColumnGap: 16,
											width: '100%',
										}}
									>
										<>
											<StyledP
												css={{
													fontSize: 16,
													fontWeight: 400,
													color: '#A5A5A5',
												}}
											>
												{ans.date ? (
													<Moment format="DD/MM/YY">
														{parseNewDate(ans.date)}
													</Moment>
												) : (
													'-'
												)}
											</StyledP>
											<StyledRow
												css={{ gap: 4, alignItems: 'center' }}
											>
												<StyledP
													css={{
														fontWeight: 500,
														fontSize: '16px',
													}}
												>
													{CapitalizeText(ans.type)}
												</StyledP>
												<QuestionIcon
													color={theme.gray400}
													width={16}
													height={16}
													data-tip
													data-for={
														'form-icon-' +
														symp.name +
														'-' +
														ans.questionId
													}
												/>
												<ReactTooltip
													id={
														'form-icon-' +
														symp.name +
														'-' +
														ans.questionId
													}
													place="top"
													effect="solid"
													delayShow={1000}
												>
													<div
														dangerouslySetInnerHTML={{
															__html: ans.question,
														}}
														style={{
															maxWidth: 300,
															textAlign: 'center',
														}}
													/>
												</ReactTooltip>
											</StyledRow>

											<StyledP
												css={{
													fontSize: '16px',
													color: theme.OncoPurple,
													textAlign: 'right',
													width: 250,
												}}
											>
												{ans.value ?? 'No hay respuesta registrada'}
											</StyledP>
										</>
									</StyledGrid>
								))}
							</StyledColumn>
						</StyledColumn>
					);
				})}
			</AccordionDetails>
		</StyledCustomAccordion>
	);
};

export default CustomAccordionWeekly;
