import React from 'react';
import { IconProps } from '../IconInterface';

const CircularDigestiveIcon = ({
	color = '#000000',
	width = 30,
	height = 30,
}: IconProps) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 30 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g id="Group 27542">
				<circle id="Ellipse 145" cx="15" cy="15" r="15" fill="#E2EAFF" />
				<g id="Group 344">
					<path
						id="Vector 11"
						d="M10.3089 22.4141H7.82614L7.27441 19.9313V17.1727L8.65372 15.2417H10.5848L13.0675 15.7934L14.171 15.2417L15.2744 14.4141L15.8261 13.5865V12.7589L14.9986 11.6555L14.4468 10.552L14.171 8.89685L14.4468 6.96582H17.2054L16.9296 8.89685L17.2054 10.552L17.4813 10.8279L18.033 11.3796H18.8606L19.6882 10.8279L20.7917 11.1038L21.8951 11.6555L22.7227 12.4831L22.9986 13.8624L22.7227 15.2417L21.6192 18.2762L20.2399 19.6555L18.3089 21.0348L15.5503 21.5865L13.3434 21.0348L10.8606 18.0003L10.033 18.2762C9.37097 18.4969 9.75717 21.3106 10.3089 22.4141Z"
						fill="#5784F7"
					/>
					<g id="Group">
						<path
							id="Vector"
							d="M14.5881 6.96582C14.1299 8.77896 14.1299 10.1258 14.5881 11.006C15.2754 12.326 16.8672 13.0557 14.8176 14.8164C12.768 16.5775 10.6901 15.0997 9.40702 15.0997C8.12434 15.0997 7 16.5685 7 18.7553C7 20.2131 7.26389 21.4327 7.79088 22.4141"
							stroke="#E2EAFF"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
						<path
							id="Vector_2"
							d="M17.3756 6.96582C16.7989 9.13224 16.9302 10.5063 17.7698 11.0881C19.0295 11.9601 19.126 10.1384 21.4402 11.2222C23.7544 12.306 23.1529 16.0848 21.8215 18.03C20.4906 19.9749 17.6489 21.9405 14.9841 21.601C12.3194 21.2616 11.3285 17.9504 10.9856 17.9504C10.6426 17.9504 9.91791 17.9605 9.77625 19.3314C9.6818 20.2452 9.95032 21.273 10.5806 22.4141"
							stroke="#E2EAFF"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
						<path
							id="Vector_3"
							d="M19.4444 16.418C19.2372 17.0317 18.8869 17.5106 18.394 17.8555C17.9011 18.2004 17.2755 18.4033 16.5166 18.4649"
							stroke="#E2EAFF"
							stroke-width="2"
							stroke-linecap="round"
						/>
					</g>
				</g>
			</g>
		</svg>
	);
};
export default CircularDigestiveIcon;
