import React from 'react';
import { IconProps } from './IconInterface';

const ReloadIcon = ({ color = '#000000', width = 24, height = 24 }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			fill="none"
			viewBox="0 0 24 24"
		>
			<path
				stroke={color}
				stroke-linecap="round"
				stroke-linejoin="round"
				stroke-width="1.5"
				d="M19.933 13.041a8 8 0 1 1-9.925-8.788c3.9-1 7.935 1.007 9.425 4.747"
			/>
			<path
				stroke={color}
				stroke-linecap="round"
				stroke-linejoin="round"
				stroke-width="1.5"
				d="M20 4v5h-5"
			/>
		</svg>
	);
};
export default ReloadIcon;
