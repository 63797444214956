import React from 'react';
import { IconProps } from './IconInterface';

const ColorIcon = ({ color }: IconProps) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="10"
			height="10"
			viewBox="0 0 10 10"
			fill="none"
		>
			<rect width="10" height="10" rx="5" fill={color} />
		</svg>
	);
};

export default ColorIcon;
