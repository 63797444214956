import React from 'react';
const IconLove = (props) => {
	return (
		<svg
			width="30"
			height="30"
			viewBox="0 0 30 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle cx="15" cy="15" r="15" fill="#F0E8FF" />
			<path
				d="M9.23424 10.1722C9.57223 9.81949 9.97353 9.53966 10.4152 9.34875C10.8569 9.15783 11.3303 9.05957 11.8084 9.05957C12.2865 9.05957 12.7599 9.15783 13.2016 9.34875C13.6433 9.53966 14.0446 9.81949 14.3826 10.1722L15.084 10.904L15.7854 10.1722C16.4681 9.46004 17.3941 9.05994 18.3596 9.05994C19.3251 9.05994 20.251 9.46004 20.9337 10.1722C21.6165 10.8844 22 11.8504 22 12.8575C22 13.8647 21.6165 14.8307 20.9337 15.5429L20.2323 16.2746L15.084 21.6452L9.93569 16.2746L9.23424 15.5429C8.8961 15.1903 8.62786 14.7716 8.44485 14.3109C8.26184 13.8501 8.16764 13.3563 8.16764 12.8575C8.16764 12.3588 8.26184 11.8649 8.44485 11.4042C8.62786 10.9434 8.8961 10.5248 9.23424 10.1722Z"
				fill="#AC7AFF"
			/>
		</svg>
	);
};

export default IconLove;
