import React, { useState, useEffect } from 'react';
import { StyledRow } from '../../common/styledCommonComponents';
import { RoundCheckbox } from '../RoundCheckbox';
import { daysMap } from './utils';
import { useOncoDispatch } from '../../hooks/redux-hook';
import {
	updateDailyConfiguration,
	updateWeeklyConfiguration,
	setDisable,
} from '../../redux/slices/utilsSlice';

interface WeekFrequencyProps {
	data: string[];
	type: 'daily' | 'weekly';
}

const WeekFrequency: React.FC<WeekFrequencyProps> = ({ data, type }) => {
	const dispatch: any = useOncoDispatch();

	const initialCheckedDays: Record<string, boolean> = {
		MONDAY: false,
		TUESDAY: false,
		WEDNESDAY: false,
		THURSDAY: false,
		FRIDAY: false,
		SATURDAY: false,
		SUNDAY: false,
	};
	const [checkedState, setCheckedState] = useState(initialCheckedDays);

	const getNextTwoDays = (day: string) => {
		const days = [
			'MONDAY',
			'TUESDAY',
			'WEDNESDAY',
			'THURSDAY',
			'FRIDAY',
			'SATURDAY',
			'SUNDAY',
		];
		const index = days.indexOf(day);
		return [days[(index + 1) % 7], days[(index + 2) % 7]];
	};

	useEffect(() => {
		const updatedCheckedDays = { ...initialCheckedDays };

		for (const day of data) {
			updatedCheckedDays[day] = true;
		}

		setCheckedState(updatedCheckedDays);
	}, [data]);

	const handleCheckboxClick = (day: string) => {
		const [nextDay1, nextDay2] = getNextTwoDays(day);

		setCheckedState((prevState) => {
			const isDayChecked = prevState[day];

			if (isDayChecked) {
				return { ...prevState, [day]: false };
			} else {
				return {
					...prevState,
					[day]: true,
					[nextDay1]: true,
					[nextDay2]: true,
				};
			}
		});

		if (type === 'daily') {
			dispatch(updateDailyConfiguration(day));
		} else {
			dispatch(updateWeeklyConfiguration(day));
			if (!checkedState[day]) {
				if (!checkedState[nextDay1]) {
					dispatch(updateWeeklyConfiguration(nextDay1));
				}
				if (!checkedState[nextDay2]) {
					dispatch(updateWeeklyConfiguration(nextDay2));
				}
			}
		}
		dispatch(setDisable(false));
	};

	return (
		<StyledRow
			css={{
				alignItems: 'flex-start',
				columnGap: '4px',
			}}
		>
			{Object.entries(checkedState).map(([day, checked]) => (
				<RoundCheckbox
					key={day}
					checked={checked}
					text={daysMap[day]}
					onClick={() => handleCheckboxClick(day)}
				/>
			))}
		</StyledRow>
	);
};

export default WeekFrequency;
