import React from 'react';
import { StyledBox } from '../../common/styledCommonComponents';
import Button from '../../common/components/Button';
import CheckIcon from '../../assets/CheckIcon';
import { toastError, toastSuccess } from './utils';
import CloseIcon from '../../assets/CloseIcon';
import { theme } from '../../common/theme';
import StyledText from '../../common/components/Text';
import {
	useValidateDoctorMutation,
	useDenyDoctorMutation,
} from '../../redux/api/patientApi';

export const ConfirmationButton = (props: any) => {
	const [validateDoctor, { isLoading: loadingValidate }] =
		useValidateDoctorMutation();
	const [denyDoctor, { isLoading: loadingDeny }] = useDenyDoctorMutation();

	const handleAproveDoctor = () => {
		validateDoctor(props.doctorId)
			.then((res: any) => {
				if (res?.error) {
					toastError(
						<>
							<StyledText variant="body1Bold">
								Algo salió mal{' '}
							</StyledText>{' '}
							<StyledText variant="body2">
								Intente de nuevo mas tarde
							</StyledText>
						</>,
					);
				} else {
					toastSuccess(
						<StyledText variant="body2">
							Usuario aceptado exitosamente
						</StyledText>,
					);
				}
			})
			.catch(() => {
				toastError(
					<>
						<StyledText variant="body1Bold">Algo salió mal </StyledText>{' '}
						<StyledText variant="body2">
							Intente de nuevo mas tarde
						</StyledText>
					</>,
				);
			});
	};

	const handleDenyDoctor = () => {
		denyDoctor(props.doctorId)
			.then((res: any) => {
				if (res?.error) {
					toastError(
						<>
							<StyledText variant="body1Bold">
								Algo salió mal{' '}
							</StyledText>{' '}
							<StyledText variant="body2">
								Intente de nuevo mas tarde
							</StyledText>
						</>,
					);
				} else {
					toastSuccess(
						<StyledText variant="body2">
							Usuario rechazado exitosamente
						</StyledText>,
					);
				}
			})
			.catch(() => {
				toastError(
					<>
						<StyledText variant="body1Bold"> Algo salió mal </StyledText>{' '}
						<StyledText variant="body2">
							Intente de nuevo mas tarde
						</StyledText>
					</>,
				);
			});
	};

	return (
		<StyledBox
			css={{
				display: 'flex',
				gap: '8px',
				justifyContent: 'flex-end',
				paddingRight: '16px',
			}}
		>
			<Button
				variant="ghost"
				onClick={handleDenyDoctor}
				css={{ width: '52px', height: '54px' }}
				disabled={loadingValidate || loadingDeny}
			>
				<CloseIcon color={theme.error400} />
			</Button>
			<Button
				variant="ghost"
				onClick={handleAproveDoctor}
				css={{ width: '52px', height: '54px' }}
				disabled={loadingValidate || loadingDeny}
			>
				<CheckIcon color={theme.success500} />
			</Button>
		</StyledBox>
	);
};
