const primaryColor = '#000';
const accentColor = '#6C63FF';
const textColor = 'white';
const inactiveColor = '#DDDDDD';
const errorColor = 'red';
const focusableColor = '#E4E4E4';
const itemBackgroundColor = '#F4F4F4';

export const theme = {
	primary: primaryColor,
	accent: accentColor,
	text: textColor,
	inactive: inactiveColor,
	error: errorColor,
	focusable: focusableColor,
	itemBackground: itemBackgroundColor,
	oncoPurple100: '#F9F1FF',
	OncoPurple: '#AF7EFF',
	oncoPurple600: '#804BE1',
	OncoLightPurple: '#E1D1FC',
	white: '#FFFFFF',
	oncoGrey: '#F8F9FA',
	oncoBlack: '#5F5F5F',
	oncoGrey100: '#F4F4F4',
	oncoGrey300: '#CBCFD3',
	oncoGrey500: '#A0A8AE',
	oncoGrey2: '#A3A3A3',
	oncoGrey3: '#e5e5e5',
	oncoGrey4: '#757575',
	oncoRed50: '#FFF7F6',
	oncoRed100: '#FAE0DE',
	oncoRed: '#FF505F',

	oncoLightBlue: '#DFE8FF',
	oncoLightBlue2: '#CDDCFF',
	oncoBlue: '#5784F7',
	oncoBlueTitle: '#304884',
	oncoBlueSubtitle: 'rgba(48, 72, 132, 0.4)',

	oncoLightPurple: '#ECE1FD',
	oncoLightPurple2: '#E1CEFF',
	oncoLightPurple3: '#C39EFF',
	oncoPurpleTitle: '#5D2BAF',
	oncoPurpleSubtitle: 'rgba(93, 43, 175, 0.4)',

	oncoLightOrange: '#FCEFEA',
	oncoLightOrange4: '#F9E0D6',
	oncoLightOrange2: 'rgba(236, 164, 133, 0.2)',
	oncoOrange: '#ECA485',
	oncoOrangeTitle: '#7E4228',
	oncoOrangeSubtitle: 'rgba(126, 66, 40, 0.4)',
	oncoLightOrange3: 'rgba(234,128,83,0.3)',

	oncoPink: '#CD7DE3',
	oncoLightPink: '#F6EAFA',
	oncoLightPink2: 'rgba(205, 125, 227, 0.2)',
	oncoPinkTitle: '#76288B',
	oncoPinkSubtitle: '#785582',

	oncoGreen: '#6AD18D',
	oncoLightGreen: 'rgba(220, 245, 229, 1)',
	oncoLightGreen2: 'rgba(106, 209, 141, 0.2)',
	oncoGreenTitle: '#1D6535',
	oncoGreenSubtitle: '#476651',

	oncoDarkGrey: '#555555',
	oncoNavbarSelection: 'rgba(175, 126, 255, 0.08)',
	calendarPurple: '#9357F7',
	calendarGrey: '#949494',

	patientListPillNegative: 'rgba(247, 97, 87, 0.42)',
	patientListPillPositive: 'rgba(100, 201, 140, 0.42)',

	textGrey: '#949494',

	typographyVariant: {
		h1: {
			fontSize: '56px',
			fontWeight: 400,
		},
		h2: {
			fontSize: '38px',
			fontWeight: 400,
		},
		h3: {
			fontSize: '32px',
			fontWeight: 400,
		},
		h4: {
			fontSize: '28px',
			fontWeight: 400,
		},
		h5: {
			fontSize: '24px',
			fontWeight: 500,
		},
		h6: {
			fontSize: '20px',
			fontWeight: 500,
		},
		body1: {
			fontSize: '16px',
			fontWeight: 400,
		},
		body1SemiBold: {
			fontSize: '16px',
			fontWeight: 500,
		},
		body1Bold: {
			fontSize: '16px',
			fontWeight: 600,
		},
		body2: {
			fontSize: '14px',
			fontWeight: 400,
		},
		body2Bold: {
			fontSize: '14px',
			fontWeight: 600,
		},
		body3: {
			fontSize: '12px',
			fontWeight: 400,
		},
		body3Bold: {
			fontSize: '12px',
			fontWeight: 600,
		},
		body4: {
			fontSize: '10px',
			fontWeight: 400,
		},
		body4Bold: {
			fontSize: '10px',
			fontWeight: 600,
		},
		body5: {
			fontSize: '8px',
			fontWeight: 400,
		},
		body5Bold: {
			fontSize: '8px',
			fontWeight: 600,
		},
		helperText: {
			fontSize: '12px',
			fontWeight: 400,
		},
		buttonSmall: {
			fontSize: '12px',
			fontWeight: 500,
		},
		buttonMedium: {
			fontSize: '14px',
			fontWeight: 500,
		},
		buttonLarge: {
			fontSize: '16px',
			fontWeight: 500,
		},
		filterTag: {
			fontSize: '10px',
			fontWeight: 400,
		},
	},

	/// NEW COLORS

	// Primary

	primary100: '#F9F1FF',
	primary200: '#E0CBFD',
	primary300: '#C6A4FB',
	primary400: '#AD7EF9',
	primary500: '#9357F7',
	primary600: '#804BE1',
	primary700: '#48259D',
	primary800: '#220C70',
	primary900: '#0F005A',

	// Gray

	gray50: '#F6F6F6',
	gray100: '#E0E1E3',
	gray200: '#CBCFD3',
	gray300: '#B7BCC2',
	gray400: '#A0A8AE',
	gray500: '#89939B',
	gray600: '#727F87',
	gray700: '#5C6B74',
	gray800: '#455660',
	gray850: '#2E424C',
	gray900: '#172D39',

	// Black

	black: '#171717',

	// Red

	error50: '#FFF7F6',
	error100: '#FAE0DE',
	error300: '#F7CCC8',
	error400: '#EC7F74',
	error700: '#88271D',

	// Orange

	orange200: '#FEE6DD',
	orange400: '#FFD6C2',
	orange500: '#ECA485',
	orange600: '#C78665',

	// Warning

	warning100: '#FFF3D6',
	warning300: '#F7DCAC',
	warning400: '#F3D097',
	warning500: '#EFC482',
	warning700: '#4A2B14',

	// Success
	success100: '#D7F4E1',
	success200: '#BDEBCD',
	success500: '#51C075',
	success700: '#0F411F',

	// Info

	info100: '#F1F6FF',
	info300: '#BACBF9',
	info400: '#9CB0F7',
	info500: '#294DEC',
	info700: '#0D279B',

	// Blue

	blue400: '#BFCFFF',
	blue500: '#6989F7',
	blue600: '#4063D6',

	// Green
	green200: '#ABEDB8',
	green400: '#8BD299',
	green500: '#52BF7E',
	green600: '#70BD83',

	// Pink
	pink200: '#E8CAF5',
	pink400: '#DDA7F5',
	pink500: '#D18EEB',
	pink600: '#C180DE',
};

export type MyTheme = typeof theme;
