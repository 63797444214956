import styled, {
	css as styledComponentCss,
	CSSObject,
	DefaultTheme,
} from 'styled-components';
import { CustomCssProps } from '../../styledCommonComponents';
import { ButtonSize, ButtonVariant } from './index';

type ButtonStatus = 'disabled' | 'hover';

export const ButtonVariantStyles = (
	theme: DefaultTheme,
): {
	[key in ButtonVariant]: CSSObject & { [key in ButtonStatus]: CSSObject };
} => ({
	filled: {
		backgroundColor: theme.primary500,
		color: theme.white,
		hover: {
			backgroundColor: theme.oncoPurple600,
			color: theme.white,
		},
		disabled: {
			backgroundColor: theme.gray50,
			color: theme.gray400,
			cursor: 'not-allowed',
		},
	},
	outlined: {
		backgroundColor: theme.white,
		color: theme.primary500,
		border: `1px solid ${theme.primary500}`,
		hover: {
			backgroundColor: theme.primary100,
			color: theme.primary500,
			border: `1px solid ${theme.primary500}`,
		},
		disabled: {
			backgroundColor: theme.oncoGrey100,
			color: theme.oncoGrey500,
			border: `1px solid ${theme.oncoGrey500}`,
			cursor: 'not-allowed',
		},
	},
	ghost: {
		backgroundColor: 'transparent',
		color: theme.primary500,
		hover: {
			backgroundColor: theme.oncoPurple100,
			color: theme.primary500,
		},
		disabled: {
			backgroundColor: theme.oncoGrey100,
			color: theme.oncoGrey500,
			cursor: 'not-allowed',
		},
	},
	white: {
		backgroundColor: theme.white,
		color: theme.primary500,
		border: `1px solid ${theme.primary500}`,
		hover: {
			backgroundColor: theme.oncoPurple100,
			color: theme.oncoGrey500,
		},
		disabled: {
			backgroundColor: theme.oncoGrey100,
			color: theme.oncoGrey500,
			cursor: 'not-allowed',
		},
	},
	destructive: {
		backgroundColor: theme.oncoRed50,
		color: theme.oncoRed,
		border: `1px solid ${theme.oncoRed}`,
		hover: {
			backgroundColor: theme.oncoRed100,
			color: theme.oncoRed,
		},
		disabled: {
			backgroundColor: theme.oncoGrey100,
			color: theme.oncoGrey500,
			border: `1px solid ${theme.oncoGrey500}`,
			cursor: 'not-allowed',
		},
	},
});

const ButtonSizeStyles = {
	small: {
		padding: '6px 10px',
	},
	medium: {
		padding: '10px 12px',
	},
	large: {
		padding: '16px',
	},
};

interface ButtonContainerProps extends CustomCssProps {
	disabled?: boolean;
	variant?: ButtonVariant;
	size?: ButtonSize;
}

export const StyledButton = styled('button')<ButtonContainerProps>`
	width: fit-content;
	padding: 6px 32px;
	border-radius: 12px;
	cursor: pointer;
	border-style: none;
	box-sizing: border-box;
	display: flex;
	align-items: center;

	&.inactive {
		background-color: ${(props) => props.theme.inactive};
	}

	&.rejected {
		width: 100%;
		color: ${(props) => props.theme.white};
		background-color: ${(props) => props.theme.error400};
		border-radius: 12px;
		span {
			font-size: 16px;
			padding-left: 1rem;
			padding-right: 1rem;
		}
		&:hover {
			background-color: ${(props) => props.theme.error400};
			span {
				color: ${(props) => props.theme.white} !important;
			}
			> div > svg > path {
				fill: ${(props) => props.theme.white};
			}
		}
	}

	&.submit {
		width: 100%;
		color: ${(props) => props.theme.white};
		background-color: ${(props) => props.theme.primary500};
		border-radius: 12px;

		span {
			font-size: 16px;
			padding-left: 1rem;
			padding-right: 1rem;
		}
		&.disabled {
			background-color: ${(props) => props.theme.oncoGrey2};
			color: ${(props) => props.theme.white};
			cursor: not-allowed;
		}
	}

	&.disabled {
		background-color: ${(props) => props.theme.oncoGrey2};
		color: ${(props) => props.theme.white};
		cursor: not-allowed;
	}

	&.action {
		width: 100%;
		color: ${(props) => props.theme.OncoPurple};
		background-color: ${(props) => props.theme.white};
		border: 1px solid ${(props) => props.theme.OncoPurple};
		border-radius: 12px;

		span {
			color: ${(props) => props.theme.OncoPurple};
			font-size: 18px;
		}

		&:hover {
			background-color: ${(props) => props.theme.OncoPurple};
			span {
				color: ${(props) => props.theme.white} !important;
			}
			> div > svg > path {
				fill: ${(props) => props.theme.white};
			}
		}
	}

	${(props) => ButtonVariantStyles(props.theme)[props.variant || 'filled']};
	${(props) => ButtonSizeStyles[props.size || 'medium']};
	${(props) =>
		props.disabled &&
		ButtonVariantStyles(props.theme)[props.variant || 'filled'].disabled};
	&:hover {
		${(props) =>
			!props.disabled &&
			ButtonVariantStyles(props.theme)[props.variant || 'filled'].hover};
	}
	${(props) =>
		props.disabled ? { cursor: 'not-allowed', pointerEvents: 'none' } : ''}
	${(props) => styledComponentCss`${props.css}`}
`;
