import React from 'react';
import { StyledRow } from '../../../../../../../common/styledCommonComponents';
import ColorIcon from '../../../../../../../assets/ColorIcon';
import StyledText from '../../../../../../../common/components/Text';
import { useTheme } from 'styled-components';
import CircleCheckIcon from '../../../../../../../assets/CircleCheckIcon';
import CircleInprogressIcon from '../../../../../../../assets/CircleInprogressIcon';
import CircleCloseIcon from '../../../../../../../assets/CircleCloseIcon';
import _ from 'lodash';
import ReactTooltip from 'react-tooltip';

interface CardHeaderProps {
	patients: any[];
	title: string;
	color: string;
}

const CardHeader = ({ patients, title, color }: CardHeaderProps) => {
	const id = _.random(0, 1000);
	const theme = useTheme();
	return (
		<StyledRow
			css={{ width: '100%', justifyContent: 'space-between', padding: 8 }}
		>
			<StyledRow css={{ alignItems: 'center', gap: 8 }}>
				<ColorIcon color={color} />
				<StyledText variant={'body2'}>{title}</StyledText>
			</StyledRow>
			<StyledRow css={{ gap: 16 }}>
				<StyledRow css={{ gap: 2, alignItems: 'center' }}>
					<CircleCheckIcon
						color={theme.green500}
						data-tip
						data-for={'form-icon-' + id + 1}
					/>
					<ReactTooltip
						id={'form-icon-' + id + 1}
						place="top"
						effect="solid"
						delayShow={1000}
					>
						Encuestas completadas
					</ReactTooltip>
					<StyledText variant={'body2'}>
						{patients.filter(
							(patient) => patient.formStatus === 'COMPLETE',
						).length ?? 0}
					</StyledText>
				</StyledRow>
				<StyledRow css={{ gap: 2, alignItems: 'center' }}>
					<CircleInprogressIcon
						color={theme.gray500}
						data-tip
						data-for={'form-icon-' + id + 2}
					/>
					<ReactTooltip
						id={'form-icon-' + id + 2}
						place="top"
						effect="solid"
						delayShow={1000}
					>
						Encuestas sin arrancar
					</ReactTooltip>
					<StyledText variant={'body2'}>
						{patients.filter(
							(patient) => patient.formStatus === 'INCOMPLETE',
						).length ?? 0}
					</StyledText>
				</StyledRow>
				<StyledRow css={{ gap: 2, alignItems: 'center' }}>
					<CircleCloseIcon
						color={theme.error400}
						data-tip
						data-for={'form-icon-' + id + 3}
					/>
					<ReactTooltip
						id={'form-icon-' + id + 3}
						place="top"
						effect="solid"
						delayShow={1000}
					>
						Encuestas incompletas o en progreso
					</ReactTooltip>
					<StyledText variant={'body2'}>
						{patients.filter((patient) => patient.formStatus === 'EMPTY')
							.length ?? 0}
					</StyledText>
				</StyledRow>
			</StyledRow>
		</StyledRow>
	);
};

export default CardHeader;
