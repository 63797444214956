import React, { useEffect, useState } from 'react';
import { StyledBox, StyledScreen } from '../../common/styledCommonComponents';
import { TableContainer } from '@mui/material';
import {
	useLazyGetAuxiliarAdminQuery,
	useLazyGetAuxiliarQuery,
} from '../../redux/api/listApi';
import TableComponent from '../../components/TableComponent';
import { MyTeamHeader } from './components/MyTeamHeader';
import ResultNotFound from '../../components/ResultNotFound';
import { UserListQueryParams } from '../../utils/types';
import { AuxiliarListHeaderConst } from '../PatientList/config';
import PatientItem from '../PatientList/PatientItem';
import TeamItem from './components/TeamItem';
import { useAuth0 } from '@auth0/auth0-react';

interface MyTeamQueryParams extends UserListQueryParams {}

const MyTeamListScreen = () => {
	const pageSize = 8;

	const [searchValue, setSearchValue] = useState<string>('');
	const [actualPage, setActualPage] = useState<number>(1);
	const [params, setParams] = useState<MyTeamQueryParams>({ pageSize });

	const { user } = useAuth0();
	const roles =
		user?.[(process.env.REACT_APP_AUTH0_AUDIENCE as string) + '/roles'];

	const [
		refetch,
		{
			data: auxiliarData,
			isLoading: auxiliarLoading,
			isError: auxiliarError,
		},
	] = useLazyGetAuxiliarQuery();

	const [
		refetchAdmin,
		{
			data: auxiliarDataAdmin,
			isLoading: auxiliarLoadingAdmin,
			isError: auxiliarErrorAdmin,
		},
	] = useLazyGetAuxiliarAdminQuery();

	useEffect(() => {
		refetch({});
	}, []);

	useEffect(() => {
		if (!searchValue) {
			setParams((prevParams) => {
				const newParams = { ...prevParams };
				delete newParams?.param;
				return newParams;
			});
		} else {
			setActualPage(1);
			setParams((prevParams) => {
				const newParams = { ...prevParams, param: searchValue };
				return newParams;
			});
		}
	}, [searchValue]);

	useEffect(() => {
		setParams((prevParams) => {
			const newParams = {
				...prevParams,
				firstPage: actualPage - 1,
			};
			return newParams;
		});
	}, [actualPage]);

	useEffect(() => {
		roles && !['Admin', 'DoctorAdmin'].find((role) => roles?.includes(role))
			? refetch(params)
			: refetchAdmin(params);
	}, [params, roles]);

	const fetchSort = (field: string, sortingOrder: 'asc' | 'desc') => {
		setActualPage(1);
		setParams((prevParams: any) => {
			const newParams = {
				...prevParams,
				column: field,
				order: sortingOrder,
			};
			return newParams;
		});
	};

	return (
		<>
			<StyledScreen
				css={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
				}}
			>
				<StyledBox
					css={{
						display: 'flex',
						flexDirection: 'column',
						margin: '20px',
						padding: '20px 40px',
						width: '100%',
						boxSizing: 'border-box',
						flex: 1,
					}}
				>
					<StyledBox
						css={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'top,',
							boxSizing: 'border-box',
							width: '100%',
							height: '16px',
						}}
					/>
					<StyledBox
						as={TableContainer}
						css={{
							width: 'calc(100vw - 40px)',
							height: '100%',
							maxWidth: '1124px',
							maxHeight: '824px',
							flex: 1,
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'space-between',
						}}
					>
						{/*
							name
							surname
							rol
							lastSignUp
							 */}
						<TableComponent
							headerComponent={
								<MyTeamHeader handleSearch={setSearchValue} />
							}
							headerStructure={AuxiliarListHeaderConst}
							fetchSort={fetchSort}
							handlePageChange={setActualPage}
							dataList={
								auxiliarDataAdmin?.doctors ?? auxiliarData?.assistants
							}
							itemComponent={TeamItem}
							actualPage={
								auxiliarDataAdmin
									? auxiliarDataAdmin?.actualPage
									: auxiliarData?.actualPage
							}
							totalPages={
								auxiliarDataAdmin
									? auxiliarDataAdmin?.maxPage + 1
									: auxiliarData?.maxPage + 1
							}
							loading={auxiliarLoading}
							emptyComponent={
								<ResultNotFound
									title={'No se han encontrado resultados'}
									text={
										'Agregue nuevos usuarios auxiliares desde el boton superior derecho de esta tabla'
									}
								/>
							}
						/>
					</StyledBox>
				</StyledBox>
			</StyledScreen>
		</>
	);
};
export default MyTeamListScreen;
