import { categoryBiometric } from '../../../../../../../utils/proctcae';
import { useTheme } from 'styled-components';
import { StyledRow } from '../../../../../../../common/styledCommonComponents';
import StyledText from '../../../../../../../common/components/Text';
import TwoPersonIcon from '../../../../../../../assets/TwoPersonIcon';
import React from 'react';
import { BiometricData } from '../../index';
import { CapitalizeText } from '../../../../../../../utils/utils';

interface HeaderBiometricProps {
	data: BiometricData;
}

const HeaderBiometric = ({ data }: HeaderBiometricProps) => {
	const biometricUtils = categoryBiometric(data.type, 18, 18);
	const theme = useTheme();

	return (
		<StyledRow
			css={{
				padding: '8px',
				justifyContent: 'space-between',
				alignItems: 'center',
				alignSelf: 'stretch',
			}}
		>
			<StyledRow
				css={{
					alignItems: 'center',
					gap: '8px',
				}}
			>
				{biometricUtils && biometricUtils.icon}
				<StyledText variant="body2">
					{CapitalizeText(data.symptom)}
				</StyledText>
			</StyledRow>
			<StyledRow
				css={{
					justifyContent: 'flex-end',
					alignItems: 'center',
					gap: '16px',
				}}
			>
				<TwoPersonIcon width={18} height={18} color={theme.gray500} />
				<StyledText variant="body3" css={{ color: theme.gray500 }}>
					{data.patients.length}
				</StyledText>
			</StyledRow>
		</StyledRow>
	);
};

export default HeaderBiometric;
