import React from 'react';
import { IconProps } from './IconInterface';

const PhysicalActivityIcon = ({
	color = '#000000',
	width = 24,
	height = 24,
}: IconProps) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M12.3136 6.05929C13.4221 6.05929 14.3222 5.14212 14.3222 4.07488C14.3222 2.95761 13.4221 2.05713 12.3136 2.05713C11.2052 2.05713 10.3301 2.95761 10.3301 4.07488C10.3301 5.14212 11.2052 6.05929 12.3136 6.05929ZM15.1556 21.9428C15.6306 21.9428 16.0557 21.5927 16.0557 21.009C16.0557 20.7922 15.9973 20.5921 15.8807 20.3587L14.0972 16.74C13.9388 16.4149 13.7555 16.123 13.5888 15.8896L12.497 14.3387L12.5637 14.1303C12.8637 13.1714 12.9804 12.5878 13.047 11.6706L13.2304 9.09425C13.3221 7.8269 12.5887 6.79301 11.2635 6.79301C10.2968 6.79301 9.59672 7.3183 8.69663 8.19377L7.32983 9.55283C6.86312 10.0114 6.71311 10.3866 6.67143 10.9703L6.49642 13.0881C6.45475 13.6634 6.77978 14.0386 7.2965 14.0719C7.78821 14.1053 8.15491 13.8135 8.21325 13.1965L8.40494 10.8785L9.21335 10.1615C9.36336 10.0281 9.58005 10.1031 9.56338 10.3283L9.41337 12.4127C9.33836 13.4216 9.78007 14.1553 10.2801 14.7807L12.0636 17.0235C12.2386 17.2403 12.2636 17.3237 12.347 17.4821L14.2472 21.3425C14.4722 21.7928 14.8056 21.9428 15.1556 21.9428ZM15.5473 12.0959H17.5642C18.1475 12.0959 18.5142 11.754 18.5142 11.2371C18.5142 10.7201 18.1392 10.3783 17.5558 10.3783H15.4473L13.9805 8.78575L13.8221 11.0787L14.4472 11.704C14.7472 12.0042 15.0306 12.0959 15.5473 12.0959ZM7.07147 21.9428C7.36317 21.9428 7.62153 21.8094 7.80488 21.5927L10.4885 18.3993C10.7635 18.0658 10.7968 17.9824 10.9135 17.6572C10.9802 17.4404 11.0385 17.232 11.0885 17.0319L9.55505 15.1142L9.105 17.207L6.56309 20.2253C6.32974 20.4921 6.17139 20.7255 6.17139 21.059C6.17139 21.576 6.56309 21.9428 7.07147 21.9428Z"
				fill={color}
			/>
		</svg>
	);
};

export default PhysicalActivityIcon;
