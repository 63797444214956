import React, { ChangeEvent, useState } from 'react';
import { StyledBox, StyledRow } from '../../styledCommonComponents';
import StyledText from '../Text';
import { useTheme } from 'styled-components';

interface TextSwitchProps {
	text1: string;
	text2: string;
	checked: boolean;
	onCheck?: any;
	onUncheck?: any;
}

const TextContainer = ({ theme, text }: any) => (
	<StyledBox
		css={{
			zIndex: 10,
			position: 'relative',
			display: 'flex',
			padding: '8px',
			justifyContent: 'center',
			alignItems: 'center',
			gap: '10px',
			flex: '1 0 0',
			alignSelf: 'stretch',
		}}
	>
		<StyledText variant="body3" css={{ color: theme.gray800 }}>
			{text}
		</StyledText>
	</StyledBox>
);
const AnimatedSelector = ({ theme, isChecked }: any) => (
	<StyledBox
		css={{
			position: 'absolute',
			left: isChecked ? '94px' : '0px',
			width: '94px',
			height: '39px',
			borderRadius: '6px',
			backgroundColor: theme.white,
			transition: 'left 0.3s',
		}}
	/>
);

export const TextSwitch = ({
	text1,
	text2,
	checked,
	onCheck,
	onUncheck,
}: TextSwitchProps) => {
	const theme = useTheme();

	const [isChecked, setIsChecked] = useState(checked);

	const handleChange = (e: ChangeEvent<HTMLInputElement>) =>
		setIsChecked(e.target.checked);

	const handleOnClick = () => {
		setIsChecked(!isChecked);
		if (isChecked && onUncheck) {
			onUncheck();
		} else if (onCheck) {
			onCheck();
		}
	};

	return (
		<StyledRow
			onChange={handleChange}
			onClick={handleOnClick}
			css={{
				cursor: 'pointer',
				display: 'flex',
				width: '192px',
				height: '43px',
				padding: '2px',
				alignItems: 'flex-start',
				borderRadius: '8px',
				background: theme.gray100,
				border: `0px solid ${theme.gray100}`,
			}}
		>
			<StyledBox css={{ position: 'relative' }}>
				<AnimatedSelector isChecked={isChecked} theme={theme} />
			</StyledBox>
			<TextContainer text={text1} theme={theme} />
			<TextContainer text={text2} theme={theme} />
		</StyledRow>
	);
};
