import styled, {
	css as styledComponentCss,
	CSSObject,
} from 'styled-components';
import { TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import StyledText from './components/Text/styles';
import { theme } from './theme';

export interface CustomCssProps {
	css?: CSSObject;
}

export const StyledBox = styled.div<CustomCssProps>`
	${(props) => styledComponentCss`${props.css}`}
	&.withBoxShadow {
		box-shadow: 0 4px 24px rgba(214, 203, 252, 0.3);
	}
`;

export const StyledGrid = styled.div<CustomCssProps>`
	${(props) => styledComponentCss`${props.css}`}
	display: grid;
`;

export const StyledColumn = styled(StyledBox)<CustomCssProps>`
	${(props) => styledComponentCss`${props.css}`}
	display: flex;
	flex-direction: column;
`;

export const StyledRow = styled(StyledBox)<CustomCssProps>`
	display: flex;
	flex-direction: row;
	${(props) => styledComponentCss`${props.css}`}
`;

export const StyledTextContainer = styled.div<CustomCssProps>`
	${(props) => styledComponentCss`${props.css}`}
	display: flex;
`;

export const StyledP = styled.p<CustomCssProps>`
	margin: 0;
	color: ${(props) => props.theme.oncoBlack};
	${(props) => styledComponentCss`${props.css}`}
`;

export const StyledSpan = styled.span<CustomCssProps>`
	${(props) => styledComponentCss`${props.css}`}
`;
export const StyledHr = styled.hr<CustomCssProps>`
	${(props) => styledComponentCss`${props.css}`}
`;

export const StyledH1 = styled.h1<CustomCssProps>`
	${(props) => styledComponentCss`${props.css}`}
`;
export const StyledH3 = styled.h3<CustomCssProps>`
	${(props) => styledComponentCss`${props.css}`}
`;

export const StyledH2 = styled.h2<CustomCssProps>`
	${(props) => styledComponentCss`${props.css}`}
`;
export const StyledH4 = styled.h4<CustomCssProps>`
	${(props) => styledComponentCss`${props.css}`}
`;

export const StyledInput = styled.input<CustomCssProps>`
	${(props) => styledComponentCss`${props.css}`}
`;

export const StyledTable = styled.table<CustomCssProps>`
	${(props) => styledComponentCss`${props.css}`}
`;
export const StyledTableBody = styled(TableBody)<CustomCssProps>`
	${(props) => styledComponentCss`${props.css}`}
`;
export const StyledTableHead = styled(TableHead)<CustomCssProps>`
	${(props) => styledComponentCss`${props.css}`}
`;
export const StyledTableHeader = styled(TableHead)<CustomCssProps>`
	${(props) => styledComponentCss`${props.css}`}
`;
export const StyledTableRow = styled(TableRow)<CustomCssProps>`
	${(props) => styledComponentCss`${props.css}`}
`;
export const StyledTableCell = styled(TableCell)<CustomCssProps>`
	padding: 0px 8px !important;
	border-color: ${({ theme }) => theme.gray50} !important;
	${(props) => styledComponentCss`${props.css}`}
`;

export const StyledImg = styled.img<
	CustomCssProps & { firstChild?: CSSObject }
>`
	${(props) => styledComponentCss`${props.css}`}
	&:first-child {
		${(props) => props.firstChild}
	}
`;

/// Custom components

export const StyledScreen = styled(StyledBox)`
	background-color: ${(props) => props.theme.gray50};
	min-height: 100vh;
	overflow-y: auto;
	flex-grow: 1;
	display: flex;
`;

export const StyledPatientsListCard = styled(StyledBox)`
	box-sizing: border-box;
	/* max-width: 306px;
     min-width: 206px;
     flex: 1;	*/
	height: 104px;
	background: #ffffff;
	box-shadow: 0px 4px 24px rgba(214, 203, 252, 0.3);
	border-radius: 15px;
`;
