import * as React from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { StyledBox } from '../../../common/styledCommonComponents';
import { theme } from '../../../common/theme';

const CustomKebab = ({ kebabOptions, row, handleOptionsClick }: any) => {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: any) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const handleOptionClick = (type: string) => {
		let showHandler = null;
		switch (type) {
			case 'Ver encuesta':
				showHandler = handleOptionsClick.find(
					(e: any) => e.type === 'Ver encuesta',
				);
				showHandler.handler(row);
				handleClose();
				break;
			case 'Ver reporte':
				showHandler = handleOptionsClick.find(
					(e: any) => e.type === 'Ver reporte',
				);
				showHandler.handler(row);
				handleClose();
				break;
			default:
			// to do: make the other handler options click
		}
	};
	const ITEM_HEIGHT = 48;

	return (
		<StyledBox>
			<IconButton
				aria-label="more"
				id="long-button"
				aria-controls={open ? 'long-menu' : undefined}
				aria-expanded={open ? 'true' : undefined}
				aria-haspopup="true"
				onClick={handleClick}
				style={{ width: '18px', height: '18px' }}
			>
				<MoreVertIcon />
			</IconButton>
			<Menu
				id="long-menu"
				MenuListProps={{
					'aria-labelledby': 'long-button',
					disablePadding: true,
				}}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'center',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'center',
					horizontal: 'left',
				}}
				style={{ marginLeft: '20px' }}
				open={open}
				onClose={handleClose}
				PaperProps={{
					style: {
						maxHeight: ITEM_HEIGHT * 4.5,
						width: 'min-content',
						borderRadius: '15px',
					},
				}}
			>
				{kebabOptions.map((option: string, index: number) => (
					<StyledBox
						key={'kebab-option-' + index}
						style={{
							display: 'flex',
							border: `1px solid ${theme.gray100}`,
						}}
					>
						<MenuItem
							style={{ padding: '8px 16px' }}
							key={option}
							onClick={() => handleOptionClick(option)}
						>
							{option}
						</MenuItem>
					</StyledBox>
				))}
			</Menu>
		</StyledBox>
	);
};
export default CustomKebab;
