import React from 'react';
import { IconProps } from './IconInterface';

const TwoPersonIcon = ({
	width = 24,
	height = 24,
	color = '#171717',
}: IconProps) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g id="person.2.fill">
				<path
					id="person.2.fill_2"
					d="M13.8254 9.80469C15.4256 9.80469 16.7775 8.3748 16.7775 6.52479C16.7775 4.71901 15.4183 3.33334 13.8254 3.33334C12.2397 3.33334 10.8732 4.74112 10.8805 6.53953C10.8805 8.3748 12.2251 9.80469 13.8254 9.80469ZM5.37815 9.97421C6.77384 9.97421 7.95031 8.72122 7.95031 7.11444C7.95031 5.53714 6.75923 4.33574 5.37815 4.33574C3.99708 4.33574 2.80599 5.55925 2.8133 7.12918C2.8133 8.72122 3.98977 9.97421 5.37815 9.97421ZM1.35185 16.6667H6.8323C6.05042 15.539 6.89076 13.3131 8.5422 12.0011C7.7384 11.4778 6.69346 11.0945 5.37815 11.0945C2.1118 11.0945 0 13.5195 0 15.5169C0 16.2466 0.365364 16.6667 1.35185 16.6667ZM9.27293 16.6593H18.3778C19.5835 16.6593 20 16.2908 20 15.6127C20 13.7185 17.6105 11.1093 13.8254 11.1093C10.0329 11.1093 7.65071 13.7185 7.65071 15.6127C7.65071 16.2908 8.06723 16.6593 9.27293 16.6593Z"
					fill={color}
				/>
			</g>
		</svg>
	);
};

export default TwoPersonIcon;
