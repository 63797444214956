import * as React from 'react';
import { SVGProps } from 'react';

const FeedbackCloseIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={24}
		height={24}
		fill="none"
		{...props}
	>
		<path
			fill="#fff"
			d="m3.765.843 8.234 8.234L20.191.885A1.963 1.963 0 0 1 21.6.267 2.133 2.133 0 0 1 23.733 2.4a1.919 1.919 0 0 1-.576 1.408L14.858 12l8.299 8.299c.351.344.557.81.576 1.3a2.133 2.133 0 0 1-2.134 2.134 1.963 1.963 0 0 1-1.472-.576L12 14.923l-8.213 8.213a1.962 1.962 0 0 1-1.387.597A2.133 2.133 0 0 1 .266 21.6a1.92 1.92 0 0 1 .576-1.408L9.141 12 .842 3.7A1.92 1.92 0 0 1 .266 2.4 2.133 2.133 0 0 1 2.399.267a1.967 1.967 0 0 1 1.366.576Z"
		/>
	</svg>
);

export default FeedbackCloseIcon;
