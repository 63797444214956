import React, { ChangeEvent, useState } from 'react';
import { StyledInput, StyledLabel, StyledSwitch } from './styles';

interface SwitchProps {
	checked: boolean;
	onCheck?: any;
	onUncheck?: any;
}

export const Switch = ({ checked, onCheck, onUncheck }: SwitchProps) => {
	const [isChecked, setIsChecked] = useState(checked);

	const handleChange = (e: ChangeEvent<HTMLInputElement>) =>
		setIsChecked(e.target.checked);

	const handleOnClick = () => {
		return isChecked ? onUncheck() : onCheck(); // callback
	};

	return (
		<StyledLabel>
			<StyledInput
				checked={isChecked}
				type="checkbox"
				onChange={handleChange}
				onClick={handleOnClick}
			/>
			<StyledSwitch />
		</StyledLabel>
	);
};
