import React, { FunctionComponent } from 'react';
import { IconProps } from '../../../../assets/IconInterface';
import { StyledRow } from '../../../../common/styledCommonComponents';
import { StyledH5 } from '../../../../common/components/Text/styles';
import { useTheme } from 'styled-components';

interface HeaderValidationFormProps {
	title: string;
	icon: FunctionComponent<IconProps>;
}

const HeaderValidationForm = ({
	title,
	icon: Icon,
}: HeaderValidationFormProps) => {
	const theme = useTheme();
	return (
		<StyledRow
			css={{
				padding: '24px 32px',
				gap: 16,
				borderBottom: `1px solid ${theme.primary500}`,
			}}
		>
			<Icon color={theme.primary500} width={28} height={28} />
			<StyledH5 css={{ color: theme.primary500 }}>{title}</StyledH5>
		</StyledRow>
	);
};

export default HeaderValidationForm;
