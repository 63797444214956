import React from 'react';
import StyledText from '../../common/components/Text';
import { StyledColumn } from '../../common/styledCommonComponents';
import { useTheme } from 'styled-components';
import { StyledH5 } from '../../common/components/Text/styles';

interface ResultNotFoundProps {
	title: string;
	text: string;
}

const ResultNotFound = ({ title, text }: ResultNotFoundProps) => {
	const theme = useTheme();

	return (
		<StyledColumn
			css={{
				alignItems: 'center',
				gap: '16px',
				textAlign: 'center',
			}}
		>
			<StyledH5 css={{ color: theme.primary500 }}>{title}</StyledH5>
			<StyledText variant="body1" css={{ color: theme.gray500 }}>
				{text}
			</StyledText>
		</StyledColumn>
	);
};

export default ResultNotFound;
